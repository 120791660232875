<div [ngSwitch]="elementEditMode">
    <div *ngSwitchCase="true" class="pp-char-data-element-edit">
        <!-- edit -->
        <div [ngSwitch]="elementType">
            <dropdown-edit-element *ngSwitchCase="'dropdown'" [cmd]="cmd" [charData]="charData" (charDataChange)="charDataChanged($event)"></dropdown-edit-element>
            <date-edit-element *ngSwitchCase="'date'" [cmd]="cmd" [charData]="charData" (charDataChange)="charDataChanged($event)" (touch)="touched($event)"></date-edit-element>
            <text-edit-element *ngSwitchCase="'text'" [cmd]="cmd" [charData]="charData" (charDataChange)="charDataChanged($event)" (touch)="touched($event)"></text-edit-element>
            <file-edit-element *ngSwitchCase="'file'" [cmd]="cmd" [charData]="charData" (charDataChange)="charDataChanged($event)"></file-edit-element>
            <timecode-edit-element *ngSwitchCase="'timecode'" [cmd]="cmd" [charData]="charData" (charDataChange)="charDataChanged($event)" (touch)="touched($event)"></timecode-edit-element>
            <number-edit-element *ngSwitchCase="'number'" [cmd]="cmd" [charData]="charData" (charDataChange)="charDataChanged($event)" (touch)="touched($event)"></number-edit-element>
            <percentage-edit-element *ngSwitchCase="'percentage'" [cmd]="cmd" [charData]="charData" (charDataChange)="charDataChanged($event)" (touch)="touched($event)"></percentage-edit-element>
            <checkbox-edit-element *ngSwitchCase="'checkbox'" [cmd]="cmd" [charData]="charData" (charDataChange)="charDataChanged($event)" (touch)="touched($event)"></checkbox-edit-element>
            <year-edit-element *ngSwitchCase="'year'" [cmd]="cmd" [charData]="charData" (charDataChange)="charDataChanged($event)" (touch)="touched($event)"></year-edit-element>
            <amount-edit-element *ngSwitchCase="'amount'" [cmd]="cmd" [charData]="charData" (charDataChange)="charDataChanged($event)" (touch)="touched($event)"></amount-edit-element>
            <money-edit-element *ngSwitchCase="'money'" [cmd]="cmd" [charData]="charData" (charDataChange)="charDataChanged($event)" (touch)="touched($event)"></money-edit-element>
            <text-display-element *ngSwitchDefault [cmd]="cmd" [charData]="charData"></text-display-element>
        </div>
    </div>
    <div *ngSwitchDefault class="pp-char-data-element-display">
        <!-- display -->
        <div [ngSwitch]="elementType">
            <file-display-element *ngSwitchCase="'file'" [cmd]="cmd" [charData]="charData"></file-display-element>
            <date-display-element *ngSwitchCase="'date'" [cmd]="cmd" [charData]="charData"></date-display-element>
            <money-display-element *ngSwitchCase="'money'" [cmd]="cmd" [charData]="charData"></money-display-element>
            <text-display-element *ngSwitchDefault [cmd]="cmd" [charData]="charData"></text-display-element>
        </div>
    </div>
</div>