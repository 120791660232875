<div class="modal fade" #outer [ngClass]="outerClasses">
    <div class="modal-dialog" *ngIf="isOpen" [style.width]="modalWidth">
        <div class="modal-content">
            <div class="modal-header">
                <ng-content select="modal-title"></ng-content>
                <button type="button" class="btn btn-close" (click)="close()" data-cy="modalCloseButton"><i class="far fa-times"></i></button>
            </div>
            <div class="modal-body">
                <ng-content select="modal-body"></ng-content>
            </div>
            <div *ngIf="showFooter" class="modal-footer">
                <ng-content select="modal-footer"></ng-content>
            </div>
        </div>
    </div>
</div>