import {Component} from "@angular/core";

import {DealsListComponent} from "./deals-list.component";
import {DealsDetailComponent} from "./deals-detail.component";


@Component({
    selector: "deal-panel",
    template: "<router-outlet></router-outlet>"
})
export class DealsPanelComponent {

    constructor() {

    }
}