import { Component, Input, OnInit } from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { IPageChangedEvent } from 'app/common/components/component-pager/component-pager.component';
import * as _ from "lodash";
import { Subscription } from "rxjs";
import { CharTypeDisplay, ExplorerView } from "../../common/consts";
import { IEntitySearchDoc } from "../../common/models";
import { AclService } from "../../common/services/acl.service";
import { ProgressService } from "../../common/services/progress.service";
import { AclUtil } from "../../common/utils/acl.util";
import { IDUtil } from "../../common/utils/id-util";
import { PartnerPortalService } from "../services/partner-portal.service";
import { PPLinkHelperService } from "../services/pp-link-helper.service";

@Component({
	selector: "association-list",
	templateUrl: "./association-list.component.html",
})
export class AssociationListComponent implements OnInit {
	@Input()
	entityId: string;

	@Input()
	associatedCharTypeId: number;

	templateId: 0;
	parentCharTypeID: number;
	parentRecordID: number;
	associatedCharTypeID: number;
	routeSub: Subscription;

	templateFacets: { name: string; id: number; }[];
	searchForm: UntypedFormGroup;

	public documents: IEntitySearchDoc[];
	public jobs: IEntitySearchDoc[];
	public catalogItems: IEntitySearchDoc[];
	public projects: IEntitySearchDoc[];
	public accountingDocs: IEntitySearchDoc[];
	public relatedDocs: IEntitySearchDoc[];
	public documentTitle: string;
	pageCount = 0;
	pageSize = 25;
	page = 1;
	rows = 25;
	canRead: boolean = null;
	numFound = 0;

	keywords: string = "";
	view: ExplorerView = ExplorerView.List;

	public get start(): number {
		return (this.page - 1) * this.pageSize;
	}

	constructor(
		private partnerPortalService: PartnerPortalService,
		private progressService: ProgressService,
		private aclService: AclService,
		private activatedRoute: ActivatedRoute,
		private router: Router,
		private fb: UntypedFormBuilder,
		public linkHelper: PPLinkHelperService) {
		this.searchForm = fb.group({
			"keywords": [this.keywords],
			"template": [this.templateId]
		});
	}

	ngOnInit() {
		this.parentCharTypeID = IDUtil.splitEntityID(this.entityId).charTypeID;
		this.parentRecordID = IDUtil.splitEntityID(this.entityId).recID;
		this.searchAssociatedDocs();
	}

	checkAcls(charTypeID: number) {
		var charTypeACL = AclUtil.getDataEntityAcl(charTypeID);
		if (this.aclService.acls) {
			return AclUtil.hasReadAccess(this.aclService.acls, charTypeACL);
		} else {
			return true;
		}
	}

	searchAssociatedDocs() {
		if (this.checkAcls(this.associatedCharTypeId)) {
			this.getAssociatedRecords();
		}
	}

	getAssociatedRecords() {
		this.progressService.startProgress();
		this.partnerPortalService
			.getAssociatedRecords(this.keywords, this.templateId, this.entityId, this.associatedCharTypeId, this.start, this.pageSize)
			.subscribe((r) => {
				this.relatedDocs = r.documents;
				this.documentTitle = CharTypeDisplay[this.associatedCharTypeId];
				this.numFound = r.numFound;
				this.pageCount = Math.ceil(r.numFound / this.pageSize);
				this.templateFacets = r.templateFacets.map((kvp) => {
					let parts = kvp.key.split("|");
					return {
						name: `${parts[0]} (${kvp.value})`,
						id: _.parseInt(parts[1])
					};
				});
				this.progressService.endProgress();
			});
	}

	pageChanged($event: IPageChangedEvent) {
		if ($event.page !== this.page || $event.pageSize !== this.pageSize) {
			this.page = $event.page;
			this.pageSize = $event.pageSize;
			this.getAssociatedRecords();
		}
	}
}