<aw-wizard #wizard class="rl-wizard">
	<aw-wizard-step stepTitle="Create New File">
		<div *ngIf="!isLoading">
			<char-data-table [charData]="uploadCharData" [template]="uploadCharMetaDataCollection" [editMode]="true"
				(tableCharDataChange)="uploadCharData=($event)" #fileUploadTable></char-data-table>
			<div class="modal-footer">
				<button type="button" class="btn btn-light" (click)="close($event)" data-cy="cancelButton">Cancel</button>
				<button [hidden]="this.totalAssociableRecords === 1" type="button" class="btn btn-success" [disabled]="!fileUploadTable.valid"
					(click)="goToAssociate()" data-cy="cancelButton">Continue</button>
				<button [hidden]="this.totalAssociableRecords !== 1" type="button" class="btn btn-success ms-2"
					[disabled]="!selectedEntityId || !templateId || isSaving" (click)="save($event)" data-cy="saveButton"><i
						class="fa"></i>Save</button>
			</div>
		</div>
	</aw-wizard-step>
	<aw-wizard-step stepTitle="Associate File" *ngIf="this.totalAssociableRecords !== 1">
		<div *ngIf="!isLoading">
			<selectable-association-list [associatedCharTypeId]="CharTypeID.Transaction" [templateId]="templateId"
				(selection)="updateSelection($event)"></selectable-association-list>
			<selectable-association-list [associatedCharTypeId]="CharTypeID.Property" [templateId]="templateId"
				(selection)="updateSelection($event)"></selectable-association-list>
			<selectable-association-list [associatedCharTypeId]="CharTypeID.Inventory" [templateId]="templateId"
				(selection)="updateSelection($event)"></selectable-association-list>
			<selectable-association-list [associatedCharTypeId]="CharTypeID.Job" [templateId]="templateId"
				(selection)="updateSelection($event)"></selectable-association-list>
			<selectable-association-list [associatedCharTypeId]="CharTypeID.Project" [templateId]="templateId" (selection)="updateSelection($event)">
			</selectable-association-list>
			<div *ngIf="this.totalAssociableRecords === 0" class="no-items-found d-flex align-items-center justify-content-center pt-3">
				<i class="far fa-th-list"></i>
				<h5 class="mb-0 ms-3">No records found.</h5>
			</div>
		</div>
		<div class="modal-footer">
			<button type="button" class="btn btn-info" [disabled]="isSaving" (click)="goToRecordFields()" data-cy="cancelButton">Back</button>
			<button type="button" class="btn btn-light" [disabled]="isSaving" (click)="close($event)" data-cy="cancelButton">Cancel</button>
			<button type="button" class="btn btn-success ms-2" [disabled]="!selectedEntityId || !templateId || isSaving" (click)="save($event)"
				data-cy="saveButton"><i class="fa"></i>Save</button>
		</div>
	</aw-wizard-step>
</aw-wizard>