import {Component, ChangeDetectionStrategy, Input} from "@angular/core";

@Component({
    selector: "text-display-control",
    template: "<span [innerText]='textValue'></span>",
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class TextDisplayComponent {

    @Input()
    textValue: string;

    constructor() {

    }
}