<header class="page-header fixed-top" *ngIf="isAuthenticated">
  <div class="d-flex justify-content-between align-items-center w-100 page-header__brand">
    <img [src]="logoUrl" [alt]="title" *ngIf="logoUrl != null">
    <div class="mb-5 text-end">
      <div ngbDropdown class="rl-account-dropdown" *ngIf="isAuthenticated">
        <a class="btn" ngbDropdownToggle title="User Name" role="button">
          Welcome, {{sessionService.username}}
        </a>
        <ul ngbDropdownMenu class="dropdown-menu-right">
          <li class="btn btn-link" ngbDropdownItem [routerLink]="linkHelper.account.myAccount()" data-cy="myAccountLink">
            My Account
          </li>
          <li *ngIf="hasConfigAccess" class="btn btn-link" ngbDropdownItem [routerLink]="linkHelper.account.config()" data-cy="configLink">
            Configuration
          </li>
          <li class="btn btn-link" ngbDropdownItem (click)="logout($event)" data-cy="logoutLink" role="button">Logout</li>
        </ul>
      </div>
      <strong class="d-block pe-4 text-white">{{currentDiv}}</strong>
    </div>
  </div>
  <pp-navbar></pp-navbar>
</header>