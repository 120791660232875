import { Injectable } from '@angular/core';
import {
	IGetTablesResponse
} from "../../common/messages";
import {
	ICharacteristicData,



	IJobReponse
} from "../models";
import { PPHttpService } from "./pp-http.service";


@Injectable()
export class TableEntitySerivce {
	private _baseUrl = "/service-api/table-entity";

	constructor(private ppHttpService: PPHttpService) { }

	getTables(charTypeID: number, recordID: number, templateID: number, start: number, rows: number, useSortKey: boolean = false, sortKey: string = "recordid", sortDir: number = 0, columnFilters: { [columnFilters: string]: string[] }) {
		return this.ppHttpService.post<IGetTablesResponse[]>(
			`${this._baseUrl}/get-tables/${charTypeID}/${recordID}/${templateID}/${start}/${rows}/${useSortKey}/${sortKey}/${sortDir}`, columnFilters);
	}

	exportTablesToCSV(charTypeID: number, recordID: number, tableTemplateID: number) {
		return this.ppHttpService.get<IJobReponse>(
			`${this._baseUrl}/export-tables/${charTypeID}/${recordID}/${tableTemplateID}`);
	}

	uploadFileToTable(charTypeID: number, recordID: number, tableRecordID: number, charData: ICharacteristicData[]) {
		return this.ppHttpService.post<void>(`${this._baseUrl}/upload-file-to-table/${charTypeID}/${recordID}/${tableRecordID}`, charData);
	}

	updateTable(recordID: number, charTypeID: number, tableRecordID: number, templateID: number, charData: ICharacteristicData[]) {
		const data = {
			parentRecordID: recordID,
			parentCharTypeID: charTypeID,
			tableRecordID: tableRecordID,
			tableTemplateID: templateID,
			charData: charData
		}
		return this.ppHttpService.post<void>(`${this._baseUrl}/update-table`, data);
	}

	createTable(recordID: number, charTypeID: number, templateID: number, charData: ICharacteristicData[]) {
		const data = {
			parentRecordID: recordID,
			parentCharTypeID: charTypeID,
			tableTemplateID: templateID,
			charData: charData
		}
		return this.ppHttpService.post<void>(`${this._baseUrl}/create-table`, data);
	}

	deleteTable(parentEntityID: string, tableRecordID: number) {
		return this.ppHttpService.post<void>(`${this._baseUrl}/delete-table/${parentEntityID}/${tableRecordID}`);
	}
}