
export namespace TemplateGroupIds {
	export let IntellectualPropertyTemplateGroup = 1;
	export let InventoryItemTemplateGroup = 2;
	export let DealCatalogLevelUsageTemplateGroup = 2;
	export let CatalogLevelUsageTemplateGroup = 3;
	export let JobTemplateGroup = 3;
	export let RightslineDocTemplateGroup = 1;
	export let UsageModuleLevel = 1;
	export let DocumentFolderTemplateGroupID = 3;
}

export const msOfficeLiveUrl = "https://view.officeapps.live.com/op/view.aspx?src=";

export const msOfficeExtensions = [".doc", ".xls", ".rtf", ".docx", ".xlsx"];

// https://developer.mozilla.org/en-US/docs/Web/HTML/Element/img
export const imgExtensions = [".apng", ".bmp", ".gif", ".ico", ".cur", ".jpg", ".jpeg", ".jfif", ".pjpeg", ".pjp", ".png", ".svg", ".tif", ".tiff", ".webp"];
