import { Directive, EventEmitter, Input, Output } from "@angular/core";
import { ICharDataChangeEvent, ICharacteristicData, ICharacteristicMetaData } from "../../../models";

@Directive()
export class BaseElement {

    @Input()
    public cmd: ICharacteristicMetaData;

    @Input()
    public charData: ICharacteristicData[]

    @Output()
    public touch = new EventEmitter<Event>();

    @Output()
    public charDataChange = new EventEmitter<ICharDataChangeEvent>();

    public createCharData(value: string, valueID: number = null): ICharacteristicData {
        return {
						charactersticID: this.cmd.characteristicID,
            recordCharacteristicID: 0,
            value: value,
            valueID: valueID
        };
    }

    touched($event: Event) {
        this.touch.emit($event);
    }
}