import { Component } from "@angular/core";

import { AccountingListComponent } from "./accounting-list.component";
import { AccountingDetailComponent } from "./accounting-detail.component";


@Component({
    selector: "accounting-panel",
    template: "<router-outlet></router-outlet>"
})
export class AccountingPanelComponent {

    constructor() {

    }
}