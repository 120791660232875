import { Component } from "@angular/core";
import { UntypedFormBuilder, Validators, UntypedFormGroup, AbstractControl } from "@angular/forms";

import { ProgressService } from "../../../common/services/progress.service";
import { PartnerPortalService } from "../../services/partner-portal.service";
import { DivSettingKey, IGetAccountInfoResponse, ISetting } from "../../../common/messages";
import { ICharacteristicData, ICharacteristicMetaDataCollection } from "../../../common/models";
import { tap, switchMap, map } from "rxjs/operators";
import { DocumentEntityService } from "app/common/services/document-entity.service";
import { DomSanitizer } from "@angular/platform-browser";

@Component({
  selector: "my-account",
  templateUrl: "./my-account.component.html"
})
export class MyAccountComponent {

  templateMetaData: ICharacteristicMetaDataCollection;
  charData: ICharacteristicData[];

  constructor(
    private partnerPortalService: PartnerPortalService,
    private progressService: ProgressService,
    private fb: UntypedFormBuilder) {

    this.progressService.startProgress();
    this.loadInfo().subscribe(() => {
      this.progressService.endProgress();
    });
  }

  loadInfo() {
    return this.partnerPortalService.getAccountInfo()
      .pipe(
        tap((result) => {
          this.templateMetaData = result.template;
          this.charData = result.charData;
        }));
  }

  onSubmit($event, form: UntypedFormGroup) {
    if (form.valid) {
      this.progressService.startProgress();
      this.partnerPortalService.saveAccountInfo(this.charData)
        .pipe(
          switchMap(() => this.loadInfo()))
        .subscribe(() => {
          window.alert("Account Info Updated");
          this.progressService.endProgress();
        });
    } else {
      this.progressService.progressEnded.emit(null);
    }
  }
}
