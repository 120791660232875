import { ICharDataMoneyValue } from "../models";
import { CharDataType } from "../consts";
import * as _ from "lodash";

export class CharDataUtil {
    static parseMoneyValue(value: string): ICharDataMoneyValue {
        if (_.isNull(value) || _.isEmpty(value)) {
            return null;
        }
        try {
            let result = JSON.parse(value);
            return <ICharDataMoneyValue>result;
        } catch (err) {
            return null;
        }
    }

	static GetCharSolrLabel(dataTypeID: number, multipleIndicator: number, tagLabel: string): string {
		if (tagLabel == "entity_status")
			return "status";
		else if (tagLabel == "entity_id")
			return "recordid";
		else if (tagLabel == "entity_title") {
			return "title";
		}
		else if (tagLabel == "entity_template") {
			return "template";
		}

		switch (dataTypeID) {
			case CharDataType.Email:
			case CharDataType.SMPTETimeCode:
			case CharDataType.Password:
			case CharDataType.InternetAddress:
			case CharDataType.Money:
			case CharDataType.Alphanumeric:
			case CharDataType.ExternalDocument:
			case CharDataType.Checkbox:
				return multipleIndicator > 0 ? `${tagLabel}_strs` : `${tagLabel}_str`;			
			case CharDataType.Date:
				return multipleIndicator > 0 ? `${tagLabel}_dates` : `${tagLabel}_date`;
			case CharDataType.FourDigitYear:
				return multipleIndicator > 0 ? `${tagLabel}_ints` : `${tagLabel}_int`;
			case CharDataType.Number:
			case CharDataType.Percentage:
			case CharDataType.Currency:
				return multipleIndicator > 0 ? `${tagLabel}_doubles` : `${tagLabel}_double`;
		}
	}
}