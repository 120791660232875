<div class="row justify-content-center mt-4">
    <div class="col-sm-12 col-md-10">
        <h1 class="title">Contact Us</h1>
        <div class="card">
            <div class="card-body">
                <form (ngSubmit)="onSubmit($event)" [formGroup]="contactUsForm">
                    <div class="mb-3">
                        <select  name="category" class="form-control form-select" formControlName="category" data-cy="contactCategory">
                            <option value="" disabled>Select a category</option>
                            <option *ngFor="let c of categories" [ngValue]="c">{{c.category}}</option>
                          </select>
                    </div>
                    <div class="mb-3" [class.has-error]="!clientInput.valid && clientInput.touched">
                        <textarea name="clientInput" class="form-control" formControlName="clientInput" rows="4" placeholder="Ask a question here..." data-cy="contactInput"></textarea>
                        <control-error-messages [control]="clientInput"></control-error-messages>
                    </div>
                    <div class="text-end mt-3">
                        <button type="submit" class="btn btn-success" [disabled]="!contactUsForm.valid" data-cy="contactSubmit">
                         <i class="fa"></i> Submit
                        </button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>