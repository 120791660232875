<div *ngIf="details" class="record-details">
	<div class="section-head d-flex justify-content-between align-items-center mb-3">
		<div class="me-auto ps-3">
			<h1 class="title" *ngIf="details?.title" [innerText]="details.title"></h1>

		</div>
		<div class="border-start px-2 py-1 d-flex flex-column record-summary" [hidden]="!details?.record.title">
			<span class="record-summary-title control-label pe-2">Template</span>
			<span class="btn btn-link record-summary-status w-100 px-0 d-flex align-items-center"
				[innerText]="details.templateName"></span>
		</div>
		<div class="pe-3 align-self-stretch border-start" [hidden]="!details?.record.title">
			<pp-workflow-action [entityId]="details?.record.id" (statusChange)="loadCatalogItem()">
			</pp-workflow-action>
		</div>
	</div>

	<div class="card card-meta mb-3">
		<div class="card-title d-flex justify-content-end mb-2">
			<button *ngIf="canWrite && !editMode" type="button" class="btn btn-info btn-icon" (click)="editCharData()"
				[disabled]="isLocked" title="Edit this record" data-cy="editRecord">
				<i class="far fa-pencil"></i>
			</button>
			<button *ngIf="canWrite && editMode" type="button" class="btn btn-sm btn-light me-2" (click)="cancel()"
				title="Cancel" data-cy="cancelEdit">
				Cancel
			</button>
			<button *ngIf="canWrite && editMode" [disabled]="!charDataTable.valid" type="button"
				class="btn btn-sm btn-success" (click)="saveCharData()" title="Save this record" data-cy="saveEdit">
				Save
			</button>
		</div>
		<div class="card-body pt-0 d-flex">
			<div class="keyart me-4" *ngIf="details.keyArt">
				<img [src]="details.keyArt" [attr.alt]="details.title">
			</div>
			<div class="flex-grow-1">
				<char-data-table #charDataTable [charData]="details.charData" [template]="details.template"
					[editMode]="editMode" (tableCharDataChange)="details.charData=$event"></char-data-table>
			</div>
		</div>
	</div>

	<catalog-items-related-items *ngIf="assetID" [assetID]="assetID"></catalog-items-related-items>

	<div *ngFor="let relatedCharTypeID of relatedCharTypeIDs">
		<association-list *ngIf="details" [entityId]="details?.record.id" [associatedCharTypeId]="relatedCharTypeID">
		</association-list>
	</div>

	<tables *ngIf="details" [entityId]="details?.record.id" [templateId]="details?.record.templateID"></tables>

	<folders *ngIf="details" [entityId]="details?.record.id" [templateId]="details?.record.templateID">
	</folders>

	<div class="card mb-3" *ngIf="details && details.inventoryMetaData | hasValues">
		<div class="card-title">
			<h3>SKUs</h3>
		</div>
		<div class="card-body">
			<div class="scroll-wrap">
				<div class="scrollable">
					<table class="table rl-table">
						<thead>
							<tr>
								<th>Type</th>
								<th>Street Date</th>
								<th>MSRP</th>
								<th>SKU</th>
								<th>UPC</th>
							</tr>
						</thead>
						<tbody>
							<tr *ngFor="let inv of details.inventoryMetaData">
								<td [innerText]="inv.name"></td>
								<td
									[innerText]="inv.streetDate == '0001-01-01T00:00:00' ? '' : inv.streetDate | stripUtc">
								</td>
								<td [innerText]="inv.msrp"></td>
								<td [innerText]="inv.sku"></td>
								<td [innerText]="inv.upc"></td>
							</tr>
						</tbody>
					</table>
				</div>
			</div>

			<div class="table-paginate">
				{{details.inventoryMetaData.length}} <span style="line-height: 30px;">records</span>
			</div>
		</div>
	</div>
</div>