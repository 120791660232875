import { Component, Input, Output, EventEmitter, OnInit, ChangeDetectionStrategy } from "@angular/core";
import * as _ from "lodash";

@Component({
    selector: "facet-selector",
    templateUrl: "./facet-selector.component.html",
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class FacetSelectorComponent implements OnInit {

    @Input()
    facetTitles: { [key: string]: string };

    @Input()
    facetValues: { [key: string]: { key: string, value: number }[] }

    @Input()
    selectedValues: { [key: string]: string[] };

    @Output()
    select = new EventEmitter<{ [key: string]: string[] }>();


    get facets() {
        return _.keys(this.facetTitles);
    }

    constructor() {

    }

    ngOnInit() {

    }

    orderFacets(key: string) {
        if (this.facetValues[key] == null) {
            return []
        }
        return _.orderBy(this.facetValues[key], [kvp => this.isSelected(key, kvp.key), kvp => kvp.value], ["desc", "desc"]);
    }

    isSelected(facetKey: string, facetValue: string) {
        return _.includes(this.selectedValues[facetKey], facetValue);
    }

    formatValue(kvp: { key: string, value: number }) {
        var cleanName = _(kvp.key).split("|").first().valueOf();
        return `${cleanName} (${kvp.value})`;
    }

    addFacet($event: Event, facetKey: string, facetValue: string) {
        $event.preventDefault();

        let selectedValues = _.clone(this.selectedValues);
        selectedValues[facetKey] = _(selectedValues[facetKey] || []).concat(facetValue).uniq().value();
        this.select.emit(selectedValues);
    }

    removeFacet($event: Event, facetKey: string, facetValue: string) {
        $event.preventDefault();

        let selectedValues = _.clone(this.selectedValues);
        let newValue = _(selectedValues[facetKey]).without(facetValue).uniq().value();
        if (newValue.length > 0) {
            selectedValues[facetKey] = newValue;
        } else {
            delete selectedValues[facetKey];
        }
        this.select.emit(selectedValues);
    }
}
