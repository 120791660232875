import { ArchwizardModule } from "@achimha/angular-archwizard";
import { CommonModule } from "@angular/common";
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA, NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from "@angular/router";
import { JWT_OPTIONS, JwtHelperService } from '@auth0/angular-jwt';
import { NgbModule, NgbPaginationConfig, NgbPaginationModule } from '@ng-bootstrap/ng-bootstrap';
import { TextMaskModule } from "angular2-text-mask";
import { COMMON_SETTINGS } from "app/common/settings";
import { environment } from "environments/environment";
import { BsDatepickerModule } from "ngx-bootstrap/datepicker";
import { BsDropdownModule } from "ngx-bootstrap/dropdown";
import { AssociationListComponent } from "../partner-portal/components/association-list.component";
import { SSOTokenComponent } from "./components/account/sso-token.component";
import { CHAR_DATA_TABLE_DIRECTIVES } from "./components/char-data/char-data-table.component";
import { AmountEditControlComponent } from "./components/char-data/controls/amount-edit-control.component";
import { CheckboxEditControlComponent } from "./components/char-data/controls/checkbox-edit-control.component";
import { DateDisplayComponent } from "./components/char-data/controls/date-display-control.component";
import { DateEditControlComponent } from "./components/char-data/controls/date-edit-control.component";
import { DropdownEditControlComponent } from "./components/char-data/controls/dropdown-edit-control.component";
import { FileDisplayComponent } from "./components/char-data/controls/file-display-control.component";
import { FileEditControlComponent } from "./components/char-data/controls/file-edit-control.component";
import { MoneyDisplayControlComponent } from "./components/char-data/controls/money-display-control.component";
import { MoneyEditControlComponent } from "./components/char-data/controls/money-edit-control.component";
import { NumberEditControlComponent } from "./components/char-data/controls/number-edit-control.component";
import { PercentageEditControlComponent } from "./components/char-data/controls/percentage-edit-control.component";
import { TextDisplayComponent } from "./components/char-data/controls/text-display-control.component";
import { TextEditControlComponent } from "./components/char-data/controls/text-edit-control.component";
import { TimecodeEditControlComponent } from "./components/char-data/controls/timecode-edit-control.component";
import { TreeListEditControlComponent } from "./components/char-data/controls/tree-list-edit-control.component";
import { YearEditControlComponent } from "./components/char-data/controls/year-edit-control.component";
import { AmountEditElementComponent } from "./components/char-data/elements/amount-edit-element.component";
import { CheckboxEditElementComponent } from "./components/char-data/elements/checkbox-edit-element.component";
import { DateDisplayElementComponent } from "./components/char-data/elements/date-display-element.component";
import { DateEditElementComponent } from "./components/char-data/elements/date-edit-element.component";
import { DropdownEditElementComponent } from "./components/char-data/elements/dropdown-edit-element.component";
import { ElementErrorMessagesComponent } from "./components/char-data/elements/element-error-messages.component";
import { ElementValueAccessor } from "./components/char-data/elements/element-value-accessor";
import { FileDisplayElementComponent } from "./components/char-data/elements/file-display-element.component";
import { FileEditElementComponent } from "./components/char-data/elements/file-edit-element.component";
import { MoneyDisplayElementComponent } from "./components/char-data/elements/money-display-element.component";
import { MoneyEditElementComponent } from "./components/char-data/elements/money-edit-element.component";
import { NumberEditElementComponent } from "./components/char-data/elements/number-edit-element.component";
import { PercentageEditElementComponent } from "./components/char-data/elements/percentgage-edit-element.component";
import { TextDisplayElementComponent } from "./components/char-data/elements/text-display-element.component";
import { TextEditElementComponent } from "./components/char-data/elements/text-edit-element.component";
import { TextEditMultiElementComponent } from './components/char-data/elements/text-edit-multi-element.component';
import { TimecodeEditElementComponent } from "./components/char-data/elements/timecode-edit-element.component";
import { YearEditElementComponent } from "./components/char-data/elements/year-edit-element.component";
import { ComponentPagerComponent } from "./components/component-pager/component-pager.component";
import { ContactGroupComponent } from './components/contact-group/contact-group.component';
import { ContactComponent } from './components/contact/contact.component';
import { ContactsComponent } from './components/contacts/contacts.component';
import { WorkflowActionComponent } from "./components/entity/workflow-action.component";
import { EXPLORER_COMPONENTS } from "./components/explorer/explorer.component";
import { SelectableAssociationListComponent } from './components/explorer/selectable-association-list/selectable-association-list.component';
import { PreviewDocumentModalComponent } from "./components/files/preview-document-modal/preview-document-modal.component";
import { PreviewDocumentComponent } from "./components/files/preview-document/preview-document.component";
import { FoldersComponent } from "./components/folders.component";
import { ControlErrorMessageComponent } from "./components/forms/control-error-messages.component";
import { JobProgressStatusComponent } from "./components/job-progress-status.component";
import { ModalBackdropComponent } from "./components/modal-backdrop.component";
import { ModalComponent } from "./components/modal.component";
import { PagerComponent } from "./components/pager.component";
import { FacetSelectorComponent } from "./components/search/facet-selector.component";
import { TablesComponent } from "./components/tables.component";
import { TREELIST_COMPONENTS } from "./components/tree-list.component";
import { BUSY_BUTTON_DIRECTIVES } from "./directives/busy-button.directive";
import { CharDataFilterPipe } from "./pipes/char-data-filter.pipe";
import { ErrorMsgPipe } from "./pipes/error-msg.pipe";
import { FuncPipe } from "./pipes/func.pipe";
import { HasValuesPipe } from "./pipes/has-values.pipe";
import { KeysPipe } from "./pipes/keys.pipe";
import { RLDatePipe } from "./pipes/rl-date.pipe";
import { SafePipe } from "./pipes/safe.pipe";
import { StripUtcPipe } from "./pipes/strip-utc.pipe";
import { AclService } from "./services/acl.service";
import { AdminService } from "./services/admin.service";
import { AuthGuard } from "./services/auth-guard.service";
import { CanDeactivateGuard } from "./services/can-deactivate-guard.service";
import { CharDataTableService } from "./services/char-data-table.service";
import { DocumentEntityService } from "./services/document-entity.service";
import { EntityService } from "./services/entity.service";
import { ModalService } from "./services/modal.service";
import { NotifyService } from "./services/notify.service";
import { PortalHttpInterceptor } from "./services/portal-http-interceptor.service";
import { PPCacheHttpService } from "./services/pp-cache-http.service";
import { PPHttpService } from "./services/pp-http.service";
import { ProgressService } from "./services/progress.service";
import { SessionService } from "./services/session.service";
import { TableEntitySerivce } from "./services/table-entity.service";
import { TemplateService } from "./services/template.service";
import { TokenService } from "./services/token.service";
import { WorkflowService } from "./services/workflow.service";
;








let DISPLAY_ELEMENTS = [TextDisplayElementComponent, FileDisplayElementComponent, DateDisplayElementComponent];
let EDIT_ELEMENTS = [DateEditElementComponent, DropdownEditElementComponent, TextEditElementComponent, FileEditElementComponent, TimecodeEditElementComponent, NumberEditElementComponent, PercentageEditElementComponent, YearEditElementComponent, AmountEditElementComponent, MoneyEditElementComponent, MoneyDisplayElementComponent, CheckboxEditElementComponent];
let EDIT_CONTROLS = [AmountEditControlComponent, DateEditControlComponent, DropdownEditControlComponent, TreeListEditControlComponent, NumberEditControlComponent, PercentageEditControlComponent, YearEditControlComponent, FileEditControlComponent, TextEditControlComponent, TimecodeEditControlComponent, MoneyEditControlComponent, MoneyDisplayControlComponent, CheckboxEditControlComponent];
let DISPLAY_CONTROLS = [DateDisplayComponent, FileDisplayComponent, TextDisplayComponent];
let CHAR_DATA_MISC = [ElementValueAccessor, ElementErrorMessagesComponent];

let CHAR_DATA_DECLARATIONS = [...CHAR_DATA_TABLE_DIRECTIVES, ...DISPLAY_ELEMENTS, ...DISPLAY_CONTROLS, ...EDIT_ELEMENTS, ...EDIT_CONTROLS, ...CHAR_DATA_MISC];




let COMMON_SERVICES = [
	PPHttpService,
	PPCacheHttpService,
	SessionService,
	ProgressService,
	EntityService,
	TemplateService,
	ModalService,
	AuthGuard,
	CanDeactivateGuard,
	CharDataTableService,
	WorkflowService,
	NotifyService,
	DocumentEntityService,
	AdminService,
	AclService,
	TokenService,
	TableEntitySerivce];

let THIRD_PARTY_SERVICES = [
	JwtHelperService,
	NgbPaginationConfig
];

let COMMON_NG_MODULES = [FormsModule,
	ReactiveFormsModule,
	HttpClientModule,
	BrowserModule,
	CommonModule,
	BrowserAnimationsModule,
	RouterModule,
	NgbPaginationModule];

export function tokenGetter(): string { return localStorage.getItem("jwt"); }

@NgModule({
	schemas: [
		CUSTOM_ELEMENTS_SCHEMA,
		NO_ERRORS_SCHEMA
	],
	imports: [
		...COMMON_NG_MODULES,
		TextMaskModule,
		BsDropdownModule.forRoot(),
		BsDatepickerModule.forRoot(),
		NgbPaginationModule,
		NgbModule,
		ArchwizardModule
	],
	exports: [
		...COMMON_NG_MODULES,
		TextMaskModule,
		...TREELIST_COMPONENTS,
		...CHAR_DATA_TABLE_DIRECTIVES,
		ModalComponent,
		ModalBackdropComponent,
		PagerComponent,
		FacetSelectorComponent,
		ControlErrorMessageComponent,
		...BUSY_BUTTON_DIRECTIVES,
		CharDataFilterPipe,
		HasValuesPipe,
		FuncPipe,
		KeysPipe,
		RLDatePipe,
		StripUtcPipe,
		ErrorMsgPipe,
		SafePipe,
		WorkflowActionComponent,
		FoldersComponent,
		AssociationListComponent,
		JobProgressStatusComponent,
		BsDropdownModule,
		BsDatepickerModule,
		NgbModule,
		...EXPLORER_COMPONENTS,
		TablesComponent,
		ComponentPagerComponent,
		ContactComponent,
		ContactGroupComponent,
		ContactsComponent,
		PreviewDocumentComponent,
		PreviewDocumentModalComponent
	],
	declarations: [
		...TREELIST_COMPONENTS,
		...CHAR_DATA_DECLARATIONS,
		ModalComponent,
		ModalBackdropComponent,
		PagerComponent,
		FacetSelectorComponent,
		ControlErrorMessageComponent,
		...BUSY_BUTTON_DIRECTIVES,
		CharDataFilterPipe,
		HasValuesPipe,
		FuncPipe,
		KeysPipe,
		RLDatePipe,
		StripUtcPipe,
		ErrorMsgPipe,
		SafePipe,
		WorkflowActionComponent,
		FoldersComponent,
		AssociationListComponent,
		JobProgressStatusComponent,
		...EXPLORER_COMPONENTS,
		TablesComponent,
		SSOTokenComponent,
		ComponentPagerComponent,
		ContactComponent,
		ContactGroupComponent,
		ContactsComponent,
		TextEditMultiElementComponent,
		PreviewDocumentComponent,
		PreviewDocumentModalComponent,
		SelectableAssociationListComponent
	],
	providers: [
		...THIRD_PARTY_SERVICES,
		...COMMON_SERVICES,
		{ provide: COMMON_SETTINGS, useValue: environment },
		{
			provide: HTTP_INTERCEPTORS,
			useClass: PortalHttpInterceptor,
			multi: true
		},
		{
			provide: JWT_OPTIONS,
			useValue: {
				tokenGetter: tokenGetter
			}
		}
	]
})
export class PPCommonModule {

}