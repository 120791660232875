import {trigger, state, transition, style, animate} from "@angular/animations";


export class AnimationHelper {

    static fade(trans: string = "50ms ease-in") {
        return trigger("fade", [
            state("out", style({ opacity: 0 })),
            state("in", style({ opacity: 1 })),
            transition("out <=> in", animate(trans))
        ]);
    }

}