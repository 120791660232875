<div class="d-flex justify-content-between align-items-center bg-light p-2">
	<div>
		{{pageMinRecord}}-{{pageMaxRecord}} of {{numFound}}
		<span class="ms-3">
			Show
			<span ngbDropdown>
				<button ngbDropdownToggle type="button" class="btn btn-sm py-0" data-cy="PagerPageSizeDropdown">
					{{pageSize}}
				</button>
				<ul ngbDropdownMenu class="pager-dropdown">
					<li ngbDropdownItem role="menuitem" *ngFor="let pSize of pageSizes">
						<div (click)="changePageSize(pSize)" [innerText]="pSize" [attr.data-cy]="'PagerPageSizeSelect'+ pSize"></div>
					</li>
				</ul>
			</span>
		</span>
	</div>
	<div class="d-flex flex-row align-items-center flex-wrap" *ngIf="numFound > (pageSize*5)">
		<label>Jump to page</label>
		<input type="text" class="jump-to-page form-control form-control-sm ms-2" style="width: 35px;"
			[(ngModel)]="jumpToPage" (keyup.enter)="changePage(jumpToPage)" data-cy="PagerPageSizeGoToPage" />
		<button class="btn btn-icon btn-sm ms-1" (click)="changePage(jumpToPage)" data-cy="PagerPageSizeGoToPageButton"><i
				class="far fa-angle-right"></i></button>
	</div>
	<div class="text-end" *ngIf="numFound > pageSize">
		<ngb-pagination [collectionSize]="numFound" [(page)]="page" [(pageSize)]="pageSize" [boundaryLinks]="true"
			[maxSize]="3" (pageChange)="changePage()"></ngb-pagination>
	</div>
</div>