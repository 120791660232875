import { Component } from "@angular/core";

import { JobsListComponent } from "./jobs-list.component";
import { JobsDetailComponent } from "./jobs-detail.component";


@Component({
	selector: "job-panel",
	template: "<router-outlet></router-outlet>"
})
export class JobsPanelComponent {

	constructor() {

	}
}