import { Component, Inject, OnDestroy, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import * as _ from "lodash";

import { UntypedFormBuilder, UntypedFormGroup } from "@angular/forms";
import { ICharacteristic, IEntitySearchDoc, IPartnerPortalSettings } from "app/common/models";
import { ProgressService } from "app/common/services/progress.service";
import { PARTNER_PORTAL_SETTINGS } from "app/partner-portal/settings";
import { Subscription } from "rxjs";
import { tap } from "rxjs/operators";
import { SessionService } from "../../../common/services/session.service";
import { PartnerPortalService } from "../../services/partner-portal.service";
import { PPLinkHelperService } from "../../services/pp-link-helper.service";
import { SiteService } from "../../services/site.service";

@Component({
    selector: "pp-home",
    templateUrl: "./home.component.html"
})
export class HomeComponent implements OnInit, OnDestroy {
    fileURLs: _.Dictionary<string>;
    files: IEntitySearchDoc[];
    fileSourceFields: ICharacteristic[];
    allFilesUrls: _.Dictionary<string>;
    projectItems: IEntitySearchDoc[];
    catalogItems: IEntitySearchDoc[];
    dealItems: IEntitySearchDoc[];
    jobItems: IEntitySearchDoc[];
    inventoryItems: IEntitySearchDoc[];
    associatedDeals: _.Dictionary<string>;
    templateFacets: { name: string; id: number; }[];
    statuses: { name: string; id: number; }[];
    searchForm: UntypedFormGroup;
    currentDivId$ = this.sessionService.currentDivId$;
    totalFilesCount: number;
    divId: number;

    pageCount = 0;
    pageSize = 5;
    page = 1;
    rows = 5;

    templateID: string = "";
    keywords: string = "";
    statusID: string = "";

    public get start(): number {
        return (this.page - 1) * this.pageSize;
    }

    get showCatalog() {
        return this.siteService.showCatalog;
    }

    get showProjects() {
        return this.siteService.showProjects;
    }

    get showDeals() {
        return this.siteService.showDeals;
    }

    get showJobs() {
        return this.siteService.showJobs;
    }

    get showAccounting() {
        return this.siteService.showAccounting;
    }

    get showInventory() {
        return this.siteService.showInventory;
    }

    get showFiles() {
        return this.siteService.showFiles && this.divId != 88;
    }

    get showUploads() {
        return this.siteService.showUploads;
    }

    _subs: Subscription[] = [];
    keyArt: _.Dictionary<string>;

    constructor(
        private siteService: SiteService,
        @Inject(PARTNER_PORTAL_SETTINGS) private settings: IPartnerPortalSettings,
        private router: Router,
        private sessionService: SessionService,
        private partnerPortalService: PartnerPortalService,
        private progressService: ProgressService,
        private fb: UntypedFormBuilder,
        public linkHelper: PPLinkHelperService) {
        this.searchForm = this.fb.group({
            "keywords": [this.keywords],
            "template": [this.templateID],
            "status": [this.statusID]
        })
    }

    ngOnDestroy(): void {
        this._subs.forEach((s) => s.unsubscribe());
    }

    ngOnInit() {
        if (this.settings.partnerPortal != null && !_.isEmpty(this.settings.partnerPortal.homePageRedirect)) {
            if (this.sessionService.isValid) {
                this.partnerPortalService.getSessionInfo().pipe(
                    tap((result) => {
                        if (result.hasB2BRole) {
                            this.router.navigateByUrl(this.settings.partnerPortal.homePageRedirect);
                        }
                        else {
                            this.router.navigate(this.linkHelper.catalogItems.list());
                        }
                    })).subscribe();
            }
        }

        this.currentDivId$.pipe(
            tap(divId => {
                this.divId = divId;
            })
        ).subscribe();
        this.progressService.startProgress();

        let sub2 = this.partnerPortalService.getAssociatedCatalogItems(this.keywords, _.parseInt(this.templateID), this.start, this.pageSize, _.parseInt(this.statusID), null)
            .subscribe((r) => {
                this.catalogItems = r.documents;
                this.keyArt = r.keyArt;

                this.templateFacets = r.templateFacets.map((kvp) => {
                    let parts = kvp.key.split("|");
                    return {
                        name: `${parts[0]} (${kvp.value})`,
                        id: _.parseInt(parts[1])
                    };
                });
                this.statuses = r.statusFacets.map((kvp) => {
                    let parts = kvp.key.split("|");
                    return {
                        name: `${parts[0]} (${kvp.value})`,
                        id: _.parseInt(parts[1])
                    };
                });

                this.progressService.endProgress();
            });

        let sub3 = this.partnerPortalService.getAssociatedProjects(this.keywords, _.parseInt(this.templateID), this.start, this.pageSize, _.parseInt(this.statusID))
            .subscribe((r) => {
                this.projectItems = r.documents;
                this.templateFacets = r.templateFacets.map((kvp) => {
                    let parts = kvp.key.split("|");
                    return {
                        name: `${parts[0]} (${kvp.value})`,
                        id: _.parseInt(parts[1])
                    };
                });
                this.statuses = r.statusFacets.map((kvp) => {
                    let parts = kvp.key.split("|");
                    return {
                        name: `${parts[0]} (${kvp.value})`,
                        id: _.parseInt(parts[1])
                    };
                });
                this.progressService.endProgress();
            });

        let sub4 = this.partnerPortalService.getAssociatedDeals(this.keywords, _.parseInt(this.templateID), this.start, this.pageSize, _.parseInt(this.statusID))
            .subscribe((r) => {
                this.dealItems = r.documents;
                this.templateFacets = r.templateFacets.map((kvp) => {
                    let parts = kvp.key.split("|");
                    return {
                        name: `${parts[0]} (${kvp.value})`,
                        id: _.parseInt(parts[1])
                    };
                });
                this.statuses = r.statusFacets.map((kvp) => {
                    let parts = kvp.key.split("|");
                    return {
                        name: `${parts[0]} (${kvp.value})`,
                        id: _.parseInt(parts[1])
                    };
                });
                this.progressService.endProgress();
            });

        let sub5 = this.partnerPortalService.getAssociatedJobs(this.keywords, _.parseInt(this.templateID), this.start, this.pageSize)
            .subscribe((r) => {
                this.jobItems = r.documents;
                this.templateFacets = r.templateFacets.map((kvp) => {
                    let parts = kvp.key.split("|");
                    return {
                        name: `${parts[0]} (${kvp.value})`,
                        id: _.parseInt(parts[1])
                    };
                });
                this.progressService.endProgress();
            });

        let sub6 = this.partnerPortalService.getAssociatedInventories(this.keywords, _.parseInt(this.templateID), this.start, this.pageSize, _.parseInt(this.statusID))
            .subscribe((r) => {
                this.inventoryItems = r.documents;
                this.templateFacets = r.templateFacets.map((kvp) => {
                    let parts = kvp.key.split("|");
                    return {
                        name: `${parts[0]} (${kvp.value})`,
                        id: _.parseInt(parts[1])
                    };
                });
                this.statuses = r.statusFacets.map((kvp) => {
                    let parts = kvp.key.split("|");
                    return {
                        name: `${parts[0]} (${kvp.value})`,
                        id: _.parseInt(parts[1])
                    };
                });
                this.progressService.endProgress();
            });
        this.loadFiles();
        this._subs.push(sub2, sub3, sub4, sub5, sub6);
    }

    loadFiles() {
        const characteristicCount = 0;
        const orderBySequence = true;
        this.partnerPortalService.getAllAssociatedFiles(this.keywords, _.parseInt(this.templateID), this.start, this.pageSize,
            characteristicCount, orderBySequence, _.parseInt(this.statusID))
            .subscribe((r) => {
                this.files = r.documents;
                this.allFilesUrls = r.fileURLs;
                this.fileSourceFields = r.sourceFields
                this.totalFilesCount = r.numFound;
            });
    }
}