import { Injectable } from "@angular/core"
import { SessionService } from "./session.service";

@Injectable()
export class AclService {

    get acls() {
        return this.sessionService.acls;
    }

    constructor(private sessionService: SessionService) {

    }

}