import {Pipe, PipeTransform} from "@angular/core";
import * as _ from "lodash";

@Pipe({
    name: "keys",
    pure: true
})
export class KeysPipe implements PipeTransform {

    transform(value: {}, args: string[]): string[] {
        if (value == null || value == undefined) {
            return [];
        }
        return _.keys(value);
    }
}