import { Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot, Data } from '@angular/router';

import { SessionService } from "../services/session.service";
import { of } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { AclUtil } from '../utils/acl.util';


@Injectable()
export class AuthGuard implements CanActivate {
    constructor(
        private sessionService: SessionService,
        private router: Router) { }

    canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        if (!this.sessionService.acls) {
            this.sessionService.setAcls();
            this.sessionService.setDivSettings();
        }
        if (next.data.acl) {
            if (!this.isAuthorized(next.data.acl)) {
                return of(false);
            }
        }
        else if (this.sessionService.isValid) {
            return of(true);
        } else if (this.sessionService.refreshToken) {
            return this.sessionService.refresh()
                .pipe(
                    map(result => {
                        return true;
                    }),
                    catchError((e) => {
                        this.redirectToLogin();
                        return of(false);
                    }));
        } else {
            this.redirectToLogin();
            return of(false);
        }
    }

    private isAuthorized(acl: string) {
        const acls = this.sessionService.acls;
        const aclNotDefined = acl === undefined;
        return aclNotDefined || !acls || AclUtil.hasReadAccess(acls, acl);
    }

    private redirectToLogin() {
        this.router.navigate(["/account", "login"]);
    }
}