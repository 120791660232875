import { Location } from "@angular/common";
import { Component, Inject, Input } from "@angular/core";
import { RouterLink } from "@angular/router";
import { NgbCollapseModule } from "@ng-bootstrap/ng-bootstrap";
import { DivSettingKey } from "app/common/messages";
import { IPartnerPortalSettings } from "app/common/models";
import { PPCommonModule } from "app/common/pp-common.module";
import { SessionService } from "app/common/services/session.service";
import { PartnerPortalService } from "app/partner-portal/services/partner-portal.service";
import { PPLinkHelperService } from "app/partner-portal/services/pp-link-helper.service";
import { SiteService } from "app/partner-portal/services/site.service";
import { PARTNER_PORTAL_SETTINGS } from "app/partner-portal/settings";
import { tap } from "rxjs/operators";


@Component({
    selector: 'pp-navbar',
    standalone: true,
    imports: [NgbCollapseModule, RouterLink, PPCommonModule],
    templateUrl: './pp-navbar.component.html',
})
export class PPNavbarComponent {


    @Input()
    hexColor2 = null;

    divId: number;
    isMenuCollapsed = true;
    contactUsEnabled = false;
    divSettings$ = this.partnerPortalService.divSettings$;
    currentDivId$ = this.sessionService.currentDivId$;


    get isAuthenticated(): boolean {
        return this.sessionService.session != null;
    };

    get showHome() {
        if (this.settings.partnerPortal != null && this.settings.partnerPortal.homePageRedirect != null) {
            return false;
        }
        else {
            return true;
        }
    }

    get showNewReleases() {
        if (this.settings.partnerPortal != null && this.settings.partnerPortal.showNewReleases != null) {
            var hasB2BRole = this.siteService.hasB2BRole;
            var showNewReleases = this.settings.partnerPortal.showNewReleases;

            return hasB2BRole && showNewReleases;
        }
        else {
            return false;
        }
    }

    get showCatalog() {
        return this.siteService.showCatalog;
    }

    get showProjects() {
        return this.siteService.showProjects;
    }

    get showDeals() {
        return this.siteService.showDeals;
    }

    get showAccounting() {
        return this.siteService.showAccounting;
    }

    get showJobs() {
        return this.siteService.showJobs;
    }

    get showInventory() {
        return this.siteService.showInventory;
    }

    get showFiles() {
        return this.siteService.showFiles && this.divId != 88;
    }

    constructor(
        public sessionService: SessionService,
        private location: Location,
        private siteService: SiteService,
        public linkHelper: PPLinkHelperService,
        public partnerPortalService: PartnerPortalService,
        @Inject(PARTNER_PORTAL_SETTINGS) private settings: IPartnerPortalSettings) { }

    ngOnInit() {
        this.divSettings$.pipe(
            tap(settings => {
                const portalContactUs = settings.find(x => x.key === DivSettingKey.PortalContactUs);
                this.contactUsEnabled = portalContactUs !== undefined;
            })).subscribe();

        this.currentDivId$.pipe(
            tap(divId => {
                this.divId = divId;
            })
        ).subscribe();
    }

    isCurrentRoute(path) {
        let currentPath = this.location.path();
        if (path == "/") {
            return currentPath == path || currentPath == "";
        }
        return currentPath.startsWith(path);
    }
}
