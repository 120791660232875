<div class="card" [class.card-h-100]="displaySummarizedView">
    <div class="card-body" [class.text-start]="displaySummarizedView">
        <header *ngIf="displaySummarizedView && !!heading">
            <div class="section-head row align-items-center">
                <div class="col">
                    <h1><a [routerLink]="linkHelper.list()" data-cy="listHeaderLink">{{ heading }}</a></h1>
                </div>
            </div>
        </header>
        <div class="scroll-horiz">
            <table class="table rl-table">
                <thead>
                    <tr>
                        <th>Title</th>
                        <th>Status</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let document of documents">
                        <td>
                            <a [routerLink]="linkHelper.detail(document.recordID)" [innerText]="document.title" data-cy="viewRecord"></a>
                        </td>
                        <td>
                            <p [innerText]="document.status"></p>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>