<div class="input-group align-items-center">
    <div class="input-group-prepend" ngbDropdown>
        <button ngbDropdownToggle type="button" class="btn btn-sm" data-cy="MoneyEditControlLocalCurrency" role="button">
            <span [innerText]="localCurrencySymbol"></span>
        </button>
        <ul ngbDropdownMenu class="dropdown-menu-right" role="menu">
            <li ngbDropdownItem *ngFor="let lov of validCurrencyListOfValues">
                <a href="#" (click)="changeCurrency($event, lov)" [innerText]="lov.characteristicValueLabel" data-cy="MoneyEditControlLovItem"></a>
            </li>
            <div class="dropdown-divider"></div>
            <li ngbDropdownItem>
                <a href="#" (click)="changeCurrency($event, null)" data-cy="MoneyEditControlClear">Clear</a>
            </li>
        </ul>
    </div>
    <input type="text" class="form-control" [ngModel]="localAmount" (keyup)="textValueChange.emit($event.target.value)"
        (change)="textValueChange.emit($event.target.value)" (blur)="touched($event)" data-cy="MoneyEditControlValue" />
    <span *ngIf="divAmount!=null && divCurrencySymbol!=localCurrencySymbol" class="input-group-append text-muted">
        <i class="far fa-exchange" title="Converted to"></i>
        <span [innerText]="divAmount | currency: divCurrencySymbol + ' ':'symbol':'1.2-2'"></span>
    </span>
</div>