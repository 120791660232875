import {Pipe, PipeTransform} from "@angular/core";
import * as _ from "lodash";
import * as moment from "moment";

@Pipe({
    name: "rlDate",
    pure: true
})
export class RLDatePipe implements PipeTransform {

    transform(value: string, args: string[]): string {
        if (value == null || value == undefined) {
            return "";
        }
        let m1 = moment(value);
        if (m1.isValid()) {
            return m1.format("L");
        } else {
            return "";
        }
    }
}