<div class="card mb-3" *ngIf="contactPartyGroups?.length > 0">
	<div class="card-title">
		<h3>Parties</h3>
	</div>
	<div class="card-body">
		<div class="pp-char-data-table" *ngIf="contactPartyGroups?.length > 0">
			<div class="pp-char-data-group" *ngFor="let group of contactPartyGroups">
				<app-contact-group *ngIf="isGroupVisible(group)" [dictionaries]="dictionaries"
					[contactPartyGroup]="group" [isExpanded]="(dictionaries.partyGroups[group.groupId] &&
					dictionaries.partyGroups[group.groupId].expandGroupIndicator)">
				</app-contact-group>
			</div>
		</div>
	</div>
</div>