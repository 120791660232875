import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CharDataType, CharTypeID, TemplateGroupIDs } from 'app/common/consts';
import { ICharacteristicData, ICharacteristicMetaDataCollection, IEntitySearchDoc } from 'app/common/models';
import { AclService } from 'app/common/services/acl.service';
import { DocumentEntityService } from 'app/common/services/document-entity.service';
import { EntityService } from 'app/common/services/entity.service';
import { ModalService } from 'app/common/services/modal.service';
import { ProgressService } from 'app/common/services/progress.service';
import { TemplateService } from 'app/common/services/template.service';
import { AclUtil } from 'app/common/utils/acl.util';
import { PartnerPortalService } from 'app/partner-portal/services/partner-portal.service';
import { EntityLinks } from 'app/partner-portal/services/pp-link-helper.service';
import * as _ from 'lodash';
import { ModalComponent } from '../../modal.component';

@Component({
  selector: 'explorer-upload-file-list',
  templateUrl: './explorer-upload-file-list.component.html'
})
export class ExplorerUploadFileListComponent implements OnInit {

  @Input()
  documents: IEntitySearchDoc[];
  @Input()
  fileURLs: _.Dictionary<string>;
  @Input()
  keyArt: _.Dictionary<string>;
  @Input()
  linkHelper: EntityLinks;
  @Input()
  showUploadFile = false;

  heading = true;
  uploadFolderOrFileToEntityId: string;
  uploadFileFolderModalTitle: string;
  documentFileTemplateGroupID: number = TemplateGroupIDs.DocumentFolderTemplateGroupID;
  documentFolderTemplateGroupID: number = TemplateGroupIDs.DocumentFolderTemplateGroupID;
  uploadCharMetaDataCollection: ICharacteristicMetaDataCollection;
  uploadCharData: ICharacteristicData[];
  entityForEdit: string;
  canWrite: boolean;

  @ViewChild('editDocumentEntityModal', { static: true })
  editDocumentEntityModal: ModalComponent;

  @Output()
  fileUploaded: EventEmitter<boolean> = new EventEmitter<boolean>();

  public get fileDataType() {
    return CharDataType.ExternalDocument;
  }

  constructor(private readonly _modalService: ModalService,
    private readonly _entityService: EntityService,
    private readonly _documentEntityService: DocumentEntityService,
    private readonly _templateService: TemplateService,
    private readonly _progressService: ProgressService,
    private readonly _partnerPortalService: PartnerPortalService,
    private readonly _aclService: AclService,
    private activatedRoute: ActivatedRoute,
    private router: Router
  ) { }

  ngOnInit(): void {
    const documentCharTypeACL = AclUtil.getDataEntityAcl(CharTypeID.Document);
    if (this._aclService.acls) {
      this.canWrite = AclUtil.hasCreateAccess(this._aclService.acls, documentCharTypeACL);
    }
    else {
      this.canWrite = false;
    }


  }

  ngOnDestroy() {

  }

  openEditFileModal(event: Event) {
    console.log('open file');
  }

  closeEditFileModal() {
    this._progressService.clearProgress();
    this.editDocumentEntityModal.close();
  }

  getFileURL(fileID: number) {
    return this.fileURLs[fileID];
  }

  exportFiles(event: any) {
    event.preventDefault();
    this._progressService.startProgress();
    const fileIds = this.documents.map(d => d.recordID);
    this._documentEntityService.exportFiles(fileIds)
      .subscribe((result) => {
        this._progressService.endProgress();
      });
  }

  loadFiles(event: any) {
    this.fileUploaded.emit(true);
  }

}
