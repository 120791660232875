<div class="card mb-3">
	<div class="card-title d-flex justify-content-between">
		<h3>Files</h3>
		<div ngbDropdown>
			<button *ngIf="canCreate" ngbDropdownToggle type="button" class="btn btn-icon btn-success hide-caret"
				[disabled]="isLocked" data-cy="createFileDropdown" title="Create" role="button" aria-label="Create">
				<i class="far fa-plus"></i>
			</button>
			<ul ngbDropdownMenu class="dropdown-menu-right" role="menu">
				<li ngbDropdownItem *ngFor="let documentTemplate of documentTemplates">
					<a href="#" *ngIf="documentTemplate.templateGroupID !== 3"
						(click)="openCreateDocumentEntity(entityId, documentTemplate, $event)"
						[innerText]="documentTemplate.description" [attr.data-cy]="'createFileType' + documentTemplate.templateGroupID"></a>
					<a href="#" *ngIf="documentTemplate.templateGroupID === 3"
						(click)="openCreateDocumentEntity(entityId, documentTemplate, $event)" [attr.data-cy]="'createFileType' + documentTemplate.templateGroupID">Create
						{{documentTemplate.templateName}}</a>
				</li>
				<li *ngIf="documentFolderTemplates.length > 0" ngbDropdownItem><a href="#"
						(click)="showFolderUpload(entityId, $event)" title="Upload Folder" data-cy="uploadFolder">Upload Folder</a>
				</li>
			</ul>
		</div>
	</div>
	<div class="card-body pt-0">
		<div *ngIf="flattenDocumentHierarchyList.length == 0 && !loading" class="no-items-found d-flex align-items-center justify-content-center pt-3">
			<i class="far fa-th-list"></i>
			<span class="mb-0 ms-3">No records found.</span>
		</div>
		<div class="scroll-wrap" *ngIf="flattenDocumentHierarchyList.length > 0 && !loading">
			<div class="scrollable">
				<table class="table rl-table">
					<thead>
						<tr>
							<th>ID</th>
							<th>Name</th>
							<th *ngFor="let headerColumn of allReadChars" [innerText]="headerColumn.label"></th>
							<th>Template</th>
							<th>Status</th>
							<th></th>
						</tr>
					</thead>
					<tbody *ngIf="loading">
						<tr>
							<td colspan="6">Loading <i class="far fa-spinner fa-spin fa-2x ms-5"></i></td>
						</tr>
					</tbody>
					<tbody>
						<tr *ngFor="let document of flattenDocumentHierarchyList">
							<td>
								<span>{{document.recordID}}</span>
							</td>
							<td>
								<i *ngIf="document.templateGroupID==documentFolderTemplateGroupID"
									[ngStyle]="{'margin-left':(documentLevel[document.recordID]*20)+'px'}"
									[ngClass]="getFolderClass(document.recordID)" (click)="toggle(document)" data-cy="FolderType" title="Folder"></i>
								<i *ngIf="document.templateGroupID!=documentFolderTemplateGroupID"
									[ngStyle]="{'margin-left':(documentLevel[document.recordID]*20)+'px'}"
									class="far fa-file me-1" data-cy="FileType" title="File"></i>
								<span>{{document.title}}</span>
							</td>
							<td *ngFor="let column of allReadChars">
								<span *ngIf="column.dataTypeID==12 && document.characteristics[column.tagLabel]"
									[innerText]="getCleanFileName(document.characteristics[column.tagLabel])"></span>
								<span *ngIf="column.dataTypeID!=12 && document.characteristics[column.tagLabel]"
									[innerText]="document.characteristics[column.tagLabel]"></span>
							</td>
							<td [innerText]="document.template"></td>
							<td [innerText]="document.status"></td>
							<td class="p-0">
								<div class="hover-icons text-end">
									<button *ngIf="canWrite" (click)="openEditDocumentEntity(document.entityID, document, $event)"
										class="btn btn-icon btn-info btn-sm me-2" data-cy="editFile" title="Edit"><i
										class="far fa-pencil"></i></button>
									<button (click)="previewFile(document)" class="btn btn-icon btn-info btn-sm me-2"
										data-cy="previewFile" title="Preview"><i class="far fa-external-link-square"></i></button>
									<a *ngIf="document.templateGroupID!=documentFolderTemplateGroupID"
										[href]="downloadUrls[document.recordID]" class="btn btn-icon btn-info btn-sm me-2" title="Download" data-cy="downloadFile">
										<i class="far fa-download"></i></a>
									<div ngbDropdown container="body" class="d-inline-block me-2">
										<a *ngIf="document.templateGroupID==documentFolderTemplateGroupID"
											ngbDropdownToggle data-cy="folderTemplateGroupToggle" class="btn btn-icon btn-info btn-sm" title="Select Folder">
											<i class="far fa-plus"></i>
										</a>
										<ul ngbDropdownMenu class="dropdown-menu" role="menu">
											<li ngbDropdownItem *ngFor="let documentTemplate of documentTemplates">
												<a href="#" *ngIf="documentTemplate.templateGroupID !== 3"
													(click)="openCreateDocumentEntity(document.entityID, documentTemplate, $event)"
													[innerText]="documentTemplate.description" data-cy="folderTemplateGroupItem"></a>
												<a href="#" *ngIf="documentTemplate.templateGroupID === 3"
													(click)="openCreateDocumentEntity(document.entityID, documentTemplate, $event)" data-cy="folderTemplateGroupCreate">Create
													{{documentTemplate.templateName}}</a>
											</li>
											<li ngbDropdownItem>
												<a *ngIf="document.templateGroupID==documentFolderTemplateGroupID" type="button"
													(click)="showFolderUpload(document.entityID, $event)" data-cy="folderTemplateGroupUpload">Upload Folder</a>
											</li>
										</ul>
									</div>
								</div>
							</td>
						</tr>
					</tbody>
					<tbody *ngIf="!canRead">
						<tr>
							<td colspan="6" class="text-center bg-light no-items-found">
								<span class="mb-0 ms-3">No access to reading documents.</span>
							</td>
						</tr>
					</tbody>
				</table>
			</div>
		</div>
	</div>
</div>

<pp-modal #uploadFolderModal modalWidth="60%">
	<modal-title>Upload Folder</modal-title>
	<modal-body>
		<div class="mb-3 row mt-4">
			<label class="col-sm-4 control-label text-end pt-1">Upload file as:</label>
			<div class="col-sm-6 col-md-4">
				<select #select class="form-control form-select" [(ngModel)]="selectedDocFileTemplateID" data-cy="uploadFileAs">
					<option value="" class="">Select</option>
					<option *ngFor="let docFileTemplate of documentFileTemplates" [ngValue]="docFileTemplate.templateID"
						[innerText]="docFileTemplate.templateName"></option>
				</select>
			</div>
		</div>
	</modal-body>
	<modal-footer>
		<button type="button" class="btn btn-light" (click)="uploadFolderModalClose();" data-cy="cancelButton">Cancel</button>
		<button type="button" class="btn btn-success ms-2" [disabled]="selectedDocFileTemplateID==0"
			(click)="uploadFolder();" data-cy="uploadFolderButton">
			<i class="far fa-cloud-upload"></i> Upload Folder
		</button>
	</modal-footer>
</pp-modal>

<pp-modal #editDocumentEntityModal modalWidth="60%">
	<modal-title>{{uploadFileFolderModalTitle}} </modal-title>
	<modal-body>
		<char-data-table #uploadTable [charData]="uploadCharData" [template]="uploadCharMetaDataCollection"
			[editMode]="true" (tableCharDataChange)="uploadCharData=$event"></char-data-table>
	</modal-body>
	<modal-footer>
		<button type="button" class="btn btn-light" (click)="editDocumentEntityModalClose();" data-cy="editModalClose">Cancel</button>
		<button type="button" class="btn btn-success ms-2" [disabled]="!uploadTable.valid"
			(click)="saveDocumentEntity(uploadTable.valid);" busyButton><i class="fa"></i>Save</button>
	</modal-footer>
</pp-modal>

<job-progress-status *ngIf="startJobProgress" [jobId]="jobId" [jobTitle]="jobTitle" [showProgressSpinner]="true"
	[showProgressBar]="false" [allowJobToCancel]="false" [showModalCloseBtn]="false"
	(progressDone)="progressDone($event)"></job-progress-status>