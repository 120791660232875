import { Pipe, PipeTransform } from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";

@Pipe({ name: "safe" })
export class SafePipe implements PipeTransform {

	constructor(private sanitizer: DomSanitizer) { }

	/* WARNING: Only use this pipe if you trust the resource URL. */
	transform(url: string) {
		return this.sanitizer.bypassSecurityTrustResourceUrl(url);
	}

}
