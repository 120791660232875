<div class="section-head row align-items-center">
	<div class="col-sm-3">
		<h1 class="py-1">New Releases</h1>
    </div>
    <div class="col-sm-6 text-end">
        <explorer-view-selector (viewChanged)="viewChanged($event)" charTypeID="1"></explorer-view-selector>
    </div>
</div>

<div class="row">
    <div class="col-12">
        <div class="alert alert-info mt-3" *ngIf="initialized && !(documents | hasValues)">
            <strong>No Catalog Items found.</strong>
        </div>
        <explorer *ngIf="initialized && documents && documents.length > 0" [documents]="documents" [keyArt]="keyArt"
            [view]="view" [currentPage]="page" [pageCount]="pageCount" (pageChanged)="pageChanged($event)"
            [disablePaging]="true" [linkHelper]="linkHelper.catalogItems"></explorer>
    </div>
</div>