import { Injectable } from "@angular/core";
import * as $ from "jquery";
require("bootstrap-notify");

@Injectable()
export class NotifyService {

    successSettings = { type: "success" };
    errorSettings = { type: "error" };

    constructor() {

    }

    success(message: string) {
        let options = {
            title: "Success: ",
            message: message
        };
        $["notify"](options, this.successSettings);
    }

    error(message: string) {
        let options = {
            Title: "Error: ",
            message: message
        };
        $["notify"](options, this.errorSettings);
    }

}