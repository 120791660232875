import {Component, ChangeDetectionStrategy, OnChanges, SimpleChange, DoCheck} from "@angular/core";
import * as _ from "lodash";

import {BaseElement} from "./base-element";
import {ICharacteristicData} from "../../../models";

@Component({
    selector: "year-edit-element",
    template: "<year-edit-control [textValue]='textValue' (textValueChange)='textValueChanged($event)' (touch)='touched($event)' ></year-edit-control>",
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class YearEditElementComponent extends BaseElement implements OnChanges {

    textValue: string;

    constructor() {
        super();
    }

    ngOnChanges(changes: { [key: string]: SimpleChange; }) {
        if (this.charData) {
            this.textValue = _.join(this.charData.map(cd => cd.value), ", ") || "";
        }
    }

    textValueChanged(value: string) {
        let charData: ICharacteristicData = null;
        if (_.some(this.charData)) {
            charData = _.clone(this.charData[0]);
            charData.value = value;
            charData.valueID = null;
        } else {
            charData = this.createCharData(value);
        }
        this.charDataChange.emit({ cmd: this.cmd, charData: [charData] });
    }
}