<div class="row justify-content-center mt-5">
    <div class="col-sm-6">
        <h1 class="title">Password Reset</h1>

        <div class="card">
            <div class="card-body">
                <span *ngIf="isValidToken === null">Loading <i class="fa fa-circle-o-notch fa-spin"></i></span>
                <div *ngIf="isValidToken" class="row">
                    <form class="col-md-6" [formGroup]="resetForm" (ngSubmit)="claimPassword(token, password.value)">
                        <div class="alert alert-danger" *ngIf="invalidLogin">
                            <strong>
                                Invalid email/password
                            </strong>
                        </div>

                        <div class="mb-3" [class.has-error]="!password.valid && password.touched">
                            <label class="control-label">Password</label>
                            <input type="password" class="form-control" formControlName="password" data-cy="passwordField"/>
                            <control-error-messages [control]="password"></control-error-messages>
                        </div>
                        <div class="mb-3" [class.has-error]="!confirmPassword.valid && confirmPassword.touched">
                            <label class="control-label">Confirm Password</label>
                            <input type="password" class="form-control" formControlName="confirmPassword" data-cy="passwordConfirmField"/>
                            <control-error-messages [control]="confirmPassword"></control-error-messages>
                        </div>
                        <button type="submit" class="btn btn-success" busyButton="" [disabled]="!resetForm.valid" data-cy="passwordSubmit">
                            Submit
                        </button>
                    </form>
                    <div class="col-md-6">
                        <strong>Password Requirements</strong>
                        <ul class="list-unstyled">
                            <li>At least 8 characters in length</li>
                            <li>At least 1 uppercase letter</li>
                            <li>At least 1 lowercase letter</li>
                            <li>At least 1 number</li>
                            <li>At least 1 special character (ex !@#$%^&*()-_=+{{'{}'}}[]:";'<>,./?)</li>
                        </ul>
                    </div>
                </div>
                <div *ngIf="isValidToken === false || errorMessage">
                    <div class="alert alert-danger">
                        {{errorMessage}}
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>