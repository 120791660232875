export class JsonUtil {

    static Parse(value: string, defaultValue: any = null): any {
        try {
            var output = JSON.parse(value);
            return output || defaultValue;
        }
        catch (err) {
            return defaultValue;
        }
    }

    static Stringify(object: any) {
        return JSON.stringify(object);
    }
}