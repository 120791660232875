<pp-header></pp-header>
<div class="container-fluid app-content">
    <div class="row mb-5">
        <div class="col-12">
            <router-outlet></router-outlet>
        </div>
    </div>
</div>

<pp-footer></pp-footer>
<modal-backdrop></modal-backdrop>
