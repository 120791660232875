import { Component, ChangeDetectionStrategy, OnChanges, SimpleChange } from "@angular/core";
import * as _ from "lodash";

import { BaseElement } from "./base-element";
import { ICharacteristicData, ICharDataMoneyValue } from "../../../models";
import { EntityService } from "../../../services/entity.service";
import { ProgressService } from "../../../services/progress.service";
import { JsonUtil } from "../../../utils/json-util";

@Component({
    selector: "money-display-element",
    template: `<money-display-control [localAmount]="localAmount" [localCurrencySymbol]="localCurrencySymbol" [divAmount]="divAmount" [divCurrencySymbol]="divCurrencySymbol"></money-display-control>`,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class MoneyDisplayElementComponent extends BaseElement implements OnChanges {

    localAmount: string;
    localCurrencySymbol: string;
    divAmount: string;
    divCurrencySymbol: string;

    constructor(private entityService: EntityService, private progressService: ProgressService) {
        super();
    }

    ngOnChanges(changes: { [key: string]: SimpleChange; }) {
        this.localAmount = this.localCurrencySymbol = this.divAmount = this.divCurrencySymbol = null;

        if (this.charData) {
            if (_.some(this.charData)) {
                if (!_.isEmpty(this.charData[0].value)) {
                    let moneyValue = <ICharDataMoneyValue>JsonUtil.Parse(this.charData[0].value);
                    if (moneyValue) {
                        this.localAmount = moneyValue.locAmt;
                        this.localCurrencySymbol = moneyValue.locSym;
                        this.divAmount = moneyValue.divAmt;
                        this.divCurrencySymbol = moneyValue.divSym;

                        // this.cd.detectChanges();
                    }
                }
            }

        }
    }
}