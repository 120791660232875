<div class="card mb-3" *ngIf="canCreate || tableGroups?.length > 0">
	<div class="card-title d-flex justify-content-between">
		<h3>Tables</h3>
		<div class="card-buttons">
			<div ngbDropdown>
				<button *ngIf="canCreate" ngbDropdownToggle type="button" class="btn btn-icon btn-success hide-caret"
					[disabled]="isLocked" data-cy="createButtonDropdown" title="Create" role="button" aria-label="Create">
					<i class="far fa-plus"></i>
				</button>
				<ul ngbDropdownMenu class="dropdown-menu-right" role="menu">
					<li ngbDropdownItem *ngFor="let createTableTemplate of createTableTemplates">
						<a href="#" (click)="createTableRow(createTableTemplate, $event)"
							[innerText]="createTableTemplate.description"
							[attr.data-cy]="'createButtonTableTemplate_' + createTableTemplate.templateID"></a>
					</li>
				</ul>
			</div>
		</div>
	</div>
	<div class="card-body pt-0">
		<div class="no-items-found d-flex align-items-center justify-content-center pt-3">
			<ng-container *ngIf="!isLoading && tableGroups?.length == 0">
				<i class="far fa-th-list"></i>
				<span class="mb-0 ms-3">No records found.</span>
			</ng-container>
			<ng-container *ngIf="isLoading">
				<i class="far fa-spinner fa-spin me-1" aria-label="Loading Content" role="image"></i>
			</ng-container>
		</div>
		<div *ngIf="tableGroups?.length > 0">
			<div *ngFor="let group of tableGroups">
				<header class="table__header d-flex justify-content-between">
					<h4 [innerText]="group.templateName"></h4>
					<div ngbDropdown class="rl-table-dropdown" placement="bottom-end">
						<button ngbDropdownToggle class="btn btn-icon btn-info hide-caret" data-cy="gridOptionsDropdown"
							title="Expand Menu">
							<i class="far fa-bars"></i>
						</button>
						<div ngbDropdownMenu class="p-2" style="width:280px;" data-cy="TableActionsDropdownMenu">
							<ul ngbNav #nav="ngbNav" class="nav-tabs" [(activeId)]="activeId">
								<li [ngbNavItem]="bulkActionsTabId">
									<a ngbNavLink>
										<i class="far fa-th-list" [title]="charTypeNamePlural + ' Actions'" [attr.data-cy]="charTypeName + 'ActionsTabs'"></i>
									</a>
									<ng-template ngbNavContent>
										<h6 class="dropdown-header">Tables</h6>
										<div class="list-group list-group-flush">
											<button class="list-group-item" (click)="exportTablesToCSV($event, group)" data-cy="gridOptionsExport">Export CSV</button>
										</div>
									</ng-template>
								</li>
								<li ngbNavItem>
									<a ngbNavLink>
										<i class="far fa-chevron-circle-right" title="Workflow Actions"
											data-cy="TableWorkflowActionsTab"></i>
									</a>
									<ng-template ngbNavContent>
										<h6 class="dropdown-header">Workflow</h6>
										<div class="list-group list-group-flush">
											<button *ngFor="let action of workflowActions" data-cy="gridOptionsActionItem" class="list-group-item"
												(click)="executeAction($event, group, action)" [value]="action.actionID">{{action.actionName}}</button>
										</div>
									</ng-template>
								</li>
							</ul>
							<div [ngbNavOutlet]="nav"></div>
						</div>
					</div>
				</header>
				<div class="scroll-wrap mb-3">
					<div class="scrollable">
						<table class="table rl-table">
							<thead>
								<tr>
									<th><input type="checkbox" [checked]="isSelectAllChecked[group.templateID]"
											(change)="toggleSelectAll(group, $event)" data-cy="selectAllRows" aria-label="Select All Rows" /></th>
									<th *ngIf="groupHasCatalogItems[group.templateID]">Catalog Items</th>
									<th *ngIf="groupHasContactItems[group.templateID]">Contact Items</th>
									<th *ngFor="let cmd of visibleCharsByTemplateID[group.templateID]">
										<span class="table-header-link"
											(click)="sortClick(cmd, group.templateID)">{{cmd.label}}</span>
										<span
											*ngIf="facetFilterLookups[group.templateID][cmd.characteristicID]&& facetFilterLookups[group.templateID][cmd.characteristicID].length > 0"
											ngbDropdown #filterDropdown="ngbDropdown" [autoClose]="'outside'">
											<button ngbDropdownToggle type="button"
												class="table-header-filter hide-caret btn btn-sm btn-icon btn-info" role="button" aria-label="Filter Menu">
												<i class="far fa-filter"></i>
											</button>
											<div ngbDropdownMenu class="pager-dropdown" role="menu">
												<div ngbDropdownItem role="menuitem" class="form-check border-bottom">
													<input type="checkbox" [formControl]="allSelected">
													SELECT ALL
												</div>
												<div class="filter-list border-bottom">
													<button role="menuitem" ngbDropdownItem
														*ngFor="let filterItem of facetFilterLookups[group.templateID][cmd.characteristicID]"
														class="form-check">
														<input type="checkbox" [formControl]="filterItem[1]">
														{{filterItem[0].name}} ({{filterItem[0].numFound}})
													</button>
												</div>
												<div class="text-end px-2 pt-2">
													<button type="button" class="btn btn-info btn-sm"
														(click)="applyFilter(cmd.characteristicID, group.templateID); filterDropdown.close();">Apply</button>
												</div>
											</div>
										</span>
									</th>
									<th></th>
								</tr>
							</thead>
							<tbody>
								<tr *ngFor="let item of group.items">
									<td>
										<input type="checkbox" name="selectedTables"
											[checked]="selectedTables[group.templateID][item.record.id]"
											(change)="updateCheckedOptions(group, item, $event)" data-cy="selectRow" aria-label="Select Item" />
									</td>
									<td style="min-width:200px;" *ngIf="groupHasCatalogItems[group.templateID]">
										<span
											*ngFor="let assetID of item.assets; let last=last">{{tableAssetTitles[assetID]}}<span
												*ngIf="!last"> | </span></span>
									</td>
									<td style="min-width:200px;" *ngIf="groupHasContactItems[group.templateID]">
										<span
											*ngFor="let contactId of item.contacts; let last=last">{{tableContactTitles[contactId]}}<span
												*ngIf="!last"> | </span></span>
									</td>
									<td *ngFor="let cmd of visibleCharsByTemplateID[group.templateID]">
										<char-data-element [cmd]="cmd"
											[charData]="groupCharDatas[group.templateID][item.record.recordID][cmd.characteristicID] || []">
										</char-data-element>
									</td>
									<td class="p-0">
										<div class="hover-icons text-end">
											<button *ngIf="groupHasFileUploadConfigured[group.templateID]" type="button"
												class="btn btn-icon btn-sm btn-info me-2"
												(click)="uploadFileToTable(group.templateMetaData, item.record, item.charData)"
												title="Upload" data-cy="uploadFile">
												<i class="far fa-cloud-upload"></i>
											</button>
											<button
												*ngIf="canEdit[group.templateID] && !isLocked && item.status.lockIndicator == 0"
												type="button" class="btn btn-icon btn-sm btn-info me-2"
												(click)="editTableRow(group.templateMetaData, item.record, item.charData)"
												title="Edit" data-cy="editRecord">
												<i class="far fa-pencil"></i>
											</button>
											<button
												*ngIf="canDelete[group.templateID] && !isLocked && item.status.deleteIndicator == 1"
												type="button" class="btn btn-remove btn-sm btn-icon me-2"
												(click)="deleteTableRow(item.record)" title="Delete"
												data-cy="deleteRecord">
												<i class="far fa-times"></i>
											</button>
										</div>
									</td>
								</tr>
							</tbody>
						</table>
					</div>
				</div>
				<div class="text-center mb-3" *ngIf="groupPageCount[group.templateID]>1">
					<pp-pager [currentPage]="groupPage[group.templateID] || 1"
						[pageCount]="groupPageCount[group.templateID]" [showPages]="tablePageSize"
						(pageChanged)="tablePageChanged(group.templateID, $event)"></pp-pager>
				</div>
			</div>
		</div>
	</div>
</div>

<pp-modal #uploadFileModal modalWidth="60%">
	<modal-title>Upload File To {{uploadTableRecord?.title}}</modal-title>
	<modal-body>
		<char-data-table #uploadTable [charData]="uploadTableCharData" [template]="uploadTableTemplate"
			[editMode]="true" (tableCharDataChange)="uploadTableCharData=$event"></char-data-table>
	</modal-body>
	<modal-footer>
		<button type="button" class="btn btn-light" (click)="uploadFileModal.close()"
			data-cy="modalCancel">Cancel</button>
		<button type="button" class="btn btn-success ms-2" [disabled]="!uploadTable.valid"
			(click)="saveFileToTable(uploadTable.valid);" [disabled]="!uploadTable.valid" busyButton
			data-cy="modalSave"><i class="fa"></i>Save</button>
	</modal-footer>
</pp-modal>

<pp-modal #editModal modalWidth="60%">
	<modal-title>Edit {{editTableRecord?.title}}</modal-title>
	<modal-body>
		<char-data-table #editTableChars [charData]="editTableCharData" [template]="editTableTemplate" [editMode]="true"
			(tableCharDataChange)="editTableCharData=$event"></char-data-table>
	</modal-body>
	<modal-footer>
		<button type="button" class="btn btn-light" (click)="editModal.close()"
			data-cy="editModalCancel">Cancel</button>
		<button type="button" class="btn btn-success ms-2" [disabled]="!editTableChars.valid"
			(click)="updateTable(editTableChars.valid);" busyButton data-cy="editModalSave"><i
				class="fa"></i>Save</button>
	</modal-footer>
</pp-modal>

<pp-modal #createModal modalWidth="60%">
	<modal-title>Create {{createTableTemplate?.template.templateName}}</modal-title>
	<modal-body>
		<char-data-table #createTableChars [charData]="createTableCharData" [template]="createTableTemplate"
			[editMode]="true" (tableCharDataChange)="createTableCharData=$event"></char-data-table>
	</modal-body>
	<modal-footer>
		<button type="button" class="btn btn-light" (click)="createModal.close()"
			data-cy="createModalCancel">Cancel</button>
		<button type="button" class="btn btn-success ms-2" [disabled]="!createTableChars.valid"
			(click)="createTable(createTableChars.valid);" busyButton data-cy="createModalSave"><i
				class="fa"></i>Save</button>
	</modal-footer>
</pp-modal>

<job-progress-status *ngIf="startJobProgress" [jobId]="jobId" [jobTitle]="jobTitle" [showProgressSpinner]="true"
	[showProgressBar]="false" [allowJobToCancel]="false" [showModalCloseBtn]="false"
	(showResultMessage)="showResultMessage($event)" (progressDone)="progressDone($event)"></job-progress-status>