import { WizardComponent } from '@achimha/angular-archwizard';
import { ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { CharDataTableComponent } from 'app/common/components/char-data/char-data-table.component';
import { CharTypeID } from 'app/common/consts';
import { ICharDatas, ICharacteristicData, ICharacteristicMetaDataCollection, IEntityID, IEntitySearchDoc } from 'app/common/models';
import { AclService } from 'app/common/services/acl.service';
import { DocumentEntityService } from 'app/common/services/document-entity.service';
import { EntityService } from 'app/common/services/entity.service';
import { ProgressService } from 'app/common/services/progress.service';
import { TemplateService } from 'app/common/services/template.service';
import { IDUtil } from 'app/common/utils/id-util';
import { PartnerPortalService } from 'app/partner-portal/services/partner-portal.service';
import * as _ from 'lodash';
import { of } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Component({
  selector: 'new-file-wizard',
  templateUrl: './new-file-wizard.component.html',
  styleUrls: ['./new-file-wizard.component.scss']
})
export class NewFileWizardModalComponent implements OnInit, OnChanges, OnDestroy {

  @Input()
  templateId: number;

  @Input()
  divId: number;

  @Output()
  fileCreated: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Output()
  closeModal: EventEmitter<void> = new EventEmitter<void>();

  @ViewChild(WizardComponent)
  wizard: WizardComponent;

  @ViewChild('fileUploadTable')
  charDataTable: CharDataTableComponent;

  isLoading = true;
  isLoadingAssociatedRecords = true;

  uploadCharMetaDataCollection: ICharacteristicMetaDataCollection;
  uploadCharData: ICharacteristicData[];
  selectedAssociateRecord: string;
  selectedRecordId: number;
  selectedEntityId: IEntityID;

  totalAssociableRecords: number;

  associableDeals: IEntitySearchDoc[];

  isSaving: boolean = false;

  public get CharTypeID() {
    return CharTypeID;
  }

  constructor(private readonly _entityService: EntityService,
    private readonly _documentEntityService: DocumentEntityService,
    private readonly _templateService: TemplateService,
    private readonly _progressService: ProgressService,
    private readonly _partnerPortalService: PartnerPortalService,
    private readonly _aclService: AclService,
    private cd: ChangeDetectorRef) { }


  ngOnChanges(changes: SimpleChanges): void {
    if (changes["templateId"] != null && changes["templateId"].currentValue != null) {
      this.uploadCharData = undefined;
      this.uploadCharMetaDataCollection = undefined;
      this.selectedEntityId = undefined;
    }
    if (this.templateId) {
      this.isLoading = true;
      const fileTemplateId = IDUtil.toTemplateID(this.divId, CharTypeID.Document, this.templateId);
      this._partnerPortalService.getEmptyFileWizardData(fileTemplateId)
        .subscribe(initializationResponse => {
          this.uploadCharMetaDataCollection = initializationResponse.emptyCharData.templateMetaData;
          this.totalAssociableRecords = initializationResponse.totalRecords;
          this.uploadCharData = {} as ICharacteristicData[];
          if (!!initializationResponse.onlyAvailableEntity && IDUtil.isEntityID(initializationResponse.onlyAvailableEntity)) {
            this.selectedEntityId = IDUtil.splitEntityID(initializationResponse.onlyAvailableEntity);
          }
          this.isLoading = false;
        });
    }
  }

  ngOnInit(): void { }

  goToAssociate() {
    if (this.wizard) {
      this.wizard.goToNextStep();
    }
  }

  goToRecordFields() {
    if (this.wizard) {
      this.wizard.goToPreviousStep();
    }
  }

  close($event: Event) {
    this.templateId = undefined;
    this.uploadCharData = undefined;
    this.uploadCharMetaDataCollection = undefined;
    this.selectedEntityId = undefined;
    this.detectChanges();
    if (this.charDataTable) {
      this.charDataTable.template = undefined;
      this.charDataTable.charData = undefined;
      this.charDataTable.detectChanges();
    }
    this.goToRecordFields();
    $event?.preventDefault();
    this.closeModal.emit();
  }

  detectChanges() {
    this.cd?.detectChanges();
  }

  save($event: Event) {
    this.isSaving = true;
    $event?.preventDefault();
    if (this.selectedEntityId) {
      this._progressService.startProgress();
      let charData: ICharDatas = {};
      _.forEach(this.uploadCharData,
        data => {
          let metaData = _.find(this.uploadCharMetaDataCollection.characteristicMetaDatas,
            cmd => cmd.characteristicID === data.charactersticID);
          charData[metaData.tagLabel] = [data];
        });
      const entityTemplateId = IDUtil
        .toTemplateID(this.divId, CharTypeID.Document, this.templateId);
      let parentId = `${this.selectedEntityId}`;
      if (this.selectedEntityId.charTypeID) {
        parentId = IDUtil.toID(this.selectedEntityId.divID, this.selectedEntityId.charTypeID, this.selectedEntityId.recID);
      }
      this._documentEntityService.createDocument(entityTemplateId, parentId, charData)
        .pipe(catchError((e) => {
          return of({} as { entityID: string, relRecId: number })
        }))
        .subscribe((result: { entityID: string, relRecId: number }) => {
          if (!!result.entityID) {
            this.fileCreated.emit(true);
            this.close(null);
            this.goToRecordFields();
          }
          this._progressService.endProgress();
          this._progressService.clearProgress();
          this.isSaving = false;
        });
    } else {
      this._progressService.clearProgress();
      this.isSaving = false;
    }
  }

  updateSelection(entityID: IEntityID) {
    this.selectedEntityId = entityID;
  }
  ngOnDestroy(): void {
    this.templateId = undefined;
    this.uploadCharData = undefined;
    this.uploadCharMetaDataCollection = undefined;
    this.selectedEntityId = undefined;
    this.detectChanges();
    if (this.charDataTable) {
      this.charDataTable.template = undefined;
      this.charDataTable.charData = undefined;
      this.charDataTable.detectChanges();
    }
  }

}
