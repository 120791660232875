
import { Component, OnDestroy, OnInit } from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { filter } from 'rxjs/operators';

import { Subscription } from "rxjs";

import { ExplorerView } from "../../../common/consts";
import { ICharacteristic, IEntityDocGroup } from "../../../common/models";
import { ProgressService } from "../../../common/services/progress.service";
import { PartnerPortalService } from "../../services/partner-portal.service";
import { PPLinkHelperService } from "../../services/pp-link-helper.service";

import * as _ from "lodash";

@Component({
    selector: "files-list",
    templateUrl: "./files-list.component.html",
})
export class FilesListComponent implements OnInit, OnDestroy {

    routeSub: Subscription;

    templateFacets: { name: string; id: number; }[];
    statuses: { name: string; id: number; }[];
    searchForm: UntypedFormGroup;

    documents: IEntityDocGroup[];
    fileURLs: _.Dictionary<string>;
    associatedDeals: _.Dictionary<string>;
    sourceFields: { [template: number]: ICharacteristic[] };

    pageCount = 0;
    pageSize = 25;
    page = 1;
    rows = 25;

    templateID: string = "";
    keywords: string = "";
    statusID: string = "";
    view: ExplorerView = ExplorerView.FileList;

    public get start(): number {
        return (this.page - 1) * this.pageSize;
    }

    constructor(
        private partnerPortalService: PartnerPortalService,
        private progressService: ProgressService,
        private activatedRoute: ActivatedRoute,
        private router: Router,
        private fb: UntypedFormBuilder,
        public linkHelper: PPLinkHelperService) {
        this.searchForm = fb.group({
            "keywords": [this.keywords],
            "template": [this.templateID],
            "status": [this.statusID]
        });
    }

    ngOnInit() {
        this.routeSub = this.activatedRoute.parent
            .params
            .subscribe(params => {
                this.keywords = params["k"] || "";
                this.templateID = params["t"] || "";
                this.page = +params["p"] || 1;
                this.statusID = params["s"] || "";
                this.search();
            });

        //this.search();
        this.searchForm.controls["template"]
            .valueChanges.pipe(
                filter((value) => value != this.templateID))
            .subscribe((value) => {
                this.page = 1;
                this.nextSearch();
            });
        this.searchForm.controls["status"]
            .valueChanges.pipe(
                filter((value) => value != this.statusID))
            .subscribe((value) => {
                this.page = 1;
                this.nextSearch();
            });
    }

    ngOnDestroy() {
        this.routeSub.unsubscribe();
    }

    search() {
        let start = this.rows * (this.page - 1);
        this.progressService.startProgress();
        return this.partnerPortalService.getAssociatedFiles(this.keywords, _.parseInt(this.templateID), this.start, this.pageSize, _.parseInt(this.statusID))
            .subscribe((r) => {
                this.documents = r.documents;
                this.fileURLs = r.fileURLs;
                this.associatedDeals = r.associatedDeals;
                this.sourceFields = r.sourceFields;

                this.pageCount = Math.ceil(r.numFound / this.pageSize);
                this.templateFacets = r.templateFacets.map((kvp) => {
                    let parts = kvp.key.split("|");
                    return {
                        name: `${parts[0]} (${kvp.value})`,
                        id: _.parseInt(parts[1])
                    };
                });
                this.statuses = r.statusFacets.map((kvp) => {
                    let parts = kvp.key.split("|");
                    return {
                        name: `${parts[0]} (${kvp.value})`,
                        id: _.parseInt(parts[1])
                    };
                });
                this.progressService.endProgress();
            });
    }

    nextSearch() {
        let keywords = this.searchForm.controls["keywords"].value || "";
        let templateID = this.searchForm.controls["template"].value || "";
        let statusID = this.searchForm.controls["status"].value || "";
        this.router.navigate(this.linkHelper.files.list({ k: keywords, p: this.page, t: templateID, s: statusID }));
    }


    optionTrackBy(value: { name: string; id: number; }) {
        return value.id;
    }

    pageChanged(page: number) {
        this.page = page;
        this.nextSearch();
    }

    viewChanged(view: ExplorerView) {
        this.view = view;
    }

    submitSearch() {
        this.page = 1;
        this.nextSearch();
    }
}