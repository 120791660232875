import { Component, Inject, Input, OnDestroy, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { PARTNER_PORTAL_SETTINGS } from "app/partner-portal/settings";
import * as _ from "lodash";
import { EntityLinks } from "../../../../partner-portal/services/pp-link-helper.service";
import { IEntitySearchDoc, IPartnerPortalSettings } from "../../../models";
import { AnimationHelper } from "../../../utils/animation-helper";

@Component({
    selector: "explorer-tile",
    templateUrl: "./explorer-tile.component.html",
    animations: [
        AnimationHelper.fade()
    ]
})
export class ExplorerTileComponent implements OnInit, OnDestroy {
    @Input()
    documents: IEntitySearchDoc[];
    @Input()
    keyArt: _.Dictionary<string>;
    @Input()
    linkHelper: EntityLinks;


    displayTitleField: string;

    constructor(
        private activatedRoute: ActivatedRoute,
        private router: Router,
        @Inject(PARTNER_PORTAL_SETTINGS) private settings: IPartnerPortalSettings) {


        if (this.settings.partnerPortal != null) {
            if (this.settings.partnerPortal.displayTitleField != null) {
                this.displayTitleField = this.settings.partnerPortal.displayTitleField;
            }
        }
    }

    ngOnInit() {

    }

    ngOnDestroy() {

    }

    getTitleString(doc: IEntitySearchDoc) {
        if (!_.isNull(this.displayTitleField)) {
            if (_.isEmpty(doc.characteristics[this.displayTitleField])) {
                return doc.title;
            }
            else {
                return doc.characteristics[this.displayTitleField];
            }
        }
        else {
            return doc.title;
        }
    }
}