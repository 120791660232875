<div class="card-grid row row-cols-2 row-cols-md-4 row-cols-xl-6">
    <div class="col mb-4" *ngFor="let document of documents">
        <div class="card">
            <div class="card-img-top text-center" [ngClass]="{'noimage': !keyArt[document.recordID]}">
                <a href="#" [routerLink]="linkHelper.catalogItems.detail(document.recordID)" data-cy="keyArtImage"><img
                        *ngIf="keyArt[document.recordID]" [src]="keyArt[document.recordID]" /></a>
            </div>
            <div class="card-body">
                <h3><a href="#"
                        [routerLink]="linkHelper.catalogItems.detail(document.recordID)" data-cy="documentTitle">{{getTitleString(document)}}</a>
                </h3>
                <p *ngIf="document.characteristics['release_year_int']">Release Year:
                    {{document.characteristics['release_year_int']}}</p>
            </div>
        </div>
    </div>
</div>
<div class="text-center" [hidden]="!documents">
    <pp-pager [currentPage]="page" [pageCount]="pageCount" showPages="10" (pageChanged)="pageChanged($event)">
    </pp-pager>
</div>