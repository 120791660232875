import { Component, Input, OnInit } from "@angular/core";
import { endsWith, some } from "lodash";
import { imgExtensions, msOfficeExtensions, msOfficeLiveUrl, TemplateGroupIds } from "./preview-document.models";
import { IPreviewDocument } from "app/common/models";

@Component({
	selector: "rl-preview-document",
	templateUrl: "./preview-document.component.html",
	styleUrls: ["./preview-document.component.scss"]
})
export class PreviewDocumentComponent implements OnInit {

	@Input()
	templateGroupId: number = TemplateGroupIds.RightslineDocTemplateGroup;

	documentSrc: string;
	isImage = false;

	@Input()
	set file(file: IPreviewDocument) {
		this.documentSrc = this.buildDocumentSrc(file);
		const fileName = file && file.fileName;
		this.isImage = some(imgExtensions, ext => endsWith(fileName, ext));
	}

	constructor() { }

	ngOnInit(
	) {
	}

	private buildDocumentSrc(file: IPreviewDocument) {
		if (!file || !file.uri) {
			return null;
		}

		const uri = file.uri;
		const fileName = file.fileName;

		if (this.templateGroupId === TemplateGroupIds.RightslineDocTemplateGroup && some(msOfficeExtensions, ext => endsWith(fileName, ext))) {
			return msOfficeLiveUrl + encodeURIComponent(uri);
		}
    
		return uri;
	}
}
