<div [ngSwitch]="isTextArea" class="input-group">
	<textarea *ngSwitchCase="true" class="form-control" [ngModel]="textValue" (keyup)="keyUp.emit($event.target.value)"
		(change)="textValueChange.emit($event.target.value)" (blur)="touched($event)" (ngModelChange)="change($event)"
		[placeholder]="placeholder" maxlength="{{maxLength}}" data-cy="TextEditControlTextarea"></textarea>
	<input *ngSwitchDefault type="text" class="form-control" [ngModel]="textValue"
		(keyup)="keyUp.emit($event.target.value)" (change)="textValueChange.emit($event.target.value)"
		[placeholder]="placeholder" (ngModelChange)="change($event)" (blur)="touched($event)"
		maxlength="{{maxLength}}" data-cy="TextEditControlInput" />
	<div class="input-group-append">
		<ng-content select="append-with"></ng-content>
	</div>
</div>

<span class="text-length">{{ contentLength}}/{{maxLength}}</span>