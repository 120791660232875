import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { CharTypeDisplay, ExplorerView } from 'app/common/consts';
import { IEntityID, IEntitySearchDoc } from 'app/common/models';
import { AclService } from 'app/common/services/acl.service';
import { ProgressService } from 'app/common/services/progress.service';
import { AclUtil } from 'app/common/utils/acl.util';
import { PartnerPortalService } from 'app/partner-portal/services/partner-portal.service';
import { PPLinkHelperService } from 'app/partner-portal/services/pp-link-helper.service';
import { Subscription } from 'rxjs';
import { IPageChangedEvent } from '../../component-pager/component-pager.component';

@Component({
  selector: 'selectable-association-list',
  templateUrl: './selectable-association-list.component.html',
  styleUrls: ['./selectable-association-list.component.scss']
})
export class SelectableAssociationListComponent implements OnInit, OnChanges {

  @Input()
  entityId: string;

  @Input()
  associatedCharTypeId: number;

  @Input()
  divId: number;

  @Input()
  selectedEntityID: IEntityID;

  @Input()
  templateId: number;

  @Output()
  selection: EventEmitter<IEntityID> = new EventEmitter<IEntityID>();

  parentCharTypeID: number;
  parentRecordID: number;
  associatedCharTypeID: number;
  routeSub: Subscription;

  templateFacets: { name: string; id: number; }[];
  searchForm: UntypedFormGroup;

  public documents: IEntitySearchDoc[];
  public jobs: IEntitySearchDoc[];
  public catalogItems: IEntitySearchDoc[];
  public projects: IEntitySearchDoc[];
  public accountingDocs: IEntitySearchDoc[];
  public relatedDocs: IEntitySearchDoc[];
  public documentTitle: string;
  pageCount = 0;
  pageSize = 25;
  page = 1;
  rows = 25;
  canRead: boolean = null;
  numFound = 0;

  keywords: string = "";
  view: ExplorerView = ExplorerView.List;

  public get start(): number {
    return (this.page - 1) * this.pageSize;
  }

  constructor(
    private partnerPortalService: PartnerPortalService,
    private progressService: ProgressService,
    private aclService: AclService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private fb: UntypedFormBuilder,
    public linkHelper: PPLinkHelperService) {
    this.searchForm = fb.group({
      "keywords": [this.keywords],
      "template": [this.templateId]
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes["selectedEntityID"] != null && changes["selectedEntityID"].currentValue != null) {
      console.log('new entity ID', this.selectedEntityID);
    }
    if (changes["templateId"] != null && changes["templateId"].currentValue != null) {
      this.searchAssociatedDocs();
    }
  }

  ngOnInit() {
    this.searchAssociatedDocs();
  }

  checkAcls(charTypeID: number) {
    var charTypeACL = AclUtil.getDataEntityAcl(charTypeID);
    if (this.aclService.acls) {
      return AclUtil.hasReadAccess(this.aclService.acls, charTypeACL);
    } else {
      return true;
    }
  }

  searchAssociatedDocs() {
    if (this.checkAcls(this.associatedCharTypeId)) {
      this.getAssociatedRecords();
    }
  }

  getAssociatedRecords() {
    if (this.templateId) {
      this.progressService.startProgress();
      this.partnerPortalService
        .getAssociableRecords(this.templateId, this.associatedCharTypeId, this.start, this.pageSize)
        .subscribe((r) => {
          this.relatedDocs = r.documents;
          this.documentTitle = CharTypeDisplay[this.associatedCharTypeId];
          this.numFound = r.numFound;
          this.pageCount = Math.ceil(r.numFound / this.pageSize);
          this.progressService.endProgress();
        });
    }
  }

  pageChanged($event: IPageChangedEvent) {
    if ($event.page !== this.page || $event.pageSize !== this.pageSize) {
      this.page = $event.page;
      this.pageSize = $event.pageSize;
      this.getAssociatedRecords();
    }
  }

  onRadioChange(entityID: IEntityID) {
    console.log(entityID);
    this.selection.emit(entityID);
  }

}
