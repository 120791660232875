<div ngbDropdown>
	<button ngbDropdownToggle type="button" class="btn btn-icon btn-success hide-caret " [disabled]="uploading" data-cy="uploadFileButton">
		<i class="far fa-plus fa-lg"></i>
	</button>
	<ul ngbDropdownMenu class="dropdown-menu-right" role="menu">
		<li *ngFor="let documentTemplate of documentTemplates">
			<a ngbDropdownItem href="#" (click)="openFileUploadModal(documentTemplate, $event)" [innerText]="documentTemplate.description"
				[attr.data-cy]="'UploadFile' + documentTemplate.templateGroupID"></a>
		</li>
	</ul>
</div>

<pp-modal #editDocumentEntityModal modalWidth="100%" [showFooter]="false">
	<modal-title>{{uploadFileFolderModalTitle}} </modal-title>
	<modal-body>
		<new-file-wizard *ngIf="uploadTemplateID" #wizardModal [templateId]="uploadTemplateID" [divId]="divId"
			(closeModal)="closeFileUploadModal($event)" (fileCreated)="fileSaved()"></new-file-wizard>
	</modal-body>
</pp-modal>