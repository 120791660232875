import { Component, Inject, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { IContactPartyGroup, IContactsDictionaries } from "app/common/messages";
import { PARTNER_PORTAL_SETTINGS } from "app/partner-portal/settings";
import * as _ from "lodash";
import { combineLatest, Subscription } from "rxjs";
import { flatMap, tap } from "rxjs/operators";
import { ModalComponent } from "../../../common/components/modal.component";
import { CharTypeID } from "../../../common/consts";
import { ICharacteristicData, ICharacteristicMetaDataCollection, IEntity, IEntitySearchDoc, IEntityStatus, IPartnerPortalSettings, IParty } from "../../../common/models";
import { AclService } from "../../../common/services/acl.service";
import { EntityService } from "../../../common/services/entity.service";
import { NotifyService } from "../../../common/services/notify.service";
import { ProgressService } from "../../../common/services/progress.service";
import { AclUtil } from "../../../common/utils/acl.util";
import { PartnerPortalService } from "../../services/partner-portal.service";
import { PPLinkHelperService } from "../../services/pp-link-helper.service";


interface IContactPair {
	party: IParty;
	contactID: number;
	contactTitle: string;
};

@Component({
	selector: "deals-detail",
	templateUrl: "./deals-detail.component.html"
})
export class DealsDetailComponent implements OnInit, OnDestroy {
	routeSub: Subscription;

	@ViewChild("editUsageModal")
	editUsageModal: ModalComponent;

	@ViewChild("uploadFileModal")
	uploadFileModal: ModalComponent;

	entity: IEntity;
	entityStatus: IEntityStatus;
	isLocked: boolean;
	recordID: number;
	title: string;
	templateMetaData: ICharacteristicMetaDataCollection;
	charData: ICharacteristicData[];
	catalogItems: IEntitySearchDoc[];
	contacts: IContactPair[];
	sortField: string;
	dictionaries: IContactsDictionaries = {} as IContactsDictionaries;
	// contactGroups: { [groupId: number]: IContactPair[] } = {};

	contactPartyGroups: IContactPartyGroup[];

	canWrite: boolean = false;
	canCreate: boolean = false;
	canDelete: boolean = false;

	editMode: boolean = false;
	relatedCharTypeIDs: number[] = [];

	constructor(
		private activatedRoute: ActivatedRoute,
		private entityService: EntityService,
		private progressService: ProgressService,
		private partnerPortalService: PartnerPortalService,
		private aclService: AclService,
		private notifyService: NotifyService,
		public linkHelper: PPLinkHelperService,
		@Inject(PARTNER_PORTAL_SETTINGS) private settings: IPartnerPortalSettings) {

		if (this.settings.partnerPortal) {
			if (this.settings.partnerPortal.displayTitleField != null) {
				this.sortField = this.settings.partnerPortal.displayTitleField;
			}
		}
	}

	ngOnInit() {
		//Permissions
		if (this.aclService.acls) {
			var charTypeACL = AclUtil.getDataEntityAcl(CharTypeID.Transaction);
			this.canCreate = AclUtil.hasCreateAccess(this.aclService.acls, charTypeACL);
			this.canWrite = AclUtil.hasWriteAccess(this.aclService.acls, charTypeACL);
			this.canDelete = AclUtil.hasDeleteAccess(this.aclService.acls, charTypeACL);
		} else {
			this.canCreate = false;
			this.canWrite = false;
			this.canDelete = false;
		}

		this.routeSub = this.activatedRoute.params
			.pipe(
				tap(params => {
					this.progressService.startProgress();
					this.recordID = _.parseInt(params["id"]);
					this.getRelatedCharTypeIds();
				}),
				flatMap(() => this.refresh$()))
			.subscribe(() => {
				this.getDealStatus$();
				this.progressService.endProgress();
			});
	}

	ngOnDestroy() {
		this.routeSub.unsubscribe();
	}

	refresh() {
		this.refresh$().subscribe();
	}

	refresh$() {
		return combineLatest(
			this.getDealDetails$()
		);
	}

	getDealDetails$() {
		return this.partnerPortalService.getDealDetails(this.recordID)
			.pipe(
				tap(result => {
					this.templateMetaData = result.template;
					this.charData = result.charData;
					this.entity = result.record;
					this.title = result.record.title;
				}));
	}
	getDealStatus$() {
		this.entityService.getTemplateStatus(this.entity.charTypeID, this.entity.templateID, this.entity.statusID).subscribe(
			result => {
				this.entityStatus = result;
				this.isLocked = this.entityStatus.lockIndicator == 1;
			});
	}


	getTitleString(doc: IEntitySearchDoc) {
		if (!_.isNull(this.sortField)) {
			if (_.isEmpty(doc.characteristics[this.sortField])) {
				return doc.title;
			} else {
				return doc.characteristics[this.sortField];
			}
		} else {
			return doc.title;
		}
	}

	editCharData() {
		this.editMode = true;
	}

	cancel() {
		this.editMode = false;
		this.getDealDetails$();
	}

	saveCharData() {
		this.progressService.startProgress();
		this.editMode = false;

		this.entityService.updateCharData(this.recordID, CharTypeID.Transaction, this.charData)
			.subscribe(result => {
				if (result.success) {
					this.notifyService.success("Save complete");
				} else {
					this.notifyService.error("Error saving");
				}

				this.progressService.endProgress();
			});
	}

	getRelatedCharTypeIds() {
		this.relatedCharTypeIDs.push(CharTypeID.Property);
		this.relatedCharTypeIDs.push(CharTypeID.Job);
		this.relatedCharTypeIDs.push(CharTypeID.Project);
		this.relatedCharTypeIDs.push(CharTypeID.Transaction);
	}
}