import {Directive, HostBinding, Attribute, Optional} from "@angular/core";

@Directive({
    selector: "i.fa:not([notBusy])",
    exportAs: "faIcon"
})
export class FaIconDirective {
    @HostBinding("class")
    iconClass: string;
    constructor(@Optional() @Attribute("class") private _class) {
        this.iconClass = _class;
    }
}