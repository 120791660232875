<div class="pp-char-data-table p-3" [formGroup]="tableForm" *ngIf="_initialized">
    <div *ngIf="!_groups || _groups.length < 1" class="no-items-found d-flex align-items-center justify-content-center pt-3">
        <i class="far fa-th-list"></i>
        <h5 class="mb-0 ms-3">No records found.</h5>
    </div>
    <div class="pp-char-data-group" *ngFor="let group of _groups">
        <h4 [innerText]="group.groupLabel"></h4>
        <div class="pp-char-data-elements">
            <div class="pp-char-data-element" *ngFor="let cmd of _cmdGroups[group.groupID]"
						[class.has-error]="hasError(cmd) || hasRequiredError(cmd)"
						[class.has-warning]="hasWarning(cmd) || isDesired(cmd)">
                <div class="pp-char-data-element-label" [cmd]="cmd" [editMode]="editMode" [isRequired]="isRequired(cmd)" [hasWarning]="hasWarning(cmd)">
                    <sup [class.text-danger]="isRequired" [class.text-warning]="cmd.requiredIndicator === 2" *ngIf="editMode && cmd.requiredIndicator === 1 || cmd.requiredIndicator === 2">*</sup>
                    <span [innerText]="cmd.label"></span>
                </div>
                <div class="pp-char-data-element-control mb-3">
                    <char-data-element [cmd]="cmd" [charData]="(_charDatas[cmd.characteristicID] || [])" [editMode]="editMode" (elementDataChange)="elementDataChanged($event)" [formControl]="tableForm.controls[cmd.tagLabel]"></char-data-element>
                    <element-error-messages *ngIf="hasError(cmd) || hasWarning(cmd)" [errors]="getFormControl(cmd).errors"
										[warnings]="getWarningFormControl(cmd).warnings" class="pp-char-data-table-errors"></element-error-messages>
                </div>
            </div>
        </div>
    </div>
    <!--{{_charDatas|json}}-->
</div>