import * as moment from "moment";

export class DateUtil {

    static perpetuityMoment = moment("09-09-9999", "MM-DD-YYYY");

    static isPerpetuity(dateValue: string) {
        let m1 = moment(dateValue, "MM-DD-YYYY");
        return m1.isValid() && m1.isSame(DateUtil.perpetuityMoment);
    }
}