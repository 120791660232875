import { PickerFileMetadata } from "filestack-js";
import { Dictionary } from "lodash";
import * as Models from "./models";

export interface ICatalogItemDetailResponse {
	record: Models.IEntity;
	title: string;
	templateID: string;
	templateName: string;
	keyArt: string;
	template: Models.ICharacteristicMetaDataCollection;
	charData: Models.ICharacteristicData[];
	files: IDetailFile[];
	inventoryMetaData: IInventoryMetaData[];
}

export interface ISPCatalogItemDetailResponse {
	title: string;
	templateID: string;
	templateName: string;
	keyArt: string;
	characteristics: { key: string; value: string }[];
	episodeID: number;
	videoID: number;
	bcAccountID: number;
	seriesID: number;
	seriesName: string;
	seasonID: number;
}

export interface IDetailFile {
	name: string;
	type: string;
	ext: string;
	fileSize: string;
	downloadUrl: string;
	test: string;
}

export interface IInventoryMetaData {
	name: string;
	streetDate: string;
	msrp: string;
	sku: string;
	upc: string;
}

export interface IGetAccountInfoResponse {
	charData: Models.ICharacteristicData[];
	template: Models.ICharacteristicMetaDataCollection;
}

export interface IGetContactsResponse {
	groupedParties: IContactPartyGroup[];
	parties: { [partyId: number]: IParty };
	partyGroups: { [groupId: number]: IPartyGroup };
	contacts: { [contactRecordId: number]: IEntityCharDataPair };
	editContacts: { [contactRecordId: number]: string };

}

export interface IEntityCharDataPair {
	characteristics: ICharacteristicDataCollection;
	entity: Models.IEntity;
}

export interface ICharacteristicDataCollection {
	characteristicDatas: Models.ICharacteristicData[];
	entityID: string;
	entityStatus: Models.IEntityStatus;
	templateMetaData: Models.ICharacteristicMetaDataCollection;
}
export interface IContact {
	recordId: number;
	sequenceNumber: number;
	tempId?: string;
}

export interface IContactPartyGroup {
	groupId: number;
	contactParties: IContactParty[];
}

export interface IContactsDictionaries {
	parties: { [partyId: number]: IParty };
	partyGroups: { [groupId: number]: IPartyGroup };
	contacts: { [contactRecordId: number]: IEntityCharDataPair };
	editContacts: { [contactRecordId: number]: string };
}

export interface IContactParty {
	partyId: number;
	isAllSelected: boolean;
	selectedCount: number;
	contacts: IContact[];
}

export interface IParty {
	divisionID: number;
	charTypeID: number;
	templateID: number;
	partyID: number;
	partyGroupID: number;
	partyName: string;
	partyDescription: string;
	requiredIndicator: number;
	multipleIndicator: number;
	editIndicator: number;
	deleteIndicator: number;
	primaryIndicator: number;
	visibilityIndicator: number;
	sequenceNumber: number;
	createdBy: number;
	createdAt: Date;
	updatedBy: number;
	updatedAt: Date;
}

export interface IPartyGroup {
	divisionID: number;
	groupID: number;
	groupLabel: string;
	groupDescription: string;
	expandIndicator: number;
	expandGroupIndicator: number;
	systemIndicator: number;
	visibilityIndicator: number;
	sequenceNumber: number;
	userID: number;
}

export interface IPartyGroup {
	divisionID: number;
	groupID: number;
	groupLabel: string;
	groupDescription: string;
	expandIndicator: number;
	expandGroupIndicator: number;
	systemIndicator: number;
	visibilityIndicator: number;
	sequenceNumber: number;
	userID: number;
}

export interface IGetEntityDataResponse {
	record: Models.IEntity;
	charData: Models.ICharacteristicData[];
	template: Models.ICharacteristicMetaDataCollection;
	files: IDetailFile[];
}

export interface IGetEmptyCharDataResponse {
	characteristicData: Models.ICharacteristicData[];
	characteristicMetaDataCollection: Models.ICharacteristicMetaDataCollection;
}

export interface IGetAssetEntityDataResponse extends IGetEntityDataResponse {
	keyart: string;
	clipUrl: string;
}

export interface IGetDealTablesResponse {
	templates: Models.ICharacteristicTemplate[];
	assetTitles: { [key: string]: string };
	groups: IDealTableGroup[];
}

export interface IGetAssociatedTemplatesForTemplateResponse {
	templates: Models.ICharacteristicTemplate[];
}

export interface IDealTableGroup {
	templateID: number;
	templateName: string;
	numFound: number;
	templateMetaData: Models.ICharacteristicMetaDataCollection;
	items: IDealTableGroupItem[];
}

export interface IDealTableGroupItem {
	assetID: number;
	assets: number[];
	record: Models.IEntity;
	status: Models.IEntityStatus;
	charData: Models.ICharacteristicData[];
}

export interface ISFGetDealDocumentsResponse {
	systemDocs: Models.IStorefrontDealSystemDocs,
	entityDocs: Models.IStorefrontDealEntityDocs,
	requireAcceptance: boolean
}

export interface IPaymentClientIdResponse {
	token: string;
}

export interface IPaymentResponse {
	success: boolean;
	errorMessage: string;
}

export interface IPaymentDataResponse<T> {
	data: T;
	success: boolean;
	errorMessage: string;
}


export interface IGetTablesResponse {
	templates: Models.ICharacteristicTemplate[];
	assetTitles: { [key: string]: string };
	contactTitles: { [key: string]: string };
	groups: ITableGroup[];
	facetResults: Dictionary<IFacetResult[]>;
	facetLookup: { [key: number]: string };

}

export interface IFacetResult {
	facet: string;
	name: string;
	numFound: number;
}

export interface ITableGroup {
	templateID: number;
	templateName: string;
	numFound: number;
	templateMetaData: Models.ICharacteristicMetaDataCollection;
	items: ITableGroupItem[];
}

export interface ITableGroupItem {
	assetID: number;
	assets: number[];
	contacts: number[];
	record: Models.IEntity;
	status: Models.IEntityStatus;
	charData: Models.ICharacteristicData[];
}

export interface IGetContactUsCategoryResponse {
	categories: Models.IContactUsCategory[];
}

export interface IFilestackFileInfo {
	filename: string;
	mimetype: string;
	size: number;
	source: string;
}

export interface IFilestackProgressEvent {
	totalPercent: number;
	totalBytes: number
}

export interface IFilestackPickOptions {
	accept?: string | string[];
	fromSources?: string | string[];
	lang?: string;
	maxSize?: number;
	maxFiles?: number;
	minFiles?: number;
	startUploadingWhenMaxFilesReached?: boolean;
	hideWhenUploading?: boolean;
	uploadInBackground?: boolean;
	disableTransformer?: boolean;
	preferLinkOverStore?: boolean;
	transformations?: {
		minDimensions?: [number, number];
		maxDimensions?: [number, number];
		crop?: boolean | {
			aspectRatio?: number;
			circle?: boolean;
		};
		filters?: string[];
	},
	storeTo?: {
		location?: "s3" | "azure" | "dropbox" | "rackspace" | "gcs";
		path?: string;
		container?: string;
		region?: string;
		access?: "public";
	},
	onFileSelected?: (file: IFilestackFileInfo) => void,
	onFileUploadStarted?: (file: IFilestackFileInfo) => void,
	onFileUploadProgress?: (file: IFilestackFileInfo, progressEvent: IFilestackProgressEvent) => void,
	onFileUploadFinished?: (file: IFilestackFileInfo) => void,
	onFileUploadFailed?: (file: IFilestackFileInfo, error: Error) => void,
	onUploadDone?: (response: IFilestackPickResponse) => void,
	onClose?: () => void
}

export interface IFilestackPickResponse {
	filesUploaded: PickerFileMetadata[],
	filesFailed: PickerFileMetadata[]
}

export interface IFilestackPickFile {
	filename: string;
	handle: string;
	mimetype: string;
	originalPath: string;
	size: number;
	source: string;
	url: string;
	key: string;
	container: string;
	status: "Stored" | "InTransit" | "Failed";
}

export interface IFilestackClient {
	pick(options: IFilestackPickOptions): any;
}

export interface IFilestackInitOptions {
	policy: string;
	signature: string;
}

export interface IFilestackStatic {
	init(key: string, options?: IFilestackInitOptions): IFilestackClient;
}

export interface IFileStackSignature {
	signature: string;
	awsRegion: string
}

export interface ISetting {
	id: string;
	key: string;
	value: string;
	derivedValue: string;
}

export enum DivSettingKey {
	PortalLogo = "portalLogo",
	HexColor1 = "portalHexColor1",
	HexColor2 = "portalHexColor2",
	HexColor3 = "portalHexColor3",
	HexColor4 = "portalHexColor4",
	PortalContactUs = "portalContactUs"
}





