import {Component, ChangeDetectionStrategy, OnChanges, SimpleChange} from "@angular/core";
import * as _ from "lodash";

import {BaseElement} from "./base-element";
import {ICharacteristicData} from "../../../models";

@Component({
    selector: "file-edit-element",
    template: `<file-edit-control [fileName]="fileName" (pick)="filePicked($event)"></file-edit-control>`,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class FileEditElementComponent extends BaseElement implements OnChanges {

    fileName: string;


    constructor() {
        super();
    }

    ngOnChanges(changes: { [key: string]: SimpleChange; }) {
        if (this.charData) {
            let fileName = null;
            if (_.some(this.charData)) {
                if (!_.isEmpty(this.charData[0].value)) {
                    fileName = this.charData[0].value;
                    if (fileName.lastIndexOf("/") > -1) {
                        fileName = fileName.substring(fileName.lastIndexOf("/") + 1);
                    }
                }
            }
            this.fileName = fileName;
            //this.textValue = _.join(this.charData.map(cd => cd.value), ", ") || "";
        }
    }

    filePicked(key: string) {
        let charData: ICharacteristicData = null;
        let value = key;
        if (_.some(this.charData)) {
            charData = _.clone(this.charData[0]);
            charData.value = value;
            charData.valueID = null;
        } else {
            charData = this.createCharData(value);
        }
        this.charDataChange.emit({ cmd: this.cmd, charData: [charData] });
    }
}