import {Component} from "@angular/core";

import {FilesListComponent} from "./files-list.component";

@Component({
    selector: "deal-panel",
    template: "<router-outlet></router-outlet>"
})
export class FilesPanelComponent {

    constructor() {

    }
}