import { Injectable } from "@angular/core";
import { PPHttpService } from "./pp-http.service";
import { IWorkflowAction, IExecuteWorkflowActionResponse } from "../models";

@Injectable()
export class WorkflowService {
	private _baseUrl = "/service-api/workflow";

	constructor(private readonly ppHttpService: PPHttpService) {

	}

	public getWorkflowActions(entityId: string) {
		return this.ppHttpService.get<IWorkflowAction[]>(`${this._baseUrl}/${entityId}/get-actions`);
	}

	public getComponentWorkflowActions(charTypeId: number, templateId: number, statusId: number) {
		return this.ppHttpService.get<IWorkflowAction[]>(`${this._baseUrl}/${charTypeId}/${templateId}/${statusId}/get-actions`);
	}

	public executeWorkflowAction(entityId: string, processID: number, actionID: number) {
		return this.ppHttpService.post<IExecuteWorkflowActionResponse>
			(`${this._baseUrl}/${entityId}/execute-action/${processID}/${actionID}`);
	}

	public executeWorkflowActionBulk(entityIds: string[], processId: number, actionId: number) {
		const request = {
			entityIds: entityIds,
			processId: processId,
			actionId: actionId
		};
		return this.ppHttpService.post<IExecuteWorkflowActionResponse[]>(`${this._baseUrl}/execute-actions`, request);
	}
}