import { Injectable } from "@angular/core";

import { SessionService } from "../../common/services/session.service";
import { PartnerPortalService } from "./partner-portal.service";

@Injectable()
export class SiteService {

    showCatalog: boolean = false;
    showProjects: boolean = false;
    showDeals: boolean = false;
    hasB2BRole: boolean = false;
    showJobs: boolean = false;
    showAccounting: boolean = false;
    showInventory: boolean = false;
    showFiles: boolean = false;
    showUploads: boolean = false;

    constructor(
        private sessionService: SessionService,
        private partnerPortalService: PartnerPortalService) {
        this.sessionService.sessionStateChanged
            .subscribe(() => {
                this.updateCapabilities();
            })
        this.updateCapabilities();
    }

    updateCapabilities() {
        if (this.sessionService.isValid) {
            this.partnerPortalService.getSessionInfo()
                .subscribe((result) => {
                    this.showCatalog = result.hasCatalog;
                    this.showProjects = result.hasProjects;
                    this.showDeals = result.hasDeals;
                    this.showJobs = result.hasJobs;
                    this.showAccounting = result.hasAccounting;
                    this.hasB2BRole = result.hasB2BRole;
                    this.showInventory = result.hasInventory;
                    this.showFiles = result.hasFiles;
                    this.showUploads = result.showUploads;
                });
        } else {
            this.showCatalog = false;
            this.showProjects = false;
            this.showDeals = false;
            this.showJobs = false;
            this.showAccounting = false;
            this.showInventory = false;
            this.showFiles = false;
            this.hasB2BRole = false;
            this.showUploads = false;
        }
    }
}