import { ICharacteristicMetaDataCollection } from "../models";
import { Acl, AccessRight, AccessResult } from "../consts";
import * as _ from "lodash";
import * as URI from "urijs";

export class AclUtil {
	static getDataEntityAcl(charTypeID: number = null, templateGroupID: number = null, templateID: number = null, charGroupID: number = null, charID: number = null): string {
		var output = Acl.Data.entities;
		if (charTypeID != null) {
			output += `/ct_${charTypeID}`;
			if (templateGroupID != null) {
				output += `/tg_${templateGroupID}`;
				if (templateID != null) {
					output += `/tm_${templateID}`;
					if (charGroupID != null) {
						output += `/cg_${charGroupID}`;
						if (charID != null) {
							output += `/ch_${charID}`;
						}
					}
				}
			}
		}
		return output;
	}

	static getDataEntityAcls(charDataCollection: ICharacteristicMetaDataCollection) {
		return _.transform(charDataCollection.characteristicMetaDatas,
			(acc, cmd) => {
				acc[cmd.characteristicID] = AclUtil.getDataEntityAcl(charDataCollection.charTypeID, charDataCollection.template.templateGroupID, charDataCollection.templateID, cmd.groupID, cmd.characteristicID);
			}, <_.Dictionary<string>>{});
	}

	static filterCMDsByRights(aclSet: string[], charDataCollection: ICharacteristicMetaDataCollection, rightFlag: number) {
		return charDataCollection.characteristicMetaDatas.filter((cmd) => AclUtil.hasAccess(aclSet, AclUtil.getDataEntityAcl(charDataCollection.charTypeID, charDataCollection.template.templateGroupID, charDataCollection.templateID, cmd.groupID, cmd.characteristicID), rightFlag));
	}


	private static hash(str) {
		var hash = 5381,
			i = str.length;

		while (i) {
			hash = (hash * 33) ^ str.charCodeAt(--i);
		}

		/* JavaScript does bitwise operations (like XOR, above) on 32-bit signed
		 * integers. Since we want the results to be always positive, convert the
		 * signed int to an unsigned by doing an unsigned bitshift. */
		return hash >>> 0;
	}

	private static effectivePermissionCache: _.Dictionary<number> = {};
	static count = 1;
	static getEffectivePermissionValue(aclSet: string[], targetAcl: string) {
		if (aclSet == null) {
			return 0;
		}
		var key = AclUtil.hash(aclSet.join("|")) + targetAcl;
		var output = AclUtil.effectivePermissionCache[key];
		if (output == null) {

			//console.log("calling getEffectivePermissionValue " + AclUtil.count++);
			var aclUris = aclSet.map((acl) => URI.parse(acl));
			var effective = 0;
			if (!_.isEmpty(targetAcl)) {
				var targetUri = URI.parse(targetAcl.toLowerCase());
				aclUris = aclUris.filter((acl) => acl.hostname == targetUri.hostname);
				var targetParts = targetUri.path.split("/");

				while (targetParts.length > 0) {
					var matchPath = targetParts.join("/");
					var matchUri = _.find(aclUris, (uri) => uri.path == matchPath);
					if (matchUri != null) {
						var rightFlag = _.parseInt(URI.parseQuery(matchUri.query)[Acl.ValueParam] || 0);
						effective |= rightFlag;
					}
					targetParts.pop();
				}
			}
			output = effective;
			AclUtil.effectivePermissionCache[key] = output;
		}
		return output;
	}

	static getAccessResult(aclSet: string[], targetAcl: string, rightFlag: number = AccessRight.None, matchAll: boolean = true) {

		if (rightFlag == AccessRight.None) {
			var targetUri = URI.parse(targetAcl);
			rightFlag = _.parseInt(URI.parseQuery(targetUri.query)[Acl.ValueParam] || 0);
		}

		if (rightFlag != AccessRight.None) {
			var effectivePermissionValue = this.getEffectivePermissionValue(aclSet, targetAcl);
			return this.calcResult(effectivePermissionValue, rightFlag, matchAll);
		}
		return AccessResult.NotSet;
	}

	static calcResult(effectivePermissionValue: number, rightFlag: number, matchAll: boolean = true) {
		var denyFlag = rightFlag << 16;
		if ((effectivePermissionValue & denyFlag) > 0) {
			return AccessResult.Deny;
		} else if (!matchAll && ((effectivePermissionValue & rightFlag) > 0)) {
			return AccessResult.Allow;
		} else if (matchAll && ((effectivePermissionValue & rightFlag) == rightFlag)) {
			return AccessResult.Allow;
		} else {
			return AccessResult.NotSet;
		}
	}

	static hasAccess(aclSet: string[], targetAcl: string, rightFlag: number = AccessRight.None) {
		return this.getAccessResult(aclSet, targetAcl, rightFlag) == AccessResult.Allow;
	}

	static hasReadAccess(aclSet: string[], targetAcl: string) {
		return this.hasAccess(aclSet, targetAcl, AccessRight.Read);
	}

	static hasWriteAccess(aclSet: string[], targetAcl: string) {
		return this.hasAccess(aclSet, targetAcl, AccessRight.Write);
	}

	static hasCreateAccess(aclSet: string[], targetAcl: string) {
		return this.hasAccess(aclSet, targetAcl, AccessRight.Create);
	}

	static hasDeleteAccess(aclSet: string[], targetAcl: string) {
		return this.hasAccess(aclSet, targetAcl, AccessRight.Delete);
	}

	static withPermissionValue(acl: string, value: number) {
		if (value == null) {
			return URI(acl).removeQuery(Acl.ValueParam).valueOf();
		} else {
			return URI(acl).setQuery(Acl.ValueParam, value.toString()).valueOf();
		}
	}
}
