import { Component } from "@angular/core";

@Component({
    selector: "inventory-panel",
    template: "<router-outlet></router-outlet>"
})
export class InventoryPanelComponent {

    constructor() {

    }
}