import { enableProdMode } from "@angular/core";
import { platformBrowserDynamic } from "@angular/platform-browser-dynamic";
import { PartnerPortalModule } from "./app/partner-portal/pp-app.module";
import { environment } from './environments/environment';

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic().bootstrapModule(PartnerPortalModule)
  .catch(err => console.log(err));
