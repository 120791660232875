import {Component, ChangeDetectionStrategy, Input, OnChanges, SimpleChange, Output, EventEmitter} from "@angular/core";

@Component({
    selector: "percentage-edit-control",
    templateUrl: "./percentage-edit-control.component.html",
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class PercentageEditControlComponent {

    @Input()
    textValue: string;

    @Output()
    textValueChange = new EventEmitter<string>();

    @Output()
    touch = new EventEmitter<Event>();

    constructor() {

    }

    touched($event: Event) {
        this.touch.emit($event);
    }
}