import {Component, ChangeDetectionStrategy, Input} from "@angular/core";

import {DateUtil} from  "../../../utils/date-util";

@Component({
    selector: "date-display-control",
    template: "<span [innerText]='textValue'></span>",
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class DateDisplayComponent {

    @Input()
    dateValue: string;

    get textValue() {
        return DateUtil.isPerpetuity(this.dateValue) ? "Perpetuity" : this.dateValue;
    }

    constructor() {

    }
}