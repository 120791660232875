import { HttpHeaders } from "@angular/common/http";

export interface JwtTokenResponse {
	access_token: string;
	refresh_token: string;
}

export interface JwtTokenAclsResponse extends JwtTokenResponse {
	acls_token: string;
}

export function getBasicHeaders() {
	let headers = new HttpHeaders()
		.append("Accept", "application/json")
		.append("Content-Type", "application/x-www-form-urlencoded");
	return headers;
}

export function getAuthenticationHeaders(jwt: string) {
	let headers = getBasicHeaders();

	headers = headers.append("Authorization", `Bearer ${jwt}`);
	return headers;
}
