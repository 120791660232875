import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { IContactUsCategory } from 'app/common/models';
import { ProgressService } from 'app/common/services/progress.service';
import { PartnerPortalService } from 'app/partner-portal/services/partner-portal.service';
import { switchMap, tap } from 'rxjs/operators';

@Component({
  selector: 'contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.scss']
})
export class ContactUsComponent {

  contactUsForm: UntypedFormGroup;
  categories: IContactUsCategory[];

  get clientInput() {
		return this.contactUsForm.controls["clientInput"];
	}

  get category() {
		return this.contactUsForm.controls["category"];
	}

  constructor( private partnerPortalService: PartnerPortalService,
    private progressService: ProgressService,
    private fb: UntypedFormBuilder) {

    this.progressService.startProgress();
    this.loadInfo().subscribe(() => {
        this.progressService.endProgress();
    });

    this.contactUsForm = this.fb.group({
			clientInput: ["", Validators.required],
      category: ["", Validators.required]
		});
  }

  loadInfo() {
    return this.partnerPortalService.getContactUsCategories()
        .pipe(
            tap((result) => {
                this.categories = result.categories
            }));
}

  onSubmit(form: UntypedFormGroup) {
    var category = this.contactUsForm.get("category").value.category;
    var email = this.contactUsForm.get("category").value.email;
    var clientInput = this.contactUsForm.get("clientInput").value;
    
    this.progressService.startProgress();
    this.partnerPortalService.contactUs(category, email, clientInput)
        .pipe(
            switchMap(() => this.loadInfo()))
        .subscribe(() => {
            window.alert("Email sent successfully.");
            this.progressService.endProgress();
        });
  }
}
