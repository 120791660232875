import * as _ from "lodash";
import {IEntityID, ITemplateEntityID} from "../models";

export class IDUtil {

    static entityRegex: RegExp = /d(\d+)c(\d+)r(-?\d+)/;
    static templateRegex: RegExp = /d(\d+)c(\d+)t(\d+)/;


    static toID(...parts: number[]) {
        let output = "";
        switch (parts.length) {
            case 2:
                output = `d0c${parts[0]}r${parts[1]}`;
                break;
            case 3:
                output = `d${parts[0]}c${parts[1]}r${parts[2]}`;
                break;
            default:
                throw "invalid parameters for ID";
        };
        return output;
    }

    static toTemplateID(...parts: number[]) {
        let output = "";
        switch (parts.length) {
            case 2:
                output = `d0c${parts[0]}t${parts[1]}`;
                break;
            case 3:
                output = `d${parts[0]}c${parts[1]}t${parts[2]}`;
                break;
            default:
                throw "invalid parameters for ID";
        };
        return output;
    }

    static splitEntityID(entityID: string): IEntityID {
        let output = { divID: -1, charTypeID: -1, recID: -1 };
        if (this.isEntityID(entityID)) {
            let match = this.entityRegex.exec(entityID);
            if (match.length == 4) {
                output.divID = _.parseInt(match[1]);
                output.charTypeID = _.parseInt(match[2]);
                output.recID = _.parseInt(match[3]);
            }
        }
        return output;
    }


    static splitTemplateID(entityTemplateID: string): ITemplateEntityID {
        let output = { divID: -1, charTypeID: -1, templateID: -1 };
        if (this.isTemplateID(entityTemplateID)) {
            let match = this.templateRegex.exec(entityTemplateID);
            if (match.length == 4) {
                output.divID = _.parseInt(match[1]);
                output.charTypeID = _.parseInt(match[2]);
                output.templateID = _.parseInt(match[3]);
            }
        }
        return output;
    }

    static isTemplateID(id: string) {
        return this.templateRegex.test(id);
    }
    static isEntityID(id: string) {
        return this.entityRegex.test(id);
    }
}