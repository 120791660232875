<div class="row justify-content-center mt-4">
  <div class="col-sm-12 col-md-10">
    <h1 class="title">Configuration</h1>
    <div class="row">
      <div class="col-lg-6 pb-3">
        <div class="card h-100">
          <div class="card-title">
            <h3>Portal Colors</h3>
          </div>
          <div class="card-body">
            <div class="border p-3 mb-3 bg-light">
              <h4>Primary Color</h4>
              <p>Top header and main titles</p>
              <div class="color-picker-combo">
                <div class="color-picker-box">
                  <input type="color" [(ngModel)]="hex1Value" (ngModelChange)="emitChange(hexColor1, hex1Value)"
                    [placeholder]="placeholderHex1" [disabled]="disabled" #colorPicker data-cy="colorBox1"/>
                </div>
                <input type="text" [(ngModel)]="hex1Value" (ngModelChange)="emitChange(hexColor1, hex1Value)"
                  [placeholder]="placeholderHex1" #input [disabled]="disabled"
                  class="color-picker-input form-control ms-2" data-cy="colorField1" />
              </div>
            </div>
            <div class="border p-3 mb-3 bg-light">
              <h4>Secondary Color</h4>
              <p>Navigation bar and button color</p>
              <div class="color-picker-combo">
                <div class="color-picker-box">
                  <input type="color" [(ngModel)]="hex2Value" (ngModelChange)="emitChange(hexColor2, hex2Value)"
                    [placeholder]="placeholderHex2" [disabled]="disabled" #colorPicker data-cy="colorBox2" />
                </div>
                <input type="text" [(ngModel)]="hex2Value" (ngModelChange)="emitChange(hexColor2, hex2Value)"
                  [placeholder]="placeholderHex2" #input [disabled]="disabled"
                  class="color-picker-input form-control ms-2" data-cy="colorField2" />
              </div>
            </div>
            <div class="border p-3 mb-3 bg-light">
              <h4>Secondary Light Color</h4>
              <p>Selected navigation item, subtitles, and hover button color</p>
              <div class="color-picker-combo">
                <div class="color-picker-box">
                  <input type="color" [(ngModel)]="hex3Value" (ngModelChange)="emitChange(hexColor3, hex3Value)"
                    [placeholder]="placeholderHex3" [disabled]="disabled" #colorPicker data-cy="colorBox3" />
                </div>
                <input type="text" [(ngModel)]="hex3Value" (ngModelChange)="emitChange(hexColor3, hex3Value)"
                  [placeholder]="placeholderHex3" #input [disabled]="disabled"
                  class="color-picker-input form-control ms-2" data-cy="colorField3" />
              </div>
            </div>
            <div class="border p-3 mb-3 bg-light">
              <h4>Tertiary Color</h4>
              <p>Header row of tables</p>
              <div class="color-picker-combo">
                <div class="color-picker-box">
                  <input type="color" [(ngModel)]="hex4Value" (ngModelChange)="emitChange(hexColor4, hex4Value)"
                    [placeholder]="placeholderHex4" [disabled]="disabled" #colorPicker data-cy="colorBox4" />
                </div>
                <input type="text" [(ngModel)]="hex4Value" (ngModelChange)="emitChange(hexColor4, hex4Value)"
                  [placeholder]="placeholderHex4" #input [disabled]="disabled"
                  class="color-picker-input form-control ms-2" data-cy="colorField4" />
              </div>
            </div>
            <div class="text-end">
              <button class="btn btn-sm btn-light me-2" [disabled]="isReseting || isSavingColors || !resetEnabled"
                (click)="reset()" data-cy="resetColors">
                <i class="far fa-spinner fa-spin" *ngIf="isReseting"></i>
                Reset Colors
              </button>
              <button class="btn btn-sm btn-info" [disabled]="isReseting || isSavingColors || !savingEnabled"
                (click)="saveColors()" data-cy="saveColors">
                <i class="far fa-spinner fa-spin" *ngIf="isSavingColors"></i>
                Save Colors
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-6 pb-3">
        <div class="card">
          <div class="card-title">
            <h3>Upload Your Logo</h3>
          </div>
          <div class="card-body">
            <div class="row">
              <div class="div-logo col-lg-6 p-4">
                <div *ngIf="(divImageUrl$ | async) as divImageUrl"
                  class="img-container d-flex align-content-center justify-content-between">
                  <img [src]="divImageUrl" />
                  <button type="button" class="btn btn-close img-delete" aria-label="Delete" (click)="deleteImage()" data-cy="deleteLogo">
                    <i class="fas fa-times"></i>
                  </button>
                </div>
                <button class="btn btn-sm btn-info mt-3" [disabled]="isSaving" (click)="upload()" data-cy="uploadLogo">
                  <i class="far fa-spinner fa-spin" *ngIf="isSaving"></i>
                  <i class="far fa-cloud-upload" *ngIf="!isSaving"></i>
                  Choose File
                </button>
              </div>

              <div class="p-4 col-lg-6">
                <h4>Image Recommendations</h4>
                <ul>
                  <li>Use a transparent .png file</li>
                  <li>The logo height should be 60 pixels. The width can vary.</li>
                  <li>
                    A white logo will look best on a dark background.
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>