<pp-modal #jobProgressStatusModal modalWidth="100%">
	<modal-title>{{jobTitle}}</modal-title>
	<modal-body>
		<div>
			<label>Status:</label>
			<strong>{{progressStatus}}</strong>
			<i class="fa fa-spinner fa-spin fa-2x ms-5" *ngIf="showProgressSpinner"></i>
		</div>
		<div [hidden]="progressTotal==0" *ngIf="showProgressBar">
			<label>Progress</label>
			<div class="progress progress-striped active">
				<div class="progress-bar" ng-class="progressClass" ng-style="{ width: progressPercentage + '%' }">
					{{progressMessage}}
				</div>
			</div>
			{{progressComplete}}/{{progressTotal}}
		</div>
	</modal-body>
	<modal-footer>
		<button type="button" class="btn btn-light" (click)="cancelJob()" *ngIf="allowJobToCancel" data-cy="cancelJobProgress">Cancel</button>
		<button type="button" class="btn btn-success ms-2" (click)="editDocumentEntityModal.close()" *ngIf="showModalCloseBtn" busyButton
			data-cy="closeJobProgress"><i class="fa"></i>Close</button>
	</modal-footer>
</pp-modal>