import { EventEmitter, Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { COMMON_SETTINGS } from 'app/common/settings';
import { catchError, map, share, switchMap, tap } from "rxjs/operators";
import { IAppOption, IBasePortalSettings, LoginOptionsResponse, PPSession } from "../models";
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { HttpClient, HttpHeaders, HttpBackend } from '@angular/common/http';
import { JwtTokenResponse, getAuthenticationHeaders } from './authentication.models';
import { PartnerPortalService } from 'app/partner-portal/services/partner-portal.service';

@Injectable()
export class SessionService {
    acls$ = new BehaviorSubject<string[]>(null);
    sessionStateChanged = new EventEmitter<any>();

    private _key = "jwt";
    private _refreshTokenKey = "rt";
    private _aclsKey = "acls";

    public get jwt() {
        return localStorage.getItem(this._key);
    }

    public set jwt(value: string) {
        localStorage.setItem(this._key, value);
    }

    public get refreshToken() {
        return localStorage.getItem(this._refreshTokenKey);
    }

    public set refreshToken(value: string) {
        localStorage.setItem(this._refreshTokenKey, value);
    }

    public get session(): PPSession {
        let jwt = this.jwt;
        if (jwt != null && !this.inDivSelection) {
            return this.jwtHelperService.decodeToken(jwt);
        } else {
            return null;
        }
    }

    get username() {
        return this.session ? this.session["RL_DISPLAY_NAME"] : "";
    }

    get isValid() {
        return this.jwt && !this.jwtHelperService.isTokenExpired(this.jwt);
    }

    get hasToken() {
        return this.jwt != null;
    }

    get aclsToken() {
        return <string>localStorage.getItem(this._aclsKey);
    }

    set aclsToken(value: string) {
        this.decodedToken = null;
        this.expirationDate = null;
        localStorage.setItem(this._aclsKey, value);
    }

    private decodedToken: any = null;
    private expirationDate: Date = null;
    private inDivSelection = false;
    userApps: IAppOption[] = [];
    currentDivName$ = new BehaviorSubject<string>(null);
    currentDivId$ = new BehaviorSubject<number>(-1);

    get acls() {
        return this.acls$.value;
    }

    constructor(private httpClient: HttpClient, private httpBackend: HttpBackend, @Inject(COMMON_SETTINGS) private settings: IBasePortalSettings, private router: Router, private jwtHelperService: JwtHelperService, private partnerPortalService: PartnerPortalService) {
    }

    setSession(jwt: string, refreshToken: string): Observable<void> {
        this.jwt = jwt;
        this.refreshToken = refreshToken;


        return this.getAcls()
            .pipe(tap(() => {
                this.sessionStateChanged.emit(null);
                this.setAcls();
                this.setDivSettings();
            })) as Observable<void>;
    }

    setAcls() {
        let aclsToken = this.aclsToken;
        if (this.decodedToken == null) {
            if (aclsToken != null && !this.jwtHelperService.isTokenExpired(aclsToken)) {
                this.expirationDate = this.jwtHelperService.getTokenExpirationDate(aclsToken);
                this.decodedToken = this.jwtHelperService.decodeToken(aclsToken);
                this.acls$.next([]);
            }
        }
        if (this.decodedToken) {
            if (this.decodedToken["client_id"] != this.settings.clientID) {
                this.clearSession();
                this.acls$.next([]);
            }
            this.acls$.next(<string[]>this.decodedToken["RL_ACL"]);
            return <string[]>this.decodedToken["RL_ACL"];
        }
        this.acls$.next([]);
    }

    setDivSettings() {
        this.partnerPortalService.refreshSettings();
    }

    clearSession() {
        localStorage.removeItem(this._key);
        localStorage.removeItem(this._refreshTokenKey);
        localStorage.removeItem(this._aclsKey);
        this.decodedToken = null;
        this.expirationDate = null;
        this.sessionStateChanged.emit(null);
    }

    getAcls() {
        let url = this.settings.serviceApiDomain + "/service-api/account/acls";
        let headers = new HttpHeaders();
        headers = headers.append("Accept", "application/json");
        headers = headers.append("Content-Type", "application/x-www-form-urlencoded");
        headers = headers.append("Authorization", `Bearer ${this.jwt}`);

        return this.httpClient.get(url, { headers: headers })
            .pipe(
                map((res) => <string>res),
                tap((value) => {
                    this.aclsToken = value;
                }));
    }

    getGuestAcls() {
        let url = this.settings.serviceApiDomain + "/service-api/account/guest-acls";
        let headers = new HttpHeaders();
        headers = headers.append("Accept", "application/json");
        headers = headers.append("Content-Type", "application/x-www-form-urlencoded");
        headers = headers.append("Client-Id", this.settings.clientID);

        return this.httpClient.get(url, { headers: headers })
            .pipe(
                map((res) => <string>res),
                tap((value) => {
                    this.aclsToken = value;
                }));
    }

    getLoginOptions(email: string) {
        const sanitizedEmail = encodeURIComponent(email);
        let url = `${this.settings.serviceApiDomain}/service-api/account/login-options?email=${sanitizedEmail}`;
        let headers = new HttpHeaders();
        headers = headers.append("Accept", "application/json");
        headers = headers.append("Content-Type", "application/x-www-form-urlencoded");
        return this.httpClient.get(url, { headers: headers })
            .pipe(
                map((res) => res as LoginOptionsResponse),
            );
    }

    login(username: string, password: string): Observable<JwtTokenResponse> {
        const httpClient = new HttpClient(this.httpBackend); // bypass HttpInterceptors
        interface TokenResponse { access_token: string, refresh_token: string };

        let url = this.settings.serviceApiDomain + "/service-api/oauth2/token";
        let headers = new HttpHeaders();
        headers = headers.append("Accept", "application/json");
        headers = headers.append("Content-Type", "application/x-www-form-urlencoded");

        let body = `username=${encodeURIComponent(username)}&password=${encodeURIComponent(password)}&grant_type=password&client_id=${this.settings.clientID}`;
        return httpClient.post(url, body, { headers: headers })
            .pipe(
                catchError((error) => {
                    return throwError(error);
                }),
                map((res) => res as TokenResponse));
    }

    loginWithDivIDAppUrlID(username: string, password: string, divId: number, appUrlId: number, urlTypeId: number, jwt: string): Observable<JwtTokenResponse> {

        const httpClient = new HttpClient(this.httpBackend); // bypass HttpInterceptors

        let url = this.settings.serviceApiDomain + "/service-api/oauth2/token";

        const body = this.encodeBody({
            "username": username,
            "password": password,
            "div_id": divId.toString(),
            "app_url_id": appUrlId.toString(),
            "grant_type": "choose_app",
            "url_type_id": urlTypeId.toString(),
            "client_id": this.settings.clientID
        });

        const headers = getAuthenticationHeaders(jwt);
        this.currentDivName$.next(this.userApps.find(x => x.divId === divId).name);
        this.currentDivId$.next(divId);

        return httpClient.post(url, body, { headers: headers })
            .pipe(
                catchError((error) => {
                    return throwError(error);
                }),
                map((res) => res as JwtTokenResponse));
    }

    private encodeBody(params: _.Dictionary<string>) {
        const urlSearchParams = Object.keys(params)
            .reduce<URLSearchParams>((searchParams, key) => {
                searchParams.set(key, params[key]);
                return searchParams;
            }, new URLSearchParams());

        return urlSearchParams.toString();
    }

    refresh() {
        return this.requestRefresh(this.refreshToken);
    }

    requestRefresh(refreshToken: string) {
        const httpClient = new HttpClient(this.httpBackend); // bypass HttpInterceptors
        interface TokenResponse { access_token: string, refresh_token: string };

        let url = this.settings.serviceApiDomain + "/service-api/oauth2/token";
        let headers = new HttpHeaders();
        headers = headers.append("Accept", "application/json");
        headers = headers.append("Content-Type", "application/x-www-form-urlencoded");

        let body = `grant_type=refresh_token&client_id=${this.settings.clientID}&refresh_token=${refreshToken}`;
        let result = httpClient.post(url, body, { headers: headers })
            .pipe(
                catchError((error) => {
                    this.clearSession();
                    this.router.navigateByUrl("/account/login");
                    return throwError(error);
                }),
                map((res) => <TokenResponse>res),
                share(),
                switchMap((value) => {
                    return this.setSession(value.access_token, value.refresh_token);
                })
            );

        return result;
    }
}
