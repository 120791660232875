import { ChangeDetectionStrategy, Component, EventEmitter, Input, NgZone, Output } from "@angular/core";
import { DocumentEntityService } from "app/common/services/document-entity.service";
import { ProgressService } from "app/common/services/progress.service";
import * as _ from "lodash";

@Component({
    selector: "file-edit-control",
    templateUrl: "./file-edit-control.component.html",
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class FileEditControlComponent {

    @Input()
    fileName: string;

    @Output()
    pick = new EventEmitter<string>();

    get hasFile() {
        return !_.isEmpty(this.fileName);
    }

    constructor(private ngZone: NgZone, private documentEntityService: DocumentEntityService, private progressService: ProgressService) {

    }


    chooseFile($event: Event) {
        this.progressService.startProgress();
        const options = this.documentEntityService.getBasicFileStackOptions();
        options.maxFiles = 1;
        options.minFiles = 1;
        options.onUploadDone = (response) => {
            this.ngZone.run(() => {
                let key = response.filesUploaded[0].key;
                this.pick.emit(key);
            });
        };
        this.documentEntityService.upload(options);
    }

    removeFile($event: Event) {
        this.pick.emit(null);
    }
}