import {Component} from "@angular/core";
import {SessionService} from "../../../common/services/session.service";
import * as moment from "moment";

@Component({
    selector: "pp-footer",
    templateUrl: "./pp-footer.component.html"
})
export class PPFooterComponent {

    year: number;

    constructor() {
        this.year = moment().year();
    }
}