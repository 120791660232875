<div class="row justify-content-center mt-5">
    <div class="col-sm-8 col-md-4">
        <h1 class="title">Forgot Your Password?</h1>
        <p>Enter your email below and we'll send you a link to reset your password.</p>

        <div class="card">
            <div class="card-body">
                <form [formGroup]="resetForm" (ngSubmit)="onSubmit($event)">
                    <div class="alert alert-danger" *ngIf="isError">
                        <strong>
                            Error resetting password.
                        </strong>
                    </div>
                    <div class="alert alert-success" *ngIf="isSuccess">
                        <strong>
                            A password reset invitation has been sent to {{email.value}}
                        </strong>
                    </div>
                    <div class="mb-3" [class.has-error]="!email.valid && email.value != ''">
                        <label class="control-label">Email</label>
                        <input type="email" name="email" class="form-control" [formControl]="email" data-cy="resetEmail" />
                        <control-error-messages [control]="email"></control-error-messages>
                    </div>
                    <div class="d-flex justify-content-end">
                        <button type="submit" class="btn btn-success" busyButton="" [disabled]="!resetForm.valid" data-cy="resetSubmit">
                            <i class="fa"></i>
                            Submit
                        </button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>