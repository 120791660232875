import { HttpInterceptor, HttpRequest, HttpEvent, HttpHandler } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { JwtHelperService, } from "@auth0/angular-jwt";
import { Observable ,  of, throwError } from "rxjs";
import { TokenService } from "./token.service";
import { ProgressService } from "./progress.service";
// import { Logger } from "./logger.service";
import { catchError, mergeMap } from "rxjs/operators";


@Injectable()
export class PortalHttpInterceptor implements HttpInterceptor {
    // private log = new Logger("HttpService");

    constructor(private jwtHelperService: JwtHelperService, private tokenService: TokenService, private progressService: ProgressService) {
    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return this.tokenService.assertToken()
            .pipe(mergeMap((token) => {
                let request = req.clone();
                if (token != null) {
                    request = request.clone({
                        setHeaders: {
                            "Authorization": `Bearer ${token}`
                        }
                    });
                }
                return next.handle(request)
                    .pipe(catchError((err) => {
                        // this.log.error(err);
                        this.progressService.clearProgress();
                        return throwError(err);
                    }));
            }));
    }

    getToken(): Promise<string> {

        let token = localStorage.getItem("jwt_token");
        if (token == null) {
            return Promise.resolve(null);
        } else if (this.jwtHelperService.isTokenExpired(token)) {

        } else {
            return Promise.resolve(token);
        }
    }

}