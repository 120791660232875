
import { Component, Inject, OnDestroy, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { PARTNER_PORTAL_SETTINGS } from "app/partner-portal/settings";
import * as _ from "lodash";
import { combineLatest as observableCombineLatest, Subscription } from 'rxjs';
import { mergeMap, tap } from 'rxjs/operators';
import { CharTypeID } from "../../../common/consts";
import {
	ICharacteristicData,

	ICharacteristicMetaData, ICharacteristicMetaDataCollection,



	IEntity, IEntitySearchDoc,





	IEntityStatus, IPartnerPortalSettings, IParty
} from '../../../common/models';
import { AclService } from "../../../common/services/acl.service";
import { EntityService } from "../../../common/services/entity.service";
import { NotifyService } from "../../../common/services/notify.service";
import { ProgressService } from "../../../common/services/progress.service";
import { TemplateService } from "../../../common/services/template.service";
import { AclUtil } from "../../../common/utils/acl.util";
import { PartnerPortalService } from "../../services/partner-portal.service";



interface IContactPair {
	party: IParty;
	contactID: number;
	contactTitle: string;
};

@Component({
	selector: "jobs-detail",
	templateUrl: "./jobs-detail.component.html"
})
export class JobsDetailComponent implements OnInit, OnDestroy {
	routeSub: Subscription;
	entity: IEntity;
	entityStatus: IEntityStatus;
	recordID: number;
	title: string;
	templateMetaData: ICharacteristicMetaDataCollection;
	charData: ICharacteristicData[];
	catalogItems: IEntitySearchDoc[];
	contacts: IContactPair[];

	groupCMDs: { [templateID: string]: ICharacteristicMetaData[] } = {};
	groupCharDatas: {
		[templateID: string]: { [recordID: string]: { [charID: string]: ICharacteristicData[] } }
	} = {};

	groupPage: { [key: string]: number } = {};
	groupPageCount: { [key: string]: number } = {};

	sortField: string;

	canWrite: boolean = false;
	canCreate: boolean = false;
	canDelete: boolean = false;
	isLocked: boolean = false;
	editMode: boolean = false;
	relatedCharTypeIDs: number[] = [];

	constructor(
		private activatedRoute: ActivatedRoute,
		private entityService: EntityService,
		private templateService: TemplateService,
		private progressService: ProgressService,
		private partnerPortalService: PartnerPortalService,
		private aclService: AclService,
		private notifyService: NotifyService,
		@Inject(PARTNER_PORTAL_SETTINGS) private settings: IPartnerPortalSettings) {

		if (this.settings.partnerPortal) {
			if (this.settings.partnerPortal.displayTitleField != null) {
				this.sortField = this.settings.partnerPortal.displayTitleField;
			}
		}
	}

	ngOnInit() {
		//Permissions
		if (this.aclService.acls) {
			var charTypeACL = AclUtil.getDataEntityAcl(CharTypeID.Job);
			this.canCreate = AclUtil.hasCreateAccess(this.aclService.acls, charTypeACL);
			this.canWrite = AclUtil.hasWriteAccess(this.aclService.acls, charTypeACL);
			this.canDelete = AclUtil.hasDeleteAccess(this.aclService.acls, charTypeACL);
		} else {
			this.canCreate = false;
			this.canWrite = false;
			this.canDelete = false;
		}
		this.getRelatedCharTypeIds();

		this.routeSub = this.activatedRoute.params.pipe(
			tap(params => {
				this.progressService.startProgress();
				this.recordID = _.parseInt(params["id"]);
			}),
			mergeMap(() => this.refresh$()))
			.subscribe(() => {
				this.getJobStatus$();
				this.progressService.endProgress();
			});
	}

	ngOnDestroy() {
		this.routeSub.unsubscribe();
	}

	refresh() {
		this.refresh$().subscribe();
	}

	refresh$() {
		return observableCombineLatest(
			this.getJobDetails$()
		);
	}

	getJobDetails$() {
		this.entityService.getEntity(this.recordID.toString()).pipe(
			tap(result => {
				result.status
			}));
		return this.partnerPortalService.getJobDetails(this.recordID).pipe(
			tap(result => {
				this.templateMetaData = result.template;
				this.charData = result.charData;
				this.entity = result.record;
				this.title = result.record.title;
			}));
	}
	getJobStatus$() {
		this.entityService.getTemplateStatus(this.entity.charTypeID, this.entity.templateID, this.entity.statusID).subscribe(
			result => {
				this.entityStatus = result;
				this.isLocked = this.entityStatus.lockIndicator == 1;
			});
	}

	getTitleString(doc: IEntitySearchDoc) {
		if (!_.isNull(this.sortField)) {
			if (_.isEmpty(doc.characteristics[this.sortField])) {
				return doc.title;
			} else {
				return doc.characteristics[this.sortField];
			}
		} else {
			return doc.title;
		}
	}

	editCharData() {
		this.editMode = true;
		let y = this.charData;
	}

	cancel() {
		this.editMode = false;
		this.getJobDetails$();
	}

	saveCharData() {
		this.progressService.startProgress();
		this.editMode = false;

		this.entityService.updateCharData(this.recordID, CharTypeID.Job, this.charData)
			.subscribe(result => {
				if (result.success) {
					this.notifyService.success("Save complete");
				}
				else {
					this.notifyService.error("Error saving");
				}

				this.progressService.endProgress();
			});
	}

	getRelatedCharTypeIds() {
		this.relatedCharTypeIDs.push(CharTypeID.Property);
		this.relatedCharTypeIDs.push(CharTypeID.Job);
		this.relatedCharTypeIDs.push(CharTypeID.Project);
		this.relatedCharTypeIDs.push(CharTypeID.Transaction);
	}
}
