import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from "@angular/core";

export interface IPageChangedEvent {
	page: number;
	pageSize: number;
	start: number;
}

@Component({
	selector: "app-component-pager",
	templateUrl: "./component-pager.component.html",
	styleUrls: ["./component-pager.component.less"]
})
export class ComponentPagerComponent implements OnInit {
	@Input()
	page: number;

	@Input()
	pageSize: number;

	@Input()
	numFound: number;

	@Input()
	showProjectedContent = false;

	@Output()
	onPageChanged = new EventEmitter<IPageChangedEvent>();

	public pageSizes: number[] = [10, 25, 50, 100];
	jumpToPage = 1;

	constructor() { }

	ngOnInit() {
		this.jumpToPage = this.page;
	}

	ngOnChanges(changes: SimpleChanges) {
		if (changes.page?.previousValue !== changes.page?.currentValue) {
			this.jumpToPage = this.page;
		}
	}

	changePage(page?: number): void {
		if (page) {
			this.page = page;
		}
		this.jumpToPage = this.page;
		this.emitEvent();
	}

	changePageSize(pageSize: number): void {
		this.pageSize = pageSize;
		this.page = 1;
		this.emitEvent(1);
	}

	emitEvent(page?: number): void {
		this.onPageChanged.emit({
			page: page ? page : this.page,
			pageSize: this.pageSize,
			start: this.start
		});
	}

	get start() {
		const defaultRowCount = 20;
		return this.pageSize > 0 ? this.pageSize * (this.page - 1) : defaultRowCount * (this.page - 1);
	}

	get pageMaxRecord(): number {
		return Math.min(this.page * this.pageSize, this.numFound) || 1;
	}

	get pageMinRecord(): number {
		return this.page * this.pageSize - this.pageSize + 1;
	}
}

