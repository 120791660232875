<div class="card card-h-100">
	<div class="card-body text-start">
		<header *ngIf="!!heading">
			<div class="section-head row align-items-center">
				<div class="col-6">
					<h1><a [routerLink]="linkHelper.list()" data-cy="listHeaderLink">Files</a></h1>
				</div>
				<div class="col-6 table__header d-flex justify-content-end">
					<div ngbDropdown class="rl-table-dropdown ms-2" placement="bottom-end">
						<button ngbDropdownToggle class="btn btn-icon btn-info hide-caret" data-cy="FilesHamburgerMenu">
							<i class="far fa-bars"></i>
						</button>
						<ul ngbDropdownMenu class="dropdown-menu-right" role="menu">
							<li ngbDropdownItem>
								<a href="javascript:void()" (click)="exportFiles($event)" data-cy="FilesExportItems">Export</a>
							</li>
						</ul>
					</div>
					<explorer-upload-file-button class="ms-1" *ngIf="showUploadFile && canWrite" [includeHeading]="true"
						(fileUploaded)="loadFiles()"></explorer-upload-file-button>
				</div>
			</div>
		</header>
		<div class="scroll-horiz">
			<table class="table rl-table">
				<thead>
					<tr>
						<th>Title</th>
						<th>Status</th>
					</tr>
				</thead>
				<tbody>
					<tr *ngFor="let document of documents">
						<td [innerHTML]="document.title"></td>
						<td [innerHTML]="document.status"></td>
					</tr>
				</tbody>
			</table>
		</div>
	</div>
</div>