import { PlatformLocation } from "@angular/common";
import { Injectable, Injector } from "@angular/core";
import { NgbModal, NgbModalOptions, NgbModalRef } from "@ng-bootstrap/ng-bootstrap";
import { Observable } from "rxjs";
import { filter, take, tap } from "rxjs/operators";


abstract class Modal<ComponentType> {

	protected _injector: Injector;
	protected _size: "sm" | "lg" | "xl" = "lg";
	protected _keyboard = true;
	protected _backdrop: boolean | "static" = true;
	protected _cancelFn: (component: ComponentType) => Observable<void>;
	protected _animation = false;

	constructor(protected readonly _ngbModal: NgbModal, protected readonly _content: any) { }

	injector(injector: Injector): this {
		this._injector = injector;
		return this;
	}

	size(size: "sm" | "lg" | "xl"): this {
		this._size = size;
		return this;
	}

	keyboard(keyboard: boolean): this {
		this._keyboard = keyboard;
		return this;
	}

	backdrop(backdrop: boolean | "static"): this {
		this._backdrop = backdrop;
		return this;
	}

	withCancelFn(cancelFn: (component: ComponentType) => Observable<void>): this {
		this._cancelFn = cancelFn;
		return this;
	}
}

class ModalWithOutput<ComponentType> extends Modal<ComponentType> {

	private _closeOnOutput = true;

	constructor(_ngbModal: NgbModal, _content: any) {
		super(_ngbModal, _content);
	}

	closeOnOutput(closeOnOutput: boolean): this {
		this._closeOnOutput = closeOnOutput;
		return this;
	}

	open<ReturnType>(setupFunc: (component: ComponentType) => Observable<ReturnType>): Observable<ReturnType> {
		const options: NgbModalOptions = {
			size: this._size,
			injector: this._injector,
			keyboard: this._keyboard,
			backdrop: this._backdrop,
			animation: this._animation
		};
		const modalRef = this._ngbModal.open(this._content, options);
		const component: ComponentType = modalRef.componentInstance;

		if (this._cancelFn) {
			this._cancelFn(component)
				.pipe(
					take(1),
					tap(onCancel => modalRef.close()))
				.subscribe();
		}

		return setupFunc(component).pipe(
			take(1),
			filter(() => this._closeOnOutput),
			tap(() => modalRef.close())
		);
	}
}

class PlainModal<ComponentType> extends Modal<ComponentType> {

	constructor(_ngbModal: NgbModal, _content: any) {
		super(_ngbModal, _content);
	}

	open(): NgbModalRef {
		const options: NgbModalOptions = {
			size: this._size,
			injector: this._injector,
			keyboard: this._keyboard,
			backdrop: this._backdrop,
			animation: this._animation
		};

		const modalRef = this._ngbModal.open(this._content, options);
		const component: ComponentType = modalRef.componentInstance;

		if (this._cancelFn) {
			this._cancelFn(component)
				.pipe(
					take(1),
					tap(onCancel => modalRef.close()))
				.subscribe();
		}

		return modalRef;
	}
}


@Injectable({ providedIn: "root" })
export class ModalBuilder {

	constructor(private readonly _ngbModal: NgbModal, platformLocation: PlatformLocation) {
		platformLocation.onPopState(() => this._ngbModal.dismissAll());
	}

	build<T>(content: any) {
		return new ModalWithOutput<T>(this._ngbModal, content);
	}

	plain<T>(content: any) {
		return new PlainModal<T>(this._ngbModal, content);
	}

	dismissAll(message: string) {
		this._ngbModal.dismissAll(message);
	}

}

