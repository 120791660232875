import { SimpleChanges } from '@angular/core';
import { CharTypeID, ExplorerView } from "./consts";

export interface PPSettings {
	serviceApiDomain: string;
	clientID: string;
	logoUrl: string;
	title: string;
	storefront: {
		assetFacets: { [key: string]: string };
		catalogSearchDetails: { [key: string]: string };
		privacyPolicyPath?: string;
		termsAndConditionsPath?: string;
		faqPath?: string;
		contentPath?: string;
		contactInfo: {
			address: string;
			email: string;
			phone: string;
			fax: string;
			orgName: string;
		}
	};
	partnerPortal: {
		homePageRedirect: string;
		displayTitleField: string;
		showNewReleases: boolean;
		newReleasesSortField: string;
	};
}

export interface PPSession {
	unique_name: string;
	sub: string;
	RL_USER_ID: string;
}

export interface IEntitySearchDoc {
	entityID: string;
	charTypeID: number;
	divisionID: number;
	recordID: number;
	title: string;
	statusID: number;
	status: string;
	templateID: number;
	template: string;
	templateGroupID: number;
	templateGroup: string;
	recordRevisionID: number;
	lastUpdatedBy: number;
	lastUpdated: string;
	statusUpdatedBy: number;
	statusUpdated: string;
	createdBy: number;
	created: string;
	characteristics: { [tagLabel: string]: any };
}

export interface ISearchResults {
	documents: IEntitySearchDoc[];
	numFound: number;
	templateFacets: { key: string; value: number; }[];
	statusFacets: { key: string; value: number; }[];
}

export interface IGrouping {
	groups: IGroup[];
}

export interface IGroup {
	documents: IEntitySearchDoc[];
	groupValue: string;
	numFound: number;
}

//#region Entity
export interface ICharacteristicData {
	charactersticID: number;
	recordCharacteristicID: number;
	value: string;
	valueID?: number;
}

export interface ICharDatas {
	[tagLabel: string]: ICharacteristicData[];
}

export interface IAppOption {
	name: string;
	divId: number;
	appUrlId: number;
}

export interface IEntity {
	id: string;
	charTypeID: number;
	divisionID: number;
	recordID: number;
	title: string;
	statusID: number;
	status: string;
	templateID: number;
	template: string;
	templateGroupID: number;
	templateGroup: string;
	recordRevisionID: number;
	lastUpdatedBy: number;
	lastUpdated: string;
	statusUpdatedBy: number;
	statusUpdated: string;
	createdBy: number;
	created: string;
}

export interface ICharDataCollection {
	entityID: string;
	status: IEntityStatus;
	templateMetaData: ICharacteristicMetaDataCollection;
	charDatas: ICharDatas;
}

export interface IEntityStatus {
	activeIndicator: number;
	stepID: number;
	processID: number;
	divisionID: number;
	stepName: string;
	deleteIndicator: number;
	stepDescription: string;
	lockIndicator: number;
	sequenceNumber: number;
	createdBy: number;
	createdAt: string;
	updatedBy: number;
	updatedAt: string;
}

export interface ICharacteristicMetaDataCollection {
	divID: number;
	charTypeID: number;
	templateID: number;
	groups: Array<ITemplateCharacteristicGroup>;
	template: ICharacteristicTemplate;
	characteristicMetaDatas: Array<ICharacteristicMetaData>;
}

export interface ITemplateCharacteristicGroup {
	groupID: number;
	groupLabel: string;
	systemIndicator: number;
	visibilityIndicator: number;
	sequenceNumber: number;
	acl: string;
}

export interface ITemplateListResponse {
	divId: number;
	templates: ICharacteristicTemplate[];
}

export interface ICharacteristicTemplate {
	charTypeID: number;
	templateID: number;
	templateGroupID: number;
	workflowProcessID: number;
	templateName: string;
	description: string;
	systemIndicator: number;
	visibilityIndicator: number;
	sequenceNumber: number;
	acl: string;
}

export interface IGetAssociatedTemplatesForTemplateResponse {
	templates: ICharacteristicTemplate[];
}



export interface ICharacteristicMetaData extends ICharacteristic {
	groupID: number;
	sequenceNumber: number;
	requiredIndicator: number;
	visibilityIndicator: number;
	charValueSourceID: string;
	editIndicator: number;
	deleteIndicator: number;
	acl: string;
	triggerTypeID: string;
	triggerCharID?: number;
	triggerValue: string;
}

export interface ICharacteristic {
	charTypeID: number;
	characteristicID: number;
	label: string;
	tagLabel: string;
	maxLength: number;
	dataTypeID: number;
	systemIndicator: number;
	multipleIndicator: number;
	charValueSetID: string;
}

export interface ICharacteristicMetaDataValue {
	characteristicValueID: number;
	characteristicValueLabel: string;
	defaultIndicator: number;
	sequenceNumber: number;
	childValues: Array<ICharacteristicMetaDataValue>;
}

export interface ICharLovMetaData {
	charValueSourceID: string;
	charValueSetID: string;
	listOfValues?: Array<ICharacteristicMetaDataValue>;
	charValueGroups?: Array<ICharacteristicValueGroup>;
	charValueGroupAssociations?: { [key: number]: string[] };
}

export interface ICharacteristicValueGroup {
	charValueGroupID: string;
	charValueSourceID: string;
	divID: number;
	charValueGroupLabel: string;
	charValueGroupDescription: string;
	charValueAssociations: Array<ICharacteristicValueGroupCharacteristicValueAssociation>
}

export interface ICharacteristicValueGroupCharacteristicValueAssociation {
	charValueGroupCharValueID: string;
	divID: number;
	charValueGroupID: string;
	charValueID: number;
}

export interface IEntityRelationship {
	divisionID: number;
	parentRecID: number;
	parentCharTypeID: number;
	childCharTypeID: number;
	childRecID: number;
	level?: number;
	relRecID?: number;
	relTypeID?: number;
	relIndicatorID?: number;
}

export interface IEntityID {
	recID: number;
	charTypeID: number;
	divID: number;
}

export interface ITemplateEntityID {
	templateID: number;
	charTypeID: number;
	divID: number;
}

export interface ICharDataMoneyValue {
	locAmt?: string;
	locCur?: number;
	locSym?: string;
	divAmt?: string;
	divCur?: number;
	divSym?: string;
}

//#endregion

//#region Workflow
export interface IWorkflowAction {
	processID: number;
	divisionID: number;
	actionID: number;
	actionName: string;
	actionDescription: string;
	pointProcID: number;
	nextPageID: number;
	sequenceNumber: number;
	docGenIndicator: number;
}

export interface IExecuteWorkflowActionResponse {
	success: boolean;
	message: string;
	errorStep: string;
	newStatusID: number;
}

//#endregion

//#region Config
export interface IParty {
	divisionID: number;
	charTypeID: number;
	templateID: number;
	partyID: number;
	partyGroupID: number;
	partyName: string;
	partyDescription: string;
	requiredIndicator: number;
	multipleIndicator: number;
	editIndicator: number;
	deleteIndicator: number;
	primaryIndicator: number;
	visibilityIndicator: number;
	sequenceNumber: number;
	createdBy: number;
	createdAt: string;
	updatedBy: number;
	updatedAt: string;
}

//#endregion

//#region Utils
type QueryNodeValue = string | number | boolean;
type QueryNodeRangeValue = string | number;

export interface IQueryNode {

	$and?: IQueryNode[];
	$or?: IQueryNode[];
	$not?: [IQueryNode];
	$eq?: [string, QueryNodeValue];
	$neq?: [string, QueryNodeValue];
	$contains?: [string, QueryNodeValue];
	$ncontains?: [string, QueryNodeValue];
	$gt?: [string, QueryNodeRangeValue];
	$gte?: [string, QueryNodeRangeValue];
	$lt?: [string, QueryNodeRangeValue];
	$lte?: [string, QueryNodeRangeValue];
	$range?: [string, QueryNodeRangeValue, QueryNodeRangeValue] | [string, QueryNodeRangeValue, QueryNodeRangeValue, boolean] | [string, QueryNodeRangeValue, QueryNodeRangeValue, boolean, boolean];
	$eq_any?: [string, (QueryNodeValue)[]];
	$contains_any?: [string, (QueryNodeValue)[]];
	$eq_all?: [string, (QueryNodeValue)[]];
	$contains_all?: [string, (QueryNodeValue)[]];
	$eq_none?: [string, (QueryNodeValue)[]];
	$contains_none?: [string, (QueryNodeValue)[]];
	$join_parent_entity?: [number, IQueryNode];
	$join_child_entity?: [number, IQueryNode];
	$join_parent_rel?: [IQueryNode];
	$join_child_rel?: [IQueryNode];
}

//#endregion

//#region CharDataTable

export interface ICharDataChangeEvent {
	cmd: ICharacteristicMetaData;
	charData: ICharacteristicData[];
	silent?: boolean;
}

export interface ITreeListChangeEvent {
	leafValueIDs: number[];
	branchValueIDs: number[];
}

//#endregion


//#region StorefrontCart

export interface IStorefrontCartDetails {
	dealEntity: IEntity;
	dealCharData: ICharacteristicData[];
	dealTemplate: ICharacteristicMetaDataCollection;
	rightsEntity: IEntity;
	rightsCharData: ICharacteristicData[];
	rightsTemplate: ICharacteristicMetaDataCollection;
	isCurrentCart: boolean;
}

export interface IStorefrontCartContacts {
	contacts: IStorefrontCartModelContactRel[];
	entities: { [recID: string]: IEntity };
	charDatas: { [recID: string]: ICharacteristicData[] };
	templates: { [recID: string]: ICharacteristicMetaDataCollection };
	partyToContactTemplates: { [partyID: string]: number[] };
	parties: IParty[];
}

export interface IStorefrontCartAssets {
	assets: IStorefrontCartModelAssetRel[];
	assetEntities: { [recID: string]: IEntity };
	assetCharDatas: { [recID: string]: ICharacteristicData[] };
	assetTemplates: { [recID: string]: ICharacteristicMetaDataCollection };
	usageEntities: { [recID: string]: IEntity };
	usageCharDatas: { [recID: string]: ICharacteristicData[] };
	usageTemplates: { [recID: string]: ICharacteristicMetaDataCollection };
	keyart: { [recID: string]: string };
}

export interface IStorefrontCartModelAssetRel {
	relRecID: number;
	recID: number;
	usages: IStorefrontCartModelUsageRel[];
}

export interface IStorefrontCartModelUsageRel {
	relRecID: number;
	recID: number;
}

export interface IStorefrontCartModelContactRel {
	relRecID: number;
	recID: number;
	relInd: number;
}

export interface IStorefrontDealInvoiceDoc {
	recordID: number;
	title: string;
	template: string;
	status: string;
	lastUpdated: string;
	paymentAllowed: boolean;
	paymentConfirmation: string;
}

export interface IStorefrontAction {
	actionName: string;
	actionID: number;
}

export interface IStorefrontDealDownload {
	created: string,
	attachmentId: number,
	attachmentLabel: string,
	fileName: string,
	filePath: string
}

export interface IStorefrontDealSystemDocs {
	documents: IRecordDocument[],
	downloadUrls: { [recID: string]: string },
	viewUrls: { [recID: string]: string }
}

export interface IStorefrontDealEntityDocs {
	entities: IEntity[],
	charDatas: { [recID: string]: ICharacteristicData[] },
	templates: { [templateID: string]: ICharacteristicMetaDataCollection },
	downloadUrls: { [recID: string]: string }
}

//#endregion

export interface ISimpleTemplateModel {
	templateName: string;
	templateID: number;
};

export interface IRecordDocument {
	documentID: number;
	documentRevisionID: number;
	charTypeID: number;
	filename: string;
	templateLabel: string;
	username: string;
	createdAt: string;
	recID: number;
}

export interface IStorefrontInvoiceDetails {
	entity: IEntity;
	charData: ICharacteristicData[],
	template: ICharacteristicMetaDataCollection
}

export interface ICreditCardInfo {
	cardType: string;
	cardNumber: string;
	expirationMM: string;
	expirationYY: string;
	cvv: string;
	cardholderName: string;
	address1: string;
	address2: string;
	city: string;
	state: string;
	zip: string;
}

export interface IJobReponse {
	jobID: string;
	jobTitle?: string;
}

export interface IEntityHierarchy {
	children: IEntityHierarchy[]
	root: IEntitySearchDoc;
}
export interface IJobMessage {
	timestamp: string;
	id: string;
	message: string;
}

export interface IJobMessage {
	timestamp: string;
	id: string;
	message: string;
}

export interface IJobState {
	jobID: string;
	status: string;
	statusDescription: string;
	total: number;
	completed: number;
	failed: number;
	infoMessages: IJobMessage[];
	createdAt: string;
	progressMessage: string;
	resultMessage: string;
	progressResultData: string;
}

export interface IBasePortalSettings {
	production: boolean;
	title: string;
	clientID: string;
	serviceApiDomain: string;
	logoUrl: string;
	filestackApiKey: string;
	filestackBucket: string;
}

export interface IPartnerPortalSettings extends IBasePortalSettings {
	partnerPortal: {
		useSSO?: boolean;
		homePageRedirect: string;
		displayTitleField: string;
		showNewReleases: boolean;
		newReleasesSortField: string;
		defaultExplorerView:
		{
			charTypeID: CharTypeID,
			view: ExplorerView
		}[]
	}
}

export interface IStoreFrontSettings extends IBasePortalSettings {
	storefront: {
		assetFacets: { [key: string]: string };
		catalogSearchDetails: { [key: string]: string };
		privacyPolicyPath?: string;
		contactUsPath?: string;
		termsAndConditionsPath?: string;
		newPaymentGateway: boolean;
		faq?: IFaqSection[],
		content?: {
			homepageH1: string;
			homepageH2: string;
			paymentSuccessMessage: string;
		}
		contactInfo: {
			address: string;
			email: string;
			phone: string;
			fax: string;
			orgName: string;
		},
		footer?: {
			items: string[];
			itemLinks: { [key: string]: string };
		},
		customTerms?: {
			termLinks: { [key: string]: string };
		}
		recaptcha3SiteKey: string;
	};
}
export interface IContactUsCategory {
	category: string;
	email: string;
}

export interface IFaqSection {
	title: string;
	items: IFaqItem[];
}

export interface IFaqItem {
	q: string;
	a: string;
}

export interface IPreviewDocument {
	uri: string;
	fileName: string;
}

export interface IContentJson {
	homepageH1: string;
	homepageH2: string;
	paymentSuccessMessage: string;
}


export interface IComponentChange<T, P extends keyof T> {
	previousValue: T[P];
	currentValue: T[P];
	firstChange: boolean;
	isFirstChange(): boolean;
}

export type ComponentChanges<T> = {
	[P in keyof T]?: IComponentChange<T, P>;
} & SimpleChanges;


export interface JwtTokenValue {
	RL_CURRENT_DIV_ID: string;
	unique_name: string;
	sub: string;
	RL_USER_ID: string;
	RL_DISPLAY_NAME: string;
	TOKEN_SCOPE: TokenScope;
	USER_APPS: string[] | string;
	RL_PROFILE_AVATAR: string;
	RL_RESET_PASSWORD_TOKEN: string;
}

export interface IAppOption {
	name: string;
	divId: number;
	appUrlId: number;
	urlTypeId: number;
}


export enum TokenScope {
	Admin = "admin",
	ChooseApp = "choose_app",
	TwoFactorRequired = "two_factor_required",
	PasswordResetRequired = "password_reset_required"
}

export interface LoginOptionsResponse {
	success: boolean;
	message: string;
	showPassword: boolean;
	showSSO: boolean;
	ssoEndpoint: string;
	ssoAuthNRequest: string;
	ssoAppUrlID: number;
	redirectToClassic: boolean;
}

export interface IInitializeWizardResponse {
	emptyCharData: ICharDataCollection;
	onlyAvailableEntity: string;
	totalRecords: number;
}

export interface IEntityDocGroup {
	template: string;
	templateId: number;
	documents: IEntitySearchDoc[];
}

