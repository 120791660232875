import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, SimpleChanges } from "@angular/core";
import * as _ from "lodash";
import { ExplorerView } from "../../../common/consts";
import { ICharacteristic, IEntityDocGroup } from "../../../common/models";
import { EntityLinks } from "../../../partner-portal/services/pp-link-helper.service";
import { ExplorerFileListComponent } from "./explorer-file-list/explorer-file-list.component";
import { ExplorerGridComponent } from "./explorer-grid/explorer-grid.component";
import { ExplorerListComponent } from "./explorer-list/explorer-list.component";
import { ExplorerTileComponent } from "./explorer-tile/explorer-tile.component";
import { ExplorerUploadFileButtonComponent } from "./explorer-upload-file-button/explorer-upload-file-button.component";
import { NewFileWizardModalComponent } from "./explorer-upload-file-button/new-file-wizard/new-file-wizard.component";
import { ExplorerUploadFileListComponent } from "./explorer-upload-file-list/explorer-upload-file-list.component";
import { ExplorerViewSelectorComponent } from "./explorer-view-selector/explorer-view-selector.component";

@Component({
    selector: "explorer",
    templateUrl: "./explorer.component.html"
})
export class ExplorerComponent implements OnInit, OnDestroy {
    @Input()
    documents: IEntityDocGroup[];

    @Input()
    keyArt: _.Dictionary<string>;

    @Input()
    fileURLs: _.Dictionary<string>;

    @Input()
    associatedDeals: _.Dictionary<string>;

    @Input()
    view: ExplorerView;

    explorerView = ExplorerView;

    @Input()
    currentPage: number;

    @Input()
    pageCount: number;

    @Input()
    disablePaging: boolean = false;

    @Output()
    pageChanged = new EventEmitter<number>();

    @Input()
    linkHelper: EntityLinks;

    @Input()
    sourceFields: { [template: number]: ICharacteristic[] };
    constructor() {

    }

    ngOnChanges(changes: SimpleChanges) {
        if ('currentPage' in changes) {
            this.pageChanged.emit(this.currentPage)
        }
    }

    ngOnInit() {
    }

    ngOnDestroy() {

    }

    onPageChange(page: number) {
        this.pageChanged.emit(page);
    }
}

export var EXPLORER_COMPONENTS = [
    ExplorerComponent,
    ExplorerGridComponent,
    ExplorerListComponent,
    ExplorerTileComponent,
    ExplorerViewSelectorComponent,
    ExplorerFileListComponent,
    ExplorerUploadFileButtonComponent,
    ExplorerUploadFileListComponent,
    NewFileWizardModalComponent
];