import { Component, Input, Output, OnInit, forwardRef, EventEmitter, OnChanges, SimpleChange, Optional } from "@angular/core";
import * as _ from "lodash";

import { ICharacteristicMetaDataValue, ITreeListChangeEvent } from "../models";
import { TreeListService } from "./tree-list.service";
@Component({
    selector: "tree-list",
    template: "<tree-list-list class='pp-tree-list' [depth]='0' [lovs]='lovs' [selectedValueIDs]='selectedValueIDs'></tree-list-list>",
    providers: [TreeListService]
})
export class TreeListComponent implements OnChanges, OnInit {

    @Input()
    lovs: ICharacteristicMetaDataValue[]

    @Input()
    selectedValueIDs: number[];

    @Input()
    singleMode: boolean;

    @Output()
    selectedValueIDsChange = new EventEmitter<ITreeListChangeEvent>();

    constructor(private treeListService: TreeListService) {

        this.treeListService.selectedValueChange
            .subscribe((result) => {
                this.selectedValueIDsChange.emit(result);
            });
    }

    ngOnInit() {
        if (this.singleMode === true) {
            this.treeListService.singleSelectionMode = true;
        }
    }

    ngOnChanges(changes: { [key: string]: SimpleChange }) {
        let lovsChange = changes["lovs"];
        if (lovsChange != null && lovsChange.currentValue != null) {
            this.treeListService.setLovs(this.lovs);
        }
        let selectedValueIDsChange = changes["selectedValueIDs"];
        if (selectedValueIDsChange != null && selectedValueIDsChange.currentValue != null) {
            this.treeListService.setSelectedValues(this.selectedValueIDs);

            if (selectedValueIDsChange.isFirstChange()) {
                this.treeListService.showSelected();
            }
        }
    }
}


@Component({
    selector: "tree-list-list",
    templateUrl: "./tree-list-list.component.html"
})
export class TreeListList {
    @Input()
    lovs: ICharacteristicMetaDataValue[];

    @Input()
    selectedValueIDs: number[];

    @Input()
    depth: number;

    constructor(private treeListService: TreeListService) {

    }

    isOpen(lov: ICharacteristicMetaDataValue) {
        return this.treeListService.isOpen[lov.characteristicValueID] || false;
    }

    getArrowClass(lov: ICharacteristicMetaDataValue) {
        if (lov.childValues.length == 0) {
            return "";
        } else if (this.isOpen(lov)) {
            return "arrow-btn far fa-angle-down";
        } else {
            return "arrow-btn far fa-angle-right";
        }
    }

    isChecked(lov: ICharacteristicMetaDataValue) {
        return _.includes(this.treeListService.checkedValueIDs, lov.characteristicValueID);
    }

    toggleOpen(lov: ICharacteristicMetaDataValue) {
        this.treeListService.toggleOpen(lov);
    }

    toggleSelected(lov: ICharacteristicMetaDataValue) {
        this.treeListService.toggleSelected(lov);
    }

}

export const TREELIST_COMPONENTS = [TreeListComponent, TreeListList];