import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { EntityLinks } from "../../../../partner-portal/services/pp-link-helper.service";
import { IEntitySearchDoc } from "../../../models";

@Component({
    selector: "explorer-list",
    templateUrl: "./explorer-list.component.html"
})
export class ExplorerListComponent implements OnInit, OnDestroy {
    @Input()
    documents: IEntitySearchDoc[];
    @Input()
    linkHelper: EntityLinks;

    @Input()
    heading: string = '';

    @Input()
    displaySummarizedView: boolean = false;

    constructor() {

    }

    ngOnInit() {

    }

    ngOnDestroy() {

    }
}