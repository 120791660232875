<div class="input-group align-items-center">
    <span class="input-group-prepend">
        <i class="far fa-calendar-alt"></i>
    </span>
    <input [style.display]="(isPerpetuity && allowPerpetuity) ? 'none' : 'block'"
        #input
        class="form-control"
        type="text"
        bsDatepicker
        [bsValue]="_dateValue"
        [bsConfig]= "{ containerClass: 'theme-default' }"
        (bsValueChange)="datePicked($event)"
        (blur)="touched($event)"
        data-cy="DateEditControl" />
    <input [style.display]="(!isPerpetuity || !allowPerpetuity)  ? 'none' : 'block'" type="text" class="form-control" disabled
        value="Perpetuity" data-cy="DateEditControlPerpetuity" />
    <div class="input-group-append" *ngIf="allowPerpetuity" title="In Perpetuity">
        <input type="checkbox" class="me-1 ms-2" [checked]="isPerpetuity" (click)="togglePerpetuity()" data-cy="togglePerpetuity" />
        <i class="far fa-infinity"></i>
    </div>
</div>