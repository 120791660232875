import { Component, OnInit, ChangeDetectionStrategy, Input, OnChanges, SimpleChanges, Output, EventEmitter } from '@angular/core';
import { ICharDataMoneyValue, ICharacteristicMetaData, ICharacteristicMetaDataValue } from '../../../models';

@Component({
    selector: 'money-edit-control',
    templateUrl: './money-edit-control.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class MoneyEditControlComponent {

    @Input()
    localAmount: string;

    @Input()
    localCurrencySymbol: string;

    @Input()
    divAmount: string;

    @Input()
    divCurrencySymbol: string;

    @Input()
    validCurrencyListOfValues: ICharacteristicMetaDataValue[];

    @Output()
    touch = new EventEmitter<Event>();

    @Output()
    textValueChange = new EventEmitter<string>();

    @Output()
    currencyChange = new EventEmitter<ICharacteristicMetaDataValue>();

    constructor() { }

    touched($event: Event) {
        this.touch.emit($event);
    }

    changeCurrency($event: Event, lov: ICharacteristicMetaDataValue) {
        $event.preventDefault();
        this.currencyChange.emit(lov);
    }
}