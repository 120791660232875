import { Injectable, Inject } from "@angular/core";
import { Observable } from "rxjs";

import { HttpClient, HttpParams, HttpHeaders } from "@angular/common/http";
import { COMMON_SETTINGS } from "app/common/settings";
import { IBasePortalSettings } from "app/common/models";


@Injectable()
export class PPHttpService {

    constructor(private httpClient: HttpClient, @Inject(COMMON_SETTINGS) private settings: IBasePortalSettings) {

    }

    request<T>(method: string, url: string, params?: HttpParams, body?: any) {
        url = this.settings.serviceApiDomain + url;
        // always attach client-id
        var headers = new HttpHeaders({ "Client-Id": this.settings.clientID });
        let requestOptions: { body: any, responseType: "json", params?: HttpParams, headers?: HttpHeaders } =
            {
                body: body,
                responseType: "json",
                params: params,
                headers: headers
            }
        return this.httpClient.request<T>(method, url, requestOptions);
    }

    get<T>(url: string, params?: {}): Observable<T> {
        let requestParams = params ? new HttpParams({ fromObject: params }) : null;
        return this.request<T>("get", url, requestParams, null);
    }

    post<T>(url: string, body?: any): Observable<T> {
        return this.request<T>("post", url, null, body);
    }

    put<T>(url: string, body?: any): Observable<T> {
        return this.request<T>("put", url, null, body);
    }

    delete<T>(url: string): Observable<T> {
        return this.request<T>("delete", url, null, null);
    }

    patch<T>(url: string, body: any): Observable<T> {
        return this.request<T>("patch", url, null, null);
    }

}