import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { ComponentChanges, ICharacteristicData, ICharacteristicMetaData } from 'app/common/models';
import { AclService } from 'app/common/services/acl.service';
import { AclUtil } from 'app/common/utils/acl.util';
import { cloneDeep, isEmpty, last } from 'lodash';
import { Subject, Subscription, asyncScheduler } from 'rxjs';
import { tap, throttleTime } from "rxjs/operators";
import { BaseElement } from "./base-element";

@Component({
	selector: 'text-edit-multi-element',
	templateUrl: './text-edit-multi-element.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class TextEditMultiElementComponent extends BaseElement implements OnInit, OnChanges {

	editedCharData: ICharacteristicData[];
	_textValueChangeDebouncer$ = new Subject<void>();
	_subs: Subscription[] = [];
	canDelete = true;

	@Input()
	textValue: string;

	@Input()
	maxLength: number;

	@Input()
	cmd: ICharacteristicMetaData;

	@Input()
	charData: ICharacteristicData[];

	@Output()
	multiValueChange = new EventEmitter<ICharacteristicData[]>();

	@Output()
	touch = new EventEmitter<Event>();

	@Input()
	isTextArea: boolean;

	constructor(private readonly _cdRef: ChangeDetectorRef, private aclService: AclService) {
		super();
	}

	ngOnInit() {
		const sub = this._textValueChangeDebouncer$
			.pipe(
				throttleTime(500, asyncScheduler, { leading: true, trailing: true }),
				tap(() => {
					this.editedCharData = cloneDeep(this.editedCharData.filter(cd => !isEmpty(cd.value)));
					this.canDelete = AclUtil.hasDeleteAccess(this.aclService.acls, this.cmd.acl);
					this.ensureBlankCD();
					this.multiValueChange.emit(
						this.editedCharData.filter(cd => !isEmpty(cd.value))
					);
				})
			)
			.subscribe();
		this._subs.push(sub);
	}

	ngOnChanges(changes: ComponentChanges<TextEditMultiElementComponent>) {
		if (changes.charData) {
			this.editedCharData = cloneDeep(changes.charData.currentValue.filter(cd => !isEmpty(cd.value)));
			this.ensureBlankCD();
		}
	}

	ngOnDestroy() {
		this._subs.forEach(s => s.unsubscribe());
	}

	ensureBlankCD() {
		const lastCD = last(this.editedCharData);
		if (this.editedCharData && lastCD == null || !isEmpty(lastCD.value)) {
			this.editedCharData.push(this.createCharData(null));
			this._cdRef.detectChanges();
		}
	}

	textValueChanged(value: string, cd: ICharacteristicData) {
		cd.value = value;
		this._cdRef.detectChanges();
		this.emitChange();
	}

	removeCharData(index: number) {
		this.editedCharData.splice(index, 1);
		this.emitChange();
	}

	emitChange() {
		this._textValueChangeDebouncer$.next();
	}

	trackByFn(i: number, cd: ICharacteristicData) {
		return `${i}_${cd.charactersticID}`;
	}

	getPlaceholder(isLast: boolean): string {
		return isLast ? "Add New Value" : "";
	}

}