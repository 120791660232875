import { Component, ChangeDetectionStrategy, Input, OnChanges, SimpleChange, Output, EventEmitter, ViewChild, ElementRef, AfterViewInit, OnDestroy, NgZone } from "@angular/core";
import * as moment from "moment";

import { DateUtil } from "../../../utils/date-util";

@Component({
    selector: "date-edit-control",
    templateUrl: "./date-edit-control.component.html",
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class DateEditControlComponent implements AfterViewInit, OnDestroy {

    _dateValue: Date;

    @Input()
    set dateValue(value: string) {
        if (value == "") {
            this._dateValue = null;
        } else {
            let m = moment(value, "MM/DD/YYYY");
            if (m.isValid()) {
                this._dateValue = m.toDate();
            }
        }
    }

    get dateValue(): string {
        let m = moment(this._dateValue);
        if (m.isValid()) {
            return m.format("MM/DD/YYYY");
        }
        return "";
    }

    @Output()
    dateValueChange = new EventEmitter<string>();

    @Output()
    touch = new EventEmitter<Event>();

    @ViewChild("input", { static: true })
    input: ElementRef;

    @Input()
    allowPerpetuity: boolean;

    tempValue: string;

    get isPerpetuity(): boolean {
        return DateUtil.isPerpetuity(this.dateValue);
    }

    constructor(private ngZone: NgZone) {

    }

    ngAfterViewInit() {
        // $(this.input.nativeElement)["datepicker"]({
        //     onSelect: (dateText: string, inst: any) => {
        //         this.ngZone.run(() => {
        //             this.dateValueChange.emit(dateText);
        //         });
        //     }
        // });
    }

    ngOnDestroy() {
        // $(this.input.nativeElement)["datepicker"]("destroy");
    }

    touched($event: Event) {
        this.touch.emit($event);
    }

    togglePerpetuity() {
        //console.log(value);
        //$event.preventDefault();
        if (!this.isPerpetuity) {
            this.tempValue = this.dateValue;
            this.dateValueChange.emit(DateUtil.perpetuityMoment.format("L"));
        } else {
            this.dateValueChange.emit(this.tempValue || "");
        }
    }

    datePicked($event: Date) {
        if (this._dateValue != $event) {
            this._dateValue = $event;
            this.dateValueChange.emit(this.dateValue);
        }
    }
}