import { Injectable } from '@angular/core'
import { PPHttpService } from "./pp-http.service";
import { PPCacheHttpService, PPCacheHttpOptions } from './pp-cache-http.service';
import * as _ from "lodash";
import {
    IJobState,
    ICharacteristicMetaData,
    ICharacteristicMetaDataValue
} from "../models";
import { duration } from 'moment';

@Injectable()
export class AdminService {
    private _baseUrl = "/service-api/admin";

    constructor(private ppHttpService: PPHttpService, private ppCacheHttpService: PPCacheHttpService) { }

    getJobState(jobId: string) {
        return this.ppHttpService.get<IJobState>(`${this._baseUrl}/get-job-state/${jobId}`);
    }

    cancelJob(jobId: string) {
        return this.ppHttpService.get<void>(`${this._baseUrl}/cancel-job/${jobId}`);
    }

    getValidCurrencies(charValueSourceID: string, charValueSetID: string) {
        const data = {
            charValueSourceID: charValueSourceID,
            charValueSetID: charValueSetID
        };
        let cacheOptions: PPCacheHttpOptions = {
            key: `getValidCurrencies_${charValueSourceID}_${charValueSetID}`,
            expiresIn: duration(10, "minutes")
        };

        return this.ppCacheHttpService.post<ICharacteristicMetaDataValue[]>(`${this._baseUrl}/get-valid-currencies`, data, cacheOptions);
    }

    getCompanyCurrency(charValueSourceID: string, charValueSetID: string) {
        const data = {
            charValueSourceID: charValueSourceID,
            charValueSetID: charValueSetID
        };

        let cacheOptions: PPCacheHttpOptions = {
            key: `getCompanyCurrency_${charValueSourceID}_${charValueSetID}`,
            expiresIn: duration(10, "minutes")
        };

        return this.ppCacheHttpService.post<{ symbol: string, currencyID: number }>(`${this._baseUrl}/get-company-currency`, data, cacheOptions);
    }

    convertCurrency(localCurrencySymbol: string, localCurrencyAmount: number) {
        const data = {
            localCurrencySymbol: localCurrencySymbol,
            localCurrencyAmount: localCurrencyAmount
        };

        return this.ppHttpService.post<{ symbol: string, amount: number }>(`${this._baseUrl}/convert-global-currency`, data);
    }

    postError(data: {}) {
        return this.ppCacheHttpService.post<void>(`${this._baseUrl}/throw-error`, data);
    }

    getError() {
        return this.ppCacheHttpService.get<void>(`${this._baseUrl}/throw-error`);
    }
}