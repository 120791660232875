import { Component, Inject } from "@angular/core";
import { Router } from "@angular/router";
import { PartnerPortalService } from "app/partner-portal/services/partner-portal.service";
import { PARTNER_PORTAL_SETTINGS } from "app/partner-portal/settings";
import { map, tap } from "rxjs/operators";
import { IPartnerPortalSettings } from "../../../common/models";
import { SessionService } from "../../../common/services/session.service";
import { PPLinkHelperService } from "../../services/pp-link-helper.service";
import { AclService } from "../../../common/services/acl.service";
import { DivSettingKey } from "app/common/messages";
import { AclUtil } from "../../../common/utils/acl.util";
import { Configuration } from "app/common/consts";
import { resourceUsage } from "process";


@Component({
    selector: "pp-header",
    templateUrl: "./pp-header.component.html"
})
export class PPHeaderComponent {

    get isAuthenticated(): boolean {
        return this.sessionService.session != null;
    };

    get username(): string {
        return this.sessionService.username;
    };

    logoUrl;
    title: string;
    menuCollapsed = true;
    divImageUrl$ = this.partnerPortalService.divImageUrl$;
    currentDivName$ = this.sessionService.currentDivName$;
    acls$ = this.sessionService.acls$;
    hasConfigAccess: boolean = false;
    currentDiv: string;

    constructor(
        public sessionService: SessionService,
        private router: Router,
        public linkHelper: PPLinkHelperService,
        public partnerPortalService: PartnerPortalService,
        private aclService: AclService,
        @Inject(PARTNER_PORTAL_SETTINGS) private settings: IPartnerPortalSettings) {

        this.logoUrl = this.settings.logoUrl;
        this.title = this.settings.title;

        this.router.events.subscribe(() => this.menuCollapsed = true);
    }

    ngOnInit() {
        this.partnerPortalService.getDivSettingsList().pipe(
            map(result => {
                return Object.keys(result).map(key => result[key]).sort((a, b) => a.key.localeCompare(b.key));
            }),
            tap(settings => {
                var portalLogo = settings.find(x => x.key === DivSettingKey.PortalLogo);

                if (portalLogo !== undefined) {
                    this.logoUrl = portalLogo.derivedValue;
                    this.partnerPortalService.divImageUrl$.next(portalLogo.derivedValue)
                }
            })
        ).subscribe();

        this.divImageUrl$.pipe(
            tap(image => {
                if (image) {
                    this.logoUrl = image;
                }
            })
        ).subscribe();

        this.acls$.pipe(
            tap((result) => {
                if (result) {
                    const configAcl = Configuration.toString();
                    this.hasConfigAccess = AclUtil.hasReadAccess(this.aclService.acls, configAcl);
                }

            })
        ).subscribe();

        this.currentDivName$.pipe(
            tap((divName) => {
                if (divName) {
                    this.currentDiv = divName;
                }
            }
            )).subscribe();
    }

    logout($event: Event) {
        $event.preventDefault();
        this.sessionService.clearSession();
        this.router.navigate(this.linkHelper.account.login());
    }
}
