import {Component, ChangeDetectionStrategy, OnChanges, SimpleChange, DoCheck} from "@angular/core";
import * as moment from "moment";
import * as _ from "lodash";

import {BaseElement} from "./base-element";
import {ICharacteristicData} from "../../../models";

@Component({
    selector: "date-edit-element",
    template: "<date-edit-control [dateValue]='dateValue' [allowPerpetuity]='allowPerpetuity' (dateValueChange)='dateValueChanged($event)' (touch)='touched($event)'></date-edit-control>",
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DateEditElementComponent extends BaseElement implements OnChanges {

    dateValue: string;

    allowPerpetuity: boolean;

    constructor() {
        super();
    }

    ngOnChanges(changes: { [key: string]: SimpleChange; }) {
        if (this.charData) {
            if (_.some(this.charData)) {
                let m1 = moment(this.charData[0].value, "MM-DD-YYYY");
                if (m1.isValid()) {
                    this.dateValue = m1.format("MM/DD/YYYY");
                } else {
                    this.dateValue = "";
                }
            } else {
                this.dateValue = "";
            }
            //this.textValue = _.join(this.charData.map(cd => cd.value), ", ") || "";
        }
        this.allowPerpetuity = this.cmd.tagLabel.endsWith("_end");
    }

    dateValueChanged(dateValue: string) {
        //console.log(dateValue);
        let charData: ICharacteristicData = null;
        let value = dateValue;
        let momentValue = moment(dateValue, "MM/DD/YYYY");
        if (momentValue.isValid()) {
            value = momentValue.format("MM/DD/YYYY");
        }
        if (_.some(this.charData)) {
            charData = _.clone(this.charData[0]);
            charData.value = value;
            charData.valueID = null;
        } else {
            charData = this.createCharData(value);
        }
        this.charDataChange.emit({ cmd: this.cmd, charData: [charData] });

    }

}