import {AbstractControl, UntypedFormArray, UntypedFormGroup, UntypedFormControl, ValidatorFn, Validators} from "@angular/forms";
import * as _ from "lodash";
import * as moment from "moment";

import {ICharacteristicData, ICharacteristicMetaData} from "../../../models";

import {CharDataType} from "../../../consts";

const yearRegex = new RegExp("^(?:\\d{4})$");
const numberRegex = /^-?(?:\d+|\d{1,3}(?:,\d{3})+)?(?:\.\d+)?$/;
const currencyRegex = /^-?(?:\d+|\d{1,3}(?:,\d{3})+)?(?:\.\d{2})?$/;
const dateRegex = /^(0[1-9]|[1-9]|1[0-2])\/(0[1-9]|[1-9]|1\d|2\d|3[01])\/(19[0-9]{2}|[2-9][0-9]{3})$/;
const dateIsoRegex = /^\d{4}[\/\-]\d{1,2}[\/\-]\d{1,2}$/;
const timecodeRegex = new RegExp("^\\d{2}:\\d{2}:\\d{2}$");

let patternValidator = (key: string, pattern: RegExp): ValidatorFn => {
    return (c: AbstractControl): { [key: string]: any } => {
        let charDatas = <ICharacteristicData[]>c.value;
        if (_.some(charDatas, (cd) => {
            return !_.isEmpty(cd.value) 
                    && !pattern.test(cd.value || "") 
                    || !passesNumberRange(key, cd.value);
        })) {
            let output = {};
            output[key] = true;
            return output;
        }
        return null;
    };
};

let emailValidator = (): ValidatorFn => {
    return (c: AbstractControl): { [key: string]: any } => {
        const charDatas = <ICharacteristicData[]>c.value;
		if (_.some(charDatas, (cd) => !_.isEmpty(cd.value))) {
			const email = charDatas.map(x => new UntypedFormControl(x.value));
			const control = new UntypedFormArray(email, []);
			return Validators.email(control);
		}
		return null;
    }
};

const urlValidator = (): ValidatorFn => {
  return (c: AbstractControl): { [key: string]: any } => {
    const charDatas = <ICharacteristicData[]>c.value;
    const httpUrlScheme = "http://";
    for (const cd of charDatas) {
      let url = cd.value?.toLocaleLowerCase();
      if (!url) {
        continue;
      }
      try {
        if (!url.includes(":")) {
          // prepend the URL with http:// if the scheme is missing
          url = `${httpUrlScheme}${url}`;
        }
        if (url.split(".").filter(x => x.length > 0).length < 2) {
          return { invalidUrl: true };
        }
        new URL(url);
      } catch {
        return { invalidUrl: true };
      }
    }
    return null;
  };
};

export class FormControlWarn extends UntypedFormControl {
	warnings: { [key: string]: any };
}

export class FormControlRequired extends UntypedFormControl {
	isRequired: boolean;
	showRequired: boolean;
	isActivated: boolean;
}

export const required: ValidatorFn = (c: AbstractControl) => {
    let charDatas = <ICharacteristicData[]>c.value;
    if (charDatas == null || _.isEmpty(charDatas) || _.isEmpty((charDatas[0].value || "").trim())) {
        return {
            "required": true
        };
    }
    return null;
};

export const desired: ValidatorFn = (c: FormControlWarn) => {
		if (!c) {
				return null;
		}
		const requiredResult = required(c);
		if (requiredResult) {
				c.warnings = { ...c.warnings, ...{ "desired": true } };
		} else if (c.warnings && c.warnings["desired"]) {
				delete c.warnings["desired"];
		}
		return null;
};

export const passesNumberRange = (key: string, value: string): boolean => {
	if (key === "number" && value.indexOf(".") === -1) {
			const number = parseInt(value);
			return number >= -2147483648 && number <= 2147483647;
	}

	return true;
};

export const validDate: ValidatorFn = (c: AbstractControl) => {
    let charDatas = <ICharacteristicData[]>c.value;
    if (_.some(charDatas, (cd) => !_.isEmpty(cd.value) && !moment(cd.value, "MM-DD-YYYY").isValid())) {
        return {
            "date": true
        };
    }
    return null;
};

export const validTimecode: ValidatorFn = (c: AbstractControl) => {
    let charDatas = <ICharacteristicData[]>c.value;
    let pattern = timecodeRegex;
    if (_.some(charDatas, (cd) => !_.isEmpty(cd.value) && (!pattern.test(cd.value || "") || !moment(cd.value, "HH:mm:ss").isValid()))) {
        let output = {};
        output["timecode"] = true;
        return output;
    }

    return null;
}

export const validNumber = patternValidator("number", numberRegex);

export const validEmail = emailValidator();

export const validYear = patternValidator("year", yearRegex);

export const validUrl = urlValidator();

export const validAmount = patternValidator("amount", currencyRegex);


export const tableValidator = (cmds: ICharacteristicMetaData[]) => {
    return (group: UntypedFormGroup) => {
        _(cmds)
            .filter(cmd => cmd.dataTypeID == CharDataType.Date && /(.+)_start/.test(cmd.tagLabel))
            .forEach(startCmd => {
                let endCmd = _.find(cmds, cmd => cmd.tagLabel == startCmd.tagLabel.replace("_start", "_end"))
                if (endCmd != null) {
                    let startCtrl = group.controls[startCmd.tagLabel];
                    let endCtrl = group.controls[endCmd.tagLabel];
                    if (startCtrl != null && endCtrl != null) {
                        let startCharDatas = <ICharacteristicData[]>startCtrl.value;
                        let endCharDatas = <ICharacteristicData[]>endCtrl.value;
                        if (startCharDatas.length > 0 && endCharDatas.length > 0) {
                            let startMoment = moment(startCharDatas[0].value, "MM-DD-YYYY");
                            let endMoment = moment(endCharDatas[0].value, "MM-DD-YYYY");
                            if (startMoment.isValid() && endMoment.isValid()) {
                                if (startMoment.isAfter(endMoment)) {
                                    startCtrl.setErrors({
                                        "start_date_after": true
                                    });
                                    endCtrl.setErrors({
                                        "end_date_before": true
                                    });
                                } else {
                                    if (startCtrl.hasError("start_date_after")) {
                                        startCtrl.updateValueAndValidity({ emitEvent: false });
                                    }
                                    if (endCtrl.hasError("end_date_before")) {
                                        endCtrl.updateValueAndValidity({ emitEvent: false });
                                    }
                                }
                            }
                        }

                    }
                }
            });
    };
};