import { KeyValue } from "@angular/common";
import { Pipe, PipeTransform } from "@angular/core";
import { ErrorMessageUtil } from "../components/char-data/elements/element-error-messages.model";

@Pipe({
	name: "errorMsg"
})
export class ErrorMsgPipe implements PipeTransform {
	transform(errorCode: KeyValue<string, string> | string): string {
		if (typeof errorCode === "string") {
			return ErrorMessageUtil.errorsLookup[errorCode];
		} else if (typeof errorCode.value === "string") {
			return errorCode.value;
		} else {
			return ErrorMessageUtil.errorsLookup[errorCode.key];
		}
	}
}