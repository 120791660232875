import { Injectable } from '@angular/core';
import {
	ICharDataCollection,
	ICharDatas,
	ICharacteristicData,
	ICharacteristicTemplate,
	IEntity,
	IEntityHierarchy,
	IEntityStatus,
	ITemplateListResponse
} from "../models";
import { PPHttpService } from "./pp-http.service";


@Injectable()
export class EntityService {

	private _baseUrl = "/service-api/entity";

	constructor(private ppHttpService: PPHttpService) {

	}

	getEntity(id: string) {
		return this.ppHttpService.get<IEntity>(`${this._baseUrl}/${id}`);
	}
	getEntityStatusFromID(id: string) {
		return this.ppHttpService.get<IEntityStatus>(`${this._baseUrl}/${id}/entity-status`);
	}
	getTemplateStatus(charTypeID: number, templateID: number, statusID: number) {
		return this.ppHttpService.get<IEntityStatus>(`${this._baseUrl}/${charTypeID}/${templateID}/${statusID}`);
	}

	getTemplates(charTypeID: number) {
		return this.ppHttpService.get<ITemplateListResponse>(`${this._baseUrl}/${charTypeID}/templates`);
	}

	getCreateTemplates(charTypeID: number) {
		return this.ppHttpService.get<ITemplateListResponse>(`${this._baseUrl}/${charTypeID}/templates/create`);
	}

	getCharData(id: string) {
		return this.ppHttpService.get<ICharacteristicData[]>(`${this._baseUrl}/${id}/char-data`);
	}

	getEmptyEntityCharData(templateID) {
		return this.ppHttpService.get<ICharDataCollection>(`${this._baseUrl}/${templateID}/empty-char-data`);
	}

	createEntity(entityTemplateID: string, parentEntityID: string, charDatas: ICharDatas) {
		return this.ppHttpService
			.post<{ entityID: string, relRecID: number }>(`${this._baseUrl}/create-entity/${entityTemplateID}/${parentEntityID}`,
				charDatas);
	}

	getDownloadUrl(recCharID: number) {
		return this.ppHttpService.get<string>(`${this._baseUrl}/get-download-url/${recCharID}`);
	}

	getPreviewUrl(recCharID: number) {
		return this.ppHttpService.get<string>(`${this._baseUrl}/get-preview-url/${recCharID}`);
	}

	getAssociatedTemplates(charTypeID: number, templateID: number, childCharTypeID: number) {
		const url = `${this._baseUrl}/${charTypeID}/${templateID}/${childCharTypeID}/get-associated-templates`;
		return this.ppHttpService.get<ICharacteristicTemplate[]>(url);
	}

	getEntityHierarchy(moduelEntityID: string, entityID: string, childCharTypeID: number, depthLevel: number) {
		return this.ppHttpService.get<IEntityHierarchy>(`${this._baseUrl}/get-entity-hierarchy/${moduelEntityID}/${entityID}/${childCharTypeID}/${depthLevel}`);
	}

	updateCharData(recordID: number, charTypeID: number, charData: ICharacteristicData[]) {
		return this.ppHttpService.post<{ success: boolean }>(`${this._baseUrl}/update-char-data/${recordID}/${charTypeID}`, charData);
	}
}