import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";
import { distinctUntilChanged } from "rxjs/operators";

@Component({
	selector: "text-edit-control",
	templateUrl: "./text-edit-control.component.html",
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class TextEditControlComponent {

	@Input()
	textValue = "";

	@Input()
	maxLength: number;

	@Input()
	placeholder = "";

	@Output()
	textValueChange = new EventEmitter<string>();

	@Output()
	touch = new EventEmitter<Event>();

	@Input()
	isTextArea: boolean;

	keyUp = new EventEmitter<string>();
	contentLength: number;

	ngOnInit(): void {
		this.contentLength = this.textValue && this.textValue.length || 0;
		// this._innerTextValue = this.textValue;
	}

	constructor() {
		this.keyUp
			.pipe(
				distinctUntilChanged())
			.subscribe((value) => {
				this.textValueChange.emit(value);
			});
	}

	change(value: string) {
		this.contentLength = value?.length || 0;
	}

	touched($event: Event) {
		this.touch.emit($event);
	}

}