<div class="section-head d-flex justify-content-between align-items-center mb-3">
	<div class="me-auto ps-3">
		<h1 class="title" *ngIf="title" [innerText]="title"></h1>
	</div>
	<div class="pe-3 align-self-stretch border-start" [hidden]="!title">
		<pp-workflow-action [entityId]="entity?.id" (statusChange)="refresh()"></pp-workflow-action>
	</div>
</div>

<app-contacts *ngIf="entity" [charTypeId]="entity?.charTypeID" [recordId]="recordID"></app-contacts>

<div class="card mb-3">
	<div class="card-title d-flex justify-content-between">
		<h3>Details</h3>
		<div>
			<button *ngIf="canWrite && !editMode" type="button" class="btn btn-icon btn-info" (click)="editCharData()"
				[disabled]="isLocked" title="Edit this record" data-cy="editRecord">
				<i class="far fa-pencil"></i>
			</button>
			<button *ngIf="canWrite && editMode" type="button" class="btn btn-sm btn-light me-2" (click)="cancel()"
				title="Cancel" data-cy="cancelEdit">
				Cancel
			</button>
			<button *ngIf="canWrite && editMode" [disabled]="!charDataTable.valid" type="button" class="btn btn-sm btn-success"
				(click)="saveCharData()" title="Save this record" data-cy="saveEdit">
				Save
			</button>
		</div>
	</div>
	<div class="card-body pt-0">
		<char-data-table #charDataTable [charData]="charData" [template]="templateMetaData" [editMode]="editMode"
			(tableCharDataChange)="charData=$event"></char-data-table>
	</div>
</div>

<div *ngFor="let relatedCharTypeID of relatedCharTypeIDs">
	<association-list *ngIf="entity" [entityId]="entity?.id" [associatedCharTypeId]="relatedCharTypeID">
	</association-list>
</div>

<tables *ngIf="entity" [entityId]="entity?.id" [templateId]="entity?.templateID"></tables>

<folders *ngIf="entity" [entityId]="entity?.id" [templateId]="entity?.templateID"></folders>