
import {filter} from 'rxjs/operators';
import { Component, Input, OnInit, OnDestroy, Inject } from "@angular/core";
import { NgModel, UntypedFormBuilder, FormControl, UntypedFormGroup } from "@angular/forms";
import { Router, ActivatedRoute } from "@angular/router";
import { Subscription } from "rxjs";
import * as _ from "lodash";

import { ProgressService } from "../../../common/services/progress.service";
import { PartnerPortalService } from "../../services/partner-portal.service";
import { PPLinkHelperService } from "../../services/pp-link-helper.service";
import { IEntitySearchDoc, IPartnerPortalSettings } from "../../../common/models";
import { ExplorerView } from "../../../common/consts"
import { PARTNER_PORTAL_SETTINGS } from "app/partner-portal/settings";


@Component({
    selector: "catalog-items-list",
    templateUrl: "./catalog-items-list.component.html"
})
export class CatalogItemsListComponent implements OnInit, OnDestroy {

    routeSub: Subscription;

    documents: IEntitySearchDoc[];
    keyArt: _.Dictionary<string>;
	templateFacets: { name: string; id: number; }[];
	statuses: { name: string; id: number; }[];

    page: number = 1;

    pageCount: number = 1;
    rows: number = 24;
    sortField: string;

    initialized = false;

    searchForm: UntypedFormGroup;
    templateID: number | string;
	keywords: string = "";
	statusID: string = "";

    fadeState = "out";

    view: ExplorerView;

    constructor(
        private partnerPortalService: PartnerPortalService,
        private progressService: ProgressService,
        private activatedRoute: ActivatedRoute,
        private router: Router,
        private fb: UntypedFormBuilder,
        private linkHelper: PPLinkHelperService,
        @Inject(PARTNER_PORTAL_SETTINGS) private settings: IPartnerPortalSettings) {

        this.searchForm = fb.group({
            "keywords": [this.keywords],
			"template": [this.templateID],
			"status": [this.statusID]
        });

        if (this.settings.partnerPortal != null) {
            if (this.settings.partnerPortal.displayTitleField != null) {
                this.sortField = this.settings.partnerPortal.displayTitleField;
            }
        }
    }

    ngOnInit() {
        this.routeSub = this.activatedRoute
            .parent
            .params
            .subscribe(params => {
                this.keywords = params["k"] || "";
                this.templateID = _.parseInt(params["t"]) || "";
                this.page = +params["p"] || 1;
				this.statusID = params["s"] || "";
                this.search();
            });

        this.searchForm.controls["template"]
            .valueChanges.pipe(
            filter((value) => value != this.templateID))
            .subscribe((value) => {
                this.page = 1;
                this.nextSearch();
			});
		this.searchForm.controls["status"]
			.valueChanges.pipe(
				filter((value) => value != this.statusID))
			.subscribe((value) => {
				this.page = 1;
				this.nextSearch();
			});
    }

    ngOnDestroy() {
        this.routeSub.unsubscribe();
    }

    search() {
        let keywords = this.searchForm.controls["keywords"].value;
        let templateID = _.parseInt(this.searchForm.controls["template"].value) || _.parseInt(this.templateID.toString());
        let start = this.rows * (this.page - 1);
        this.fadeState = "out";
		this.progressService.startProgress();
		this.partnerPortalService.getAssociatedCatalogItems(keywords, templateID, start, this.rows, _.parseInt(this.statusID), this.sortField)
            .subscribe((results) => {
                this.documents = results.documents;
                this.keyArt = results.keyArt;

                this.templateFacets = results.templateFacets.map((kvp) => {
                    let parts = kvp.key.split("|");
                    return {
                        name: `${parts[0]} (${kvp.value})`,
                        id: _.parseInt(parts[1])
                    };
				});
				this.statuses = results.statusFacets.map((kvp) => {
					let parts = kvp.key.split("|");
					return {
						name: `${parts[0]} (${kvp.value})`,
						id: _.parseInt(parts[1])
					};
				});

                this.pageCount = Math.ceil(results.numFound / this.rows);
                this.initialized = true;
                this.fadeState = "in";
                this.progressService.endProgress();
            });
    }

    nextSearch() {
        let keywords = this.searchForm.controls["keywords"].value || "";
        let templateID = this.searchForm.controls["template"].value;
		let statusID = this.searchForm.controls["status"].value;
        let params = {
            p: this.page
        };
        if (keywords) {
            params["k"] = keywords;
        }
        if (templateID) {
            params["t"] = templateID;
        }
		if (statusID) {
			params["s"] = statusID;
		}

        this.router.navigate(this.linkHelper.catalogItems.list(params));
    }

    optionTrackBy(value: { name: string; id: number; }) {
        return value.id;
    }

    pageChanged(page: number) {
        this.page = page;
        this.nextSearch();
    }

    submitSearch() {
        this.page = 1;
        this.nextSearch();
    }

    viewChanged(view: ExplorerView) {
        this.view = view;
    }
}