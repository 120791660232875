<div class="mb-3" *ngIf="relatedDocs?.length > 0">
	<div>
		<h3>{{documentTitle}}</h3>
	</div>
	<div>
		<table class="table rl-table mb-0">
			<!-- margin-bottom 0 so paging footer is not separated -->
			<thead>
				<tr>
					<th></th>
					<th>ID</th>
					<th>Title</th>
					<th>Template</th>
					<th>Status</th>
					<th>Created Date</th>
					<th>Updated Date</th>
				</tr>
			</thead>
			<tbody>
				<tr *ngFor="let doc of relatedDocs">
					<th>
						<input type="radio" name="itemRadio" [value]="doc.entityID" [(ngModel)]="selectedEntityID"
							(change)="onRadioChange(doc.entityID)" />
					</th>
					<td [innerText]="doc.recordID"></td>
					<td *ngIf="documentTitle === 'Catalog Items'"><a [routerLink]="linkHelper.catalogItems.detail(doc.recordID)" target="_blank"
							[innerText]="doc.title" data-cy="recordDetails"></a></td>
					<td *ngIf="documentTitle === 'Jobs'"><a [routerLink]="linkHelper.jobs.detail(doc.recordID)" target="_blank"
							[innerText]="doc.title"></a></td>
					<td *ngIf="documentTitle === 'Projects'"><a [routerLink]="linkHelper.projects.detail(doc.recordID)" target="_blank"
							[innerText]="doc.title"></a></td>
					<td *ngIf="documentTitle === 'Deals'"><a [routerLink]="linkHelper.deals.detail(doc.recordID)" target="_blank"
							[innerText]="doc.title"></a></td>
					<td *ngIf="documentTitle === 'Accounting'"><a [routerLink]="linkHelper.accounting.detail(doc.recordID)" target="_blank"
							[innerText]="doc.title" data-cy="recordDetailsAlt"></a></td>
					<td [innerText]="doc.template"></td>
					<td [innerText]="doc.status"></td>
					<td [innerText]="doc.created | date:'short'"></td>
					<td [innerText]="doc.lastUpdated | date:'short'"></td>
				</tr>
			</tbody>
		</table>
		<app-component-pager [page]="page" [pageSize]="pageSize" [numFound]="numFound" (onPageChanged)="pageChanged($event)"></app-component-pager>
	</div>
</div>