import { CharTypeID, ExplorerView } from "app/common/consts";
import { IPartnerPortalSettings } from "app/common/models";

// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export var environment: IPartnerPortalSettings = {
  title: "Rightsline Portal",
  clientID: "7f2653ea31614b7ca223795a6d5c302a",
  production: false,
  serviceApiDomain: "https://localhost:30003",
  logoUrl: "/assets/logo-rightsline.png",
  partnerPortal: {
    homePageRedirect: null,
    displayTitleField: null,
    showNewReleases: null,
    newReleasesSortField: null,
    useSSO: true,
    defaultExplorerView: [
      {
        charTypeID: CharTypeID.Property,
        view: ExplorerView.List
      },
      {
        charTypeID: CharTypeID.Transaction,
        view: ExplorerView.List
      },
      {
        charTypeID: CharTypeID.Project,
        view: ExplorerView.List
      },
      {
        charTypeID: CharTypeID.Job,
        view: ExplorerView.List
      }
    ]
  },
  filestackApiKey: "AOWRFSYChRXeDnREn8vgUz",
  filestackBucket: "dev-rtl-main"
};
