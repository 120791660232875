import { Component, Inject } from "@angular/core";
import { Title } from "@angular/platform-browser";
import { NgbDropdownConfig } from "@ng-bootstrap/ng-bootstrap";
import { IPartnerPortalSettings } from "app/common/models";
import { DivSettingKey } from "app/common/messages";
import { AdminService } from "app/common/services/admin.service";
import { PartnerPortalService } from "app/partner-portal/services/partner-portal.service";
import { tap, map } from "rxjs/operators";
import { PARTNER_PORTAL_SETTINGS } from "app/partner-portal/settings";


@Component({
  selector: "pp-app",
  templateUrl: "./pp-app.component.html",
})

export class PPApp {
  constructor(private title: Title, @Inject(PARTNER_PORTAL_SETTINGS) private settings: IPartnerPortalSettings, public adminService: AdminService,
    private partnerPortalService: PartnerPortalService, dropDownConfig: NgbDropdownConfig) {
    this.title.setTitle(this.settings.title);
    dropDownConfig.container = "body";
    this.refreshSettings();
  }

  refreshSettings() {
    this.partnerPortalService.getDivSettingsList().pipe(
      map(result => {
        return Object.keys(result).map(key => result[key]).sort((a, b) => a.key.localeCompare(b.key));
      }),
      tap(settings => {
        var hexColor1 = settings.find(x => x.key === DivSettingKey.HexColor1);
        var hexColor2 = settings.find(x => x.key === DivSettingKey.HexColor2);
        var hexColor3 = settings.find(x => x.key === DivSettingKey.HexColor3);
        var hexColor4 = settings.find(x => x.key === DivSettingKey.HexColor4);

        if (hexColor1 != null) { document.documentElement.style.setProperty('--brand-primary-color', hexColor1.value); }
        if (hexColor2 != null) { document.documentElement.style.setProperty('--brand-secondary-color', hexColor2.value); }
        if (hexColor3 != null) { document.documentElement.style.setProperty('--brand-secondary-light-color', hexColor3.value); }
        if (hexColor4 != null) { document.documentElement.style.setProperty('--brand-tertiary-color', hexColor4.value); }
      })).subscribe();
  }
}
