import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import * as _ from "lodash";
import { EntityLinks } from "../../../../partner-portal/services/pp-link-helper.service";
import { IEntitySearchDoc } from "../../../models";

@Component({
    selector: "explorer-grid",
    templateUrl: "./explorer-grid.component.html"
})
export class ExplorerGridComponent implements OnInit, OnDestroy {
    @Input()
    documents: IEntitySearchDoc[];
    @Input()
    keyArt: _.Dictionary<string>;
    @Input()
    linkHelper: EntityLinks;

    constructor(
        private activatedRoute: ActivatedRoute,
        private router: Router) {

    }

    ngOnInit() {

    }

    ngOnDestroy() {

    }
}