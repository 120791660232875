import { Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from "@angular/core";
import { CharDataType, CharTypeID, TemplateGroupIDs } from "app/common/consts";
import { AclService } from "app/common/services/acl.service";
import { DocumentEntityService } from "app/common/services/document-entity.service";
import { EntityService } from "app/common/services/entity.service";
import { ModalService } from "app/common/services/modal.service";
import { ProgressService } from "app/common/services/progress.service";
import { TemplateService } from "app/common/services/template.service";
import { AclUtil } from "app/common/utils/acl.util";
import { CharDataUtil } from "app/common/utils/char-data-util";
import { IDUtil } from "app/common/utils/id-util";
import { PartnerPortalService } from "app/partner-portal/services/partner-portal.service";
import * as _ from "lodash";
import { Subscription, forkJoin } from "rxjs";
import { filter, map, switchMap, tap } from "rxjs/operators";
import { EntityLinks } from "../../../../partner-portal/services/pp-link-helper.service";
import { ICharDatas, ICharacteristic, ICharacteristicData, ICharacteristicMetaDataCollection, IEntityDocGroup, IEntitySearchDoc, IPreviewDocument } from "../../../models";
import { ModalComponent } from "../../modal.component";

@Component({
	selector: "explorer-file-list",
	templateUrl: "./explorer-file-list.component.html"
})
export class ExplorerFileListComponent implements OnInit, OnChanges {
	@Input()
	documents: IEntityDocGroup[];
	@Input()
	fileURLs: _.Dictionary<string>;
	@Input()
	associatedDeals: _.Dictionary<string>;
	@Input()
	linkHelper: EntityLinks;
	@Input()
	includeHeading: boolean = false;
	@Input()
	displaySummarizedView: boolean = false;
	@Input()
	sourceFields: { [template: number]: ICharacteristic[] };

	startJobProgress: boolean;
	jobId: string;
	jobTitle: string;
	uploadFolderOrFileToEntityId: string;
	uploadFileFolderModalTitle: string;
	documentFileTemplateGroupID: number = TemplateGroupIDs.DocumentFolderTemplateGroupID;
	documentFolderTemplateGroupID: number = TemplateGroupIDs.DocumentFolderTemplateGroupID;
	uploadCharMetaDataCollection: ICharacteristicMetaDataCollection;
	uploadCharData: ICharacteristicData[];
	entityForEdit: string;
	canWrite: boolean = false;

	@ViewChild('editDocumentEntityModal', { static: true })
	editDocumentEntityModal: ModalComponent;

	public get fileDataType() {
		return CharDataType.ExternalDocument;
	}

	private _subs: Subscription[] = [];

	constructor(
		private readonly _modalService: ModalService,
		private readonly _entityService: EntityService,
		private readonly _documentEntityService: DocumentEntityService,
		private readonly _templateService: TemplateService,
		private readonly _progressService: ProgressService,
		private readonly _partnerPortalService: PartnerPortalService,
		private readonly _aclService: AclService) {
	}
	ngOnChanges(changes: SimpleChanges): void {
		this.getFileCharTagLabels();
	}

	ngOnInit() {
		const documentCharTypeACL = AclUtil.getDataEntityAcl(CharTypeID.Document);
		if (this._aclService.acls) {
			this.canWrite = AclUtil.hasWriteAccess(this._aclService.acls, documentCharTypeACL);
		}
		else {
			this.canWrite = false;
		}

	}

	getFileCharTagLabels() {
		if (this.documents && this.sourceFields) {
			this.documents.forEach((g: IEntityDocGroup) => {
				this.sourceFields[g.templateId].forEach(f => {
					f.tagLabel = CharDataUtil.GetCharSolrLabel(f.dataTypeID, f.multipleIndicator, f.tagLabel);
				});
			});
		}
	}

	getFileURL(fileID: number) {
		return this.fileURLs[fileID];
	}

	getDealID(fileID: number) {
		const deal = this.associatedDeals[fileID];
		const dealSplit = _.split(deal, "|");

		return dealSplit[0];
	}

	getDealTitle(fileID: number) {
		const deal = this.associatedDeals[fileID];
		const dealSplit = _.split(deal, "|");

		return dealSplit[1];
	}

	previewFile(record: IEntitySearchDoc) {
		const previewFileSub = forkJoin([
			this._entityService.getCharData(record.entityID),
			this._templateService.getTemplateMetaData(record.entityID)
		]).pipe(
			map(([charDatas, tmd]) => {
				let tuple: [number, string] = null;
				const characteristicID = tmd.characteristicMetaDatas.find(x => x.dataTypeID === CharDataType.ExternalDocument).characteristicID;
				const cd = charDatas.find(x => x.charactersticID === characteristicID);
				if (!cd) {
					// this._growlerService.error(`Preview Failed`).growl(`No external doc characteristic.`);
					return tuple;
				}
				const recordCharacteristicId = cd.recordCharacteristicID;
				const uri = charDatas.find(x => x.recordCharacteristicID === recordCharacteristicId)?.value;
				if (!uri) {
					// this._growlerService.warning(`Preview Failed`).growl(`No external doc value.`);
					return tuple;
				}
				let fileName = uri;
				if (uri.lastIndexOf("/") > -1) {
					fileName = uri.substring(uri.lastIndexOf("/") + 1);
				}
				tuple = [recordCharacteristicId, fileName];
				console.log('building preview ', tuple);
				return tuple;
			}),
			filter(tuple => !!tuple),
			switchMap(([recordCharacteristicID, fileName]) => {
				const file$ = this._entityService.getPreviewUrl(recordCharacteristicID).pipe(
					map(result => <IPreviewDocument>{ fileName, uri: result })
				);
				return file$;
			}),
			switchMap(file => this._modalService.previewDocument(file))
		).subscribe();
		this._subs.push(previewFileSub);
	}

	exportFiles(event: any) {
		event.preventDefault();
		this._progressService.startProgress();
		const fileIds = this.documents.flatMap(x => x.documents).map(d => d.recordID);
		this._documentEntityService.exportFiles(fileIds)
			.subscribe((result) => {
				this.jobId = result.jobID;
				this.jobTitle = result.jobTitle;
				this.startJobProgress = true;
				this._progressService.endProgress();
			});
	}

	openEditDocumentEntity(entityID: string, template: IEntitySearchDoc, $event: Event) {
		this.entityForEdit = entityID;
		$event.preventDefault();
		this._progressService.startProgress();
		const divID = IDUtil.splitEntityID(entityID).divID;
		this.uploadFolderOrFileToEntityId = entityID;
		let fileCharData;
		const fileTemplateID = IDUtil.toTemplateID(divID, CharTypeID.Document, template.templateID);
		if (template.templateGroupID == this.documentFolderTemplateGroupID) {
			this.uploadFileFolderModalTitle = "Edit Folder";
		}
		else {
			this.uploadFileFolderModalTitle = "Edit File";
		}
		this._partnerPortalService.getFileDetails(template.recordID).pipe(
			tap((result) => fileCharData = result),
			switchMap(() => this._entityService.getEmptyEntityCharData(fileTemplateID))
		).subscribe((response) => {
			this.uploadCharMetaDataCollection = response.templateMetaData;
			this.uploadCharData = fileCharData.charData;
			this.editDocumentEntityModal.open();
		});
	}

	saveDocumentEntity(valid: boolean) {
		if (valid) {
			this._progressService.startProgress();
			let charData: ICharDatas = {};
			_.forEach(this.uploadCharData,
				data => {
					let metaData = _.find(this.uploadCharMetaDataCollection.characteristicMetaDatas,
						cmd => cmd.characteristicID === data.charactersticID);
					charData[metaData.tagLabel] = [data];
				});
			this._entityService.updateCharData(IDUtil.splitEntityID(this.entityForEdit).recID, CharTypeID.Document, this.uploadCharData)
				.subscribe(() => {
					this.editDocumentEntityModal.close();
					this._progressService.endProgress();
					this._progressService.clearProgress();
				});
		} else {
			this._progressService.clearProgress();
		}
	}

	editDocumentEntityModalClose() {
		this._progressService.clearProgress();
		this.editDocumentEntityModal.close()
	}

	getCleanFileName(fullFileNameChars: any) {
		if (!_.isEmpty(fullFileNameChars)) {
			var fullFileName;
			if (Array.isArray(fullFileNameChars)) {
				fullFileName = fullFileNameChars[0];
			}
			else {
				fullFileName = fullFileNameChars;
			}
			var index = fullFileName.lastIndexOf("/");
			var cleanFileName = "";
			if (index >= 0) {
				cleanFileName = fullFileName.substring(index + 1);
			}
			else {
				cleanFileName = fullFileName;
			}
			return cleanFileName;
		}
		else {
			return "";
		}
	}
}
