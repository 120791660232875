import { Component, Input, OnInit, ViewChild } from "@angular/core";
import { UntypedFormArray, UntypedFormBuilder, UntypedFormControl } from "@angular/forms";
import { NgbDropdown } from "@ng-bootstrap/ng-bootstrap";
import * as _ from "lodash";
import { Dictionary, every } from "lodash";
import { Observable, Subscription, combineLatest, of } from "rxjs";
import { switchMap, tap } from "rxjs/operators";
import { CharDataType, CharTypeID, SortDirection } from "../../common/consts";
import { IFacetResult, IGetTablesResponse, ITableGroup, ITableGroupItem } from "../../common/messages";
import {
	ICharDatas,
	ICharacteristicData,
	ICharacteristicMetaData,
	ICharacteristicMetaDataCollection,
	ICharacteristicTemplate,
	IEntity,
	IEntityStatus,
	IWorkflowAction
} from "../../common/models";
import { IDUtil } from "../../common/utils/id-util";
import { AclService } from "../services/acl.service";
import { EntityService } from "../services/entity.service";
import { NotifyService } from "../services/notify.service";
import { ProgressService } from "../services/progress.service";
import { TableEntitySerivce } from "../services/table-entity.service";
import { AclUtil } from "../utils/acl.util";
import { CharDataUtil } from "../utils/char-data-util";
import { WorkflowService } from "./../services/workflow.service";
import { ModalComponent } from "./modal.component";

@Component({
	selector: "tables",
	templateUrl: "./tables.component.html",
	styleUrls: ["./tables.component.scss"]
})
export class TablesComponent implements OnInit {
	@Input()
	entityId: string;

	@Input()
	templateId: number;



	parentRecordID: number;
	parentCharTypeID: number;
	parentTemplateID: number;
	tableGroups: ITableGroup[];
	tableAssetTitles: { [key: string]: string };
	tableContactTitles: { [key: string]: string } = {};
	groupHasCatalogItems: { [key: string]: boolean };
	groupHasContactItems: { [key: string]: boolean } = {};
	groupHasFileUploadConfigured: { [key: string]: boolean };
	groupPage: { [key: string]: number };
	groupPageCount: { [key: string]: number };
	groupCMDs: { [templateID: string]: ICharacteristicMetaData[] };
	groupCharDatas: {
		[templateID: string]: { [recordID: string]: { [charID: string]: ICharacteristicData[] } }
	};
	loading: boolean;
	tablePageSize: number = 10;
	tableTemplates: ICharacteristicTemplate[];
	startJobProgress: boolean;
	jobId: string;
	jobTitle: string;
	headerSort: { [key: number]: number };
	isLoading = false;

	public workflowActions: IWorkflowAction[] = [];
	public selectedTables: { [templateId: number]: { [entityId: string]: boolean } } = {};
	public selectedRows: { [templateId: number]: IEntity[] } = {};

	@ViewChild("uploadFileModal", { static: true })
	uploadFileModal: ModalComponent;

	@ViewChild("editModal", { static: true })
	editModal: ModalComponent;

	@ViewChild("createModal", { static: true })
	createModal: ModalComponent;

	@ViewChild("filterDropdown", { static: true })
	filterDropdown: NgbDropdown;

	uploadTableCharData: ICharacteristicData[];
	uploadTableTemplate: ICharacteristicMetaDataCollection;
	uploadTableRecord: IEntity;

	//Edit
	editTableCharData: ICharacteristicData[];
	editTableTemplate: ICharacteristicMetaDataCollection;
	editTableRecord: IEntity;
	//--

	//Create
	createTableCharData: ICharacteristicData[] = [];
	createTableTemplate: ICharacteristicMetaDataCollection;
	createTableTemplates: ICharacteristicTemplate[] = [];
	//--

	isSelectAllChecked: { [templateId: number]: boolean } = {};
	visibleCharsByTemplateID: { [templateId: number]: ICharacteristicMetaData[] } = {};
	canRead: boolean = false;
	canCreate: boolean = false;
	canEdit: { [templateId: number]: boolean } = {};
	canDelete: { [templateId: number]: boolean } = {};
	useSortKey = false;
	sortKey;
	sortDir = SortDirection.DESC;
	columnFacets: Dictionary<IFacetResult[]>;
	facetLookups: { [templateId: number]: { [key: number]: string } } = {};
	facetFilterLookups: { [templateId: number]: { [cmd: number]: [IFacetResult, UntypedFormControl][] } } = {};
	allSelected: UntypedFormControl;
	selectedArray: UntypedFormArray;
	filterItems: { [templateId: number]: [IFacetResult, UntypedFormControl][] };
	columnFilters: { [facetFilter: string]: string[] } = {};
	isUpdate = false;
	entityStatus: IEntityStatus;
	isLocked: boolean = false;
	charData: ICharDatas = {};
	private _subs: Subscription[] = [];


	constructor(
		private readonly progressService: ProgressService,
		private readonly entityService: EntityService,
		private readonly aclService: AclService,
		private readonly tableEntitySerivce: TableEntitySerivce,
		private readonly workflowService: WorkflowService,
		private readonly notifyService: NotifyService,
		private _fb: UntypedFormBuilder
	) {
		this.tableGroups = [];
		this.tableAssetTitles = {};
		this.groupHasCatalogItems = {};
		this.groupPage = {};
		this.groupPageCount = {};
		this.groupCMDs = {};
		this.groupCharDatas = {};
		this.startJobProgress = false;
		this.groupHasFileUploadConfigured = {};
		this.headerSort = {};
		this.allSelected = this._fb.control(true);
		this.selectedArray = this._fb.array([]);

		const sub1 = this.allSelected.valueChanges.subscribe(() => {
			const selected: boolean = this.allSelected.value;
			const nextValue = (<boolean[]>this.selectedArray.value).map(_ => selected);
			this.selectedArray.patchValue(nextValue, { emitEvent: false });
		});
		this._subs.push(sub1);

		const sub2 = this.selectedArray.valueChanges.subscribe(() => {
			const allSelected = every(<boolean[]>this.selectedArray.value, v => v);
			this.allSelected.patchValue(allSelected, { emitEvent: false });
		});
		this._subs.push(sub2);
	}

	ngOnInit() {
		this.parentCharTypeID = IDUtil.splitEntityID(this.entityId).charTypeID;
		this.parentRecordID = IDUtil.splitEntityID(this.entityId).recID;
		this.progressService.startProgress();
		this.loading = true;

		//Permissions
		if (this.aclService.acls) {
			let usageCharTypeACL = AclUtil.getDataEntityAcl(CharTypeID.Usage);
			this.canRead = AclUtil.hasReadAccess(this.aclService.acls, usageCharTypeACL);
			this.canCreate = AclUtil.hasCreateAccess(this.aclService.acls, usageCharTypeACL);
		} else {
			this.canRead = false;
			this.canCreate = false;
		}

		combineLatest(
			this.getTableTemplates$(),
			this.getTables$(0))
			.pipe(switchMap(() => {
				return this.getWorkFlowActions();
			}))
			.subscribe(() => {
				this.initSelectedStatuses();
				this.getTemplateStatus$();
				this.loading = false;
				this.progressService.endProgress();
			});
	}

	getTemplateStatus$() {
		this.entityService.getEntityStatusFromID(this.entityId).subscribe(
			result => {
				this.entityStatus = result;
				this.isLocked = this.entityStatus.lockIndicator == 1;
			});
	}

	private initSelectedTables(): void {
		this.tableGroups.forEach(tableGroup => {

			this.selectedTables[tableGroup.templateID] = {};
			tableGroup.items.forEach(item => {
				this.selectedTables[tableGroup.templateID][item.record.id] = false;
			});
		});
	}

	private initSelectedStatuses(): void {
		this.tableGroups.forEach(tableGroup => {
			this.selectedRows[tableGroup.templateID] = [];
		});
	}

	public updateCheckedOptions(group: ITableGroup, option: ITableGroupItem, event): void {
		this.selectedTables[group.templateID][option.record.id] = event.target.checked;
		if (event.target.checked) {
			this.selectedRows[group.templateID].push(option.record);
		}
		else {
			this.selectedRows[group.templateID] = this.selectedRows[group.templateID].filter(row => row !== option.record);
		}
		this.updateWorkflowActionCheckbox(group.templateID);

		//Is everything selected or not selected?
		let enabled = true;
		Object.entries(this.selectedTables[group.templateID]).forEach(([, value]) => {
			enabled = enabled && value;
		});

		this.isSelectAllChecked[group.templateID] = enabled;
	}

	private updateWorkflowActionCheckbox(templateID: number) {
		var selectedStatuses: number[] = [];
		this.selectedRows[templateID].forEach((record) => {
			selectedStatuses.push(record.statusID);
		});
		if (selectedStatuses.length > 0) {
			//Make sure records selected share same status
			if (new Set(selectedStatuses).size == 1) {
				this.getWorkFlowActions(templateID, selectedStatuses[0]).subscribe(result => this.workflowActions = result);
			}
			//Remove any existing workflow actions if more than one status is present
			else {
				this.workflowActions = [];
			}
		}
		else {
			this.workflowActions = [];
		}
	}

	public executeAction(event: Event, group: ITableGroup, action: IWorkflowAction): void {
		event.preventDefault();
		const entityIds = [];
		Object.entries(this.selectedTables[group.templateID]).forEach(([key, value]) => {
			if (value) {
				entityIds.push(key);
			}
		});
		if (entityIds.length > 0) {
			this.progressService.startProgress();
			this.workflowService.executeWorkflowActionBulk(entityIds, action.processID, action.actionID)
				.pipe(switchMap(() => this.getTables$(0)))
				.subscribe(() => {
					this.selectedRows[group.templateID] = [];
					this.progressService.endProgress();
					this.notifyService.success(`Workflow Action(s) Executed`);
				})
		}
	}

	private getWorkFlowActions(templateID?: number, statusID?: number) {
		let obs: Observable<IWorkflowAction[]>;
		if (this.tableGroups.length > 0) {
			const templateId = templateID != null ? templateID : this.tableGroups[0].templateID;
			const statusId = statusID != null ? statusID : this.tableGroups[0].items[0].status.stepID;
			obs = this.workflowService.getComponentWorkflowActions(CharTypeID.Usage, templateId, statusId);
		} else {
			obs = of([]);
		}
		return obs.pipe(tap((results) => {
			this.workflowActions = results;
		}));

	}

	getTableTemplates$() {
		return this.entityService.getAssociatedTemplates(this.parentCharTypeID, this.templateId, CharTypeID.Usage)
			.pipe(tap(result => {
				this.createTableTemplates = result.filter(ct => AclUtil.hasCreateAccess(this.aclService.acls, ct.acl) && ct.visibilityIndicator == 2);
				//No available templates to add to table, hide the + sign
				if (this.createTableTemplates.length == 0) {
					this.canCreate = false;
				}
			}));
	}

	getTables$(start: number) {
		this.isLoading = true;
		return this.tableEntitySerivce
			.getTables(this.parentCharTypeID, this.parentRecordID, -1, start, this.tablePageSize, this.useSortKey, this.sortKey, this.sortDir, this.columnFilters)
			.pipe(
				tap((result) => {
					this.isLoading = false;
					if (result != undefined && result.length > 0) {
						this.tableGroups = result.flatMap(x => x.groups);
						this.tableTemplates = result[0].templates;
						this.tableAssetTitles = Object.assign(this.tableAssetTitles, result[0].assetTitles);
						this.tableContactTitles = Object.assign(this.tableContactTitles, result[0].contactTitles);
						this.setupTableGroupCMDs();
						this.initSelectedTables();

						//Get Visible Chars, Edit and Delete
						result.flatMap(x => x.templates).forEach((template) => {
							this.visibleCharsByTemplateID[template.templateID] = this.getVisibleCharacteristics(template.templateID);
							this.canEdit[template.templateID] = this.canEditTemplate(template);
							this.canDelete[template.templateID] = this.canDeleteTemplate(template);
							const templateFacetLookup = result.find(x => x.groups[0]?.templateID === template.templateID);
							if (templateFacetLookup) {
								this.facetLookups[template.templateID] = templateFacetLookup.facetLookup;
								this.setupTableGroupFacets(templateFacetLookup);
							}
						});
					}
				}));
	}

	setupTableGroupFacets(result: IGetTablesResponse) {
		const templateId = result.groups[0].templateID;
		const groupFacet = result.facetLookup;
		if (!this.facetFilterLookups[templateId] || this.isUpdate) {
			this.facetFilterLookups[templateId] = {};
			this.isUpdate = false;
		}

		for (var key in groupFacet) {
			let checkSelected: (input: string) => boolean = () => true;
			const facetValues = result.facetResults[groupFacet[key]];
			const filterItems = facetValues.map(fv => [fv, this._fb.control(checkSelected(fv.facet))] as [IFacetResult, UntypedFormControl]);

			filterItems.forEach(t => this.selectedArray.push(t[1]));

			// check if all selected should true
			this.allSelected.patchValue(every(<boolean[]>this.selectedArray.value, v => v), { emitEvent: false });

			// put the selected ones up top
			const lookupFilterItems = [...filterItems.filter(t => t[1].value), ...filterItems.filter(t => !t[1].value)];

			if (!this.facetFilterLookups[templateId] || !this.facetFilterLookups[templateId][key]) {
				this.facetFilterLookups[templateId][key] = lookupFilterItems;
			}
		}
	}

	applyFilter(characteristicId: number, templateId: number) {
		const facetFilterKey = this.facetLookups[templateId][characteristicId];
		const selectedValues = this.facetFilterLookups[templateId][characteristicId].filter(t => t[1].value).map(t => t[0].facet);

		if (this.allSelected.value === true || selectedValues.length === 0) {
			delete this.columnFilters[facetFilterKey]
		} else {
			this.columnFilters[facetFilterKey] = selectedValues;
		}

		this.getTable$(templateId, 0).subscribe();
	}

	setupTableGroupCMDs() {
		_.forEach(this.tableGroups,
			(group) => {
				this.groupHasCatalogItems[group.templateID] = _.filter(group.items, i => i.assets != null && i.assets.length > 0).length > 0;
				this.groupHasContactItems[group.templateID] = _.filter(group.items, i => i.contacts != null && i.contacts.length > 0).length > 0;
				this.groupHasFileUploadConfigured[group.templateID] = _.filter(group.templateMetaData.characteristicMetaDatas, cmd => cmd.dataTypeID == CharDataType.ExternalDocument).length > 0;
				this.groupPageCount[group.templateID] = Math.ceil(group.numFound / this.tablePageSize);
				let cmds = _(group.templateMetaData.characteristicMetaDatas)
					.sortBy((cmd) => cmd.sequenceNumber)
					.value();

				this.groupCMDs[group.templateID] = cmds;
				this.groupCharDatas[group.templateID] = {};
				_.forEach(group.items,
					(item) => {
						this.groupCharDatas[group.templateID][item.record.recordID] =
							_.groupBy(item.charData, (cd) => cd.charactersticID);
					});
			});
	}

	getTable$(templateID: number, start: number) {
		this.isLoading = true;
		return this.tableEntitySerivce
			.getTables(this.parentCharTypeID, this.parentRecordID, templateID, start, this.tablePageSize, this.useSortKey, this.sortKey, this.sortDir, this.columnFilters)
			.pipe(
				tap((result) => {
					this.isLoading = false;
					const groupIndex = this.tableGroups.findIndex(x => x.templateID === templateID);
					this.tableGroups[groupIndex] = result.find(x => x.groups.find(x => x.templateID === templateID)).groups[0];
					this.tableAssetTitles = Object.assign(this.tableAssetTitles, result[0].assetTitles);
					this.tableContactTitles = Object.assign(this.tableContactTitles, result[0].contactTitles);
					this.setupTableGroupCMDs();
					this.setupTableGroupFacets(result.find(x => x.groups.find(x => x.templateID === templateID)));
				}));
	}

	tablePageChanged(templateID: number, page: number) {
		this.progressService.startProgress();
		this.getTable$(templateID, (page - 1) * this.tablePageSize)
			.subscribe(() => {
				this.groupPage[templateID] = page;
				this.progressService.endProgress();
			});
	}

	exportTablesToCSV(event: any, usageGroup: ITableGroup) {
		event.preventDefault();
		this.progressService.startProgress();
		this.tableEntitySerivce.exportTablesToCSV(this.parentCharTypeID, this.parentRecordID, usageGroup.templateID)
			.subscribe((result) => {
				this.jobId = result.jobID;
				this.jobTitle = result.jobTitle;
				this.startJobProgress = true;
				this.progressService.endProgress();
			});
	}

	progressDone() {
		this.startJobProgress = false;
		this.jobId = undefined;
	}

	showResultMessage(resultMessage: string) {
		window.location.assign(resultMessage);
	}

	canEditTemplate(template: ICharacteristicTemplate) {
		let templateSpecificACL = AclUtil.getDataEntityAcl(CharTypeID.Usage, template.templateGroupID, template.templateID);
		return AclUtil.hasWriteAccess(this.aclService.acls, templateSpecificACL);
	}

	canDeleteTemplate(template: ICharacteristicTemplate) {
		let templateSpecificACL = AclUtil.getDataEntityAcl(CharTypeID.Usage, template.templateGroupID, template.templateID);
		return AclUtil.hasDeleteAccess(this.aclService.acls, templateSpecificACL);
	}

	uploadFileToTable(template: ICharacteristicMetaDataCollection, record: IEntity, charData: ICharacteristicData[]) {
		let fileCmd = template.characteristicMetaDatas.find(cmd => cmd.dataTypeID === CharDataType.ExternalDocument);
		if (fileCmd != null) {
			this.uploadTableCharData = _.cloneDeep(charData.filter(cmd => cmd.charactersticID === fileCmd.characteristicID));
			this.uploadTableTemplate = _.clone(template);
			this.uploadTableTemplate.characteristicMetaDatas = [fileCmd];
			this.uploadTableRecord = record;
			this.uploadFileModal.open();
		}
	}

	editTableRow(template: ICharacteristicMetaDataCollection, record: IEntity, charData: ICharacteristicData[]) {
		this.editTableCharData = _.cloneDeep(charData);
		this.editTableTemplate = _.clone(template);
		this.editTableRecord = record;
		this.editModal.open();
	}

	deleteTableRow(record: IEntity) {
		if (confirm("Are you sure?")) {
			this.progressService.startProgress();
			let groupStart = ((this.groupPage[record.templateID] || 1) - 1) * this.tablePageSize;

			this.tableEntitySerivce.deleteTable(this.entityId, record.recordID)
				.pipe(
					switchMap(() => this.getTables$(groupStart)))
				.subscribe(() => {
					this.progressService.endProgress();
				});
		}
	}

	createTableRow(template: ICharacteristicTemplate, $event: Event) {
		$event.preventDefault();

		this.progressService.startProgress();
		let divID = IDUtil.splitEntityID(this.entityId).divID;

		this.entityService.getEmptyEntityCharData(IDUtil.toTemplateID(divID, CharTypeID.Usage, template.templateID))
			.subscribe(response => {
				this.createTableTemplate = response.templateMetaData;
				this.createTableCharData = [];
				this.progressService.endProgress();
				this.createModal.open();
			});
	}

	saveFileToTable(valid: boolean) {
		if (valid) {
			this.progressService.startProgress();
			let templateID = this.uploadTableRecord.templateID;
			let groupStart = ((this.groupPage[templateID] || 1) - 1) * this.tablePageSize;

			this.tableEntitySerivce.uploadFileToTable(this.parentCharTypeID, this.parentRecordID, this.uploadTableRecord.recordID, this.uploadTableCharData)
				.pipe(
					switchMap(() => this.getTable$(templateID, groupStart)))
				.subscribe(() => {
					this.uploadFileModal.close();
					this.progressService.endProgress();
				});

		} else {
			this.progressService.clearProgress();
		}
	}

	sortClick(cmd: ICharacteristicMetaData, templateId: number) {
		var currentValue = this.headerSort[cmd.characteristicID]
		if (currentValue == null) {
			this.headerSort[cmd.characteristicID] = SortDirection.DESC;
		}
		else {
			this.headerSort[cmd.characteristicID] == SortDirection.DESC ? this.headerSort[cmd.characteristicID] = SortDirection.ASC : this.headerSort[cmd.characteristicID] = SortDirection.DESC;
		}
		this.useSortKey = true;
		this.sortKey = CharDataUtil.GetCharSolrLabel(cmd.dataTypeID, cmd.multipleIndicator, cmd.tagLabel);
		this.sortDir = this.headerSort[cmd.characteristicID];
		this.getTable$(templateId, 0).subscribe();
	}

	updateTable(valid: boolean) {
		if (valid) {
			this.progressService.startProgress();
			let templateID = this.editTableRecord.templateID;
			let groupStart = ((this.groupPage[templateID] || 1) - 1) * this.tablePageSize;

			this.tableEntitySerivce.updateTable(this.parentRecordID, this.parentCharTypeID, this.editTableRecord.recordID, templateID, this.editTableCharData)
				.pipe(
					tap(() => {
						this.editModal.close();
						this.isUpdate = true;
					}),
					switchMap(() => this.getTable$(templateID, groupStart)))
				.subscribe(() => {
					this.progressService.endProgress();
				});

		} else {
			this.progressService.clearProgress();
		}
	}

	createTable(valid: boolean) {
		if (valid) {
			this.progressService.startProgress();
			let templateID = this.createTableTemplate.templateID;

			let charData: ICharDatas = {};
			_.forEach(this.createTableCharData,
				data => {
					let metaData = _.find(this.createTableTemplate.characteristicMetaDatas,
						cmd => cmd.characteristicID === data.charactersticID);
					charData[metaData.tagLabel] = [data];
				});

			this.tableEntitySerivce.createTable(this.parentRecordID, this.parentCharTypeID, templateID, this.createTableCharData)
				.pipe(
					tap(() => {
						this.createModal.close();
					}),
					switchMap(() => this.getTable$(templateID, 0)))
				.subscribe(() => {
					this.progressService.endProgress();
				});

		} else {
			this.progressService.clearProgress();
		}
	}

	public getVisibleCharacteristics(templateId: number): ICharacteristicMetaData[] {
		if (this.groupCMDs[templateId]) {
			return this.groupCMDs[templateId].filter(cmd => {
				const relatedTemplate = _.find(this.tableTemplates,
					template => {
						return template.templateID === templateId;
					});
				const tableAcl =
					AclUtil.getDataEntityAcl(CharTypeID.Usage,
						relatedTemplate.templateGroupID,
						templateId,
						cmd.groupID,
						cmd.characteristicID);
				const canReadColumn = AclUtil.hasReadAccess(this.aclService.acls, tableAcl);
				return canReadColumn;
			});
		}
		return null;
	}

	public toggleSelectAll(group: ITableGroup, event: any): void {
		const groupToUpdate = _.find(this.tableGroups, tg => tg.templateID === group.templateID);
		const toggleFlag = event.target.checked;
		this.selectedTables[groupToUpdate.templateID] = {};
		groupToUpdate.items.forEach(item => {
			this.selectedTables[groupToUpdate.templateID][item.record.id] = toggleFlag;
			if (toggleFlag) {
				this.selectedRows[group.templateID].push(item.record);
			}
			else {
				this.selectedRows[group.templateID] = this.selectedRows[group.templateID].filter(row => row !== item.record);
			}
		});
		this.isSelectAllChecked[group.templateID] = toggleFlag;
		this.updateWorkflowActionCheckbox(group.templateID);
	}
}
