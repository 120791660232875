import {Component, ChangeDetectionStrategy, Input, OnChanges, SimpleChange, Output, EventEmitter, ViewChild, AfterViewInit, ElementRef, Optional} from "@angular/core";

import * as _ from "lodash";

import {ICharacteristicMetaDataValue} from "../../../models";
@Component({
    selector: "dropdown-edit-control",
    templateUrl: "./dropdown-edit-control.component.html"
})
export class DropdownEditControlComponent {

    @Input()
    selectedValue: string[];

    @Input()
    lovs: ICharacteristicMetaDataValue[]

    @Input()
    showEmptyOption: boolean;

    @Output()
    selectedValueChange = new EventEmitter<string[]>();

    constructor() {
    }

    updated(element: HTMLSelectElement) {
        let selected = [];
        for (let i = 0; i < element.options.length; i++) {
            let option = <HTMLOptionElement>element.options[i];
            if (option.selected) {
                selected.push(option.value);
            }
        }
        this.selectedValueChange.emit(selected);
    }

    isSelected(lov: ICharacteristicMetaDataValue) {
        return _.includes(this.selectedValue, lov.characteristicValueID.toString());
    }
}