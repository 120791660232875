import { ChangeDetectorRef, Component, EventEmitter, Input, Output } from "@angular/core";
import { ModalService } from "../services/modal.service";

@Component({
    selector: "pp-modal, sf-modal, sp-modal",
    templateUrl: "./modal.component.html",
})
export class ModalComponent {

    width: any;

    @Input()
    showFooter = true;

    @Output("open")
    opening = new EventEmitter<ModalEvent>();

    @Output("close")
    closing = new EventEmitter<ModalEvent>();

    @Output("closed")
    closed = new EventEmitter<any>();

    public get modalWidth() {
        if (this.width != null) {
            return this.width;
        } else {
            return "40%";
        }
    }

    @Input("modalWidth")
    public set modalWidth(value: any) {
        this.width = value;
    }

    isOpen: boolean = false;
    outerClasses: string[] = [];

    constructor(private modalService: ModalService, private cdRef: ChangeDetectorRef) {

    }

    open() {
        let event = new ModalEvent();
        this.opening.emit(event);
        if (!event.isDefaultPrevented) {
            this.isOpen = true;
            this.cdRef.markForCheck();
            this.modalService.modalOpen.next(null);
            this.outerClasses = ["show", "modal-open"];
        }
        return !event.isDefaultPrevented;
    }

    close() {
        let event = new ModalEvent();
        this.closing.emit(event);
        if (!event.isDefaultPrevented) {
            this.outerClasses = ["out", "modal-close"];
            this.modalService.modalClose.next(null);
            this.isOpen = false;
            this.closed.emit(null);
        }
        return !event.isDefaultPrevented;
    }

    clickedOutside() {
        if (this.isOpen) {
            this.close();
        }
    }
}

export class ModalEvent {
    private _isDefaultPrevented: boolean;

    public get isDefaultPrevented() {
        return this._isDefaultPrevented;
    }

    public preventDefault() {
        this._isDefaultPrevented = true;
    }
}