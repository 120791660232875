import { Component, Input, OnInit } from '@angular/core';
import { IContactPartyGroup, IContactsDictionaries } from 'app/common/messages';
import { PartnerPortalService } from 'app/partner-portal/services/partner-portal.service';
import { some } from "lodash";
import { tap } from "rxjs/operators";

@Component({
	selector: 'app-contacts',
	templateUrl: './contacts.component.html',
	styleUrls: ['./contacts.component.less']
})
export class ContactsComponent implements OnInit {

	@Input()
	charTypeId: number;

	@Input()
	recordId: number;

	dictionaries: IContactsDictionaries = {} as IContactsDictionaries;

	contactPartyGroups: IContactPartyGroup[];

	constructor(private partnerPortalService: PartnerPortalService) { }

	ngOnInit(): void {
		this.getDealParties();
	}

	isGroupVisible(contactPartyGroup: IContactPartyGroup): boolean {
		return some(contactPartyGroup.contactParties, contactParty => {
			return contactParty.contacts.length > 0;
		});
	}

	getDealParties() {
		return this.partnerPortalService.getParties(this.recordId, this.charTypeId)
			.pipe(
				tap((data) => {
					this.contactPartyGroups = data.groupedParties;
					this.dictionaries = {
						parties: data.parties,
						partyGroups: data.partyGroups,
						contacts: data.contacts,
						editContacts: data.editContacts,
					};
				})).subscribe();
	}

}
