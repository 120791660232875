
import { Pipe, PipeTransform } from "@angular/core";
import { ICharacteristicData } from "../models";

@Pipe({
    name: "charDataFilter",
    pure: true
})
export class CharDataFilterPipe implements PipeTransform {
    transform(value: ICharacteristicData[], charID: number): ICharacteristicData[] {
        if (value == null) {
            return [];
        }
        return value.filter(cd => cd.charactersticID == charID);
    }
}