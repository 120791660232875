import {Component, ChangeDetectionStrategy, Input, Output, EventEmitter} from "@angular/core";

@Component({
    selector: "file-display-control",
    template: `<a *ngIf='fileName' href="#" (click)="clicked($event)">{{fileName}} <i class="glyphicon glyphicon-download-alt"></i></a>`,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class FileDisplayComponent {

    @Input()
    fileName: string;

    @Output()
    fileClick = new EventEmitter<any>();

    constructor() {

    }

    clicked($event: Event) {
        event.preventDefault();
        this.fileClick.emit(null);
    }
}