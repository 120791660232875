<div class="view-selector">
    <a href="#" [ngClass]="{'inactive': view != explorerViews.Tile}" (click)="setView(explorerViews.Tile, $event)" class="pe-3" data-cy="setViewTile">
        <i class="fa fa-th-large"></i>
        <!-- <i class="far fa-th-large"></i> --></a>
    <a href="#" [ngClass]="{'inactive': view != explorerViews.Grid}" (click)="setView(explorerViews.Grid, $event)" class="pe-3" data-cy="setViewGrid">
        <i class="fa fa-th-list"></i>
        <!-- <i class="far fa-th-list"></i> --></a>
    <a href="#" [ngClass]="{'inactive': view != explorerViews.List}" (click)="setView(explorerViews.List, $event)" data-cy="setViewList">
        <i class="far fa-list-alt"></i>
        <!-- <i class="far fa-bars"></i> --></a>
</div>
