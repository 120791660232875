import { Component, SimpleChanges, EventEmitter, OnInit, OnChanges, Input, Output } from "@angular/core";
import { ProgressService } from "../../services/progress.service";
import { IWorkflowAction } from "../../models";
import { WorkflowService } from "../../services/workflow.service";
import { EntityService } from "../../services/entity.service";
import { NotifyService } from "../../services/notify.service";
import { combineLatest } from "rxjs";
import { tap, switchMap } from "rxjs/operators";


@Component({
    selector: "pp-workflow-action",
    templateUrl: "./workflow-action.component.html"
})
export class WorkflowActionComponent implements OnChanges {

    @Output()
    statusChange = new EventEmitter<number>();

    @Input()
    entityId: string;

    status: string = "Loading";
    actions: IWorkflowAction[] = null;

    constructor(private entityService: EntityService, private workflowService: WorkflowService, private progressService: ProgressService, private notifyService: NotifyService) {

    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes["entityId"] && changes["entityId"].currentValue != null) {
            combineLatest(
                this.getWorkflowActions$(),
                this.getEntityStatus$()
            ).subscribe();
        }
    }

    getWorkflowActions$() {
        this.progressService.startProgress();
        return this.workflowService.getWorkflowActions(this.entityId)
            .pipe(tap((results) => {
                this.actions = results;
                this.progressService.endProgress();
            }));
    }

    getEntityStatus$() {
        this.progressService.startProgress();
        return this.entityService.getEntity(this.entityId)
            .pipe(tap((result) => {
                this.status = result.status;
                this.progressService.endProgress();
            }));
    }

    executeAction($event: Event, action: IWorkflowAction) {
        $event.preventDefault();
        this.executeAction$(action.processID, action.actionID);
    }

    executeAction$(processID: number, actionID: number) {
        let newStatusID: number = null;
        this.progressService.startProgress();
        this.workflowService.executeWorkflowAction(this.entityId, processID, actionID)
            .pipe(
            tap((result) => {
                if (result.success) {
                    this.notifyService.success("Workflow Action Executed");
                    newStatusID = result.newStatusID;
                } else {
                    this.notifyService.error(`Workflow Error: ${result.message}`);
                }
            }),
            switchMap(() => combineLatest(
                this.getWorkflowActions$(),
                this.getEntityStatus$()
            )))
            .subscribe(() => {
                this.progressService.endProgress();
                if (newStatusID) {
                    this.statusChange.next(newStatusID);
                }
            });
    }

}