import {Component} from "@angular/core";

import {NewReleasesListComponent} from "./new-releases-list.component";


@Component({
    selector: "new-releases-panel",
    template: "<router-outlet></router-outlet>"
})
export class NewReleasesPanelComponent {

    constructor() {

    }
}