import { Injectable } from "@angular/core";

import { Observable } from "rxjs";

import { HttpParams } from "@angular/common/http";

import { PPHttpService } from "app/common/services/pp-http.service";
import * as moment from "moment";
import { publishReplay, refCount } from "rxjs/operators";


export interface PPCacheHttpOptions { key: string, expiresIn?: moment.Duration, expiresAt?: moment.Moment };

@Injectable()
export class PPCacheHttpService {

    cacheResults = new Map<string, Observable<any>>();
    cacheTimeouts = new Map<string, moment.Moment>();

    constructor(private ppHttpService: PPHttpService) {

    }

    request<T>(method: string, url: string, params?: HttpParams, body?: any, cacheOptions?: PPCacheHttpOptions) {
        let response: Observable<T>;
        if (cacheOptions) {
            let key = cacheOptions.key;
            let now = moment();
            if (this.cacheTimeouts.get(key) < now) {
                this.cacheResults.delete(key);
            }
            response = this.cacheResults.get(key);
            if (response == null) {
                let shouldCache = cacheOptions.expiresIn != null || cacheOptions.expiresAt != null;
                if (cacheOptions.expiresIn) {
                    this.cacheTimeouts.set(key, now.add(cacheOptions.expiresIn));
                } else if (cacheOptions.expiresAt) {
                    this.cacheTimeouts.set(key, cacheOptions.expiresAt);
                }
                response = this.ppHttpService.request<T>(method, url, params, body)
                    .pipe(
                        publishReplay(1),
                        refCount()
                    );
                if (shouldCache) {
                    this.cacheResults.set(key, response);
                }
            }
        } else {
            response = this.ppHttpService.request<T>(method, url, params, body);
        }
        return response;
    }

    get<T>(url: string, params?: {}, cacheOptions?: PPCacheHttpOptions): Observable<T> {
        let requestParams = params ? new HttpParams({ fromObject: params }) : null;
        return this.request<T>("get", url, requestParams, null, cacheOptions);
    }

    post<T>(url: string, body?: any, cacheOptions?: PPCacheHttpOptions): Observable<T> {
        return this.request<T>("post", url, null, body, cacheOptions);
    }

    put<T>(url: string, body?: any, cacheOptions?: PPCacheHttpOptions): Observable<T> {
        return this.request<T>("put", url, null, body, cacheOptions);
    }

    delete<T>(url: string, cacheOptions?: PPCacheHttpOptions): Observable<T> {
        return this.request<T>("delete", url, null, null, cacheOptions);
    }

    patch<T>(url: string, cacheOptions?: PPCacheHttpOptions): Observable<T> {
        return this.request<T>("patch", url, null, null, cacheOptions);
    }

}