<div class="card-grid row row-cols-2 row-cols-md-4 row-cols-xl-6 mt-4">
    <div class="col mb-4" *ngFor="let document of documents">
        <div class="card">
            <div class="card-img-top text-center" [ngClass]="{'noimage': !keyArt || !keyArt[document.recordID]}">
                <a href="#" [routerLink]="linkHelper.detail(document.recordID)" data-cy="viewRecord"><img *ngIf="keyArt && keyArt[document.recordID]" [src]="keyArt[document.recordID]" /></a>
            </div>
            <div class="card-body">
                <h3><a href="#" [routerLink]="linkHelper.detail(document.recordID)" data-cy="viewRecordTitle">{{getTitleString(document)}}</a></h3>
                <p *ngIf="document.characteristics['release_year_int']">Release Year: {{document.characteristics['release_year_int']}}</p>
            </div>
        </div>
    </div>
</div>