import { Component, Inject } from "@angular/core";
import { NavigationEnd, Router } from '@angular/router';
import { IPartnerPortalSettings } from "app/common/models";
import { DocumentEntityService } from "app/common/services/document-entity.service";
import { PARTNER_PORTAL_SETTINGS } from "app/partner-portal/settings";
import { Subscription } from "rxjs";
import { filter, map, tap } from "rxjs/operators";
import { DivSettingKey, ISetting } from "../../../common/messages";
import { PartnerPortalService } from "../../services/partner-portal.service";


@Component({
	selector: "config",
	templateUrl: "./config.component.html"
})
export class ConfigComponent {
	isSaving = false;
	isResetting = false;
	isSavingColors = false;
	savingEnabled = false;
	resetEnabled = false;
	divImageUrl$ = this.partnerPortalService.divImageUrl$;
	divImageUrl = null;
	divImageDivSetting = null;
	value: string;
	defaultLogo = this.portalSettings.logoUrl;
    placeholderHex1 = "#1E162B";
    placeholderHex2 = "#3B2473";
    placeholderHex3 = "#4F3099";
	placeholderHex4 = "#ECEAF9";
	disabled = false;
	hexColor1 = DivSettingKey.HexColor1;
	hexColor2 = DivSettingKey.HexColor2;
	hexColor3 = DivSettingKey.HexColor3;
	hexColor4 = DivSettingKey.HexColor4;
	hex1Value: string = this.placeholderHex1;
	hex2Value: string = this.placeholderHex2;
	hex3Value: string = this.placeholderHex3;
	hex4Value: string = this.placeholderHex4;
	settings: ISetting[];
	hexColor1$ = this.partnerPortalService.hexColor1$;
	hexColor2$ = this.partnerPortalService.hexColor2$;
	hexColor3$ = this.partnerPortalService.hexColor3$;
	hexColor4$ = this.partnerPortalService.hexColor4$;


	onChange = (value: string) => { };
	onTouched = () => { };

	constructor(
		private partnerPortalService: PartnerPortalService,
		private documentService: DocumentEntityService,
		private readonly _router: Router,
		@Inject(PARTNER_PORTAL_SETTINGS) private portalSettings: IPartnerPortalSettings
	) {

		var divImageUrlSub = this.divImageUrl$.pipe(
			tap(image => {
				this.divImageUrl = image;
			})
		).subscribe();

		var hex1Sub = this.hexColor1$.pipe(
			tap(color => {
				this.hex1Value = color;
			})
		).subscribe();

		var hex2Sub = this.hexColor2$.pipe(
			tap(color => {
				this.hex2Value = color;
			})
		).subscribe();

		var hex3Sub = this.hexColor3$.pipe(
			tap(color => {
				this.hex3Value = color;
			})
		).subscribe();

		var hex4Sub = this.hexColor4$.pipe(
			tap(color => {
				this.hex4Value = color;
			})
		).subscribe();

		this.subscriptions.push(divImageUrlSub, hex1Sub, hex2Sub, hex3Sub, hex4Sub)
		this.partnerPortalService.refreshSettings();
	}
	subscriptions: Subscription[] = [];

	ngOnDestroy() {
		this.subscriptions.forEach(sub => sub.unsubscribe());
	}

	ngOnInit() {
		const routeChangedSub = this._router.events.pipe(
		).subscribe(() => {
			filter(e => e instanceof NavigationEnd),
				this.partnerPortalService.refreshSettings();
			routeChangedSub.unsubscribe();
		});
		this.subscriptions.push(routeChangedSub);

		if (this.hex1Value !== this.placeholderHex1 || this.hex2Value !== this.placeholderHex2 || this.hex3Value !== this.placeholderHex3 || this.hex4Value !== this.placeholderHex4) {
			this.resetEnabled = true;
		}
	}

	upload() {
		this.deleteImage();
		const options = this.documentService.getBasicFileStackOptions();
		options.onUploadDone = (response) => {
			this.isSaving = true;
			const file = response.filesUploaded[0];
			this.partnerPortalService.createImageDivSetting("portalLogo", {
				name: file.originalPath,
				path: file.key
			}
			).subscribe(() => {
				this.isSaving = false;
				this.partnerPortalService.refreshSettings();
			});
		};
		this.documentService.upload(options);
	}

	deleteImage() {
		const idsToDelete = this.partnerPortalService.divSettings.filter(x => x.key === DivSettingKey.PortalLogo);
		if (idsToDelete.length > 0) {
			idsToDelete.forEach(idToDelete => {
				if (idToDelete) {
					const sub = this.partnerPortalService.deleteDivSetting(idToDelete.id).subscribe(() => {
						this.partnerPortalService.divImageUrl$.next(this.portalSettings.logoUrl);
					});
					this.subscriptions.push(sub);
				}
			});
		}
	}

	registerOnChange(fn: any): void {
		this.onChange = fn;
	}

	registerOnTouched(fn: any): void {
		this.onTouched = fn;
	}

	emitChange(hexColorKey: string, value: string) {
		this.onChange(this.value);
		this.partnerPortalService.changeTheme(hexColorKey, value);
		this.savingEnabled = true;
		this.resetEnabled = true;
	}

	async saveColors() {
		this.isSavingColors = true;
		this.partnerPortalService.getDivSettingsList().pipe(
			map(result => {
				return Object.keys(result).map(key => result[key]).sort((a, b) => a.key.localeCompare(b.key));
			}),
			tap(async (settings) => {
				this.partnerPortalService.divSettings$.next(settings);
				this.partnerPortalService.divSettings = settings;
				const p1 = await this.saveColor(this.hexColor1, this.hex1Value);
				const p2 = await this.saveColor(this.hexColor2, this.hex2Value);
				const p3 = await this.saveColor(this.hexColor3, this.hex3Value);
				const p4 = await this.saveColor(this.hexColor4, this.hex4Value);

				Promise.all([p1, p2, p3, p4]).then(() => {
					this.partnerPortalService.refreshSettings();
					this.resetEnabled = true;
					this.savingEnabled = false;
					this.isSavingColors = false;
				});
			})
		).subscribe();
	}

	saveColor(hexColorKey: string, value: string) {
		var existingSetting = this.partnerPortalService.divSettings?.find(x => x.key == hexColorKey);
		if (existingSetting) {
			if (existingSetting.value !== value) {
				return this.partnerPortalService.updateDivSetting(existingSetting.id, hexColorKey, value).toPromise();
			}
		}
		else {
			return this.partnerPortalService.createDivSetting(hexColorKey, value).toPromise();
		}
	}

	async reset() {

		this.isResetting = true;
		this.resetEnabled = false;

		//Delete the div settings
		this.partnerPortalService.getDivSettingsList().pipe(
			map(result => {
				return Object.keys(result).map(key => result[key]).sort((a, b) => a.key.localeCompare(b.key));
			}),
			tap((settings) => {
				this.partnerPortalService.divSettings$.next(settings);
				this.partnerPortalService.divSettings = settings;
				var hexColorKeyArray = [DivSettingKey.HexColor1.toString(), DivSettingKey.HexColor2.toString(), DivSettingKey.HexColor3.toString(), DivSettingKey.HexColor4.toString()];
				var hexItems = this.partnerPortalService.divSettings?.filter(x => hexColorKeyArray.indexOf(x.key) > -1)
				var promises = [];

				if (hexItems.length > 0) {
					hexItems.forEach(async hexItem => {
						if (hexItem?.id) {
							var p1 = await this.partnerPortalService.deleteDivSetting(hexItem.id).toPromise();
							promises.push(p1);
						}
					});
				}

				Promise.all(promises).then(() => {
					// default colors
					this.isResetting = false;
					this.partnerPortalService.changeTheme(DivSettingKey.HexColor1, this.placeholderHex1);
					this.partnerPortalService.changeTheme(DivSettingKey.HexColor2, this.placeholderHex2);
					this.partnerPortalService.changeTheme(DivSettingKey.HexColor3, this.placeholderHex3);
					this.partnerPortalService.changeTheme(DivSettingKey.HexColor4, this.placeholderHex4);

					//clear color picker boxes so we don't have to wait for all deleting to finish
					this.hex1Value = this.placeholderHex1;
					this.hex2Value = this.placeholderHex2;
					this.hex3Value = this.placeholderHex3;
					this.hex4Value = this.placeholderHex4;
				})
			})).subscribe();
	}
}
