import { Component, OnInit, Host, SkipSelf, Optional, Input } from "@angular/core";
import { UntypedFormControl } from "@angular/forms";
import { Subscription } from "rxjs";
import * as _ from "lodash";


@Component({
    selector: "control-error-messages",
    template: `<span *ngIf="control.touched && messages"><span class="help-block" *ngFor="let message of messages" [innerHTML]="message"></span></span>`
})
export class ControlErrorMessageComponent implements OnInit {
    @Input("control") control: UntypedFormControl;

    errorMessages: { [key: string]: string };
    messages: string[];

    constructor() {
        this.errorMessages = {
            "required": "Required",
						"desired": "Desired",
            "invalidEmail": "Invalid Email",
            "compare": "Mismatched",
            "invalidPassword": "Invalid Password"
        };
        this.messages = [];
    }

    ngOnInit() {
        this.control
            .statusChanges
            .subscribe(() => {
                this.messages = _.keys(this.control.errors)
                    .map((key) => this.errorMessages[key])
                    .filter((value) => <boolean>(value || false));
            });
    }

}