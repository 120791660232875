import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnChanges, OnInit, SimpleChange } from "@angular/core";
import * as _ from "lodash";

import { map } from "rxjs/operators";
import { ICharacteristicData, ICharacteristicMetaDataValue } from "../../../models";
import { TemplateService } from "../../../services/template.service";
import { BaseElement } from "./base-element";
@Component({
    selector: "dropdown-edit-element",
    templateUrl: "./dropdown-edit-element.component.html",
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class DropdownEditElementComponent extends BaseElement implements OnChanges, OnInit {

    selectedValueIDs: string[];
    //lovs$: Observable<ICharacteristicMetaDataValue[]>;
    lovs: ICharacteristicMetaDataValue[];
    type: "dropdown" | "tree";

    public get showEmptyOption() {
        return true;
    }

    constructor(private templateService: TemplateService, private cdRef: ChangeDetectorRef) {
        super();
    }

    ngOnInit() {
        this.templateService.getLovMetaData(this.cmd.charValueSourceID)
            .pipe(map((result) => result.listOfValues))
            .subscribe((result) => {
                this.lovs = result;
                if (_.some(this.lovs, (lov) => _.some(lov.childValues)) || this.cmd.multipleIndicator > 0) {
                    this.type = "tree";
                } else {
                    this.type = "dropdown";
                    //this.selectFirstValue();
                }
                this.cdRef.markForCheck();
            });
    }

    ngOnChanges(changes: { [key: string]: SimpleChange; }) {
        if (changes["charData"]) {
            //this.selectFirstValue();
            this.selectedValueIDs = (this.charData || []).filter(cd => cd.valueID != null).map(cd => cd.valueID.toString());
        }
    }

    //selectFirstValue() {
    //    if (this.type == "dropdown" && this.charData.length == 0 && this.cmd.requiredIndicator > 0) {
    //        let firstLov = _.first(this.lovs);
    //        if (firstLov) {
    //            let cd = this.createCharData(firstLov.characteristicValueLabel, firstLov.characteristicValueID);
    //            this.charDataChange.emit({ cmd: this.cmd, charData: [cd], silent: true });
    //        }
    //    }
    //}

    selectedValueChanged(value: string[]) {
        this.selectedValueIDsChanged(value.map(id => _.parseInt(id)));
    }

    selectedValueIDsChanged(valueIDs: number[]) {
        let selectedLovs: ICharacteristicMetaDataValue[] = [];
        let findSelected = (lovs: ICharacteristicMetaDataValue[]) => {
            for (let lov of lovs) {
                if (_.includes(valueIDs, lov.characteristicValueID)) {
                    selectedLovs.push(lov);
                }
                if (_.some(lov.childValues)) {
                    findSelected(lov.childValues);
                }
            }
        };
        findSelected(this.lovs);

        let existingRecCharIDs = (this.charData || []).filter(cd => cd.recordCharacteristicID > 0).map(cd => cd.recordCharacteristicID);

        let newCharData: ICharacteristicData[] = selectedLovs
            .map(lov => {
                let cd: ICharacteristicData = {
										charactersticID: this.cmd.characteristicID,
                    recordCharacteristicID: existingRecCharIDs.pop() || 0,
                    value: lov.characteristicValueLabel,
                    valueID: lov.characteristicValueID
                };
                return cd;
            });
        this.charDataChange.emit({ cmd: this.cmd, charData: newCharData });
    }
}