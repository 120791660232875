import {EventEmitter, NgZone,Injectable} from '@angular/core';
import * as NProgress from "nprogress";
@Injectable()
export class ProgressService {

    stack: number = 0;

    progressStarted: EventEmitter<any>;
    progressEnded: EventEmitter<any>;

    constructor(private ngZone: NgZone) {
        this.progressStarted = new EventEmitter();
        this.progressEnded = new EventEmitter();

        NProgress.configure({ speed: 0 });
    }

    startProgress() {
        if (this.stack == 0) {
            NProgress.start();
            this.progressStarted.emit(null);
        }
        this.stack++;
    }

    endProgress() {
        if (this.stack > 0) {
            this.stack--;
            if (this.stack == 0) {
                NProgress.done();
                this.progressEnded.emit(null);
            }
        }
    }

    clearProgress() {
        this.stack = 0;
        NProgress.done();
        this.progressEnded.emit(null);
    }

    wrapPromise<T>(func: () => Promise<T>) {
        this.startProgress();
        return func()
            .then(() => {
                this.endProgress();
            },
            (reason) => {
                this.endProgress();
            });
    }

    //wrap(func: () => any) {
    //    return this.ngZone.run(() => {
    //        this.startProgress();
    //        func();
    //        this.endProgress();
    //    });
    //}
}