import { Injectable } from '@angular/core';
import {
	ICharDatas,
	IJobReponse
} from "../models";
import { PPHttpService } from "./pp-http.service";

import { environment } from 'environments/environment';
import * as filestack from "filestack-js";
import { Client } from 'filestack-js/build/main/lib/client';
import { PickerOptions } from 'filestack-js/build/main/lib/picker';
import { some } from 'lodash';
import * as moment from 'moment';
import { ExecutableExtensions } from '../consts';
import { IFileStackSignature } from '../messages';

@Injectable()
export class DocumentEntityService {
	private _baseUrl = "/service-api/document-entity";

	constructor(private ppHttpService: PPHttpService) { }

	uploadFolder(entityID: string, folderTemplateID: number, fileTemplateID: number, originalFilePaths: string[], filePickerPaths: string[]) {
		let data = {
			entityID: entityID,
			folderTemplateID: folderTemplateID,
			fileTemplateID: fileTemplateID,
			originalFilePaths: originalFilePaths,
			filePickerPaths: filePickerPaths
		};
		return this.ppHttpService.post<IJobReponse>(`${this._baseUrl}/upload-folder`, data);
	}

	createDocument(templateID: string, parentEntityID: string, charDatas: ICharDatas) {
		let data = {
			templateID: templateID,
			parentEntityID: parentEntityID,
			charDatas: charDatas
		}
		return this.ppHttpService.post<{ entityID: string, relRecID: number }>(`${this._baseUrl}/create-document`, data);
	}

	getDownloadUrl(recordID: number) {
		return this.ppHttpService.get<string>(`${this._baseUrl}/get-download-url/${recordID}`);
	}

	getFileStackSignature(expiry: number) {
		let data = {
			expiry: expiry
		}
		return this.ppHttpService.post<{ signature: string }>(`${this._baseUrl}/file-stack-signature`, data);
	}

	exportFiles(fileIds: number[]) {
		let data = {
			fileIds: fileIds
		};
		return this.ppHttpService.post<IJobReponse>(
			`${this._baseUrl}/export-files`, data);
	}

	public getBasicFileStackOptions(): PickerOptions {
		return {
			fromSources: ["local_file_system", "googledrive", "dropbox", "box", "gmail", "onedrive", "clouddrive"],
			storeTo: {
				location: "s3",
				path: "/filepicker/",
				access: "private",
				container: environment.filestackBucket
			},
			onFileSelected: (file) => {
				const executableTypes: string[] = ExecutableExtensions.Types;
				const splits = file.filename.split(".");
				if (splits.length === 1 || (splits[0] === "" && splits.length === 2)) {
					throw new Error("Please select a file with a valid extension");
				}
				const fileExt = splits.pop();
				if (some(executableTypes, e => e === fileExt.toLowerCase())) {
					throw new Error("Please select a file that is not an executable");
				}
			},
		};
	}



	public upload(options: PickerOptions) {
		const expiry = moment.utc().add(30, "minutes").unix();
		const policy = { "expiry": expiry };
		const policyEncoded = btoa(JSON.stringify(policy));
		this.getFileStackSignature(expiry).subscribe((result: IFileStackSignature) => {
			const security = { policy: policyEncoded, signature: result.signature };
			if (options.storeTo) {
				options.storeTo.region = result.awsRegion;
			}
			const client: Client = filestack.init(environment.filestackApiKey, { security });
			client.picker(options)
				.open();
		});
	}


}