import { Component, OnInit, ChangeDetectionStrategy, Input, OnChanges, SimpleChanges } from '@angular/core';
import { ICharDataMoneyValue } from '../../../models';

@Component({
    selector: 'money-display-control',
    templateUrl: './money-display-control.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class MoneyDisplayControlComponent {

    @Input()
    localAmount: string;
    @Input()
    localCurrencySymbol: string;
    @Input()
    divAmount: string;
    @Input()
    divCurrencySymbol: string;

    constructor() { }
}