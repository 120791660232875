import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, forwardRef, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChange } from "@angular/core";
import { ControlValueAccessor, NG_VALUE_ACCESSOR, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from "@angular/forms";
import { AppendWithDirective } from "app/common/directives/append-with.directive";
import * as _ from "lodash";
import { isEmpty } from "lodash";
import { Subscription } from "rxjs";
import { ICharacteristicData, ICharacteristicMetaData, ICharacteristicMetaDataCollection, ICharDataChangeEvent, ITemplateCharacteristicGroup } from "../../models";
import { CharDataTableService } from "../../services/char-data-table.service";
import { CharDataElementComponent } from "./char-data-element.component";
import { FormControlRequired, FormControlWarn } from "./elements/element-validators";

export const CHAR_DATA_TABLE_VALUE_ACCESSOR: any = {
	provide: NG_VALUE_ACCESSOR,
	useExisting: forwardRef(() => CharDataTableComponent),
	multi: true
};
@Component({
	selector: "char-data-table",
	templateUrl: "./char-data-table.component.html",
	changeDetection: ChangeDetectionStrategy.OnPush,
	providers: [CHAR_DATA_TABLE_VALUE_ACCESSOR]
})
export class CharDataTableComponent implements OnChanges, OnInit, OnDestroy, ControlValueAccessor {

	@Input()
	charData: ICharacteristicData[];

	@Input()
	template: ICharacteristicMetaDataCollection;

	@Output()
	tableCharDataChange = new EventEmitter<ICharacteristicData[]>();

	@Input()
	public set editMode(value: boolean) {
		this._editMode = value;
	}
	public get editMode() {
		return this._editMode;
	}
	private _editMode: boolean = false;
	private readonly _ignoredErrors = new Set(["fetch"]);

	public _groups: ITemplateCharacteristicGroup[];
	public _cmdGroups: { [groupID: string]: ICharacteristicMetaData[] };
	public _charDatas: { [charID: string]: ICharacteristicData[] };

	public _initialized = false;

	public tableForm: UntypedFormGroup;

	public valid = false;
	public dirty = false;
	public pristine = false;
	public touched = false;

	public get visibleCmdCount() {
		return _(this._cmdGroups).values().sumBy((cmds) => cmds.length);
	}

	validatorSubscription: Subscription;

	onChange = (_: any) => { };
	onTouched = () => { };

	constructor(private fb: UntypedFormBuilder, private cd: ChangeDetectorRef, private charDataTableService: CharDataTableService) {
		this.tableForm = fb.group({});
	}

	writeValue(obj: any) {
		this.charData = <ICharacteristicData[]>obj;
		if (this.charData != null) {
			this.onCharDataChanged();
		}
	};

	registerOnChange(fn: (_: any) => {}): void { this.onChange = fn; }
	registerOnTouched(fn: () => {}): void { this.onTouched = fn; }

	ngOnChanges(changes: { [key: string]: SimpleChange; }) {
		if (changes["template"] != null && changes["template"].currentValue != null) {
			this.onTemplateChanged();
		}
		if (changes["charData"] != null && changes["charData"].currentValue != null) {
			this.onCharDataChanged();
		}
		if (changes["editMode"] != null && changes["editMode"].currentValue != null) {
			this.setupCharDataTable();
		}
	}

	ngOnInit() {

	}

	ngOnDestroy(): void {
		if (this.validatorSubscription) {
			this.validatorSubscription.unsubscribe();
		}
	}

	private onTemplateChanged() {
		this._initialized = false;
		this.cd.detectChanges();
		this.setupCharDataTable();
	}

	private onCharDataChanged() {
		this.setupCharData();
		if (this.template != null && this.charData != null) {
			if (!this._initialized) {
				this.setupForms();
			}
			this._initialized = true;
			this.cd.markForCheck();
		}
		this.setupCharDataTable();
	}

	markChanged() {
		this.cd.markForCheck();
	}

	setupCharDataTable() {
		var result = this.charDataTableService.getVisibleCmds(this.template, this.charData, this.editMode);
		this._groups = result.groups;
		this._cmdGroups = result.groupCmds;

	}

	setupCharData() {
		this._charDatas = this.charDataTableService.groupCharDatas(this.charData);
	}

	setupForms() {
		this.tableForm = this.charDataTableService.buildFormGroup(this.template.characteristicMetaDatas, this._charDatas);
		//disable form controls at first, CharDataElementComponent will handle enabling
		_(this.tableForm.controls).values().forEach((control) => control.disable()).valueOf();

		// update tableform states when emitted;
		if (this.validatorSubscription) {
			this.validatorSubscription.unsubscribe();
		}
		this.validatorSubscription = this.tableForm.statusChanges.subscribe(() => {
			setTimeout(
				() => {
					this.valid = this.tableForm.valid;
					this.dirty = this.tableForm.dirty;
					this.pristine = this.tableForm.pristine;
					this.touched = this.tableForm.touched;
				});
		});

		this.cd.detectChanges();
	}

	elementDataChanged($event: ICharDataChangeEvent) {
		// run on-modified chardata changes, merge into current data
		let outputCharDataMap: _.Dictionary<ICharacteristicData[]> = null;
		let currentCharDataMap = this.charDataTableService.mapCharData(this.charData);
		let newCharDataMap: _.Dictionary<ICharacteristicData[]> = {};
		newCharDataMap[$event.cmd.characteristicID] = $event.charData;

		if ($event.silent) {
			outputCharDataMap = this.charDataTableService.mergeCharDataMaps(currentCharDataMap, newCharDataMap);
		} else {
			outputCharDataMap = this.charDataTableService.changeElementData(this.template, $event.cmd, currentCharDataMap, newCharDataMap);
		}

		let newCharDatas = _(outputCharDataMap).values().flatten().value();
		//this.charData = newCharDatas;
		this.onChange(newCharDatas);
		this.tableCharDataChange.emit(newCharDatas);
		this.cd.markForCheck();
		this.cd.detectChanges();
	}

	markAsPristine() {
		this.tableForm.markAsPristine();
	}

	markAsDirty() {
		this.tableForm.markAsDirty();
	}

	markAsPending() {
		this.tableForm.markAsPending();
	}

	markAsTouched() {
		this.tableForm.markAsTouched();
	}

	markAsUntouched() {
		this.tableForm.markAsUntouched();
	}

	hasError(cmd: ICharacteristicMetaData) {
		const control = this.getFormControl(cmd);
		return !control.disabled && control.touched && control.invalid && _.some(Object.keys(control.errors ?? {}), errorKey => !this._ignoredErrors.has(errorKey));
	}

	isRequired(cmd: ICharacteristicMetaData) {
		const control = this.getFormControl<FormControlRequired>(cmd);
		const hasRequired = control.isRequired === true && control.showRequired === true;
		return !control.disabled && hasRequired;
	}

	hasRequiredError(cmd: ICharacteristicMetaData) {
		const control = this.getFormControl<FormControlRequired>(cmd);
		return this.isRequired(cmd) && !control.touched && isEmpty(control.value);
	}

	hasWarning(cmd: ICharacteristicMetaData) {
		const control = this.getFormControl<FormControlWarn>(cmd);
		return control.touched && !control.disabled && control.warnings && !isEmpty(control.warnings);
	}

	isDesired(cmd: ICharacteristicMetaData) {
		const control = this.getFormControl(cmd);
		return !control.disabled && cmd.requiredIndicator === 2 && !control.touched && isEmpty(control.value);
	}

	getFormControl<T extends UntypedFormControl = UntypedFormControl>(cmd: ICharacteristicMetaData): T {
		return this.tableForm.controls[cmd.tagLabel] as T;
	}

	getWarningFormControl(cmd: ICharacteristicMetaData): FormControlWarn {
		return this.getFormControl<FormControlWarn>(cmd);
	}

	detectChanges() {
		this.cd.detectChanges();
	}
}

export var CHAR_DATA_TABLE_DIRECTIVES = [CharDataTableComponent, CharDataElementComponent, AppendWithDirective];