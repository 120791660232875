<ul>
    <li *ngFor="let facetKey of facets">
        <span [innerText]="facetTitles[facetKey]"></span>
        <ul *ngIf="facetValues[facetKey]?.length > 0">
            <li *ngFor="let facetValue of orderFacets(facetKey)" [ngClass]="{'active':isSelected(facetKey, facetValue.key)}">
                <div [ngSwitch]="isSelected(facetKey, facetValue.key) === true">
                    <div *ngSwitchCase="false">
                        <a href="#" [innerText]="formatValue(facetValue)" (click)="addFacet($event, facetKey, facetValue.key)" data-cy="facetSelectorFalse"></a>
                    </div>
                    <div *ngSwitchCase="true">
                        <a href="#" [innerText]="formatValue(facetValue)" (click)="removeFacet($event, facetKey, facetValue.key)" data-cy="facetSelectorTrue"></a>
                        <a href="#" (click)="removeFacet($event, facetKey, facetValue.key)" data-cy="facetSelectorRemove" title="Remove Facet"><i class="far fa-times ms-2"></i></a>
                    </div>
                </div>
            </li>
        </ul>
    </li>
</ul>