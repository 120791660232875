import { Directive } from "@angular/core";

@Directive({
	selector: "append-with"
})
export class AppendWithDirective {

	constructor() { }

}
