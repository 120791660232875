
<div class="row justify-content-center mt-4">
  <div class="col-sm-12 col-md-10">
    <h1 class="title">My Account</h1>
    <div class="card">
      <div class="card-body">
        <form (ngSubmit)="onSubmit($event, table)">
          <char-data-table #table [charData]="charData" [template]="templateMetaData" [editMode]="true"
                           (tableCharDataChange)="charData=$event"></char-data-table>
          <div class="text-end mt-3">
            <button type="submit" class="btn btn-success" busyButton="" [disabled]="!table.valid" data-cy="accountUpdate">
              <i class="fa"></i> Update
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
