import {Component} from "@angular/core";

import {ProjectsListComponent} from "./projects-list.component";
import {ProjectsDetailComponent} from "./projects-detail.component";


@Component({
    selector: "project-panel",
    template: "<router-outlet></router-outlet>"
})
export class ProjectsPanelComponent {

    constructor() {

    }
}