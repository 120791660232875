import { EventEmitter, Injectable } from "@angular/core";
import {ICharacteristicMetaDataValue} from "../models";
import * as _ from "lodash";
import * as uuid from "uuid";

@Injectable()
export class TreeListService {

    private _lovs: ICharacteristicMetaDataValue[];
    selectedLeafValueIDs: number[];
    selectedBranchValueIDs: number[];
    checkedValueIDs: number[];

    isOpen: { [valueID: string]: boolean } = {};
    valueToChildIDs: { [valueID: string]: number[] } = {};
    valueToLeafIDs: { [valueID: string]: number[] } = {};
    valueIDToLov: { [valueID: string]: ICharacteristicMetaDataValue } = {};
    valueIDPaths: { [valueID: string]: number[] } = {};

    selectedValueChange = new EventEmitter<{ leafValueIDs: number[]; branchValueIDs: number[] }>();

    singleSelectionMode: boolean = false;

    radioID: string = uuid.v4();

    constructor() {

    }

    setLovs(value: ICharacteristicMetaDataValue[]) {
        this._lovs = value;
        this.setupMaps();
        this.rectifyValues();
    }

    setupMaps() {

        this.isOpen = {};
        this.valueToLeafIDs = {};
        this.valueIDToLov = {};
        this.valueIDPaths = {};

        let walkIsOpen = (lovs: ICharacteristicMetaDataValue[]) => {
            lovs.forEach((lov) => {
                this.isOpen[lov.characteristicValueID] = false;
                if (_.some(lov.childValues)) {
                    walkIsOpen(lov.childValues);
                }
            });
        };

        let walkLeafIDs = (lovs: ICharacteristicMetaDataValue[]) => {
            let output: number[] = [];

            lovs.forEach((lov) => {
                this.valueIDToLov[lov.characteristicValueID] = lov;

                if (_.some(lov.childValues)) {
                    let childValueIDs = walkLeafIDs(lov.childValues);
                    this.valueToLeafIDs[lov.characteristicValueID] = childValueIDs;
                    output.push(...childValueIDs);
                } else {
                    this.valueToLeafIDs[lov.characteristicValueID] = [lov.characteristicValueID];
                    output.push(lov.characteristicValueID);
                }
            });

            return _(output).sortBy(i => i).uniq().value();
        };

        let walkChildIDs = (lovs: ICharacteristicMetaDataValue[]) => {
            let output: number[] = [];
            lovs.forEach((lov) => {
                if (_.some(lov.childValues)) {
                    let childValueIDs = walkChildIDs(lov.childValues);
                    this.valueToChildIDs[lov.characteristicValueID] = childValueIDs;
                    output.push(...childValueIDs);
                } else {
                    this.valueToChildIDs[lov.characteristicValueID] = [];
                }
                output.push(lov.characteristicValueID);

            });
            return _(output).sortBy(i => i).uniq().value();
        };

        let buildPath = (lovs: ICharacteristicMetaDataValue[], path: number[]) => {
            lovs.forEach((lov) => {
                path.push(lov.characteristicValueID);
                this.valueIDPaths[lov.characteristicValueID] = _.clone(path);
                if (_.some(lov.childValues)) {
                    buildPath(lov.childValues, path);
                }
            });
        }

        walkIsOpen(this._lovs);
        walkLeafIDs(this._lovs);
        walkChildIDs(this._lovs);
        buildPath(this._lovs, []);

        if (this._lovs.length == 1) {
            this.isOpen[this._lovs[0].characteristicValueID] = true;
        }
    }

    toggleOpen(lov: ICharacteristicMetaDataValue) {
        if (this.isOpen[lov.characteristicValueID]) {
            this.isOpen[lov.characteristicValueID] = false;
        } else {
            this.isOpen[lov.characteristicValueID] = true;
        }
    }

    toggleSelected(lov: ICharacteristicMetaDataValue) {
        let leafValueIDs = this.valueToLeafIDs[lov.characteristicValueID];
        if (this.singleSelectionMode) {
            if (_.isEqual(leafValueIDs.sort(), this.selectedLeafValueIDs.sort())) {
                this.selectedLeafValueIDs = [];
            } else {
                this.selectedLeafValueIDs = leafValueIDs;
            }
        } else {
            if (_.every(leafValueIDs, (leafID) => _.includes(this.selectedLeafValueIDs, leafID))) {
                this.selectedLeafValueIDs = _.difference(this.selectedLeafValueIDs, leafValueIDs);
            } else {
                this.selectedLeafValueIDs = _.union(this.selectedLeafValueIDs, this.valueToLeafIDs[lov.characteristicValueID]);
            }
        }
        this.rectifyValues();
        this.selectedValueChange.emit({
            leafValueIDs: this.selectedLeafValueIDs,
            branchValueIDs: this.selectedBranchValueIDs
        });
    }

    setSelectedValues(valueIDs: number[]) {
        this.selectedBranchValueIDs = valueIDs;
        this.selectedLeafValueIDs = _(valueIDs).map((valueID) => this.valueToLeafIDs[valueID]).flatten().uniq().value();
        this.rectifyValues();
    }

    showSelected() {
        let valueIDsToOpen: number[] = [];
        _(this.selectedBranchValueIDs)
            .map((id) => _.dropRight(this.valueIDPaths[id], 1))
            .flatten()
            .uniq()
            .forEach((id) => this.isOpen[id] = true);
    }

    private rectifyValues() {
        let selectedBranchValueIDs = [];
        let checkedValueIDs = [];

       
        let walkLovs = (lovs: ICharacteristicMetaDataValue[]) => {
            lovs.forEach((lov) => {
                let valueID = lov.characteristicValueID;
                let leafIDs = this.valueToLeafIDs[valueID];
                if (_.every(leafIDs, (leafID) => _.includes(this.selectedLeafValueIDs, leafID))) {
                    selectedBranchValueIDs.push(valueID);
                    checkedValueIDs = _(checkedValueIDs).union([valueID], this.valueToChildIDs[valueID]).sortBy(i => i).uniq().value();
                } else if (_.some(lov.childValues)) {
                    walkLovs(lov.childValues);
                }
            });
        };
        walkLovs(this._lovs);
        this.selectedBranchValueIDs = selectedBranchValueIDs;
        this.checkedValueIDs = checkedValueIDs;
    }

}