import { Component, Input, NgZone, OnInit, ViewChild } from "@angular/core";
import * as moment from "moment";
import { Subscription } from "rxjs";
import { CharDataType, CharTypeID, TemplateGroupIDs } from '../../common/consts';
import {
	ICharDatas,
	ICharacteristicData,
	ICharacteristicMetaData,
	ICharacteristicMetaDataCollection,
	ICharacteristicTemplate,
	IEntityHierarchy,
	IEntitySearchDoc,
	IEntityStatus,
	IPreviewDocument
} from "../../common/models";
import { CharDataUtil } from "../../common/utils/char-data-util";
import { IDUtil } from '../../common/utils/id-util';
import { AclService } from "../services/acl.service";
import { DocumentEntityService } from "../services/document-entity.service";
import { EntityService } from "../services/entity.service";
import { ProgressService } from "../services/progress.service";
import { TemplateService } from "../services/template.service";
import { AclUtil } from "../utils/acl.util";
import { ModalComponent } from "./modal.component";

import { PartnerPortalService } from "app/partner-portal/services/partner-portal.service";
import { environment } from "environments/environment";
import * as filestack from "filestack-js";
import * as _ from 'lodash';
import { combineLatest, EMPTY as empty, forkJoin } from "rxjs";
import { filter, map, switchMap, tap } from "rxjs/operators";
import { ModalService } from "../services/modal.service";

@Component({
	selector: "folders",
	templateUrl: './folders.component.html'
})
export class FoldersComponent implements OnInit {
	@Input()
	entityId: string;

	@Input()
	templateId: number;

	parentCharTypeID: number;
	parentRecordID: number;
	documentTemplates: ICharacteristicTemplate[];
	documentFolderTemplates: ICharacteristicTemplate[];
	documentFileTemplates: ICharacteristicTemplate[];

	@ViewChild("uploadFolderModal", { static: true })
	uploadFolderModal: ModalComponent;

	selectedFolderTemplateID: number;
	selectedDocFileTemplateID: number;
	uploadTemplateID: string;
	uploadCharMetaDataCollection: ICharacteristicMetaDataCollection;
	uploadCharData: ICharacteristicData[];

	@ViewChild('editDocumentEntityModal', { static: true })
	editDocumentEntityModal: ModalComponent;
	entityHierarchy: IEntityHierarchy;
	entityRoot: IEntitySearchDoc;
	loading: boolean;
	jobId: string;
	jobTitle: string;
	startJobProgress: boolean;

	flattenDocumentHierarchyList: IEntitySearchDoc[] = [];
	documentFileTemplateGroupID: number = TemplateGroupIDs.DocumentFolderTemplateGroupID;
	documentFolderTemplateGroupID: number = TemplateGroupIDs.DocumentFolderTemplateGroupID;
	documentLevel: { [key: number]: number };
	loadedHierarchy: IEntityHierarchy[];
	openFolder: { [key: number]: boolean };
	downloadUrls: { [key: number]: string };
	canRead: boolean = null;
	canCreate: boolean = null;
	canWrite: boolean = false;
	uploadFolderOrFileToEntityId: string;
	uploadFileFolderModalTitle: string;
	maxSize: number = 3 * 1024 * 1024 * 1024;
	allReadChars: ICharacteristicMetaData[] = [];
	allReadCharSolrLbl: string[];
	templateMetaDataLoaded: { [key: number]: boolean };
	entityIdForEdit: string = "";

	entityStatus: IEntityStatus;
	isLocked: boolean = false;

	private _subs: Subscription[] = [];

	constructor(
		private progressService: ProgressService,
		private entityService: EntityService,
		private ngZone: NgZone,
		private documentEntityService: DocumentEntityService,
		private templateService: TemplateService,
		private aclService: AclService,
		private modalService: ModalService,
		private partnerPortalService: PartnerPortalService) {
		this.documentTemplates = [];
		this.documentFolderTemplates = [];
		this.documentFileTemplates = [];
		this.selectedDocFileTemplateID = 0;
		this.loading = true;
		this.startJobProgress = false;
		this.documentLevel = {};
		this.loadedHierarchy = [];
		this.openFolder = {};
		this.downloadUrls = {};
		this.allReadChars = [];
		this.templateMetaDataLoaded = {};
	}

	ngOnInit() {
		this.parentCharTypeID = IDUtil.splitEntityID(this.entityId).charTypeID;
		this.parentRecordID = IDUtil.splitEntityID(this.entityId).recID;
		this.documentLevel = {};
		this.loadedHierarchy = [];
		this.flattenDocumentHierarchyList = [];
		this.openFolder = {};
		this.progressService.startProgress();
		this.loading = true;
		combineLatest(
			this.getDocumentTemplates$(),
			this.getEntityDocuments$(this.entityId, 0).pipe(tap(() => {
				this.setUpFolderStructure()
			}))
		).subscribe(() => {
			this.getTemplateStatus$();
			this.loading = false;
			this.progressService.endProgress();
		});
	}

	getTemplateStatus$() {
		this.entityService.getEntityStatusFromID(this.entityId).subscribe(
			result => {
				this.entityStatus = result;
				this.isLocked = this.entityStatus.lockIndicator == 1;
			});
	}

	getDocumentTemplates$() {
		return this.entityService.getAssociatedTemplates(this.parentCharTypeID, this.templateId, CharTypeID.Document)
			.pipe(tap(result => {
				var documentCharTypeACL = AclUtil.getDataEntityAcl(CharTypeID.Document);
				if (this.aclService.acls) {
					this.canRead = AclUtil.hasReadAccess(this.aclService.acls, documentCharTypeACL);
					this.canCreate = AclUtil.hasCreateAccess(this.aclService.acls, documentCharTypeACL);
					this.canWrite = AclUtil.hasWriteAccess(this.aclService.acls, documentCharTypeACL);
				}
				else {
					this.canRead = true;
					this.canCreate = false;
					this.canWrite = false;
				}

				this.documentTemplates = result.filter(ct => AclUtil.hasCreateAccess(this.aclService.acls, ct.acl));
				//No available templates for users to upload files, hide the + sign
				if (this.documentTemplates.length == 0) {
					this.canCreate = false;
				}
				this.documentFolderTemplates = this.documentTemplates.filter(t => t.templateGroupID == this.documentFolderTemplateGroupID);
				this.documentFileTemplates = this.documentTemplates.filter(t => t.templateGroupID != this.documentFolderTemplateGroupID);
				if (this.documentFolderTemplates.length > 0) {
					this.selectedFolderTemplateID = this.documentFolderTemplates[0].templateID;
				}
				else {
					this.selectedFolderTemplateID = 0;
				}

			}));
	}

	getEntityDocuments$(entityId: string, level: number) {
		return this.entityService.getEntityHierarchy(this.entityId, entityId, CharTypeID.Document, 1)
			.pipe(tap(result => {
				var hierarchy = _.find(this.loadedHierarchy, l => l.root.entityID == entityId)
				if (hierarchy == undefined) {
					this.loadedHierarchy.push(result);
				}

				this.setDocumentLevel(result.children, level);
				this.walkHierarchy(level);
			}));
	}

	setUpFolderStructure() {
		var files = _.filter(this.flattenDocumentHierarchyList, f => f.templateGroupID != this.documentFolderTemplateGroupID);
		let observableBatch = [];
		var hasObservable: boolean = false;
		_.forEach(files, (file) => {
			if (!this.downloadUrls[file.recordID]) {
				observableBatch.push(this.getDocumentDownloadUrl(file.recordID));
				hasObservable = true;
			}

			if (!this.templateMetaDataLoaded[file.templateID]) {
				this.templateMetaDataLoaded[file.templateID] = true;
				observableBatch.push(this.getReadCharsFromTemplateMetaData(file));
				hasObservable = true;
			}
		});

		if (hasObservable) {
			return forkJoin(observableBatch);
		}
		else {
			return empty;
		}
	}

	getDocumentDownloadUrl(recordID: number) {
		return this.documentEntityService.getDownloadUrl(recordID)
			.subscribe(result => {
				this.downloadUrls[recordID] = result;
			});
	}

	getReadCharsFromTemplateMetaData(document: IEntitySearchDoc) {
		return this.templateService.getTemplateMetaData(IDUtil.toTemplateID(CharTypeID.Document, document.templateID))
			.subscribe((result) => {
				var charACL = "";
				_.forEach(result.characteristicMetaDatas, (cmd) => {
					if (cmd.systemIndicator != -1) {
						var t = _.find(this.allReadChars, ac => ac.characteristicID == cmd.characteristicID);
						if (t == undefined) {
							if (AclUtil.hasReadAccess(this.aclService.acls, cmd.acl)) {
								var solrLbl = CharDataUtil.GetCharSolrLabel(cmd.dataTypeID, cmd.multipleIndicator, cmd.tagLabel);
								var cmdCopy = JSON.parse(JSON.stringify(cmd))
								cmdCopy.tagLabel = solrLbl;
								this.allReadChars.push(cmdCopy);
							}
						}
					}
				});
			});
	}

	showFolderUpload(entityID: string, $event: Event) {
		this.uploadFolderOrFileToEntityId = entityID;
		$event.preventDefault();
		this.uploadFolderModal.open();
	}

	uploadFolder() {

		var expiry = moment.utc().add(30, "minutes").unix();
		var policy = { "expiry": expiry };
		var policyEncoded = btoa(JSON.stringify(policy));
		this.progressService.startProgress();
		this.documentEntityService.getFileStackSignature(expiry)
			.subscribe((result) => {
				this.progressService.endProgress();
				var client = filestack.init(environment.filestackApiKey, { security: { policy: policyEncoded, signature: result.signature } });
				client.picker({
					maxFiles: 100000,
					storeTo: {
						location: "S3",
						path: "/filepicker/"
					},
					onUploadDone: ((options) => {
						this.ngZone.run(() => {
							var originalFilePaths: string[] = [];
							var filePickerPaths: string[] = [];
							var allFilesSize: number = 0;

							_.forEach(options.filesUploaded, (file) => {
								allFilesSize = allFilesSize + file.size;
								originalFilePaths.push(file.originalPath);
								filePickerPaths.push(file.key);
							});

							if (this.maxSize > allFilesSize) {
								if (originalFilePaths.length > 0) {
									this.startFolderUpload(originalFilePaths, filePickerPaths);
								}
							}
							else {
								alert("You are trying to upload folder that exceeds the max size limit allowed.");
							}

							this.uploadFolderModal.close();
						});
					})
				}).open();
			});
	}

	startFolderUpload(originalPaths: string[], filePickerPaths: string[]) {
		this.progressService.startProgress();
		this.documentEntityService.uploadFolder(this.uploadFolderOrFileToEntityId, this.selectedFolderTemplateID, this.selectedDocFileTemplateID, originalPaths, filePickerPaths)
			.subscribe(result => {
				this.uploadFolderOrFileToEntityId = "";
				this.jobId = result.jobID;
				this.jobTitle = result.jobTitle;
				this.startJobProgress = true;
				this.progressService.endProgress();
			});
	}

	openEditDocumentEntity(entityID: string, template: IEntitySearchDoc, $event: Event) {
		this.entityIdForEdit = entityID;
		var templateInfo: ICharacteristicTemplate = {
			templateID: template.templateID, templateGroupID: template.templateGroupID,
			charTypeID: 0,
			workflowProcessID: 0,
			templateName: "",
			description: "",
			systemIndicator: 0,
			visibilityIndicator: 0,
			sequenceNumber: 0,
			acl: ""
		};
		this.partnerPortalService.getFileDetails(template.recordID).subscribe((result) => {
			this.openCreateDocumentEntity(entityID, templateInfo, $event, result.charData, true);
		})

	}

	openCreateDocumentEntity(entityID: string, template: ICharacteristicTemplate, $event: Event, uploadCharData = [], isEdit = false) {
		$event.preventDefault();
		if (!isEdit) {
			this.entityIdForEdit = "";
		}
		this.progressService.startProgress();
		var divID = IDUtil.splitEntityID(this.entityId).divID;
		this.uploadFolderOrFileToEntityId = entityID;
		this.uploadTemplateID = IDUtil.toTemplateID(divID, CharTypeID.Document, template.templateID);
		if (template.templateGroupID == this.documentFolderTemplateGroupID) {
			this.uploadFileFolderModalTitle = "Create Folder";
			if (isEdit) {
				this.uploadFileFolderModalTitle = "Edit Folder";
			}
		}
		else {
			this.uploadFileFolderModalTitle = "Upload File To";
			if (isEdit) {
				this.uploadFileFolderModalTitle = "Edit File";
			}
		}


		this.entityService.getEmptyEntityCharData(this.uploadTemplateID)
			.subscribe(response => {
				this.uploadCharMetaDataCollection = response.templateMetaData;
				this.uploadCharData = uploadCharData;
				this.editDocumentEntityModal.open();
			});
	}

	saveDocumentEntity(valid: boolean) {
		if (valid) {
			this.progressService.startProgress();
			let charData: ICharDatas = {};
			_.forEach(this.uploadCharData,
				data => {
					let metaData = _.find(this.uploadCharMetaDataCollection.characteristicMetaDatas,
						cmd => cmd.characteristicID === data.charactersticID);
					charData[metaData.tagLabel] = [data];
				});

			if (this.entityIdForEdit !== "") {
				this.entityService.updateCharData(IDUtil.splitEntityID(this.entityIdForEdit).recID, CharTypeID.Document, this.uploadCharData)
					.subscribe(() => {
						this.editDocumentEntityModal.close();
						this.progressService.endProgress();
						this.progressService.clearProgress();
						this.ngOnInit();
					});
			}
			else {

				this.documentEntityService.createDocument(this.uploadTemplateID, this.uploadFolderOrFileToEntityId, charData)
					.subscribe(() => {
						this.editDocumentEntityModal.close();
						this.progressService.endProgress();
						this.progressService.clearProgress();
						this.ngOnInit();
					});
			}

		} else {
			this.progressService.clearProgress();
		}
	}

	progressDone() {
		this.startJobProgress = false;
		this.jobId = undefined;
		this.ngOnInit();
	}

	toggle(document: IEntitySearchDoc) {
		this.loading = true;

		if (this.openFolder[document.recordID]) {
			this.openFolder[document.recordID] = false;
			this.walkHierarchy(0);
			this.loading = false;

		} else {
			this.openFolder[document.recordID] = true;
			if (this.loadedHierarchy[document.entityID]) {
				this.walkHierarchy(0);
			}
			else {
				var level = this.documentLevel[document.recordID];
				level++;

				this.getEntityDocuments$(document.entityID, level)
					.pipe(tap(() => {
						this.setUpFolderStructure()
					}))
					.subscribe(() => {
						this.loading = false;
					});
			}
		}
	}

	getFolderClass(recordID: number) {
		if (this.openFolder[recordID]) {
			return "far fa-folder-open pe-1";
		} else {
			return "far fa-folder pe-1";
		}
	}

	walkHierarchy(level: number) {
		var rootHierarchy = this.loadedHierarchy[0];
		this.flattenDocumentHierarchyList = [];
		this.walkHierarchyChildren(rootHierarchy.children, true);
	}

	walkHierarchyChildren(hierarchyChildren: IEntityHierarchy[], addOrRemove: boolean) {
		let output: IEntitySearchDoc[] = [];
		hierarchyChildren.forEach((child) => {
			if (addOrRemove) {
				this.flattenDocumentHierarchyList.push(child.root);
			}

			if (this.openFolder[child.root.recordID]) {
				var openFolderItem = _.find(this.loadedHierarchy, l => l.root.recordID == child.root.recordID);
				this.walkHierarchyChildren(openFolderItem.children, true);
			}
			else {
				var openFolderHierarchy = _.find(this.loadedHierarchy, l => l.root.recordID == child.root.recordID);
				if (openFolderHierarchy != undefined) {
					_.forEach(openFolderHierarchy.children, (openFolderHierarchyChild) => {
						_.remove(this.flattenDocumentHierarchyList, f => f.recordID == openFolderHierarchyChild.root.recordID);
						if (this.openFolder[openFolderHierarchyChild.root.recordID]) {
							this.openFolder[openFolderHierarchyChild.root.recordID] = false;
							this.walkHierarchyChildren(openFolderHierarchy.children, false);
						}
					});
				}
			}
		});
	}

	setDocumentLevel(hierarchyChildren: IEntityHierarchy[], level: number) {
		hierarchyChildren.forEach((child) => {
			this.documentLevel[child.root.recordID] = level;
		});
	}

	uploadFolderModalClose() {
		this.progressService.clearProgress();
		this.uploadFolderModal.close();
	}

	editDocumentEntityModalClose() {
		this.progressService.clearProgress();
		this.editDocumentEntityModal.close()
	}

	getCleanFileName(fullFileNameChars: any) {
		if (!_.isEmpty(fullFileNameChars)) {
			var fullFileName;
			if (Array.isArray(fullFileNameChars)) {
				fullFileName = fullFileNameChars[0];
			}
			else {
				fullFileName = fullFileNameChars;
			}
			var index = fullFileName.lastIndexOf("/");
			var cleanFileName = "";
			if (index >= 0) {
				cleanFileName = fullFileName.substring(index + 1);
			}
			else {
				cleanFileName = fullFileName;
			}
			return cleanFileName;
		}
		else {
			return "";
		}
	}

	previewFile(record: IEntitySearchDoc) {
		const previewFileSub = forkJoin([
			this.entityService.getCharData(record.entityID),
			this.templateService.getTemplateMetaData(record.entityID)
		]).pipe(
			map(([charDatas, tmd]) => {
				let tuple: [number, string] = null;
				const characteristicID = tmd.characteristicMetaDatas.find(x => x.dataTypeID === CharDataType.ExternalDocument).characteristicID;
				const cd = charDatas.find(x => x.charactersticID === characteristicID);
				if (!cd) {
					// this._growlerService.error(`Preview Failed`).growl(`No external doc characteristic.`);
					return tuple;
				}
				const recordCharacteristicId = cd.recordCharacteristicID;
				const uri = charDatas.find(x => x.recordCharacteristicID === recordCharacteristicId)?.value;
				if (!uri) {
					// this._growlerService.warning(`Preview Failed`).growl(`No external doc value.`);
					return tuple;
				}
				let fileName = uri;
				if (uri.lastIndexOf("/") > -1) {
					fileName = uri.substring(uri.lastIndexOf("/") + 1);
				}
				tuple = [recordCharacteristicId, fileName];
				return tuple;
			}),
			filter(tuple => !!tuple),
			switchMap(([recordCharacteristicID, fileName]) => {
				const file$ = this.entityService.getPreviewUrl(recordCharacteristicID).pipe(
					map(result => <IPreviewDocument>{ fileName, uri: result })
				);
				return file$;
			}),
			switchMap(file => this.modalService.previewDocument(file))
		).subscribe();
		this._subs.push(previewFileSub);
	}
}
