<form class="px-2 py-1 d-flex flex-column record-summary text-nowrap" style="min-height: 54px;">
    <span class="record-summary-title control-label pe-2">Status / Workflow</span>
    <span *ngIf="actions != null && actions.length == 0"
        class="btn btn-link record-summary-status w-100 px-0 d-flex align-items-center" [innerText]="status"></span>
    <div ngbDropdown *ngIf="actions?.length > 0" placement="bottom-right">
        <button ngbDropdownToggle type="button" class="btn btn-link record-summary-status w-100 px-0 d-flex align-items-center"
            data-cy="StatusDropdownToggleButton" role="button">
            <span class="me-auto">{{ status }}</span>
        </button>
        <ul ngbDropdownMenu class="workflow-action" role="menu">
            <li ngbDropdownItem *ngIf="actions.length == 0">
                No Workflow Actions Available
            </li>
            <li ngbDropdownItem role="menuitem" *ngFor="let action of actions">
                <a href="#" (click)="executeAction($event, action)" [attr.data-cy]="'StatusDropdownSelectItem' + action.actionID">
                    <span class="status" [innerText]="action.actionName"></span>
                </a>
            </li>
        </ul>
    </div>
</form>