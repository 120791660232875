import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from "@angular/core";
import { ReactiveFormsModule } from "@angular/forms";
import { Title } from "@angular/platform-browser";
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule, Routes } from "@angular/router";
import { SSOTokenComponent } from "app/common/components/account/sso-token.component";
import { PARTNER_PORTAL_SETTINGS } from "app/partner-portal/settings";
import { environment } from "environments/environment";
import { BsDropdownModule, CollapseModule } from "ngx-bootstrap";
import { PPCommonModule } from "../common/pp-common.module";
//#region services
import { AuthGuard } from "../common/services/auth-guard.service";
//#endregion
import { AccountsPanelComponent } from "./components/account/accounts-panel.component";
import { ClaimPasswordResetComponent } from "./components/account/claim-password-reset.component";
import { ForgotPasswordComponent } from "./components/account/forgot-password.component";
import { LoginComponent } from "./components/account/login.component";
import { MyAccountComponent } from "./components/account/my-account.component";
import { AccountingDetailComponent } from "./components/accounting/accounting-detail.component";
import { AccountingListComponent } from "./components/accounting/accounting-list.component";
import { AccountingPanelComponent } from "./components/accounting/accounting-panel.component";
import { CatalogItemsDetailComponent } from "./components/catalog-items/catalog-items-detail.component";
import { CatalogItemsListComponent } from "./components/catalog-items/catalog-items-list.component";
import { CatalogItemsPanelComponent } from "./components/catalog-items/catalog-items-panel.component";
import { CatalogItemsRelatedItemsComponent } from "./components/catalog-items/catalog-items-related-items.component";
import { DealsDetailComponent } from "./components/deals/deals-detail.component";
import { DealsListComponent } from "./components/deals/deals-list.component";
import { DealsPanelComponent } from "./components/deals/deals-panel.component";
import { FilesComponent } from "./components/files-component";
import { FilesListComponent } from "./components/files/files-list.component";
import { FilesPanelComponent } from "./components/files/files-panel.component";
import { HomeComponent } from "./components/home/home.component";
import { InventoryDetailComponent } from "./components/inventory/inventory-detail.component";
import { InventoryListComponent } from "./components/inventory/inventory-list.component";
import { InventoryPanelComponent } from "./components/inventory/inventory-panel.component";
import { JobsDetailComponent } from "./components/jobs/jobs-detail.component";
import { JobsListComponent } from "./components/jobs/jobs-list.component";
import { JobsPanelComponent } from "./components/jobs/jobs-panel.component";
import { NewReleasesListComponent } from "./components/new-releases/new-releases-list.component";
import { NewReleasesPanelComponent } from "./components/new-releases/new-releases-panel.component";
import { PPApp } from "./components/pp-app.component";
import { ProjectsDetailComponent } from "./components/projects/projects-detail.component";
import { ProjectsListComponent } from "./components/projects/projects-list.component";
import { ProjectsPanelComponent } from "./components/projects/projects-panel.component";
import { PPFooterComponent } from "./components/site/pp-footer.component";
import { PPHeaderComponent } from "./components/site/pp-header.component";
import { PartnerPortalService } from "./services/partner-portal.service";
import { PPLinkHelperService } from "./services/pp-link-helper.service";
import { SiteService } from "./services/site.service";
import { ContactUsComponent } from './components/contact-us/contact-us.component';
import { PPNavbarComponent } from "./components/site/pp-navbar.component";
import { SelectDivControlComponent } from './components/select-div-control/select-div-control.component';
import { ConfigComponent } from "./components/account/config.component";
import { Configuration } from "app/common/consts";
import { SsoLoginComponent } from './components/account/sso-login/sso-login.component';

let PP_COMPONENTS = [
  PPApp,
  PPHeaderComponent,
  PPFooterComponent,
  HomeComponent,
  AccountsPanelComponent,
  LoginComponent,
  MyAccountComponent,
  ForgotPasswordComponent,
  ClaimPasswordResetComponent,
  AccountingPanelComponent,
  AccountingListComponent,
  AccountingDetailComponent,
  CatalogItemsPanelComponent,
  CatalogItemsListComponent,
  CatalogItemsDetailComponent,
  CatalogItemsRelatedItemsComponent,
  NewReleasesPanelComponent,
  NewReleasesListComponent,
  DealsPanelComponent,
  DealsListComponent,
  DealsDetailComponent,
  FilesComponent,
  FilesListComponent,
  FilesPanelComponent,
  ProjectsPanelComponent,
  ProjectsListComponent,
  ProjectsDetailComponent,
  JobsPanelComponent,
  JobsListComponent,
  JobsDetailComponent,
  InventoryPanelComponent,
  InventoryListComponent,
  InventoryDetailComponent,
  ContactUsComponent,
  SelectDivControlComponent,
  ConfigComponent,
  SsoLoginComponent
];

const ppAppRoutes: Routes = [
  {
    path: "",
    component: HomeComponent,
    pathMatch: "full",
    canActivate: [AuthGuard]
  },
  {
    path: "account",
    component: AccountsPanelComponent,
    children: [
      {
        path: "",
        redirectTo: "../my-account",
        pathMatch: "full"
      },
      {
        path: "login",
        component: LoginComponent
      },
      {
        path: "login/update",
        component: LoginComponent,
        data: {
          update: true
        }
      },
      {
        path: "",
        redirectTo: "../config",
        pathMatch: "full"
      },
      {
        path: "sso/:token",
        component: SSOTokenComponent
      },
      {
        path: "claim-password-reset/:token",
        component: ClaimPasswordResetComponent
      },
      {
        path: "forgot-password",
        component: ForgotPasswordComponent
      },
      {
        path: "my-account",
        canActivate: [AuthGuard],
        component: MyAccountComponent
      },
      {
        path: "config",
        canActivate: [AuthGuard],
        component: ConfigComponent,
        data: {
          acl: Configuration
        }
      }
    ]
  },
  {
    path: "accounting",
    component: AccountingPanelComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: "",
        component: AccountingListComponent
      },
      {
        path: ":id",
        component: AccountingDetailComponent
      }
    ]
  },
  {
    path: "catalog-items",
    component: CatalogItemsPanelComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: "",
        component: CatalogItemsListComponent
      },
      {
        path: ":id",
        component: CatalogItemsDetailComponent
      }
    ]
  },
  {
    path: "new-releases",
    component: NewReleasesPanelComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: "",
        component: NewReleasesListComponent
      }
    ]
  },
  {
    path: "deals",
    component: DealsPanelComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: "",
        component: DealsListComponent
      },
      {
        path: ":id",
        component: DealsDetailComponent
      }
    ]
  },
  {
    path: "projects",
    component: ProjectsPanelComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: "",
        component: ProjectsListComponent
      },
      {
        path: ":id",
        component: ProjectsDetailComponent
      }
    ]
  },
  {
    path: "jobs",
    component: JobsPanelComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: "",
        component: JobsListComponent
      },
      {
        path: ":id",
        component: JobsDetailComponent
      }
    ]
  },
  {
    path: "inventory",
    component: InventoryPanelComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: "",
        component: InventoryListComponent
      },
      {
        path: ":id",
        component: InventoryDetailComponent
      }
    ]
  },
  {
    path: "files",
    component: FilesPanelComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: "",
        component: FilesListComponent
      },
      {
        path: ":id",
        component: FilesComponent
      }
    ]
  },
  {
    path: "contact-us",
    component: ContactUsComponent,
    pathMatch: "full",
    canActivate: [AuthGuard]
  },
];

@NgModule({
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  imports: [
    PPCommonModule,
    ReactiveFormsModule,
    BsDropdownModule,
    CollapseModule,
    RouterModule.forRoot(ppAppRoutes, { relativeLinkResolution: 'legacy' }),
    BrowserAnimationsModule,
    PPNavbarComponent
  ],
  declarations: [
    ...PP_COMPONENTS
  ],
  providers: [
    SiteService,
    PartnerPortalService,
    PPLinkHelperService,
    Title,
    { provide: PARTNER_PORTAL_SETTINGS, useValue: environment }
  ],
  bootstrap: [PPApp]
})
export class PartnerPortalModule {

}
