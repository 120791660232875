import {
	Component,
	EventEmitter, Input,
	OnInit,
	Output,
	ViewChild
} from "@angular/core";
import { ModalComponent } from "../../common/components/modal.component";
import { CharTypeID } from '../../common/consts';
import { IDetailFile } from "../../common/messages";
import {
	ICharDatas,
	ICharacteristicData,
	ICharacteristicMetaDataCollection,
	ICharacteristicTemplate,
	IEntity
} from "../../common/models";
import { EntityService } from "../../common/services/entity.service";
import { ProgressService } from "../../common/services/progress.service";
import { IDUtil } from '../../common/utils/id-util';
import { PartnerPortalService } from '../services/partner-portal.service';

import * as _ from 'lodash';

@Component({
	selector: 'files-list',
	templateUrl: './files-component.html'
})
export class FilesComponent implements OnInit {
	@Input()
	files: IDetailFile[];

	@Input()
	parentTitle: string;

	@Input()
	parentEntity: IEntity;

	@Output()
	fileUploaded = new EventEmitter<any>();

	@ViewChild('uploadFileModal')
	uploadFileModal: ModalComponent;

	uploadCharMetaDataCollection: ICharacteristicMetaDataCollection;
	documentTemplates: ICharacteristicTemplate[];
	uploadDocumentTemplate: ICharacteristicTemplate;
	uploadCharData: ICharacteristicData[];
	uploadTemplateID: string;

	constructor(
		private progressService: ProgressService,
		private partnerPortalService: PartnerPortalService,
		private entityService: EntityService) {
	}

	ngOnInit() {
		this.partnerPortalService
			.getAssociatedTemplatesForTemplate(this.parentEntity.charTypeID,
				this.parentEntity.templateID,
				CharTypeID.Document)
			.subscribe(response => {
				this.documentTemplates = response.templates;
			});
	}

	openUploadModal(template: ICharacteristicTemplate, $event: Event) {
		$event.preventDefault();
		this.progressService.startProgress();
		this.uploadTemplateID = IDUtil
			.toTemplateID(this.parentEntity.divisionID, CharTypeID.Document, template.templateID);

		this.entityService.getEmptyEntityCharData(this.uploadTemplateID)
			.subscribe(response => {
				this.uploadCharMetaDataCollection = response.templateMetaData;
				this.uploadCharData = [];
				this.uploadFileModal.open();
			});
	}

	saveFile(valid: boolean) {
		if (valid) {
			this.progressService.startProgress();
			let charData: ICharDatas = {};
			_.forEach(this.uploadCharData,
				data => {
					let metaData = _.find(this.uploadCharMetaDataCollection.characteristicMetaDatas,
						cmd => cmd.characteristicID === data.charactersticID);
					charData[metaData.tagLabel] = [data];
				});

			this.entityService.createEntity(this.uploadTemplateID, this.parentEntity.id, charData)
				.subscribe(() => {
					this.fileUploaded.emit(null);
					this.uploadFileModal.close();
					this.progressService.endProgress();
				});

		} else {
			this.progressService.clearProgress();
		}
	}
}