import { Injectable } from "@angular/core";
import { Observable ,  Subscriber } from "rxjs";

import { SessionService } from "./session.service";
import { JwtHelperService } from "@auth0/angular-jwt";
import { tap, share } from "rxjs/operators";
@Injectable()
export class TokenService {

    private _refreshing = false;
    private _tokenRefresher: Observable<any>;

    constructor(private sessionService: SessionService, private jwtHelperService: JwtHelperService) {

    }

    assertToken(): Observable<string> {
        return new Observable<string>((obs: Subscriber<string>) => {
            if (this.sessionService.jwt && this.sessionService.refreshToken && this.jwtHelperService.isTokenExpired(this.sessionService.jwt)) {
                if (!this._refreshing) {
                    console.log("TOKEN: REFRESHING");
                    this._refreshing = true;
                    this._tokenRefresher = this.sessionService
                        .refresh()
                        .pipe(
                            tap((response) => {
                                console.log("TOKEN: REFRESHED");
                                this._refreshing = false;
                            },
                            (error: any) => {
                                this._refreshing = false;
                                // this.sessionService.logOut();
                            }),
                            share()
                        );
                } else {
                    console.log("TOKEN: SHARING REFRESH");
                }
                this._tokenRefresher.subscribe(() => {
                    obs.next(this.sessionService.jwt);
                    obs.complete();
                });
            } else {
                obs.next(this.sessionService.jwt);
                obs.complete();
            }
        });
    }
}
