import { IPartnerPortalSettings } from "app/common/models";
import { extend } from "lodash";
import * as baseEnv from "./environment.base";

export const environment = extend<IPartnerPortalSettings>(baseEnv.environment, {
    serviceApiDomain: "https://portal-api-qa.rightsline.com",
    clientID: "a971bcf6aba04e3fa2bd28c04347eb74",
    filestackBucket: "qa-rtl-main"
});

