import { Component, EventEmitter, Inject, Input, OnChanges, Output } from "@angular/core";
import { PARTNER_PORTAL_SETTINGS } from "app/partner-portal/settings";
import * as _ from "lodash";
import { CharTypeID, ExplorerView } from "../../../consts";
import { IPartnerPortalSettings } from "../../../models";

@Component({
    selector: "explorer-view-selector",
    templateUrl: "./explorer-view-selector.component.html"
})
export class ExplorerViewSelectorComponent implements OnChanges {
    @Output()
    view: ExplorerView;

    @Input()
    charTypeID: CharTypeID;

    @Output()
    viewChanged = new EventEmitter<ExplorerView>();

    explorerViews = ExplorerView;

    constructor(@Inject(PARTNER_PORTAL_SETTINGS) private settings: IPartnerPortalSettings) {
    }

    ngOnChanges() {
        if (this.settings.partnerPortal && this.settings.partnerPortal.defaultExplorerView) {
            let defaultView = _.find(this.settings.partnerPortal.defaultExplorerView, (setting) => {
                return setting.charTypeID == this.charTypeID;
            });

            this.setView(defaultView.view);
        }
    }

    setView(view: ExplorerView, $event?: Event) {
        this.view = view;
        this.viewChanged.emit(view);

        if ($event) {
            $event.preventDefault();
        }
    }
}