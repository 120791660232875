import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChange } from "@angular/core";
import * as _ from "lodash";

@Component({
    selector: "pp-pager",
    templateUrl: "./pager.component.html",
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class PagerComponent implements OnChanges, OnInit {

    @Input()
    currentPage: number;

    @Input()
    pageCount: number;

    @Output()
    pageChanged = new EventEmitter<number>();

    @Input()
    showPages: number;

    @Input()
    withClass: string;

    firstPage: number = null;
    lastPage: number = null;

    pages: number[];

    constructor() {

    }

    ngOnInit() {
        this.setupPages();
    }

    ngOnChanges(changes: { [propName: string]: SimpleChange }) {
        this.setupPages();
    }

    setupPages() {
        this.firstPage = null;
        this.lastPage = null;

        let showPages = +(this.showPages || 10);

        let min = Math.max(1, this.currentPage - showPages / 2);

        let max = Math.min(this.pageCount, min + showPages);

        if (min > 1) {
            this.firstPage = 1;
        }

        if (max < this.pageCount) {
            this.lastPage = this.pageCount;
        }

        this.pages = _.range(min, max + 1);
    }

    changePage($event: Event, page: number) {
        $event.preventDefault();
        this.pageChanged.emit(page);
    }
}
