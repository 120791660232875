import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-sso-login',
  templateUrl: './sso-login.component.html',
  styleUrls: ['./sso-login.component.scss']
})
export class SsoLoginComponent implements OnInit {


  @Input()
  ssoEndpoint: string;

  @Input()
  ssoAuthNRequest: string;

  @Input()
  ssoAppUrlID: string;

  constructor() { }

  ngOnInit(): void {
  }
}


