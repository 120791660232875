<div class="card mb-3" *ngIf="files">
    <div class="card-title d-flex justify-content-between">
        <h3>Files</h3>
        <div ngbDropdown dropdown-append-to-body>
            <button ngbDropdownToggle type="button" class="btn btn-icon btn-success hide-caret"
                ng-if="!dfhiVM.locked && dfhiVM.canEdit" ng-show="dfhiVM.documentTemplates.length > 0" data-cy="createFileMenu"
                title="Create File" role="button">
                <i class="far fa-plus"></i>
            </button>
            <ul ngbDropdownMenu class="dropdown-menu-right" role="menu">
                <li ngbDropdownItem *ngFor="let documentTemplate of documentTemplates">
                    <a href="#" *ngIf="documentTemplate.templateGroupID !== 3"
                        (click)="openUploadModal(documentTemplate, $event)"
                        [innerText]="documentTemplate.description" data-cy="createExisting"></a>
                    <a href="#" *ngIf="documentTemplate.templateGroupID === 3"
                        (click)="openUploadModal(documentTemplate, $event)" data-cy="createNew">Create
                        {{documentTemplate.templateName}}</a>
                </li>
            </ul>
        </div>

    </div>
    <div class="card-body pt-0">
        <div class="scroll-wrap">
            <div class="scrollable">
                <table class="table rl-table">
                    <thead>
                        <tr>
                            <th>File</th>
                            <th>Category</th>
                            <th>Ext.</th>
                            <th>Size</th>
                            <th class="text-center">Download</th>
                        </tr>
                    </thead>
                    <tbody *ngIf="files | hasValues">
                        <tr *ngFor="let file of files">
                            <td [innerText]="file.name"></td>
                            <td [innerText]="file.type"></td>
                            <td [innerText]="file.ext"></td>
                            <td [innerText]="file.fileSize"></td>
                            <td class="p-0">
                                <div class="hover-icons text-end">
                                    <a href="#" [href]="file.downloadUrl" class="btn btn-icon btn-sm btn-info" title="Download">
                                        <i class="far fa-download"></i>
                                    </a>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <div class="table-paginate">
            {{files.length}}
            <span style="line-height: 30px;">records</span>
        </div>
    </div>
</div>

<pp-modal *ngIf="false" #uploadFileModal modalWidth="60%">
    <modal-title>Upload File To {{parentTitle}}</modal-title>
    <modal-body>
        <char-data-table #uploadTable [charData]="uploadCharData" [template]="uploadCharMetaDataCollection"
            [editMode]="true" (tableCharDataChange)="uploadCharData=$event"></char-data-table>
    </modal-body>
    <modal-footer>
        <button type="button" class="btn btn-light" (click)="uploadFileModal.close()" data-cy="modalCancel">Cancel</button>
        <button type="button" class="btn btn-success ms-2" [disabled]="!uploadTable.valid"
            (click)="saveFile(uploadTable.valid);" busyButton data-cy="modalSave"><i class="fa"></i> Save</button>
    </modal-footer>
</pp-modal>