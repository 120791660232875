<div class="modal-header">
	<h2 class="modal-title">View Document</h2>
	<button type="button" class="btn btn-close" aria-label="Close" (click)="close()"
		data-cy="PreviewDocumentModalButtonXClose"><i class="far fa-times"></i></button>
</div>
<div class="modal-body">
	<rl-preview-document [file]="file" *ngIf="file" data-cy="RlPreviewDocument"></rl-preview-document>
</div>
<div class="modal-footer">
	<button type="button" class="btn btn-light" (click)="close()"
		data-cy="PreviewDocumentModalButtonClose">Close</button>
</div>