import { Component, ChangeDetectionStrategy, Input, OnChanges, SimpleChange, Output, EventEmitter, SimpleChanges, ChangeDetectorRef } from "@angular/core";

@Component({
    selector: "checkbox-edit-control",
    templateUrl: "./checkbox-edit-control.component.html",
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class CheckboxEditControlComponent implements OnChanges {

    @Input()
    state: number;

    @Input()
    tristate: boolean;

    @Input()
    yesLabel: string;

    @Input()
    noLabel: string;

    @Output()
    checkboxStateChange = new EventEmitter<number>();

    @Output()
    touch = new EventEmitter<Event>();

    label: string;

    constructor(private cdRef: ChangeDetectorRef) {

    }

    checkboxChanged($event: Event) {
        var nextState = this.nextState(this.state);
        if (nextState != 1) {
            (<HTMLInputElement>$event.target).checked = false;
        }
        this.checkboxStateChange.emit(nextState);
    }

    touched($event: Event) {
        this.touch.emit($event);
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes["state"]) {
            switch (<number>changes["state"].currentValue) {
                case 1:
                    this.label = this.yesLabel;
                    break;
                case -1:
                    this.label = this.noLabel;
                    break;
                default:
                    this.label = null;
                    break;
            }
            this.cdRef.markForCheck();
        }
    }

    nextState(state: number) {
        if (this.noLabel != null) {
            if (state == 0) {
                // null to true
                return 1;
            } else if (state == 1) {
                // true to false
                return -1;
            } else if (this.tristate) {
                // false to null if tri-state
                return 0;
            } else {
                // false to true if non-tri-state
                return 1;
            }
        } else {
            if (state == 0) {
                return 1;
            } else {
                return 0;
            }
        }
    };
}