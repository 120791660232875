import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
	name: "func",
	pure: true
})
export class FuncPipe implements PipeTransform {
	transform(value: any, fn: (input: any) => any): any {
		if (value == null || fn == null) {
			return null;
		}
		return fn(value);
	}
}
