import { Component, Inject } from "@angular/core";
import { ActivatedRoute, Router } from '@angular/router';
import { UntypedFormBuilder, Validators, UntypedFormGroup } from "@angular/forms";

import { FormValidators } from "../../../common/utils/form-validators";

import { ProgressService } from "../../../common/services/progress.service";
import { PartnerPortalService } from "../../services/partner-portal.service";
import { IGetAccountInfoResponse } from "../../../common/messages";
import { PPLinkHelperService } from "../../services/pp-link-helper.service";
import { PARTNER_PORTAL_SETTINGS } from "app/partner-portal/settings";
import { IPartnerPortalSettings } from "app/common/models";
import { map, tap, flatMap } from "rxjs/operators";


@Component({
    selector: "claim-password-reset",
    templateUrl: "./claim-password-reset.component.html"
})
export class ClaimPasswordResetComponent {

    resetForm: UntypedFormGroup;
    token: string;
    isValidToken: boolean = null;
    errorMessage: string;

    get password() {
        return this.resetForm.controls["password"];
    }
    get confirmPassword() {
        return this.resetForm.controls["confirmPassword"];
    }

    constructor(
        private partnerPortalService: PartnerPortalService,
        private fb: UntypedFormBuilder,
        private progressService: ProgressService,
        private activatedRoute: ActivatedRoute,
        private router: Router,
        @Inject(PARTNER_PORTAL_SETTINGS) private settings: IPartnerPortalSettings,
        private linkHelper: PPLinkHelperService) {

        this.progressService.startProgress();

        this.activatedRoute.params
            .pipe(
                map(params => <string>params["token"]),
                tap(token => this.token = token),
                flatMap(token => this.partnerPortalService.verifyPasswordResetToken(token))
            )
            .subscribe((result) => {
                this.isValidToken = result.success;
                if (result.status == "not_found") {
                    this.errorMessage = "Reset Token invalid/not found";
                } else if (result.status == "expired") {
                    this.errorMessage = "Reset Token has expired";
                } else {
                    this.errorMessage = null;
                }
                this.progressService.endProgress();
            });

        this.resetForm = this.fb.group({
            password: ["", Validators.compose([Validators.required, FormValidators.validPassword])],
            confirmPassword: ["", Validators.compose([Validators.required])]
        }, { validator: this.validateCompare("password", "confirmPassword") });
    }

    private validateCompare(field1, field2) {
        return (group: UntypedFormGroup) => {
            let fieldValue1 = group.controls[field1];
            let fieldvalue2 = group.controls[field2];
            if (fieldValue1.value != fieldvalue2.value) {
                fieldvalue2.setErrors({ "compare": true });
            }
        };
    }

    claimPassword(token: string, password: string) {
        //alert(token + " " + password);
        let divID = -1;// this.settingsService.settings.divID;
        this.progressService.startProgress();
        this.partnerPortalService.claimPasswordReset(divID, token, password)
            .subscribe((result) => {
                if (result.success) {
                    if (result.contactUpdateRequired) {
                        this.router.navigate(this.linkHelper.account.login(true));
                    } else {
                        this.router.navigate(this.linkHelper.account.login());
                    }
                } else {
                    this.errorMessage = result.message;
                }
                this.progressService.endProgress();
            });
    }
}