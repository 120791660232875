import { Component, OnInit, OnDestroy, Inject } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { Subscription } from "rxjs";
import { ProgressService } from "../../../common/services/progress.service";
import { PartnerPortalService } from "../../services/partner-portal.service";
import { ICatalogItemDetailResponse } from "../../../common/messages";
import { CharTypeID } from "../../../common/consts";
import { AclService } from "../../../common/services/acl.service";
import { NotifyService } from "../../../common/services/notify.service";
import { EntityService } from "../../../common/services/entity.service";
import { AclUtil } from "../../../common/utils/acl.util";

import * as _ from "lodash";
import { PARTNER_PORTAL_SETTINGS } from "app/partner-portal/settings";
import { ICharacteristicData, IEntityStatus } from "../../../common/models";
import { IPartnerPortalSettings } from "app/common/models";
import { map, tap, flatMap } from "rxjs/operators";

@Component({
	selector: "catalog-items-detail",
	templateUrl: "./catalog-items-detail.component.html"
})
export class CatalogItemsDetailComponent implements OnInit, OnDestroy {
	routeSub: Subscription;

	details: ICatalogItemDetailResponse;
	entityStatus: IEntityStatus;
	isLocked: boolean;
	assetID: number;

    displayTitleField: string;

    canWrite: boolean = false;
    canCreate: boolean = false;
    canDelete: boolean = false;

    editMode: boolean = false;
    relatedCharTypeIDs: number[] = [];

	constructor(
		private partnerPortalService: PartnerPortalService,
		private activatedRoute: ActivatedRoute,
        private progressService: ProgressService,
        private aclService: AclService,
        private notifyService: NotifyService,
        private entityService: EntityService,
		@Inject(PARTNER_PORTAL_SETTINGS) private settings: IPartnerPortalSettings) {

		if (this.settings.partnerPortal != null) {
			if (this.settings.partnerPortal.displayTitleField != null) {
				this.displayTitleField = this.settings.partnerPortal.displayTitleField;
			}
		}
	}

    ngOnInit() {
        //Permissions
        if (this.aclService.acls) {
            var catalogItemCharTypeACL = AclUtil.getDataEntityAcl(CharTypeID.Property);
            this.canCreate = AclUtil.hasCreateAccess(this.aclService.acls, catalogItemCharTypeACL);
            this.canWrite = AclUtil.hasWriteAccess(this.aclService.acls, catalogItemCharTypeACL);
            this.canDelete = AclUtil.hasDeleteAccess(this.aclService.acls, catalogItemCharTypeACL);
        } else {
            this.canCreate = false;
            this.canWrite = false;
            this.canDelete = false;
        }

        this.getRelatedCharTypeIds();

		this.routeSub = this.activatedRoute.params
			.pipe(
				map(params => _.parseInt(params["id"])),
				tap(id => this.assetID = id),
				flatMap(() => this.loadCatalogItem$()))
			.subscribe();
	}

	loadCatalogItem$() {
		this.progressService.startProgress();
		return this.partnerPortalService.getCatalogItemDetail(this.assetID, this.displayTitleField)
			.pipe(
				tap((result) => {
					this.details = result;
					this.getCatalogStatus$();
					this.progressService.endProgress();
				}));
	}

	getCatalogStatus$() {
		this.entityService.getTemplateStatus(this.details.record.charTypeID, this.details.record.templateID, this.details.record.statusID).subscribe(
			result => {
				this.entityStatus = result;
				this.isLocked = this.entityStatus.lockIndicator == 1;
			});
	}


	loadCatalogItem() {
		this.loadCatalogItem$().subscribe();
	}

	ngOnDestroy() {
		this.routeSub.unsubscribe();
	}

	fileUploaded() {
		this.partnerPortalService.getFiles(this.assetID, CharTypeID.Property)
			.subscribe(result => {
				this.details.files = result;
			});
    }

    editCharData() {
        this.editMode = true;
    }

    cancel() {
        this.editMode = false;
        this.loadCatalogItem();
    }

    saveCharData() {
        this.progressService.startProgress();
        this.editMode = false;

        this.entityService.updateCharData(this.assetID, CharTypeID.Property, this.details.charData)
            .subscribe(result => {
                if (result.success) {
                    this.notifyService.success("Save complete");
                }
                else {
                    this.notifyService.error("Error saving");
                }

                this.progressService.endProgress();
            });
    }

    getRelatedCharTypeIds() {
        this.relatedCharTypeIDs.push(CharTypeID.Property);
        this.relatedCharTypeIDs.push(CharTypeID.Document);
        this.relatedCharTypeIDs.push(CharTypeID.Job);
        this.relatedCharTypeIDs.push(CharTypeID.Project);
        this.relatedCharTypeIDs.push(CharTypeID.Transaction);
    }
}