import { Component, ChangeDetectionStrategy, Input, OnChanges, SimpleChange, Output, EventEmitter, Optional, ChangeDetectorRef } from "@angular/core";
import * as _ from "lodash";

import { ICharacteristicMetaDataCollection, ICharacteristicData, ITemplateCharacteristicGroup, ICharacteristicMetaData, ICharDataChangeEvent } from "../../models";
import { CharDataType } from "../../consts";
import { AclService } from "../../services/acl.service";
import { AclUtil } from "../../utils/acl.util";
import { UntypedFormControl } from "@angular/forms";

@Component({
    selector: "char-data-element",
    templateUrl: "./char-data-element.component.html",
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class CharDataElementComponent implements OnChanges {

    @Input()
    cmd: ICharacteristicMetaData;

    @Input()
    charData: ICharacteristicData[];

    @Input("editMode")
    tableEditMode: boolean;

    @Input()
    formControl: UntypedFormControl;

    public elementEditMode: boolean = null;
    private canWrite: boolean = null;
    private canCreate: boolean = null;
    private charDataInitiallyEmpty: boolean = true;

    @Output()
    elementDataChange = new EventEmitter<ICharDataChangeEvent>();

    @Output()
    touch = new EventEmitter<Event>();

    public elementType: "text" | "dropdown" | "date" | "file" | "timecode" | "number" | "amount" | "money" | "year" | "percentage" | "checkbox" = null;

    constructor(private cd: ChangeDetectorRef, private aclService: AclService) {

    }

    ngOnChanges(changes: { [key: string]: SimpleChange; }) {
        if (changes["cmd"]) {
            if (this.aclService.acls) {
                this.canWrite = AclUtil.hasWriteAccess(this.aclService.acls, this.cmd.acl);
                this.canCreate = AclUtil.hasCreateAccess(this.aclService.acls, this.cmd.acl);
            } else {
                // short circuit these for anonymous usage, will be enforced server-side
                this.canWrite = true;
                this.canCreate = true;
            }
        }
        if (changes["charData"]) {
            this.charDataInitiallyEmpty = !_.some(this.charData, cd => cd.recordCharacteristicID > 0);
        }
        this.setEditMode();
        if (changes["cmd"] || changes["tableEditMode"]) {
            this.setupElement();
        }
    }

    setEditMode() {
        if (!this.canWrite) {
            this.elementEditMode = false;
        }
        else {
            this.elementEditMode = this.tableEditMode &&
                (
                    (this.canWrite && this.canCreate) ||
                    (this.canWrite && !this.canCreate && !this.charDataInitiallyEmpty) ||
                    (!this.canWrite && this.canCreate && this.charDataInitiallyEmpty)
                );
        }

        if (this.formControl != null) {
            if (this.elementEditMode) {
                this.formControl.enable();
            } else {
                this.formControl.disable();
            }
        }
    }

    setupElement() {
        if (this.tableEditMode && this.elementEditMode) {
            switch (this.cmd.dataTypeID) {
                case CharDataType.Alphanumeric:
                case CharDataType.Email:
                case CharDataType.InternetAddress:
                    this.elementType = "text";
                    if (!_.isEmpty(this.cmd.charValueSourceID)) {
                        this.elementType = "dropdown";
                    }
                    break;
                case CharDataType.Date:
                    this.elementType = "date";
                    break;
                case CharDataType.ExternalDocument:
                    this.elementType = "file";
                    break;
                case CharDataType.SMPTETimeCode:
                    this.elementType = "timecode";
                    break;
                case CharDataType.Number:
                    this.elementType = "number";
                    break;
                case CharDataType.Currency:
                    this.elementType = "amount";
                    break;
                case CharDataType.Money:
                    this.elementType = "money";
                    break;
                case CharDataType.FourDigitYear:
                    this.elementType = "year";
                    break;
                case CharDataType.Percentage:
                    this.elementType = "percentage";
                    break;
                case CharDataType.Checkbox:
                    this.elementType = "checkbox";
                    break;
                default:
                    this.elementType = null;
                    break;
            }
        } else {
            switch (this.cmd.dataTypeID) {
                case CharDataType.Alphanumeric:
                    this.elementType = "text";
                    break;
                case CharDataType.Date:
                    this.elementType = "date";
                    break;
                case CharDataType.ExternalDocument:
                    this.elementType = "file";
                    break;
                case CharDataType.Money:
                    this.elementType = "money";
                    break;
                default:
                    this.elementType = "text";
                    break;
            }
        }
    }

    touched($event: Event) {
        this.touch.emit($event);
    }

    charDataChanged(changeEvent: ICharDataChangeEvent) {
        this.elementDataChange.emit(changeEvent);
    }

    markChanged() {
        this.cd.markForCheck();
    }
}