<div class="view-selector">
    <a href="#" [ngClass]="{'active': view == explorerViews.Tile}" (click)="setView(explorerViews.Tile, $event)"
        class="me-3 view-type" data-cy="setViewTile" title="View Tiles">
        <i class="fa fa-th-large"></i></a>
    <a href="#" [ngClass]="{'active': view == explorerViews.Grid}" (click)="setView(explorerViews.Grid, $event)"
        class="me-3 view-type" data-cy="setViewGrid" title="View Grid">
        <i class="fa fa-th-list"></i></a>
    <a href="#" class="view-type" [ngClass]="{'active': view == explorerViews.List}" (click)="setView(explorerViews.List, $event)"
        data-cy="setViewList" title="View List">
        <i class="far fa-list-alt"></i></a>
</div>
