import { Component, ChangeDetectionStrategy, Input, OnChanges, SimpleChange, Output, EventEmitter } from "@angular/core";


@Component({
    selector: "timecode-edit-control",
    templateUrl: "./timecode-edit-control.component.html",
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class TimecodeEditControlComponent {

    @Input()
    textValue: string;

    @Output()
    textValueChange = new EventEmitter<string>();

    @Output()
    touch = new EventEmitter<Event>();

    maskOptions = {
        mask: [/\d/, /\d/, ":", /\d/, /\d/, ":", /\d/, /\d/],
        placeholderChar: "_",
        guide: true
    };

    constructor() {

    }

    touched($event: Event) {
        this.touch.emit($event);
    }
}