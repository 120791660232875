export namespace ErrorMessageUtil {
	export const errorsLookup = {
		"required": "Value is required.",
		"email": "Please provide a valid email address.",
		"date": "Please provide a valid date.",
		"timecode": "Please provide a timecode in hh:mm:ss format.",
		"percentage": "Please provide a valid percentage.",
		"number": "Please provide a valid number.",
		"year": "Please provide a valid year.",
		"url": "Please provide a valid URL.",
		"amount": "Please provide a valid currency amount.",
		"start_date_after": "Starting dates must come before Ending dates.",
		"end_date_before": "Ending dates must come after Starting dates."
	};

	export const warningsLookup = {
		"desired": "Value is desired."
	};
}