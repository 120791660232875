import { ChangeDetectionStrategy, Component, OnChanges, SimpleChange } from "@angular/core";
import * as _ from "lodash";
import { ICharacteristicData } from "../../../models";
import { BaseElement } from "./base-element";


@Component({
	selector: "text-edit-element",
	templateUrl: "./text-edit-element.component.html",
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class TextEditElementComponent extends BaseElement implements OnChanges {

	textValue: string;

	constructor() {
		super();
	}

	ngOnChanges(changes: { [key: string]: SimpleChange; }) {
		if (this.charData) {
			this.textValue = _.join(this.charData.map(cd => cd.value), ", ") || "";
		}
	}

	multiTextValueChanged(charData: ICharacteristicData[]) {
		this.charDataChange.emit({ cmd: this.cmd, charData: charData });
	}

	textValueChanged(value: string) {
		let charData: ICharacteristicData[] = [];
		if (!_.isEmpty(value)) {

			let cd: ICharacteristicData = null;
			if (_.some(this.charData)) {
				cd = _.clone(this.charData[0]);
				cd.value = value;
				cd.valueID = null;
			} else {
				cd = this.createCharData(value);
			}
			charData = [cd];
		}
		this.charDataChange.emit({ cmd: this.cmd, charData: charData });
	}
}