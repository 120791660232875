<h1 class="login-title text-center">{{title}}</h1>

<div class="login-step-container d-flex justify-content-center card">
    <div class="login-step-title">
        <ng-container *ngIf="!chooseApps">
            <h1>Welcome back.</h1>
        </ng-container>
        <ng-container *ngIf="chooseApps">
            <h1>Choose Application</h1>
        </ng-container>
    </div>
    <div class="login-step-body">
        <ng-container *ngIf="initialLoad">
            <span *ngIf="showError" class="alert alert-danger">No login options available</span>
            <form [formGroup]="loginForm">
                <div class="mb-3" [class.has-error]="!email.valid && email.touched">
                    <label class="control-label">Email</label>
                    <input type="email" class="form-control" formControlName="email" data-cy="loginEmail"
                        [rlAutoFocus] />
                    <control-error-messages [control]="email"></control-error-messages>
                </div>
                <div class="text-end mt-4">
                    <button type="submit" class="btn btn-success" [disabled]="!email.valid" data-cy="loginSubmit"
                        (click)="next()">
                        Next
                    </button>
                </div>
            </form>
        </ng-container>
        <ng-container *ngIf="!initialLoad">
            <ng-container *ngIf="!chooseApps">
                <form [formGroup]="loginForm" (ngSubmit)="onSubmit()">
                    <div class="alert alert-danger" *ngIf="invalidLogin">
                        <strong>
                            Invalid email/password
                        </strong>
                    </div>
                    <div class="mb-3" [class.has-error]="!email.valid && email.touched">
                        <label class="control-label">Email</label>
                        <input type="email" class="form-control" formControlName="email" data-cy="loginEmail" />
                        <control-error-messages [control]="email"></control-error-messages>
                    </div>
                    <div *ngIf="showLogin" class="mb-3" [class.has-error]="!password.valid && password.touched">
                        <label class="control-label">Password</label>
                        <input type="password" class="form-control" formControlName="password"
                            data-cy="loginPassword" />
                        <control-error-messages [control]="password"></control-error-messages>
                    </div>
                    <div class="d-flex justify-content-between align-items-center">
                        <a *ngIf="showLogin" [routerLink]="linkHelper.account.forgotPassword()"
                            data-cy="forgotPassword">Forgot
                            your password?</a>
                        <button *ngIf="showLogin" type="submit" class="btn btn-success ms-auto" busyButton=""
                            [disabled]="!loginForm.valid" data-cy="loginSubmit">
                            Login
                        </button>
                        <app-sso-login *ngIf="showSSO" [ssoEndpoint]="ssoEndpoint" [ssoAuthNRequest]="ssoAuthNRequest"
                            [ssoAppUrlID]="ssoAppUrlID"></app-sso-login>
                    </div>
                </form>
            </ng-container>
            <ng-container *ngIf="chooseApps">
                <form [formGroup]="loginForm" (ngSubmit)="onSubmit()">
                    <div class="mb-3">
                        <rl-select-div-control [apps]="chooseApps" [(ngModel)]="typeAheadSelectedApp"
                            (ngModelChange)="typeAheadChooseDiv()" data-cy="selectDiv">
                        </rl-select-div-control>
                    </div>
                    <div class="mb-3">
                        <select class="form-select" [formControl]="chooseApp" (ngModelChange)="submitWithApp()"
                            data-cy="chooseApplication">
                            <option [ngValue]="null">Choose an Application</option>
                            <option *ngFor="let app of chooseApps" [ngValue]="app"
                                [innerText]="app.name + ' -- Div '+ app.divId">
                            </option>
                        </select>
                    </div>
                </form>
            </ng-container>
        </ng-container>
    </div>
</div>