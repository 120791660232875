import { Component, OnInit } from '@angular/core';
import { SessionService } from 'app/common/services/session.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ProgressService } from 'app/common/services/progress.service';

@Component({
    selector: 'sso-token',
    templateUrl: './sso-token.component.html'
})
export class SSOTokenComponent implements OnInit {
    
    constructor(private sessionService: SessionService, private activatedRoute: ActivatedRoute, private progressService: ProgressService, private router: Router) {

    }

    ngOnInit(): void {
        var refresh_token = this.activatedRoute.snapshot.params["token"];
        this.progressService.startProgress();
        this.sessionService.requestRefresh(refresh_token)
            .subscribe(() => {
                this.progressService.endProgress();
                this.router.navigate([""]);
            }, () => {
                this.progressService.clearProgress();
                this.router.navigate(["/account", "login"]);
            });
    }
}
