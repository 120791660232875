<ul>
    <li *ngFor="let lov of lovs">
        <div class="tree-row">
            <i [ngClass]="getArrowClass(lov)" [ngStyle]="{'margin-left': (depth*20)+'px'}" (click)="toggleOpen(lov)" data-cy="treeListToggleOpen" title="Toggle Expand"></i>
            <div class="box">
                <input type="checkbox" [ngModel]="isChecked(lov)" (change)="toggleSelected(lov)" data-cy="treeListToggleSelect" />
            </div>
            <div class="title" [innerText]="lov.characteristicValueLabel" (click)="toggleSelected(lov)" data-cy="treeListToggleSelectTitle"></div>
        </div>
        <tree-list-list *ngIf="isOpen(lov) && lov.childValues.length > 0" [lovs]="lov.childValues" [depth]="depth + 1"></tree-list-list>
    </li>
</ul>