import { Component, OnInit } from "@angular/core";
import { UntypedFormBuilder, Validators, UntypedFormGroup, FormControl, Validator } from "@angular/forms";
import { Router, ActivatedRoute } from '@angular/router';

import { ProgressService } from "../../../common/services/progress.service";

import { FormValidators } from "../../../common/utils/form-validators";
import { PPLinkHelperService } from "../../services/pp-link-helper.service";
import { PartnerPortalService } from "../../services/partner-portal.service";
import { SessionService } from "../../../common/services/session.service";

@Component({
    selector: "forgot-password",
    templateUrl: "./forgot-password.component.html"
})
export class ForgotPasswordComponent implements OnInit {

    resetForm: UntypedFormGroup;

    get email() {
        return this.resetForm.controls["email"];
    };

    isSuccess = false;
    isError = false;

    constructor(
        private fb: UntypedFormBuilder,
        private progressSerice: ProgressService,
        private sessionService: SessionService,
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private linkHelper: PPLinkHelperService,
        private partnerPortalService: PartnerPortalService) {

        this.resetForm = this.fb.group({
            email: ["", Validators.compose([Validators.required, FormValidators.validEmail])],
        });
    }

    ngOnInit() {
    }

    onSubmit($event) {
        let email = this.email.value;

        this.isSuccess = false;
        this.isError = false;

        this.progressSerice.startProgress();
        this.partnerPortalService.resetPassword(email)
            .subscribe((value) => {
                this.isSuccess = true;
                this.progressSerice.endProgress();
            },
            (err: { error: string; error_description: string }) => {
                this.isError = true;
                this.progressSerice.endProgress();
            });
    }

}