<div class="section-head d-flex justify-content-between align-items-center mb-3">
	<div class="me-auto ps-3">
		<h1 class="title" *ngIf="title" [innerText]="title"></h1>
	</div>
	<div class="pe-3 align-self-stretch border-start" [hidden]="!title">
		<pp-workflow-action [entityId]="entity?.id" (statusChange)="refresh()"></pp-workflow-action>
	</div>
</div>

<app-contacts *ngIf="entity" [charTypeId]="entity?.charTypeID" [recordId]="recordID"></app-contacts>

<div class="card mb-3" *ngIf="amounts?.length > 0">
	<div class="card-title">
		<h3>Amounts</h3>
	</div>
	<div class="card-body">
		<table class="table rl-table">
			<thead>
				<tr>
					<th>Template</th>
					<th>Status</th>
					<th>Amount</th>
					<th>Last Updated</th>
				</tr>
			</thead>
			<tbody>
				<tr *ngFor="let doc of amounts">
					<td [innerText]="doc.template"></td>
					<td [innerText]="doc.status"></td>
					<td [innerText]="getAmountString(doc)"></td>
					<td [innerText]="doc.lastUpdated | rlDate"></td>
				</tr>
			</tbody>
		</table>
		<div class="pt-2 text-end bold" style="font-size:14px;">
			TOTAL:
			<span class="currency nowrap" ng-class="{'danger': (positiveTotal - negativeTotal) < 0 }">
				{{ (positiveTotal - negativeTotal) | currency: invoiceCurrencySym + ' ' }}
			</span>
		</div>
	</div>
</div>

<div class="card mb-3">
	<div class="card-title d-flex justify-content-between">
		<h3>Details</h3>
		<div>
			<button *ngIf="canWrite && !editMode" type="button" class="btn btn-icon btn-info me-2" (click)="editCharData()"
				[disabled]="isLocked" title="Edit" data-cy="editItem">
				<i class="fa far-pencil"></i>
			</button>
			<button *ngIf="canWrite && editMode" type="button" class="btn btn-sm btn-light me-2" (click)="cancel()"
				title="Cancel" data-cy="cancel">
				Cancel
			</button>
			<button *ngIf="canWrite && editMode" [disabled]="!charDataTable.valid" type="button"
				class="btn btn-sm btn-success" (click)="saveCharData()" title="Save" data-cy="save">
				Save
			</button>
		</div>
	</div>
	<div class="card-body pt-0">
		<char-data-table #charDataTable [charData]="charData" [template]="templateMetaData" [editMode]="false"
			(tableCharDataChange)="charData=$event"></char-data-table>
	</div>
</div>

<div *ngFor="let relatedCharTypeID of relatedCharTypeIDs">
	<association-list *ngIf="entity" [entityId]="entity?.id" [associatedCharTypeId]="relatedCharTypeID">
	</association-list>
</div>

<folders *ngIf="entity" [entityId]="entity?.id" [templateId]="entity?.templateID"></folders>