import { Component, Injector, Renderer2 } from "@angular/core";
import { DOCUMENT } from "@angular/common";

import {AnimationHelper} from "../utils/animation-helper";
import {ModalService} from "../services/modal.service";
import { trigger, state, style, transition, animate } from "@angular/animations";

@Component({
    selector: "modal-backdrop",
    template: "<div class='modal-backdrop fade' [ngClass]='classes' [@fade]='fadeState'></div>",
    animations: [
        trigger("fade", [
            state("out", style({ opacity: 0 })),
            state("show", style({ opacity: 0.5 })),
            transition("out <=> show", animate("50ms ease-in"))])
    ]
})
export class ModalBackdropComponent {
    fadeState: "out" | "show" = "out";
    classes: string[];

    constructor(private modalService: ModalService, private injector: Injector, private renderer: Renderer2) {

        this.classes = ["show", "modal-close"];

        let document = this.injector.get(DOCUMENT);

        this.modalService.modalClose
            .subscribe(() => {
                this.fadeState = "out";
                setTimeout(() => {
                    this.classes = ["modal-close"];
                    this.renderer.removeClass(document.body, "modal-open");
                }, 50)
            })

        this.modalService.modalOpen
            .subscribe(() => {
                this.fadeState = "show";
                this.classes = ["modal-open"];
                this.renderer.addClass(document.body, "modal-open");
            });
    }


}