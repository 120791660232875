import { Injectable } from "@angular/core";
import { AbstractControl, UntypedFormBuilder, Validators } from "@angular/forms";
import * as _ from "lodash";
import * as moment from "moment";
import * as ElementValidators from "../components/char-data/elements/element-validators";
import { ICharacteristicData, ICharacteristicMetaData, ICharacteristicMetaDataCollection, ITemplateCharacteristicGroup } from "../models";

import { AclService } from "app/common/services/acl.service";
import { AclUtil } from "app/common/utils/acl.util";
import { CharDataType } from "../consts";


@Injectable()
export class CharDataTableService {

    conditionalFieldRules: {
        charID: number,
        tagLabel: string,
        triggerCharID: number,
        triggerTagLabel: string,
        triggerValue: any[]
    }[] = [];
    constructor(private fb: UntypedFormBuilder, private aclService: AclService) {

    }

    getVisibleCmdsForDisplay(template: ICharacteristicMetaDataCollection, charData: ICharacteristicData[]) {
        return this.getVisibleCmds(template, charData, false);
    }

    getVisibleCmdsForEdit(template: ICharacteristicMetaDataCollection, charData: ICharacteristicData[]) {
        return this.getVisibleCmds(template, charData, true);
    }

    getVisibleCmds(template: ICharacteristicMetaDataCollection, charData: ICharacteristicData[], editMode: boolean) {
        let groups: ITemplateCharacteristicGroup[] = [];
        let groupCmds: { [groupID: string]: ICharacteristicMetaData[] } = {};
        let charDataByCharID = this.mapCharData(charData);
        // foreach group, ordered
        if (template) {
            this.getConditionalFieldRules(template.characteristicMetaDatas);
            _(template.groups)
                .orderBy((g) => g.sequenceNumber)
                .forEach((g) => {

                    // each visible cmd, ordered
                    let cmds = _(template.characteristicMetaDatas)
                        .filter((cmd) => cmd.groupID == g.groupID && AclUtil.hasReadAccess(this.aclService.acls, cmd.acl))
                        .orderBy((cmd) => cmd.sequenceNumber)
                        .value();

                    // if some cmds are present
                    if (_.some(cmds)) {
                        if (editMode) {
                            // if edit mode always push to the group
                            // first filter away any empty "entity_*" cmds (except entity_title) which are often empty/uneditable 
                            var cmdsForEdit = cmds.filter(cmd => !cmd.tagLabel.startsWith("entity_") || cmd.tagLabel == "entity_title" || _.some(charDataByCharID[cmd.characteristicID]));
                            //Check for conditional fields and hide cmds that don't have conditions met
                            cmdsForEdit = this.checkAgainstConditionalFieldRules(cmdsForEdit, charData);
                            if (_.some(cmdsForEdit)) {
                                groups.push(g);
                                groupCmds[g.groupID] = cmdsForEdit;
                            }
                        } else {
                            // if display mode only push groups with cmds that have data
                            let cmdsWithCharData = cmds.filter(cmd => _.some(charDataByCharID[cmd.characteristicID]));
                            //Hide cmds that don't have conditions met 
                            cmdsWithCharData = this.checkAgainstConditionalFieldRules(cmdsWithCharData, charData);
                            if (_.some(cmdsWithCharData)) {
                                groups.push(g);
                                groupCmds[g.groupID] = cmdsWithCharData;
                            }
                        }
                    }
                });
        }

        return {
            groups,
            groupCmds
        };
    }

    getConditionalFieldRules(cmds: ICharacteristicMetaData[]) {
        this.conditionalFieldRules = [];
        _.forEach(cmds, (cmd) => {
            if (cmd.triggerCharID) {
                var trigger = (_.filter(cmds, c => c.characteristicID == cmd.triggerCharID));
                if (trigger.length > 0) {
                    var tagLabel = _.first(trigger).tagLabel;
                    var triggerValue = [];
                    if (cmd.triggerValue.indexOf("|") > -1) {
                        triggerValue = cmd.triggerValue.split("|");
                    }
                    else {
                        triggerValue[0] = cmd.triggerValue;
                    }
                    this.conditionalFieldRules.push({
                        charID: cmd.characteristicID,
                        tagLabel: cmd.tagLabel,
                        triggerCharID: cmd.triggerCharID,
                        triggerTagLabel: tagLabel,
                        triggerValue: triggerValue
                    });
                }
            }
        });
    }

    checkAgainstConditionalFieldRules(cmds: ICharacteristicMetaData[], charData: ICharacteristicData[]) {
        var loopToCheck = true;
        while (loopToCheck) {
            let cmdsToRemove = [];
            _.forEach(cmds, cmd => {
                var rules = _.filter(this.conditionalFieldRules, cfr => cfr.tagLabel == cmd.tagLabel);
                if (rules.length > 0) {
                    var rule = _.first(rules);
                    //Get the data that matches the trigger
                    var triggerData = _.filter(charData, c => c.charactersticID == rule.triggerCharID);
                    //Check if data is the correct value for trigger
                    var hasValue = triggerData.some(d => rule.triggerValue.includes(d.valueID.toString()));
                    if (!hasValue && !rule.triggerValue.includes("-999")) {
                        //clear the data if conditional field does not match and hide input
                        cmdsToRemove.push(cmd.characteristicID);
                        var cmdData = _.filter(charData, c => c.charactersticID == cmd.characteristicID);
                        _.forEach(cmdData, dv => {
                            dv.value = null;
                            dv.valueID = null;
                        });
                    };
                }
            });
            loopToCheck = cmdsToRemove.length > 0;
            _.remove(cmds, function (cmd) {
                var toRemove = _.find(cmdsToRemove, c => c == cmd.characteristicID);
                return toRemove;
            });
        }
        return cmds;
    }

    groupCharDatas(charData: ICharacteristicData[]) {
        return _(charData)
            .groupBy((cd) => cd.charactersticID)
            .value();
    }

    buildFormGroup(cmds: ICharacteristicMetaData[], charDatas: { [charID: string]: ICharacteristicData[] }) {
        let controlsConfig: { [key: string]: any } = {};

        _(cmds)
            .forEach((cmd) => {
                let tagLabel = cmd.tagLabel;
                let charDataValues = charDatas[cmd.characteristicID] || [];
                controlsConfig[tagLabel] = this.buildFormControlConfigValue(cmd, charDataValues);;
            });
        return this.fb.group(controlsConfig, { validator: ElementValidators.tableValidator(cmds) });
    }

    buildFormControlConfigValue(cmd: ICharacteristicMetaData, charDataValues: ICharacteristicData[]) {
        let configValue: any[] = [charDataValues];
        let validators = this.getValidators(cmd);
        if (_.some(validators)) {
            configValue.push(Validators.compose(validators));
        }
        return configValue;
    }

    getValidators(cmd: ICharacteristicMetaData) {
        let validators: ((c: AbstractControl) => { [key: string]: any })[] = [];

        if (cmd.editIndicator === 0) {
            return validators;
        }

        if (cmd.requiredIndicator === 1) {
            validators.push(ElementValidators.required);
        }

        if (cmd.requiredIndicator === 2) {
            validators.push(ElementValidators.desired);
        }

        switch (cmd.dataTypeID) {
            case CharDataType.Date:
                validators.push(ElementValidators.validDate);
                break;
            case CharDataType.Email:
                validators.push(ElementValidators.validEmail);
                break;
            case CharDataType.Number:
                validators.push(ElementValidators.validNumber);
                break;
            case CharDataType.FourDigitYear:
                validators.push(ElementValidators.validYear);
                break;
            case CharDataType.InternetAddress:
                validators.push(ElementValidators.validUrl);
                break;
            case CharDataType.SMPTETimeCode:
                validators.push(ElementValidators.validTimecode);
                break;
            case CharDataType.Currency:
                validators.push(ElementValidators.validAmount);
                break;
            default:
                break;
        }

        return validators;
    }

    changeElementData(template: ICharacteristicMetaDataCollection, modifiedCmd: ICharacteristicMetaData, currentCharDataMap: _.Dictionary<ICharacteristicData[]>, newCharDataMap: _.Dictionary<ICharacteristicData[]>, depth: number = 0) {
        let oldCharData = _.cloneDeep(currentCharDataMap);
        let modifiedCharData = _.cloneDeep(newCharDataMap);
        switch (modifiedCmd.dataTypeID) {
            case CharDataType.SMPTETimeCode:

                let inCMD: ICharacteristicMetaData = null;
                let outCMD: ICharacteristicMetaData = null;
                let lengthCMD: ICharacteristicMetaData = _.find(template.characteristicMetaDatas, cmd => cmd.tagLabel == "clip_length");
                if (modifiedCmd.tagLabel.endsWith("_in")) {
                    inCMD = modifiedCmd;
                    outCMD = _.find(template.characteristicMetaDatas, cmd => cmd.tagLabel == modifiedCmd.tagLabel.replace("_in", "_out"));
                } else if (modifiedCmd.tagLabel.endsWith("_out")) {
                    outCMD = modifiedCmd;
                    inCMD = _.find(template.characteristicMetaDatas, cmd => cmd.tagLabel == modifiedCmd.tagLabel.replace("_out", "_in"));
                }
                if (inCMD != null && outCMD != null && lengthCMD != null) {
                    let inCD = _.first(this.getMostRecentValues(oldCharData, modifiedCharData, inCMD.characteristicID));
                    let outCD = _.first(this.getMostRecentValues(oldCharData, modifiedCharData, outCMD.characteristicID));
                    let lengthCD = _.first(this.getMostRecentValues(oldCharData, modifiedCharData, lengthCMD.characteristicID)) || this.createCharData(lengthCMD.characteristicID, "");
                    if (inCD != null && outCD != null && lengthCD != null) {
                        let inMoment = moment(inCD.value, "HH:mm:ss");
                        let outMoment = moment(outCD.value, "HH:mm:ss");
                        if (inMoment.isValid() && outMoment.isValid() && outMoment.isAfter(inMoment)) {
                            let difference = moment().startOf("day").milliseconds(outMoment.valueOf() - inMoment.valueOf());
                            lengthCD.value = difference.format("HH:mm:ss");

                            let lengthPatch: _.Dictionary<ICharacteristicData[]> = {};
                            lengthPatch[lengthCMD.characteristicID] = [lengthCD];
                            modifiedCharData = this.mergeCharDataMaps(modifiedCharData, lengthPatch);
                        }
                    }
                }
                break;
            default:
                break;
        }
        return this.mergeCharDataMaps(oldCharData, modifiedCharData);
    };

    mergeCharDataMaps(currentCharData: { [charID: string]: ICharacteristicData[] }, modifiedCharData: { [charID: string]: ICharacteristicData[] }) {
        //let charIDs = _(modifiedCharData).map(cd => cd.charactersticID).uniq().value();
        //return _(currentCharData)
        //    .filter((cd) => !_.includes(charIDs, cd.charactersticID))
        //    .union(modifiedCharData)
        //    .value();
        let keys = _(currentCharData).keys().union(_.keys(modifiedCharData)).value();
        return _.transform<string, _.Dictionary<ICharacteristicData[]>>(keys, (acc, key) => {
            acc[key] = currentCharData[key] || [];
            acc[key] = modifiedCharData[key] || acc[key];
        }, {});
    }

    getMostRecentValues(currentCharDataMap: _.Dictionary<ICharacteristicData[]>, modifiedCharDataMap: _.Dictionary<ICharacteristicData[]>, charID: number) {
        return modifiedCharDataMap[charID] || currentCharDataMap[charID];
    }

    mapCharData(charData: ICharacteristicData[]) {
        return _.groupBy(charData, cd => cd.charactersticID);
    }

    public createCharData(charID: number, value: string, valueID: number = null): ICharacteristicData {
        return {
						charactersticID: charID,
            recordCharacteristicID: 0,
            value: value,
            valueID: valueID
        };
    }

}