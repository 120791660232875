<div class="section-head row align-items-center">
	<div class="col-sm-3">
		<h1 class="py-1">Deals</h1>
	</div>
	<div class="col-sm-9">
		<form method="post" class="d-flex flex-row flex-wrap justify-content-end align-items-center" [formGroup]="searchForm"
			(ngSubmit)="submitSearch()">
			<div class="my-1 has-feedback search-form me-3">
				<div class="input-group">
					<input id="searchKeywords" type="text" class="form-control search-input" autofocus
						placeholder="Search" formControlName="keywords" [ngModel]="keywords" data-cy="searchKeywords"/>
						<div class="input-group-append">
							<button type="submit" class="btn btn-info btn-icon ms-1" data-cy="searchSubmit"><i class="far fa-search"></i></button>
						</div>
				</div>
			</div>
			<div class="my-1 me-2">
				<select class="form-control form-select" formControlName="template" #templateFacet [ngModel]="templateID" data-cy="filterTemplate">
					<option value="" class="">All Templates</option>
					<option *ngFor="let facet of templateFacets; trackBy:optionTrackBy" [innerText]="facet.name"
						[value]="facet.id"></option>
				</select>
			</div>
			<div class="my-1">
				<select class="form-control form-select" formControlName="status" #templateFacet [ngModel]="statusID" data-cy="filterStatus">
					<option value="" class="">All Statuses</option>
					<option *ngFor="let status of statuses; trackBy:optionTrackBy" [innerText]="status.name"
						[value]="status.id"></option>
				</select>
			</div>
		</form>
	</div>
</div>
<!--/section-head-->

<div class="row">
	<div class="col-12 pt-3">
		<div class="alert alert-info" *ngIf="!(documents | hasValues)">
			<strong>No Deals found.</strong>
		</div>
		<explorer *ngIf="documents && documents.length > 0" [documents]="documents" [view]="view" [currentPage]="page"
			[pageCount]="pageCount" (pageChanged)="pageChanged($event)" [linkHelper]="linkHelper.deals"></explorer>
	</div>
</div>