<div class="card-grid row justify-content-start">
    <div class="col-lg-4 mt-3" *ngIf="showProjects" data-cy="Projects">
        <explorer-list [documents]="projectItems" [linkHelper]="linkHelper.projects" [displaySummarizedView]="true"
            [heading]="'Projects'"></explorer-list>
    </div>
    <div class="col-lg-4 mt-3" *ngIf="showCatalog" data-cy="CatalogItems">
        <explorer-list [documents]="catalogItems" [linkHelper]="linkHelper.catalogItems" [displaySummarizedView]="true"
            [heading]="'Catalog'"></explorer-list>
    </div>
    <div class="col-lg-4 mt-3" *ngIf="showDeals" data-cy="Deals">
        <explorer-list [documents]="dealItems" [linkHelper]="linkHelper.deals" [displaySummarizedView]="true" [heading]="'Deals'"></explorer-list>
    </div>
    <div class="col-lg-4 mt-3" *ngIf="showJobs" data-cy="Jobs">
        <explorer-list [documents]="jobItems" [linkHelper]="linkHelper.jobs" [displaySummarizedView]="true" [heading]="'Jobs'"></explorer-list>
    </div>
    <div class="col-lg-4 mt-3" *ngIf="showInventory" data-cy="InventoryItems">
        <explorer-list [documents]="inventoryItems" [linkHelper]="linkHelper.inventory" [displaySummarizedView]="true"
            [heading]="'Inventory'"></explorer-list>
    </div>
    <div class="col-lg-4 mt-3" data-cy="FilesUploadList">
        <explorer-upload-file-list *ngIf="showUploads || files?.length" [documents]="files" [fileURLs]="allFilesUrls" [linkHelper]="linkHelper.files"
            [includeHeading]="true" [displaySummarizedView]="true" [sourceFields]="fileSourceFields"
            [showUploadFile]="showUploads"></explorer-upload-file-list>
    </div>
</div>