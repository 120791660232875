<div class="pp-char-data-group-label contacts-group-label" (click)="toggle()" *ngIf="!!dictionaries.partyGroups[contactPartyGroup.groupId] ||
	!!dictionaries.partyGroups[contactPartyGroup.groupId]?.groupLabel">
	<span>{{dictionaries.partyGroups[contactPartyGroup.groupId] &&
		dictionaries.partyGroups[contactPartyGroup.groupId].groupLabel}}</span>
	<i class="ps-3 far fa-chevron-right" [class.fa-chevron-down]="isExpanded" [class.fa-chevron-right]="!isExpanded" arai-label="Toggle Expand"></i>
</div>
<div *ngIf="isExpanded" class="pp-char-data-elements">
	<ng-container *ngFor="let contactParty of contactPartyGroup.contactParties">
		<div *ngIf="contactParty.contacts && contactParty.contacts.length > 0" class="pp-char-data-element">
			<div class="pp-char-data-element-label">
				<span [innerText]="dictionaries.parties[contactParty.partyId].partyName"></span>
			</div>
			<div class="pp-char-data-element-control mb-3">
				<div *ngFor="let contact of contactParty.contacts" class="pp-char-data-element-display">
					<app-contact [entityCharDataPair]="dictionaries.contacts[contact.recordId]"
						[parentEntityId]="parentEntityId" [partyId]="contactParty.partyId"
						[groupId]="contactPartyGroup.groupId">
					</app-contact>
				</div>

			</div>
		</div>
	</ng-container>
</div>