import {Component, ChangeDetectionStrategy, OnChanges, SimpleChange} from "@angular/core";
import * as _ from "lodash";

import {BaseElement} from "./base-element";
import {ICharacteristicData} from "../../../models";
import {EntityService} from "../../../services/entity.service";
import {ProgressService} from "../../../services/progress.service";

@Component({
    selector: "file-display-element",
    template: `<file-display-control [fileName]="fileName" (fileClick)="fileClicked()"></file-display-control>`,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class FileDisplayElementComponent extends BaseElement implements OnChanges {

    fileName: string;

    constructor(private entityService: EntityService, private progressService: ProgressService) {
        super();
    }

    ngOnChanges(changes: { [key: string]: SimpleChange; }) {
        if (this.charData) {
            let fileName = null;
            if (_.some(this.charData)) {
                if (!_.isEmpty(this.charData[0].value)) {
                    fileName = this.charData[0].value;
                    if (fileName.lastIndexOf("/") > -1) {
                        fileName = fileName.substring(fileName.lastIndexOf("/") + 1);
                    }
                }
            }
            this.fileName = fileName;
        }
    }

    fileClicked() {
        let cd = _.first(this.charData);
        if (cd != null) {
            if (cd.recordCharacteristicID == 0) {
                alert("File will not be available for download until this entity has been saved");
            } else {
                this.progressService.startProgress();
                this.entityService.getDownloadUrl(cd.recordCharacteristicID)
                    .subscribe((result) => {
                        this.progressService.endProgress();
                        window.location.assign(result);
                    });
            }
        } else {
            alert("No char data");
        }
    }
}