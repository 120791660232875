import {Directive, forwardRef, ChangeDetectorRef} from "@angular/core";
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from "@angular/forms";

import {CharDataElementComponent} from "../char-data-element.component";
import {ICharDataChangeEvent, ICharacteristicData} from "../../../models";

export const CHAR_DATA_ELEMENT_VALUE_ACCESSOR: any = {
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => ElementValueAccessor),
    multi: true
};


@Directive({
    selector: "char-data-element[formControlName],char-data-element[formControl]",
    providers: [CHAR_DATA_ELEMENT_VALUE_ACCESSOR]
})
export class ElementValueAccessor implements ControlValueAccessor {

    onChange = (_: any) => { };
    onTouched = () => { };

    constructor(private element: CharDataElementComponent, private cdRef: ChangeDetectorRef) {
        element.elementDataChange
            .subscribe((value: ICharDataChangeEvent) => {
                if (!value.silent) {
                    this.onChange(value.charData);
                    this.onTouched();
                }
            });

        element.touch
            .subscribe(() => {
                this.onTouched();
            });
    }

    writeValue(obj: any) {
        this.element.charData = <ICharacteristicData[]>obj;
        this.element.markChanged();
    };

    registerOnChange(fn: (_: any) => {}): void { this.onChange = fn; }
    registerOnTouched(fn: () => {}): void { this.onTouched = fn; }
}