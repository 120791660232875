import { Component, ChangeDetectionStrategy, OnInit, OnChanges, SimpleChange, ChangeDetectorRef } from "@angular/core";
import * as _ from "lodash";

import { BaseElement } from "./base-element";
import { ICharacteristicData, ICharacteristicMetaData, ICharacteristicMetaDataValue, ICharDataMoneyValue } from "../../../models";
import { EntityService } from "../../../services/entity.service";
import { AdminService } from "../../../services/admin.service";
import { ProgressService } from "../../../services/progress.service";
import { JsonUtil } from "../../../utils/json-util";

@Component({
    selector: "money-edit-element",
    template: `<money-edit-control [localAmount]="localAmount" [localCurrencySymbol]="localCurrencySymbol" [divAmount]="divAmount" [divCurrencySymbol]="divCurrencySymbol" [validCurrencyListOfValues]="validCurrencyListOfValues" (currencyChange)='currencyChanged($event)' (textValueChange)='textValueChanged($event)' (touch)='touched($event)'></money-edit-control>`,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class MoneyEditElementComponent extends BaseElement implements OnInit, OnChanges {

    localAmount: string;
    localCurrencySymbol: string;
    localCurrencyID: number;
    divAmount: string;
    divCurrencySymbol: string;
    divCurrencyID: number;

    validCurrencyListOfValues: ICharacteristicMetaDataValue[] = [];

    constructor(private entityService: EntityService, private adminService: AdminService, private progressService: ProgressService, private cd: ChangeDetectorRef) {
        super();
    }

    ngOnInit() {
        //Set the local currency 
        this.adminService.getCompanyCurrency(this.cmd.charValueSourceID, this.cmd.charValueSetID)
            .subscribe(result => {
                this.localCurrencyID = result.currencyID;
                this.localCurrencySymbol = result.symbol;

                this.cd.markForCheck();
            });

        //Get the valid currencies
        this.adminService.getValidCurrencies(this.cmd.charValueSourceID, this.cmd.charValueSetID)
            .subscribe(result => {
                this.validCurrencyListOfValues = result;
            });
    }

    ngOnChanges(changes: { [key: string]: SimpleChange; }) {
        if (changes['charData']) {
            this.localAmount = this.localCurrencySymbol = this.divAmount = this.divCurrencySymbol = null;

            if (this.charData) {
                if (_.some(this.charData)) {
                    if (!_.isEmpty(this.charData[0].value)) {
                        let moneyValue = <ICharDataMoneyValue>JsonUtil.Parse(this.charData[0].value);
                        if (moneyValue) {
                            this.localAmount = moneyValue.locAmt;
                            this.localCurrencySymbol = moneyValue.locSym;
                            this.localCurrencyID = moneyValue.locCur;
                            this.divAmount = moneyValue.divAmt;
                            this.divCurrencySymbol = moneyValue.divSym;
                            this.divCurrencyID = moneyValue.divCur;
                        }
                    }
                }
            }
        }
    }

    textValueChanged(value: string) {
        this.localAmount = value;
        this.currencyChanged(null);
    }

    currencyChanged(lov: ICharacteristicMetaDataValue) {

        if (lov) {
            this.localCurrencySymbol = lov.characteristicValueLabel;
            this.localCurrencyID = lov ? lov.characteristicValueID : null;
        }

        //Do the currency conversion
        var cleanCurrencyAmount = parseFloat(this.localAmount.replace(/[^0-9\.]/g, ""));

        this.adminService.convertCurrency(this.localCurrencySymbol, cleanCurrencyAmount)
            .subscribe(result => {
                var lov = _.find(this.validCurrencyListOfValues, lov => lov.characteristicValueLabel == result.symbol);
                this.divCurrencyID = lov ? lov.characteristicValueID : null;
                this.divAmount = result.amount.toFixed(2);
                this.divCurrencySymbol = result.symbol;

                //Update the char data
                let charData: ICharacteristicData[] = [];
                if (!_.isEmpty(this.divAmount)) {

                    let moneyValue: ICharDataMoneyValue = null;
                    let cd: ICharacteristicData = null;

                    if (_.some(this.charData)) {
                        cd = _.clone(this.charData[0]);
                        moneyValue = <ICharDataMoneyValue>JsonUtil.Parse(cd.value);
                    } else {
                        cd = this.createCharData("");
                        moneyValue = {};
                    }

                    moneyValue.locAmt = this.localAmount;
                    moneyValue.locSym = this.localCurrencySymbol;
                    moneyValue.locCur = this.localCurrencyID;
                    moneyValue.divAmt = this.divAmount;
                    moneyValue.divSym = this.divCurrencySymbol;
                    moneyValue.divCur = this.divCurrencyID;

                    cd.value = JsonUtil.Stringify(moneyValue);
                    charData = [cd];
                }
                this.charDataChange.emit({ cmd: this.cmd, charData: charData });

                this.cd.markForCheck();
            });
    }
}