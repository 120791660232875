import { UntypedFormControl, Validators } from "@angular/forms";
import * as _ from "lodash";
export class FormValidators {
    static EMAIL_REGEXP = /^[a-z0-9!#$%&'*+\/=?^_`{|}~.-]+@[a-z0-9]([a-z0-9-]*[a-z0-9])?(\.[a-z0-9]([a-z0-9-]*[a-z0-9])?)*$/i;

    static PW_NUM = /\d/;
    static PW_UC = /[A-Z]/;
    static PW_LC = /[a-z]/;
    static PW_SC = /[^0-9a-zA-Z]/;

    static validEmail(c: UntypedFormControl): { [key: string]: boolean } {
        if (c.value && !FormValidators.EMAIL_REGEXP.test(c.value)) {
            return { "invalidEmail": true };
        }
    }

    static validPassword(c: UntypedFormControl): { [key: string]: boolean } {
        let output: { [key: string]: boolean } = {};
        if (c.value) {
            let value = <string>c.value;
            if (value.length < 8) {
                output["invalidPasswordLength"] = true;
            }
            if (!FormValidators.PW_NUM.test(value)) {
                output["invalidPasswordNumber"] = true;
            }
            if (!FormValidators.PW_UC.test(value)) {
                output["invalidPasswordUpperCase"] = true;
            }
            if (!FormValidators.PW_LC.test(value)) {
                output["invalidPasswordLowerCase"] = true;
            }
            if (!FormValidators.PW_SC.test(value)) {
                output["invalidPasswordSpecialCharacter"] = true;
            }
        }
        if (!_.isEmpty(output)) {
            output["invalidPassword"] = true;
            return output;
        }
        return null;
    }
}