import { Component, Inject, OnDestroy, OnInit } from "@angular/core";
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from '@angular/router';

import { JwtHelperService } from '@auth0/angular-jwt';

import { ProgressService } from "../../../common/services/progress.service";

import { IAppOption, IPartnerPortalSettings, JwtTokenValue, TokenScope } from "app/common/models";
import { JwtTokenResponse } from "app/common/services/authentication.models";
import { SessionService } from "app/common/services/session.service";
import { PARTNER_PORTAL_SETTINGS } from "app/partner-portal/settings";
import { isArray } from "lodash";
import { Subscription } from "rxjs";
import { tap } from "rxjs/operators";
import { FormValidators } from "../../../common/utils/form-validators";
import { PPLinkHelperService } from "../../services/pp-link-helper.service";

@Component({
    selector: "login",
    templateUrl: "./login.component.html"
})
export class LoginComponent implements OnInit, OnDestroy {
    ssoAppUrlID: number;

    showSSO: boolean;
    ssoEndpoint: string;
    ssoAuthNRequest: string;

    title: string;
    loginForm: UntypedFormGroup;
    chooseApp: UntypedFormControl;
    update: boolean = false;
    invalidLogin: boolean = false;
    showChooseApp: boolean = false;
    chooseApps: IAppOption[];
    typeAheadSelectedApp: IAppOption;
    initialLoad = true;
    showLogin = false;
    showError = false;

    chooseAppJwt: string;
    private _subs: Subscription[] = [];


    get email() {
        return this.loginForm.controls["email"];
    };

    get password() {
        return this.loginForm.controls["password"];
    };

    constructor(
        private fb: UntypedFormBuilder,
        private progressSerice: ProgressService,
        private sessionService: SessionService,
        private router: Router,
        private activatedRoute: ActivatedRoute,
        public linkHelper: PPLinkHelperService,
        private jwtHelperService: JwtHelperService,
        @Inject(PARTNER_PORTAL_SETTINGS) private partnerPortalSettings: IPartnerPortalSettings) {

        this.title = this.partnerPortalSettings.title;

        this.loginForm = this.fb.group({
            email: ["", Validators.compose([Validators.required, FormValidators.validEmail])],
            password: ["", Validators.required]
        });

    }
    ngOnDestroy(): void {
        this._subs.forEach(s => s.unsubscribe());
    }

    ngOnInit() {
        this.update = this.activatedRoute.snapshot.data["update"] == "true";
        this.chooseApp = this.fb.control(null);
        this.initialLoad = true;
        this.showSSO = false;
    }

    next() {
        this.showError = false;
        this.getLoginOptions();
        this.loginForm.controls["email"].markAsPristine;
    }

    getLoginOptions() {
        let email = this.email.value;
        this.sessionService.getLoginOptions(email).pipe(tap((result) => {
            if (!result.showSSO && !result.showPassword) {
                this.showError = true;
            }
            else {
                this.showSSO = result.showSSO;
                this.ssoEndpoint = result.ssoEndpoint;
                this.ssoAuthNRequest = result.ssoAuthNRequest;
                this.ssoAppUrlID = result.ssoAppUrlID;
                this.initialLoad = false;
                this.showLogin = result.showPassword;
            }

        })).subscribe();
    }

    async onSubmit() {
        let email = this.email.value;
        let password = this.password.value;

        this.progressSerice.startProgress();
        try {
            const loginResult = await this.sessionService.login(email, password).toPromise();
            this.invalidLogin = false;
            this.handleLoginResult(loginResult);
            const decodeToken: JwtTokenValue = this.jwtHelperService.decodeToken(loginResult.access_token);
            if (decodeToken.TOKEN_SCOPE === TokenScope.ChooseApp) {
                this.chooseAppJwt = loginResult.access_token;
            } else {
                await this.sessionService.setSession(loginResult.access_token, loginResult.refresh_token).toPromise();
                if (this.update) {
                    this.router.navigate(this.linkHelper.account.myAccount());
                } else {
                    this.router.navigate(this.linkHelper.home());
                }
            }
            this.progressSerice.endProgress();
        } catch {
            this.invalidLogin = true;
            this.progressSerice.endProgress();
        }
    }

    async submitWithApp() {
        let email = this.email.value;
        let password = this.password.value;
        const appValue: IAppOption = this.chooseApp.value;
        const divId = appValue.divId;
        const appUrlId = appValue.appUrlId;
        const urlTypeId = appValue.urlTypeId;
        this.chooseApp.disable();

        try {
            const result = await this.sessionService.loginWithDivIDAppUrlID(email, password, divId, appUrlId, urlTypeId, this.chooseAppJwt).toPromise();
            this.invalidLogin = false;
            this.progressSerice.endProgress();
            await this.sessionService.setSession(result.access_token, result.refresh_token).toPromise();
            if (this.update) {
                this.router.navigate(this.linkHelper.account.myAccount());
            } else {
                this.router.navigate(this.linkHelper.home());
            }
        }
        catch {
            this.invalidLogin = true;
            this.progressSerice.endProgress();
        }
    }

    private getUserAppsFromDecodeToken(decodeToken: JwtTokenValue) {
        const apps = isArray(decodeToken.USER_APPS) ? decodeToken.USER_APPS : [decodeToken.USER_APPS];
        return apps.filter(x => !!x)
            .map((x) => {
                const parts = x.split("|");
                const appOption: IAppOption = {
                    divId: parseInt(parts[0]),
                    appUrlId: parseInt(parts[1]),
                    name: parts[2],
                    urlTypeId: parseInt(parts[3])
                };
                return appOption;
            });
    }

    handleLoginResult(result: JwtTokenResponse) {
        var accessToken = result.access_token;
        const decodeToken: JwtTokenValue = this.jwtHelperService.decodeToken(accessToken);
        var decodedToken = decodeToken.TOKEN_SCOPE === TokenScope.ChooseApp && !this.jwtHelperService.isTokenExpired(accessToken);
        if (decodedToken) {
            this.chooseApps = this.getChooseApps(accessToken);
            this.showChooseApp = true;
        }
        else {
            this.showChooseApp = false;
        }
    }

    getChooseApps(token: string) {
        const decodeToken: JwtTokenValue = this.jwtHelperService.decodeToken(token);
        var userApps = this.getUserAppsFromDecodeToken(decodeToken);
        this.sessionService.userApps = userApps;
        return userApps;
    }

    typeAheadChooseDiv() {
        if (!this.typeAheadSelectedApp) {
            return;
        }
        const divId = this.typeAheadSelectedApp.divId;
        const appUrlId = this.typeAheadSelectedApp.appUrlId;
        const urlTypeId = this.typeAheadSelectedApp.urlTypeId;
        const selected = this.chooseApps.find(x => x.divId === divId && x.appUrlId === appUrlId && urlTypeId == urlTypeId);
        this.chooseApp.patchValue(selected);
    }
}