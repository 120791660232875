<nav class="navbar navbar-expand-lg" [style.background-color]="hexColor2">
    <div class="container-fluid">
        <button class="navbar-toggler ms-auto text-white my-2" type="button"
            (click)="isMenuCollapsed = !isMenuCollapsed" data-cy="toggleNavMenu">&#9776;</button>
        <div [ngbCollapse]="isMenuCollapsed" class="collapse navbar-collapse">
            <ul class="navbar-nav w-100">
                <li *ngIf="showHome && isAuthenticated" [class.active]="isCurrentRoute('/')" class="nav-item">
                    <a href="#" class="nav-link d-block" [routerLink]="linkHelper.home()"
                        (click)="isMenuCollapsed = true" data-cy="HomeLink">Home</a>
                </li>
                <li *ngIf="showNewReleases && isAuthenticated" [class.active]="isCurrentRoute('/new-releases')"
                    class="nav-item">
                    <a href="#" class="nav-link d-block" [routerLink]="linkHelper.newReleases.list()"
                        (click)="isMenuCollapsed = true" data-cy="NewReleasesLink">New Releases</a>
                </li>
                <li *ngIf="showProjects" [class.active]="isCurrentRoute('/projects')" class="nav-item">
                    <a href="#" class="nav-link d-block" [routerLink]="linkHelper.projects.list()"
                        (click)="isMenuCollapsed = true" data-cy="ProjectsLink">Projects</a>
                </li>
                <li *ngIf="showCatalog" [class.active]="isCurrentRoute('/catalog-items')" class="nav-item">
                    <a href="#" class="nav-link d-block" [routerLink]="linkHelper.catalogItems.list()"
                        (click)="isMenuCollapsed = true" data-cy="CatalogLink">Catalog</a>
                </li>
                <li *ngIf="showDeals" [class.active]="isCurrentRoute('/deals')" class="nav-item">
                    <a href="#" class="nav-link d-block" [routerLink]="linkHelper.deals.list()"
                        (click)="isMenuCollapsed = true" data-cy="DealsLink">Deals</a>
                </li>
                <li *ngIf="showInventory" [class.active]="isCurrentRoute('/inventory')" class="nav-item">
                    <a href="#" class="nav-link d-block" [routerLink]="linkHelper.inventory.list()"
                        (click)="isMenuCollapsed = true" data-cy="InventoryLink">Inventory</a>
                </li>
                <li *ngIf="showJobs" [class.active]="isCurrentRoute('/jobs')" class="nav-item">
                    <a href="#" class="nav-link d-block" [routerLink]="linkHelper.jobs.list()"
                        (click)="isMenuCollapsed = true" data-cy="JobsLink">Jobs</a>
                </li>
                <li *ngIf="showAccounting" [class.active]="isCurrentRoute('/accounting')" class="nav-item">
                    <a href="#" class="nav-link d-block" [routerLink]="linkHelper.accounting.list()"
                        (click)="isMenuCollapsed = true" data-cy="AccountingLink">Accounting</a>
                </li>
                <li *ngIf="showFiles" [class.active]="isCurrentRoute('/files')" class="nav-item">
                    <a href="#" class="nav-link d-block" [routerLink]="linkHelper.files.list()"
                        (click)="isMenuCollapsed = true" data-cy="FilesLink">Files</a>
                </li>
                <li *ngIf="isAuthenticated && contactUsEnabled" [class.active]="isCurrentRoute('/contact-us')"
                    class="nav-item ms-md-auto">
                    <a href="#" class="nav-link d-block" [routerLink]="linkHelper.contactUs.contactUs()"
                        (click)="isMenuCollapsed = true" data-cy="ContactUsLink">Contact Us</a>
                </li>
            </ul>
        </div>
    </div>
</nav>