import { Component, EventEmitter, Input, OnInit } from "@angular/core";
import { IPreviewDocument } from "app/common/models";

@Component({
	selector: "rl-preview-document-modal",
	templateUrl: "./preview-document-modal.component.html",
	styleUrls: ["./preview-document-modal.component.scss"]
})
export class PreviewDocumentModalComponent implements OnInit {

	@Input()
	file: IPreviewDocument;

	onClose = new EventEmitter<void>();

	constructor() { }
	ngOnInit(): void {
		console.log('opening preview ', this.file.fileName)
	}

	close() {
		this.onClose.emit();
	}
}
