<div class="card my-3">
    <div class="card-body">
        <table class="table rl-table">
            <tbody>
                <tr *ngFor="let document of documents">
                    <td>
                        <a href="#" [routerLink]="linkHelper.detail(document.recordID)" data-cy="viewDocument">
                            <img *ngIf="keyArt && keyArt[document.recordID]" [src]="keyArt[document.recordID]"
                                style="max-width:100px;" />
                        </a>
                    </td>
                    <td style="vertical-align:middle;">
                        <h6 class="mb-0"><a [routerLink]="linkHelper.detail(document.recordID)"
                                [innerText]="document.title" data-cy="ViewDocumentTitle"></a></h6>
                        <div *ngFor="let key of catalogFields | keys">
                            <div *ngIf="document.characteristics[key]">
                                <strong [innerText]="catalogFields[key]"></strong>:
                                {{formatCharData(document.characteristics[key])}}
                            </div>
                        </div>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</div>