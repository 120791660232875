import { Component, SimpleChanges, ViewChild, NgZone, EventEmitter, OnInit, Input, Output } from "@angular/core";
import { ProgressService } from "../services/progress.service";
import { AdminService } from "../services/admin.service";
import { EntityService } from "../services/entity.service";
import { Subscription } from "rxjs";
import { NotifyService } from "../services/notify.service";
import { CharTypeID, TemplateGroupIDs } from '../../common/consts';
import { IDUtil } from '../../common/utils/id-util';
import { ModalComponent } from "./modal.component";
import {
	IJobState
} from "../../common/models";
import * as _ from 'lodash';
import { map, tap, flatMap } from "rxjs/operators";
import { interval ,  EMPTY as empty } from "rxjs";

@Component({
	selector: "job-progress-status",
	templateUrl: './job-progress-status.component.html'
})
export class JobProgressStatusComponent implements OnInit {
	@Input()
	jobId: string;

	@Input()
	jobTitle: string;

	@Input()
	showProgressSpinner: boolean;

	@Input()
	showProgressBar: boolean;

	@Input()
	allowJobToCancel: boolean;

	@Input()
	showModalCloseBtn: boolean;

	progressStatus: string;
	progressClass: string;
	progressPercentage: number;
	progressMessage: string;
	progressComplete: number;
	progressTotal: number;
	progressFailed: number;
	progressFailedPercentage: number;

	@ViewChild("jobProgressStatusModal", { static: true })
	jobProgressStatusModal: ModalComponent;

	@Output()
	progressDone = new EventEmitter<any>();

	@Output()
	showResultMessage = new EventEmitter<string>();

	constructor(private progressService: ProgressService,
		private adminService: AdminService) {
		this.progressClass = "progress-bar-success";
		this.progressPercentage = 0;
	}

	ngOnInit() {
		if (this.jobId != undefined) {
			this.jobProgressStatusModal.open();
			this.progressService.startProgress();
			interval(2000)
				.pipe(
					map(hasJobId => _.isEmpty(this.jobId) ? 0 : 1),
					tap(hasJobId => hasJobId),
					flatMap((hasJobId) => {
						if (hasJobId) {
							return this.ShowProgress();
						}
						else {
							return empty;
						}
					}))
				.subscribe(() => {
					this.progressService.endProgress();
				});
		}
	}

	ShowProgress() {
		this.progressService.startProgress();
		return this.adminService.getJobState(this.jobId)
			.pipe(
				tap((result) => {
					this.progressFailed = result.failed;
					this.progressStatus = result.statusDescription;
					this.progressTotal = result.total;
					this.progressMessage = result.progressMessage;
					this.showProgressSpinner = true;

					this.progressPercentage = (this.progressComplete / this.progressTotal) * 100;
					this.progressFailedPercentage = (this.progressFailed / this.progressTotal) * 100;
					if (this.progressFailed > 0 || result.status === "Failed") {
						this.progressClass = "progress-bar-warning";
					}

					if (result.status === "Done" || result.status === "Failed" || result.status === "Cancelled") {
					this.showResultMessage.emit(result.resultMessage);
						this.jobId = undefined;
						this.progressDone.emit(null);
						this.progressService.endProgress();
						this.jobProgressStatusModal.close();
					}
				}));

	}

	cancelJob() {
		this.adminService.cancelJob(this.jobId)
			.subscribe((res) => { });
	}
}