import { Injectable, Inject } from '@angular/core';
import { SessionService } from "./session.service";
import { IEntitySearchDoc, ICharacteristicMetaDataCollection, ICharLovMetaData, IBasePortalSettings } from "../models";
import * as _ from "lodash";
import { COMMON_SETTINGS } from 'app/common/settings';
import { refCount, publishReplay } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { PPCacheHttpService, PPCacheHttpOptions } from 'app/common/services/pp-cache-http.service';
import { duration } from 'moment';

@Injectable()
export class TemplateService {

    private _baseUrl = "/service-api/template";

    constructor(private ppCacheHttpService: PPCacheHttpService, private sessionService: SessionService, @Inject(COMMON_SETTINGS) private settings: IBasePortalSettings) {

    }

    getTemplateMetaData(id: string) {
        var cacheOptions: PPCacheHttpOptions = {
            key: `getTemplateMetaData_${id}`,
            expiresIn: duration(10, "minute")
        };
        return this.ppCacheHttpService.get<ICharacteristicMetaDataCollection>(`${this._baseUrl}/${id}`, null, cacheOptions);
    }

    getLovMetaData(charValueSourceID: string) {
        var cacheOptions: PPCacheHttpOptions = {
            key: `getLovMetaData_${charValueSourceID}`,
            expiresIn: duration(10, "minute")
        };
        if (!this.sessionService.isValid) {
            cacheOptions.key = `${this.settings.clientID}_${cacheOptions.key}`;
        }
        let data = {};
        if (!this.sessionService.isValid) {
            data["clientID"] = this.settings.clientID;
        }
        return this.ppCacheHttpService.get<ICharLovMetaData>(`${this._baseUrl}/get-lov-meta-data/${charValueSourceID}`, data, cacheOptions);
    }
}
