import {Pipe, PipeTransform} from "@angular/core";
import * as _ from "lodash";

@Pipe({
    name: "hasValues",
    pure: true
})
export class HasValuesPipe implements PipeTransform {

    transform(value: any[], args: string[]): boolean {
        return value != null && _.isArray(value) && !_.isEmpty(value);
    }
}