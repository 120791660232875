import {Component, ChangeDetectionStrategy, OnChanges, SimpleChange, DoCheck} from "@angular/core";
import * as _ from "lodash";

import {BaseElement} from "./base-element";
import {ICharacteristicData} from "../../../models";

@Component({
    selector: "date-display-element",
    template: "<date-display-control [dateValue]='dateValue'></date-display-control>",
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class DateDisplayElementComponent extends BaseElement implements OnChanges {

    dateValue: string;

    constructor() {
        super();
    }

    ngOnChanges(changes: { [key: string]: SimpleChange; }) {
        if (this.charData) {
            this.dateValue = _.first(this.charData.map(cd => cd.value));
        }
    }
}