import {Component, Input, ChangeDetectionStrategy, OnChanges, SimpleChange} from "@angular/core";
import { ComponentChanges } from "app/common/models";
import * as _ from "lodash";
import { ErrorMessageUtil } from "./element-error-messages.model";
@Component({
    selector: "element-error-messages",
    templateUrl: "./element-error-messages.component.html",
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ElementErrorMessagesComponent implements OnChanges {

    @Input()
    errors: { [key: string]: any }
    errorMessages: string[] = [];
    messages: string[] = [];
    public get hasErrors() {
        return _.some(this.errorMessages);
    }

		@Input()
		warnings: { [key: string]: any };
		warningMessages: string[] = [];
		public get hasWarnings() {
			return _.some(this.warningMessages);
		}

    constructor() {
    }

		ngOnChanges(changes: ComponentChanges<ElementErrorMessagesComponent>) {
			if (changes.errors) {
				this.errorMessages = this.mapMessages(this.errors, ErrorMessageUtil.errorsLookup);
			}
			if (changes.warnings) {
				this.warningMessages = this.mapMessages(this.warnings, ErrorMessageUtil.warningsLookup);
			}
		}

		private mapMessages(errors: { [key: string]: any }, lookup: { [key: string]: string }) {
			return (errors && Object.keys(errors) || [])
				.filter(key => {
					if (!(key in lookup)) {
						console.warn(`'${key}' is missing from error/warning lookup.`);
					}
					return errors[key] === true && key in lookup;
				}).map(key => lookup[key]);
		}
}