
import {filter} from 'rxjs/operators';
import { Component, OnInit, OnDestroy } from "@angular/core";
import { UntypedFormGroup, UntypedFormBuilder, NgModel } from "@angular/forms";
import { Router, ActivatedRoute } from "@angular/router";

import { Observable, Subject, Subscription } from "rxjs";

import { ProgressService } from "../../../common/services/progress.service";
import { IEntitySearchDoc } from "../../../common/models";
import { PPLinkHelperService } from "../../services/pp-link-helper.service";
import { PartnerPortalService } from "../../services/partner-portal.service";
import { ExplorerView } from "../../../common/consts";

import * as _ from "lodash";

@Component({
	selector: "jobs-list",
	templateUrl: "./jobs-list.component.html",
})
export class JobsListComponent implements OnInit, OnDestroy {

	routeSub: Subscription;

	templateFacets: { name: string; id: number; }[];
	searchForm: UntypedFormGroup;

	public documents: IEntitySearchDoc[];
	pageCount = 0;
	pageSize = 25;
	page = 1;
	rows = 25;

	templateID: string = "";
	keywords: string = "";
	view: ExplorerView = ExplorerView.List;

	public get start(): number {
		return (this.page - 1) * this.pageSize;
	}

	constructor(
		private partnerPortalService: PartnerPortalService,
		private progressService: ProgressService,
		private activatedRoute: ActivatedRoute,
		private router: Router,
		private fb: UntypedFormBuilder,
		public linkHelper: PPLinkHelperService) {
		this.searchForm = fb.group({
			"keywords": [this.keywords],
			"template": [this.templateID]
		});
	}

	ngOnInit() {
		this.routeSub = this.activatedRoute.parent
			.params
			.subscribe(params => {
				this.keywords = params["k"] || "";
				this.templateID = params["t"] || "";
				this.page = +params["p"] || 1;
				this.search();
			});

		this.searchForm.controls["template"]
			.valueChanges.pipe(
			filter((value) => value != this.templateID))
			.subscribe((value) => {
				this.page = 1;
				this.nextSearch();
			});
	}

	ngOnDestroy() {
		this.routeSub.unsubscribe();
	}

	search() {
		let start = this.rows * (this.page - 1);
		this.progressService.startProgress();
		return this.partnerPortalService.getAssociatedJobs(this.keywords, _.parseInt(this.templateID), this.start, this.pageSize)
			.subscribe((r) => {
				this.documents = r.documents;
				this.pageCount = Math.ceil(r.numFound / this.pageSize);
				this.templateFacets = r.templateFacets.map((kvp) => {
					let parts = kvp.key.split("|");
					return {
						name: `${parts[0]} (${kvp.value})`,
						id: _.parseInt(parts[1])
					};
				});
				this.progressService.endProgress();
			});
	}

	nextSearch() {
		let keywords = this.searchForm.controls["keywords"].value || "";
		let templateID = this.searchForm.controls["template"].value || "";
		this.router.navigate(this.linkHelper.jobs.list({ k: keywords, p: this.page, t: templateID }));
	}

	optionTrackBy(value: { name: string; id: number; }) {
		return value.id;
	}

	pageChanged(page: number) {
		this.page = page;
		this.nextSearch();
	}

	submitSearch() {
		this.page = 1;
		this.nextSearch();
	}

	viewChanged(view: ExplorerView) {
		this.view = view;
	}
}