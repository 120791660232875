import { Component, ChangeDetectionStrategy, OnChanges, SimpleChange, DoCheck, OnInit, ChangeDetectorRef } from "@angular/core";
import * as _ from "lodash";

import {BaseElement} from "./base-element";
import { ICharacteristicData, ICharacteristicMetaDataValue } from "../../../models";
import { TemplateService } from "app/common/services/template.service";
import { map } from "rxjs/operators";

@Component({
    selector: "checkbox-edit-element",
    template: "<checkbox-edit-control *ngIf='lovs!=null' [tristate]='tristate' [state]='state' [yesLabel]='yesLov?.characteristicValueLabel' [noLabel]='noLov?.characteristicValueLabel' (checkboxStateChange)='checkboxStateChange($event)' (touch)='touched($event)' ></checkbox-edit-control>",
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class CheckboxEditElementComponent extends BaseElement implements OnChanges, OnInit {

    lovs: ICharacteristicMetaDataValue[];

    state: number;
    tristate: boolean;

    yesLov: ICharacteristicMetaDataValue;
    noLov: ICharacteristicMetaDataValue;

    intialized: boolean = false;

    constructor(private templateService: TemplateService, private cdRef: ChangeDetectorRef) {
        super();
    }

    ngOnInit(): void {
        this.templateService.getLovMetaData(this.cmd.charValueSourceID)
            .pipe(map((result) => result.listOfValues))
            .subscribe((result) => {
                this.lovs = result;

                this.yesLov = _.find(this.lovs, (lov) => lov.characteristicValueID == 1);
                this.noLov = _.find(this.lovs, (lov) => lov.characteristicValueID == 2);
                this.tristate = this.cmd.requiredIndicator == 0 && (this.yesLov != null && this.noLov != null);
                this.intialized = true;
                this.state = this.charDataToState(_.first(this.charData));

                this.cdRef.markForCheck();
            });

    }

    ngOnChanges(changes: { [key: string]: SimpleChange; }) {
        if (changes["charData"] && this.intialized) {
            this.state = this.charDataToState(_.first(this.charData));
        }
    }

    checkboxStateChange(state: number) {
        var value = "";
        var valueID: number = null;
        if (state == 1) {
            value = this.yesLov.characteristicValueLabel;
            valueID = this.yesLov.characteristicValueID;
        } else if (state == -1) {
            value = this.noLov.characteristicValueLabel;
            valueID = this.noLov.characteristicValueID;
        }

        let charData: ICharacteristicData = null;
        if (_.some(this.charData)) {
            charData = _.clone(this.charData[0]);
            charData.value = value;
            charData.valueID = valueID;
        } else {
            charData = this.createCharData(value, valueID);
        }
        this.charDataChange.emit({ cmd: this.cmd, charData: [charData] });

        this.cdRef.markForCheck();
    }

    charDataToState(cd: ICharacteristicData) {
        if (this.noLov != null) {
            if (cd == null) {
                return 0;
            } else if (cd.valueID == this.yesLov.characteristicValueID) {
                return 1;
            } else if (cd.valueID == this.noLov.characteristicValueID) {
                return -1;
            } else if (this.tristate) {
                return 0;
            } else {
                return -1;
            }
        } else {
            if (cd == null) {
                return 0;
            } else if (cd.valueID == this.yesLov.characteristicValueID) {
                return 1;
            } else {
                return 0;
            }
        }
    };


}