import {Component, Input, Output, EventEmitter, Optional} from "@angular/core";

import {ICharacteristicMetaDataValue, ITreeListChangeEvent} from "../../../models";

@Component({
    selector: "tree-list-edit-control",
    templateUrl: "./tree-list-edit-control.component.html"
})
export class TreeListEditControlComponent {

    @Input()
    lovs: ICharacteristicMetaDataValue[];

    @Input()
    selectedValueIDs: number[];

    @Output()
    selectedValueIDsChange = new EventEmitter<ITreeListChangeEvent>();

    @Input()
    singleMode: boolean;

    constructor() {

    }

}