import * as _ from "lodash";
import { Injectable } from "@angular/core";

// normalize url params, add / to first param
let norm = (...params: any[]) => {
  return params.filter(p => p != null && p != "").map((p, i) => i == 0 ? "/" + p : p);
}

export interface EntityLinks {
  detail(id: number);
  list(params: any);
}

@Injectable()
export class PPLinkHelperService {

  account = new AccountLinks();
  catalogItems = new CatalogItems();
  projects = new Projects();
  newReleases = new NewReleases();
  deals = new Deals();
  jobs = new Jobs();
  accounting = new Accounting();
  inventory = new Inventory();
  files = new Files();
  contactUs = new ContactUsLink();
  home() {
    return [""];
  }
}

class AccountLinks {

  private _account = "account";
  private _login = "login";
  private _myAccount = "my-account";
  private _claimPasswordReset = "claim-password-reset";
  private _update = "update";
  private _forgotPassword = "forgot-password";
  private _config = "config";
  login(update?: boolean) {
    return norm(this._account, this._login, update ? this._update : null);
  }

  myAccount() {
    return norm(this._account, this._myAccount);
  }

  claimPasswordReset(token: string) {
    return norm(this._account, this._claimPasswordReset, token);
  }

  forgotPassword() {
    return norm(this._account, this._forgotPassword);
  }

  config() {
    return norm(this._account, this._config);
  }
}

class ContactUsLink {
  private _contactUs = "contact-us";

  contactUs() {
    return norm(this._contactUs);
  }
}

class CatalogItems implements EntityLinks {
  private _catalogItems = "catalog-items";
  private _newReleases = "new-releases";

  list(params?: {}) {
    return norm(this._catalogItems, params);
  }

  detail(id: number) {
    return norm(this._catalogItems, id);
  }
}


class Projects implements EntityLinks {
  private _projects = "projects";

  list(params?: {}) {
    return norm(this._projects, params);
  }

  detail(id: number) {
    return norm(this._projects, id);
  }
}

class NewReleases implements EntityLinks {
  private _newReleases = "new-releases";

  list(params?: {}) {
    return norm(this._newReleases, params);
  }

  detail(id: number) {

  }
}

class Deals implements EntityLinks {
  private _deals = "deals";

  list(params?: {}) {
    return norm(this._deals, params);
  }

  detail(id: number) {
    return norm(this._deals, id);
  }
}

class Jobs implements EntityLinks {
  private _jobs = "jobs";

  list(params?: {}) {
    return norm(this._jobs, params);
  }

  detail(id: number) {
    return norm(this._jobs, id);
  }
}

class Accounting implements EntityLinks {
  private _accounting = "accounting";

  list(params?: {}) {
    return norm(this._accounting, params);
  }

  detail(id: number) {
    return norm(this._accounting, id);
  }
}


class Inventory implements EntityLinks {
  private _inventory = "inventory";

  list(params?: {}) {
    return norm(this._inventory, params);
  }

  detail(id: number) {
    return norm(this._inventory, id);
  }
}

class Files implements EntityLinks {
  private _files = "files";

  list(params?: {}) {
    return norm(this._files, params);
  }

  detail(id: number) {
    return norm(this._files, id);
  }

  deal(id: number) {
    return norm("deals", id);
  }

  downloadURL(url: string) {
    return norm(url);
  }
}
