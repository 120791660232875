import { Component, Input, OnInit } from '@angular/core';
import { IContactPartyGroup, IContactsDictionaries } from 'app/common/messages';

@Component({
	selector: 'app-contact-group',
	templateUrl: './contact-group.component.html',
	styleUrls: ['./contact-group.component.less']
})
export class ContactGroupComponent implements OnInit {

	@Input()
	contactPartyGroup: IContactPartyGroup;

	@Input()
	dictionaries: IContactsDictionaries;

	@Input()
	expandIndicator = 1;

	isExpanded = true;
	showLoading = null;

	constructor() { }

	ngOnInit() {
		this.isExpanded = this.expandIndicator !== 0;
		if (!this.dictionaries.partyGroups[this.contactPartyGroup.groupId] && !this.dictionaries.partyGroups[this.contactPartyGroup.groupId]?.groupLabel) {
			this.isExpanded = true;
		}
	}

	toggle() {
		this.isExpanded = !this.isExpanded;
	}
}

