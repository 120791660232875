import { Input, Component, OnChanges, SimpleChanges, Inject } from "@angular/core";
import * as _ from "lodash";

import { ProgressService } from "../../../common/services/progress.service";
import { PartnerPortalService } from "../../services/partner-portal.service";

import { IEntitySearchDoc, IPartnerPortalSettings } from "../../../common/models";
import { PPLinkHelperService } from "../../services/pp-link-helper.service";
import { PARTNER_PORTAL_SETTINGS } from "app/partner-portal/settings";

@Component({
    selector: "catalog-items-related-items",
    templateUrl: "./catalog-items-related-items.component.html"
})
export class CatalogItemsRelatedItemsComponent implements OnChanges {
    @Input()
    assetID: number;

    documents: IEntitySearchDoc[];
    keyArt: _.Dictionary<string>;

    page: number = 1;
    pageCount: number = 1;
    rows: number = 8;

    displayTitleField: string;

    constructor(
        private partnerPortalService: PartnerPortalService,
        private progressService: ProgressService,
        private linkHelper: PPLinkHelperService,
        @Inject(PARTNER_PORTAL_SETTINGS) private settings: IPartnerPortalSettings) {

        if (this.settings.partnerPortal != null) {
            if (this.settings.partnerPortal.displayTitleField != null) {
                this.displayTitleField = this.settings.partnerPortal.displayTitleField;
            }
        }
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes["assetID"]) {
            this.loadRelatedItems();
        }
    }

    loadRelatedItems() {
        let start = this.rows * (this.page - 1);
        this.progressService.startProgress();

        this.partnerPortalService.getRelatedCatalogItems(this.assetID, start, this.rows)
            .subscribe((results) => {
                this.documents = results.documents;
                this.keyArt = results.keyArt;

                this.pageCount = Math.ceil(results.numFound / this.rows);
                this.progressService.endProgress();
            });
    }

    pageChanged(page: number) {
        this.page = page;
        this.loadRelatedItems();
    }

    getTitleString(doc: IEntitySearchDoc) {
        if (!_.isNull(this.displayTitleField)) {
            if (_.isEmpty(doc.characteristics[this.displayTitleField])) {
                return doc.title;
            }
            else {
                return doc.characteristics[this.displayTitleField];
            }
        }
        else {
            return doc.title;
        }
    }
}