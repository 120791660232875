import { Component, Input, OnInit } from '@angular/core';
import { IEntityCharDataPair } from 'app/common/messages';
import { find } from "lodash";

@Component({
	selector: 'app-contact',
	templateUrl: './contact.component.html',
	styleUrls: ['./contact.component.less']
})
export class ContactComponent implements OnInit {
	@Input()
	entityCharDataPair: IEntityCharDataPair;

	@Input()
	parentEntityId: string;

	@Input()
	partyId: number;

	@Input()
	groupId: number;

	contactName: string;

	constructor() { }

	ngOnInit(): void {
		this.contactName = this.getCharValue("entity_title");
	}

	getCharValue(tagLabel: string) {
		const matchingCmd =
			find(this.entityCharDataPair.characteristics.templateMetaData.characteristicMetaDatas, cmd => cmd.tagLabel === tagLabel);
		const matchingCharData = find(this.entityCharDataPair.characteristics.characteristicDatas,
			charData => matchingCmd && charData.charactersticID === matchingCmd.characteristicID);
		return matchingCharData && matchingCharData.value;
	}

}
