import { EventEmitter, Injectable } from "@angular/core";
import { PreviewDocumentModalComponent } from "../components/files/preview-document-modal/preview-document-modal.component";
import { ModalBuilder } from "../components/modal-builder/modal-builder";
import { IPreviewDocument } from "../models";
@Injectable()
export class ModalService {

	modalOpen = new EventEmitter<any>();
	modalClose = new EventEmitter<any>();

	constructor(
		private readonly _modalBuilder: ModalBuilder
	) {

	}

	previewDocument(file: IPreviewDocument) {
		return this._modalBuilder
			.build<PreviewDocumentModalComponent>(PreviewDocumentModalComponent)
			.open(component => {
				component.file = file;
				return component.onClose;
			});
	}
}
