export const enum CharDataType {
	FourDigitYear = 1,
	InternetAddress = 2,
	Alphanumeric = 3,
	Number = 4,
	Date = 5,
	Email = 6,
	Currency = 7,
	Percentage = 8,
	Password = 9,
	SMPTETimeCode = 10,
	ExternalDocument = 12,
	Checkbox = 13,
	Money = 14
}

export enum CharTypeID {
	Relationship = 0,
	Property = 1,
	User = 2,
	Right = 3,
	Transaction = 4,
	Usage = 5,
	Invoice = 7,
	Amount = 10,
	Royalty = 13,
	Document = 14,
	Job = 15,
	Project = 16,
	Inventory = 17
}

export const systemIndicatorIDs = {
	characteristics: {
		balance: 10
	}
}

export namespace AccessRight {
	export var None = 0;
	export var Read = 1 << 0;
	export var Write = 1 << 1;
	export var Create = 1 << 2;
	export var Delete = 1 << 3;
	export var Admin = 1 << 4;
	export var Restrict = 1 << 5;
	export var All = Read | Write | Create | Delete | Admin;
	export var FlagArray = [Read, Write, Create, Delete, Admin, Restrict];
}

export namespace AccessResult {
	export var NotSet = 0;
	export var Allow = 1;
	export var Deny = -1;
}

export namespace Acl {
	export var ValueParam = "value";

	export var AclProtocol = "acl";

	var activityHost = AclProtocol + "://activity"
	var dataHost = AclProtocol + "://data";
	var entities = "/entities";

	export var Data = {
		entities: dataHost + entities
	};
}
export const enum TemplateGroupIDs {
	DocumentFileTemplateGroupID = 1,
	DocumentFolderTemplateGroupID = 3
}

export enum ExplorerView {
	Grid,
	Tile,
	List,
	FileList
}

export namespace ExecutableExtensions {
	export var Types: string[] = ["action", "apk", "app", "bat", "bin", "cmd", "com", "command", "cpl", "csh", "exe", "gadget", "inf1", "ins", "inx", "ipa", "isu", "job", "jse", "ksh", "lnk", "msc", "msi", "msp", "mst", "osx", "out", "paf", "pif", "prg", "ps1", "reg", "rgs", "run", "scr", "sct", "shb", "shs", "u3p", "vb", "vbe", "vbs", "vbscript", "workflow", "ws", "wsf", "wsh", "0xe", "73k", "89k", "a6p", "ac", "acc", "acr", "actm", "ahk", "air", "app", "arscript", "as", "asb", "awk", "azw2", "beam", "btm", "cel", "celx", "chm", "cof", "crt", "dek", "dld", "dmc", "docm", "dotm", "dxl", "ear", "ebm", "ebs", "ebs2", "ecf", "eham", "elf", "es", "ex4", "exopc", "ezs", "fas", "fky", "fpi", "frs", "fxp", "gs", "ham", "hms", "hpf", "hta", "iim", "ipf", "isp", "jar", "js", "jsx", "kix", "lo", "ls", "mam", "mcr", "mel", "mpx", "mrc", "ms", "ms", "mxe", "nexe", "obs", "ore", "otm", "pex", "plx", "potm", "ppam", "ppsm", "pptm", "prc", "pvd", "pwc", "pyc", "pyo", "qpx", "rbx", "rox", "rpj", "s2a", "sbs", "sca", "scar", "scb", "script", "smm", "spr", "tcp", "thm", "tlb", "tms", "udf", "upx", "url", "vlx", "vpm", "wcm", "widget", "wiz", "wpk", "wpm", "xap", "xbap", "xlam", "xlm", "xlsm", "xltm", "xqt", "xys", "zl9"];
}

export enum CharTypeDisplay {
	'Catalog Items' = 1,
	'Jobs' = 15,
	'Deals' = 4,
	'Accounting' = 14,
	'Projects' = 16,
}

export const enum SortDirection {
	ASC = 0,
	DESC = 1
}

export enum AclHosts {
	Activity = "acl://activity"
}

export namespace Activities {
	export const Admin = `${AclHosts.Activity}/admin`;
}

export const Configuration = `${Activities.Admin}/config`;