import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { CharTypeID, TemplateGroupIDs } from 'app/common/consts';
import { ICharacteristicData, ICharacteristicMetaDataCollection, ICharacteristicTemplate } from 'app/common/models';
import { AclService } from 'app/common/services/acl.service';
import { DocumentEntityService } from 'app/common/services/document-entity.service';
import { EntityService } from 'app/common/services/entity.service';
import { ModalService } from 'app/common/services/modal.service';
import { ProgressService } from 'app/common/services/progress.service';
import { TemplateService } from 'app/common/services/template.service';
import { AclUtil } from 'app/common/utils/acl.util';
import { PartnerPortalService } from 'app/partner-portal/services/partner-portal.service';
import * as _ from 'lodash';
import { ModalComponent } from '../../modal.component';
import { NewFileWizardModalComponent } from './new-file-wizard/new-file-wizard.component';

@Component({
  selector: 'explorer-upload-file-button',
  templateUrl: './explorer-upload-file-button.component.html'
})
export class ExplorerUploadFileButtonComponent implements OnInit {


  @Input()
  includeHeading: boolean = false;

  @Output()
  fileUploaded: EventEmitter<boolean> = new EventEmitter<boolean>();

  associatedDeals: _.Dictionary<string>;

  divId: number;
  startJobProgress: boolean;
  jobId: string;
  jobTitle: string;
  uploadFolderOrFileToEntityId: string;
  uploadFileFolderModalTitle: string;
  documentFileTemplateGroupID: number = TemplateGroupIDs.DocumentFolderTemplateGroupID;
  documentFolderTemplateGroupID: number = TemplateGroupIDs.DocumentFolderTemplateGroupID;
  uploadCharMetaDataCollection: ICharacteristicMetaDataCollection;

  uploadCharData: ICharacteristicData[];
  documentTemplates: ICharacteristicTemplate[];
  uploadDocumentTemplate: ICharacteristicTemplate;

  uploadTemplateID: number;
  entityForEdit: string;

  canWrite: boolean = false;
  onLastPage = false;
  uploading = false;

  @ViewChild('editDocumentEntityModal', { static: true })
  editDocumentEntityModal: ModalComponent;

  @ViewChild('wizardModal')
  wizardModal: NewFileWizardModalComponent;

  constructor(private readonly _modalService: ModalService,
    private readonly _entityService: EntityService,
    private readonly _documentEntityService: DocumentEntityService,
    private readonly _templateService: TemplateService,
    private readonly _progressService: ProgressService,
    private readonly _partnerPortalService: PartnerPortalService,
    private readonly _aclService: AclService,
    private cd: ChangeDetectorRef) { }

  ngOnInit(): void {
    const documentCharTypeACL = AclUtil.getDataEntityAcl(CharTypeID.Document);
    if (this._aclService.acls) {
      this.canWrite = AclUtil.hasWriteAccess(this._aclService.acls, documentCharTypeACL);
      this._entityService.getCreateTemplates(CharTypeID.Document)
        .subscribe(response => {
          this.documentTemplates = response.templates;
          this.divId = response.divId;
        });
    }
    else {
      this.canWrite = false;
    }
  }

  openFileUploadModal(template: ICharacteristicTemplate, $event: Event) {
    $event.preventDefault();
    this.uploadTemplateID = template.templateID;
    this.uploadFileFolderModalTitle = `Create New ${template.templateName} Record`;
    this.uploadDocumentTemplate = template;
    this.cd.detectChanges();
    this.wizardModal?.detectChanges();
    this.editDocumentEntityModal.open();
  }
  closeFileUploadModal($event: Event) {
    this.uploadTemplateID = undefined;
    this.uploadDocumentTemplate = undefined;
    $event?.preventDefault();
    this.editDocumentEntityModal.close();
  }
  fileSaved() {
    this.fileUploaded.emit(true);
  }
}
