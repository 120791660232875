<ng-container *ngFor="let singleCharData of editedCharData; index as i; trackBy:trackByFn; last as last">
	<text-edit-control [textValue]='singleCharData.value' (textValueChange)='textValueChanged($event, singleCharData)'
		[isTextArea]='cmd?.maxLength>255' [maxLength]='cmd?.maxLength' [placeholder]="last | func:getPlaceholder"
		(touch)='touched($event)' [updateOn]="'keyUp'" (ngModelChange)="change($event)">
		<append-with>
			<button *ngIf="!last && canDelete" class="btn btn-remove" tabindex="-1" (click)="removeCharData(i)">
				<i class="fa fa-times"></i>
			</button>
		</append-with>
	</text-edit-control>
</ng-container>